import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Field, FieldArray } from "formik";
import { Service, ServiceTitle, ServiceTypeTitle } from "./components/Service";
import {
  InputText,
  InputValidation,
  InputDate,
  InputCheckbox,
  InputRow,
  InputColumn
} from "../../../components/Inputs";
import {
  SelectClient,
  SelectWork,
  SelectCompany,
  SelectUser
} from "../../../components/Selects";
import * as client_api from "../../../services/api/client";
import * as admin_api from "../../../services/api/admin";
import { HeaderTitle } from "../../../components/Headers";
import useFlags from "src/hooks/useFlags";

const ContractForm: React.FC<FormikProps<ContractValuesProps>> = ({
  errors,
  values,
  setFieldValue
}) => {
  const { isMatech } = useFlags();
  const [, setClient] = useState();
  const [subDepartments, setSubDepartments] = useState<
    SubDepartmentFormValues[]
  >([]);

  useEffect(() => {
    admin_api.getSubDepartments().then(({ data }) => setSubDepartments(data));
  }, []);

  const renderContractInformation = () => (
    <>
      <HeaderTitle title="Dados do contrato" />
      <InputRow label="Descrição" label_col={2}>
        <Field
          component={InputText}
          name="description_title"
          required={false}
        />
      </InputRow>
      <InputRow label="Cliente" label_col={2}>
        <Field
          component={SelectClient}
          name="client"
          request={client_api.getClients}
          onChange={setClient}
          required
        />
      </InputRow>
      <InputRow label="Obra" label_col={2}>
        <Field
          component={SelectWork}
          name="work"
          client={values.client}
          isClearable={false}
          required
        />
        <InputValidation message={errors.work} />
      </InputRow>

      <Row>
        <Col>
          <InputRow label="Início" label_col={4}>
            <Field name="start_date" component={InputDate} required />
          </InputRow>
          <InputValidation message={errors.start_date} />
        </Col>

        <Col>
          <InputRow label="Fim" label_col={4}>
            <Field
              name="end_date"
              component={InputDate}
              required
              min={values.start_date}
            />
          </InputRow>
          <InputValidation message={errors.end_date} />
        </Col>
      </Row>

      <InputRow label="Responsável" label_col={2}>
        <Field name="manager" component={InputText} required />
        <InputValidation message={errors.manager} />
      </InputRow>

      <InputRow label="Empresa" label_col={2}>
        <Field
          component={SelectCompany}
          name="company"
          required
          isClearable={false}
        />
        <InputValidation message={errors.company} />
      </InputRow>
    </>
  );

  const renderSubDepartments = () => {
    const options: JSX.Element[] = [];
    subDepartments.forEach((item, index) => {
      const checkId = `services_${item.id}`;
      options.push(
        <InputCheckbox
          key={index}
          label={item.name}
          name={`${item.id}`}
          checked={!!(values as { [key: string]: any })[checkId]}
          onClick={() => {
            if (!(values as { [key: string]: any })[checkId]) {
              setFieldValue(checkId, [{}]);
            } else {
              setFieldValue(checkId, undefined);
            }
          }}
        />
      );
    });

    return (
      <>
        <HeaderTitle title="Áreas de serviço" />
        {options}
      </>
    );
  };

  const renderControlType = () => (
    <>
      <HeaderTitle title="Controle tecnológico" />
      <InputCheckbox
        label="Controle de concreto"
        name="has_concrete_control"
        checked={values.has_concrete_control}
        onClick={() =>
          setFieldValue("has_concrete_control", !values.has_concrete_control)
        }
      />
      <InputCheckbox
        label="Controle de solos"
        name="has_sample_soil_control"
        checked={values.has_sample_soil_control}
        onClick={() =>
          setFieldValue(
            "has_sample_soil_control",
            !values.has_sample_soil_control
          )
        }
      />
      <InputCheckbox
        label="Controle de graute"
        name="has_graute_control"
        checked={values.has_graute_control}
        onClick={() =>
          setFieldValue("has_graute_control", !values.has_graute_control)
        }
      />
      <InputCheckbox
        label="Controle de blocos cerâmicos"
        name="has_block_ceramic_control"
        checked={values.has_block_ceramic_control}
        onClick={() =>
          setFieldValue(
            "has_block_ceramic_control",
            !values.has_block_ceramic_control
          )
        }
      />
      <InputCheckbox
        label="Controle de blocos de concreto"
        name="has_block_concrete_control"
        checked={values.has_block_concrete_control}
        onClick={() =>
          setFieldValue(
            "has_block_concrete_control",
            !values.has_block_concrete_control
          )
        }
      />
      <InputCheckbox
        label="Controle de piso"
        name="has_floor"
        checked={values.has_floor}
        onClick={() => setFieldValue("has_floor", !values.has_floor)}
      />
    </>
  );

  const renderServices = () => {
    return subDepartments.map((subDepartment, index) => {
      return (
        <FieldArray
          key={index}
          name={`services_${subDepartment.id}`}
          render={({ insert, remove, name }) => (
            <>
              {(values as { [key: string]: any })[name] ? (
                <>
                  <ServiceTypeTitle title={subDepartment.name} />
                  {(values as { [key: string]: any })[name].map(
                    (_: any, index: number) => (
                      <Service
                        key={index}
                        name={`${name}[${index}]`}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        subDepartment={subDepartment}
                        insert={insert}
                        remove={remove}
                        index={index}
                      />
                    )
                  )}
                </>
              ) : null}
            </>
          )}
        />
      );
    });
  };

  return (
    <>
      {isMatech && (
        <Row className="mb-3">
          <Col>
            <InputColumn label="Quem pode acessar este contrato e suas medições">
              <Field name="users_managers" component={SelectUser} />
            </InputColumn>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={7}>{renderContractInformation()}</Col>
        {isMatech && <Col>{renderSubDepartments()}</Col>}
        <Col>{renderControlType()}</Col>
      </Row>
      {isMatech && (
        <>
          <hr />
          <Row>
            <Col>
              <HeaderTitle title="Serviços" />
              <ServiceTitle />
              {renderServices()}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ContractForm;
