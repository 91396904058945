import React, { useMemo, useState, useCallback } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { InputText } from "../../../components/Inputs";
import { postReport } from "src/services/api/client_side";
import { ButtonSuccess, ButtonSave } from "../../../components/Buttons";
import { toLocateNumber } from "src/utils/format";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#f58220",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

interface Props {
  contractId: number;
  shouldUpdateFiles: (value: boolean) => void;
}

const DropFileUpload: React.FC<Props> = ({ contractId, shouldUpdateFiles }) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sendedFiles, setSendedFiles] = useState(new Array(99).fill(""));
  const [notes, setNotes] = useState(new Array(99).fill(""));
  const [myFiles, setMyFiles] = useState<{ name: string; size: number }[]>([]);
  const onDrop = useCallback(
    acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      setSuccess(false);
    },
    [myFiles]
  );

  let {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: "application/pdf,.doc,.docx,.xls,.xlsx,.dwg,.kmz,.zip"
  });

  const files = myFiles.map((file, index) => {
    return (
      <Card key={file.name}>
        <CardBody>
          <Row>
            <Col sm="auto">
              <i className="mdi mdi-18px mdi-file-pdf" />
            </Col>
            <Col sm="4">{file.name}</Col>
            <Col sm="2">
              {toLocateNumber(file.size / (1024 * 1024), 2, "-", "", " MB")}
            </Col>
            <Col>
              <InputText
                name={"file"}
                required
                disabled={false}
                onChange={event => {
                  let n = notes;
                  n[index] = event.target.value;
                  setNotes(n);
                }}
              />
            </Col>
            <Col sm="auto">100%</Col>
            <Col sm="auto">
              {sendedFiles[index] === "sending" && (
                <i className="mdi mdi-spin mdi-loading" />
              )}
              {sendedFiles[index] === "success" && (
                <i className="mdi mdi-check" />
              )}
              {sendedFiles[index] === "fail" && <i className="mdi mdi-close" />}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragAccept, isDragActive, isDragReject]
  );

  const progress = () => {};

  const clearFiles = () => {
    setMyFiles([]);
    setSendedFiles(new Array(99).fill(""));
    setNotes(new Array(99).fill(""));
    setSuccess(false);
  };

  const sendFiles = () => {
    let count = 0;
    myFiles.forEach((file, index) => {
      setSubmitting(true);
      if (sendedFiles[index] !== "success") {
        sendedFiles[index] = "sending";
        let s = sendedFiles;
        setSendedFiles(s);

        let data = {
          report: file,
          notes: notes[index],
          work: contractId
        };
        postReport(contractId, data, progress)
          .then(() => {
            count += 1;
            sendedFiles[index] = "success";
            let s = sendedFiles;
            setSendedFiles(s);
            if (count === myFiles.length) {
              setSubmitting(false);
              setSuccess(true);
            }
            shouldUpdateFiles(true);
            shouldUpdateFiles(false);
          })
          .catch(() => {
            sendedFiles[index] = "fail";
            let s = sendedFiles;
            setSendedFiles(s);
          });
      }
    });
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className="dz-message needsclick text-center">
          <i className="h1 text-muted dripicons-cloud-upload" />
          <h3>Solte os arquivos ou clique para escolher.</h3>
        </div>
      </div>
      <aside>
        <h4>Arquivos</h4>
        <Card className="bg-secondary">
          <CardBody className="pt-0 pb-0">
            <Row className="bg-secondary mt-0 mb-0">
              <Col sm="auto">
                <h5 className="text-light text-uppercasep-2">#</h5>
              </Col>
              <Col sm="4">
                <h5 className="text-light text-uppercasep-2">
                  Nome do arquivo
                </h5>
              </Col>
              <Col sm="2">
                <h5 className="text-light text-uppercasep-2">Tamanho</h5>
              </Col>
              <Col>
                <h5 className="text-light text-uppercasep-2">Descrição</h5>
              </Col>
              <Col sm="auto" />
            </Row>
          </CardBody>
        </Card>
        {files}
        <div className="clearfix text-right mt-3 mb-3">
          <button
            disabled={files.length === 0}
            onClick={clearFiles}
            className="btn btn-sm btn-outline-danger"
          >
            <i className="mdi mdi-close mr-1" /> Limpar
          </button>{" "}
          {!success ? (
            <ButtonSave
              disabled={files.length === 0}
              submitForm={sendFiles}
              isSubmitting={submitting}
            >
              <i className="mdi mdi-send mr-1" /> Enviar
            </ButtonSave>
          ) : (
            <ButtonSuccess isSubmitting={false} />
          )}
        </div>
      </aside>
    </div>
  );
};

export default DropFileUpload;
