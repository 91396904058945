import React from "react";
import { Bar } from "react-chartjs-2";
import { formatDateLabel } from "../utils";

const options = () => {
  return {
    type: "bar",
    showLines: true,
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            suggestedMax: 10,
            stepSize: 1,
            padding: 10
          },
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false
          }
        }
      ],
      xAxes: [
        {
          offsetGridLines: false,
          ticks: {
            // Include a dollar sign in the ticks
            callback(value: any) {
              return `${formatDateLabel(value)}`;
            }
          },
          gridLines: {
            display: true,
            drawOnChartArea: false
          }
        }
      ]
    },
    legend: {
      display: false
    },
    gridLines: {
      display: false
    },
    tooltips: {
      mode: "index",
      callbacks: {
        label(tooltipItem: any) {
          let label = formatDateLabel(tooltipItem.xLabel) || "";

          if (label) {
            label += ": ";
          }
          label += `${tooltipItem.yLabel}`;
          return label;
        },
        title() {
          return `Quantidade`;
        }
      }
    }
  };
};

interface Props {
  data: { date: string; amount: number }[];
  color: string;
}

const BreakChart: React.FC<Props> = props => {
  const sortDates = () => {
    return props.data.sort(
      (a, b) =>
        parseInt((a.date as any).replaceAll("-", ""), 10) -
        parseInt((b.date as any).replaceAll("-", ""), 10)
    );
  };

  const getDataSet = () => {
    const dataset: { [key: string]: any }[] = [];

    dataset.push({
      label: "Quantidade",
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: props.color,
      borderColor: props.color,
      borderCapStyle: "butt",
      borderDash: [10, 5],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: props.color,
      pointBackgroundColor: props.color,
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: props.color,
      pointHoverBorderColor: props.color,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: sortDates().map(item => item.amount)
    });

    return dataset;
  };

  return (
    <div className="canvas-size-concrete-summary">
      <Bar
        data={{
          labels: sortDates().map(item => item.date),
          datasets: getDataSet()
        }}
        options={options()}
      />
    </div>
  );
};

export default BreakChart;
