import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { InputColumn } from "src/components/Inputs";
import {
  SelectCapsule,
  SelectContract,
  SelectCylinder
} from "src/components/Selects";
import { ButtonSave, ListButtonRow } from "src/components/Buttons";
import * as contractApi from "src/services/api/contract";
import { Field, Form, Formik, FormikActions } from "formik";

const ResourcesAllocation: React.FC<Props> = () => {
  const [allocation, setAllocation] = useState<ContractAllocation>();

  const contractRequest = () => {
    return contractApi.getContracts("", "", "", "", "soil");
  };

  const onContractChange = (id?: number) => {
    if (id !== null && id !== undefined) {
      contractApi.getAllocation(id).then(({ data }) => {
        setAllocation(data[0]);
      });
    } else {
      setAllocation(undefined);
    }
  };

  const onSubmit = (values: any, { setSubmitting }: FormikActions<any>) => {
    setSubmitting(true);
    values.capsules = JSON.stringify(values.capsules);
    values.cylinders = JSON.stringify(values.cylinders);
    if (values.id) {
      contractApi
        .putAllocation(values.id, values)
        .then(() => {})
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      contractApi
        .postAllocation(values.contract, values)
        .then(() => {})
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={allocation}
      enableReinitialize
      render={({ setFieldValue, isSubmitting }) => (
        <Form>
          <Row>
            <Col sm={4}>
              <InputColumn label="Contrato">
                <Field
                  name={"contract"}
                  component={SelectContract}
                  request={contractRequest}
                  client={1}
                  required
                  onChange={(id?: number) => {
                    setFieldValue("contract", id);
                    onContractChange(id);
                  }}
                />
              </InputColumn>
              <InputColumn label="Capsulas">
                <Field
                  name="capsules"
                  component={SelectCapsule}
                  isMulti
                  onChange={(value: any) => {
                    setFieldValue("capsules", value);
                  }}
                  filter={false}
                />
              </InputColumn>
              <InputColumn label="Cilindros">
                <Field
                  name="cylinders"
                  component={SelectCylinder}
                  isMulti
                  onChange={(value: any) => {
                    setFieldValue("cylinders", value);
                  }}
                  filter={false}
                />
              </InputColumn>
              <Row>
                <Col>
                  <ListButtonRow
                    marginTop="mt-2"
                    buttonsLeft={
                      <>
                        <ButtonSave isSubmitting={isSubmitting} />
                      </>
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default ResourcesAllocation;
