import React, { useState, useEffect, useCallback } from "react";
import { InputSelect } from "../Inputs";
import { LoadingComponent } from "../Loader";
import * as api from "../../services/api/service";

interface Props extends SelectType<ServiceValuesProps> {
  subDepartments: number[];
}

const SelectService: React.FC<Props> = props => {
  const [items, setItems] = useState<ServiceValuesProps[]>([]);
  const [loading, setLoading] = useState(false);

  const getItems = useCallback(() => {
    setLoading(true);
    api
      .getServices(props.subDepartments.join(","))
      .then(({ data }) => setItems(data))
      .finally(() => setLoading(false));
  }, [props.subDepartments]);

  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = () => {
    return items.map(item => ({
      value: item.id,
      label: item.name
    }));
  };

  return (
    <LoadingComponent loading={loading}>
      <InputSelect
        placeholder="Serviço..."
        options={options()}
        onClick={props.onChange}
        {...props}
      />
    </LoadingComponent>
  );
};

export default SelectService;
