const search = <T>(
  sortedArray: T[],
  seekElement: T,
  comparator: (a: T, b: T) => number
) => {
  let startIndex = 0;
  let endIndex = sortedArray.length - 1;

  while (startIndex <= endIndex) {
    // Let's calculate the index of the middle element.
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);

    // If we've found the element just return its position.
    if (comparator(sortedArray[middleIndex], seekElement) === 0) {
      return middleIndex;
    }

    // Decide which half to choose for seeking next: left or right one.
    if (comparator(sortedArray[middleIndex], seekElement) < 0) {
      // Go to the right half of the array.
      startIndex = middleIndex + 1;
    } else {
      // Go to the left half of the array.
      endIndex = middleIndex - 1;
    }
  }

  return -1;
};

export { search };
