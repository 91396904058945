import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "./router";
import * as Sentry from "@sentry/react";
import * as Tracker from "../src/services/track";

Tracker.init();

Sentry.init({
  dsn: "https://be052cc9ea9e47d4b3288f65ea3edaf4@sentry.io/1457548",
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.NODE_ENV
});

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
