import React from "react";
import { Field } from "formik";
// @ts-ignore
import CurrencyInput from "react-currency-input";

interface Props {
  name: string;
  required?: boolean;
  disabled?: boolean;
  handleValue?: (value: number) => void;
}

const InputMoney: React.FC<Props> = ({
  name,
  required,
  disabled,
  handleValue
}) => (
  <Field
    name={name}
    render={({ field, form: { setFieldValue } }: any) => (
      <CurrencyInput
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        className="form-control form-control-sm text-right"
        {...field}
        disabled={disabled}
        required={required}
        onChangeEvent={(
          _event: any,
          _maskedvalue: string,
          floatValue: number
        ) => {
          setFieldValue(name, floatValue);
          if (handleValue !== undefined) handleValue(floatValue);
        }}
      />
    )}
  />
);

InputMoney.defaultProps = {
  disabled: false,
  required: false
};

export default InputMoney;
