import React from "react";
import { Scatter } from "react-chartjs-2";
// @ts-ignore
import everpolate from "everpolate";
import {
  MIN_HUMIDITY_PERCENTAGE,
  MAX_HUMIDITY_PERCENTAGE,
  MIN_DENSITY,
  MAX_DENSITY,
  HUMIDITY_PRECISON,
  DENSITY_PRECISION
} from "../../../../../utils/precisions";
import { useCompaction } from "./context";

const options = (dataset: any[]) => {
  let min_humidity = dataset.reduce((a, b) => {
    return Math.min(a.x || a, b.x);
  }, 999);

  let max_humidity = dataset.reduce((a, b) => {
    return Math.max(a.x || a, b.x);
  }, 0);

  max_humidity = Math.ceil(max_humidity);
  min_humidity = Math.floor(min_humidity);

  const chartOptions = {
    type: "line",
    showLines: true,
    responsive: true,
    maintainAspectRatio: false,
    cubicInterpolationMode: "monotone",
    scales: {
      yAxes: [
        {
          ticks: {
            min: 1,
            suggestedMax: 2,
            stepSize: 0.3
          },
          scaleLabel: {
            display: true,
            labelString: "DENS. APAR. SECA (g/cm³)"
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            min: min_humidity,
            max: max_humidity
          },
          scaleLabel: {
            display: true,
            labelString: "TEOR DE UMIDADE (%)"
          }
        }
      ]
    },
    legend: {
      display: false,
      position: "bottom",
      usePointStyle: true
    },
    layout: {
      padding: {
        bottom: 30,
        top: 30,
        left: 20
      }
    },
    tooltips: {
      mode: "index"
    }
  };

  return chartOptions;
};

const Chart = () => {
  const [compaction] = useCompaction();

  const normalizeHumidity = (value: number): number => {
    value = Math.max(value, MIN_HUMIDITY_PERCENTAGE);
    value = Math.min(value, MAX_HUMIDITY_PERCENTAGE);

    return parseFloat(value.toFixed(HUMIDITY_PRECISON * 2)) * 1;
  };

  const normalizeDensity = (value: number): number => {
    value = Math.max(value, MIN_DENSITY);
    value = Math.min(value, MAX_DENSITY);

    return parseFloat(value.toFixed(DENSITY_PRECISION)) * 1;
  };

  const getDataSet = () => {
    const data = compaction.dryDensities.map((item, index) => {
      const _humidity: number = normalizeHumidity(compaction.humidities[index]);

      const density: number = normalizeDensity(item);

      return {
        x: _humidity * 100,
        y: density
      };
    });

    const getDataLine = (x: number[], y: number[]) => {
      const min_x = x.reduce((a, b) => {
        return Math.min(a, b);
      });
      const max_x = x.reduce((a, b) => {
        return Math.max(a, b);
      });
      const delta = (max_x - min_x) / 30;

      const pointsToEvaluate = [min_x];
      for (let i = min_x; i < max_x; i += delta) {
        pointsToEvaluate.push(i);
      }
      pointsToEvaluate.push(max_x);

      y = everpolate.polynomial(pointsToEvaluate, x, y);
      return pointsToEvaluate.map((x, index) => {
        return {
          x: x.toFixed(4),
          y: normalizeDensity(y[index]).toFixed(4)
        };
      });
    };

    const dataLine = getDataLine(
      data.map(c => {
        return c.x;
      }),
      data.map(c => {
        return c.y;
      })
    );

    const data_great_density = [
      { x: 0, y: normalizeDensity(compaction.maxDensity) },
      {
        x: normalizeHumidity(compaction.greatHumidity) * 100,
        y: normalizeDensity(compaction.maxDensity)
      },
      { x: normalizeHumidity(compaction.greatHumidity) * 100, y: 0 }
    ];

    const dataset: { [key: string]: any } = [];

    dataset.push({
      label: `Pontos de compactação`,
      fill: false,
      showLine: false,
      backgroundColor: "blue",
      borderColor: "blue",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "black",
      pointHoverBorderColor: "black",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data,
      cubicInterpolationMode: "monotone"
    });

    dataset.push({
      label: `Curva de compactação `,
      fill: false,
      showLine: true,
      backgroundColor: "blue",
      borderColor: "blue",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointRadius: 0,
      data: dataLine,
      cubicInterpolationMode: "default"
    });

    dataset.push({
      label: "Umidade ótima",
      fill: false,
      showLine: true,
      lineTension: 0,
      backgroundColor: "coral",
      borderColor: "coral",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: data_great_density
    });

    return dataset;
  };

  return (
    <div className="canvas-size-comp">
      <Scatter
        data={{
          datasets: getDataSet()
        }}
        options={options(getDataSet()[0].data)}
      />
    </div>
  );
};

export default Chart;
