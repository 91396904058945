import React from "react";
import { FormikActions } from "formik";
import Form from "./Form";
import { putPress, postPress } from "src/services/api/tools";
import { ModalForm } from "src/components/Modal";
import { getPressId } from "src/services/api/characterization";

const InsertPress: React.FC<InsertType<CBRPress>> = ({ insertedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return postPress(values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastrar nova prensa"
      initialValues={() => {
        return {};
      }}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
    />
  );
};

const EditPress: React.FC<EditType<CBRPress>> = ({ item, updatedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return putPress(values.id, { press_ref: values.press_ref })
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="put"
      size="md"
      data={item ? { id: item } : undefined}
      getItemRequest={id => getPressId(id)}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
      initialValues={item => item}
      title="Editar prensa"
    />
  );
};

export { InsertPress, EditPress };
