/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { InputCheckbox, InputColumn, InputDate } from "src/components/Inputs";
import { getConcreteSummary } from "src/services/api/concrete";
import { toLocateNumber } from "src/utils/format";
import BreakChart from "./BreakChart";
import moment from "moment";
import { LoadingComponent } from "src/components/Loader";
import { SmallText } from "src/components/Text";
import { Form, Formik } from "formik";

interface Props {
  contractId: number;
}

const Summary = ({ contractId }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState<TConcreteSummary>();
  const [startDate, setStartDate] = useState<string>(
    moment().add(-7, "days").local().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    moment().local().format("YYYY-MM-DD")
  );
  const [useExpectedDate, setUseExpectedDate] = useState<boolean>(true);

  useEffect(() => {
    getSummary();
  }, [startDate, endDate, useExpectedDate]);

  const getSummary = () => {
    setLoading(true);

    getConcreteSummary(
      contractId,
      startDate,
      endDate,
      useExpectedDate ? "0" : "1"
    ).then(({ data }) => {
      setLoading(false);
      setSummary(data);
    });
  };

  const notTestedSummary = () => {
    if (summary && summary.daily_summary !== undefined) {
      return Object.keys(summary?.daily_summary).map(key => {
        return { date: key, amount: summary.daily_summary[key].not_tested };
      });
    }

    return [];
  };

  const testedSummary = () => {
    if (summary && summary.daily_summary !== undefined) {
      return Object.keys(summary?.daily_summary).map(key => {
        return { date: key, amount: summary.daily_summary[key].tested };
      });
    }

    return [];
  };

  const onChangeStart = (event: any) => {
    event.preventDefault();
    const value = event.target.value;

    setStartDate(value);
  };

  const onChangeEnd = (event: any) => {
    event.preventDefault();
    const value = event.target.value;

    setEndDate(value);
  };

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => {}}
        render={() => (
          <Form>
            <Row className="text-left">
              <Col sm={2}>
                <InputColumn label="Início de ruptura">
                  <InputDate
                    name="start"
                    onChange={onChangeStart}
                    value={startDate}
                    required
                  />
                </InputColumn>
              </Col>
              <Col sm={2}>
                <InputColumn label="Fim de ruptura">
                  <InputDate
                    name="end"
                    onChange={onChangeEnd}
                    value={endDate}
                    min={startDate}
                    required
                  />
                </InputColumn>
              </Col>
              <Col className="mt-3" sm={3}>
                <InputCheckbox
                  name="rupture"
                  onClick={() => setUseExpectedDate(!useExpectedDate)}
                  checked={useExpectedDate}
                  label={"Usar data de ruptura esperada"}
                />
              </Col>
            </Row>
          </Form>
        )}
      />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <LoadingComponent loading={loading}>
                <Row>
                  <Col>
                    <h3>{toLocateNumber(summary?.period?.not_tested || 0)}</h3>
                    <p>Aguardando ruptura</p>
                    <BreakChart data={notTestedSummary()} color="#fa5c7c" />
                    <SmallText>
                      A data representam o dia esperado de ruptura dos corpos de
                      prova
                    </SmallText>
                  </Col>
                </Row>
              </LoadingComponent>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <LoadingComponent loading={loading}>
                <Row>
                  <Col>
                    <h3>{toLocateNumber(summary?.period?.tested || 0)}</h3>
                    <p>Rompidos</p>
                    <BreakChart data={testedSummary()} color="#5ad092" />
                    <SmallText>
                      A data representam o dia real em que os corpos de prova
                      foram rompidos
                    </SmallText>
                  </Col>
                </Row>
              </LoadingComponent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Summary;
