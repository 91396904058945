import React from "react";
import { InputSelect } from "../Inputs";

const SelectActivity = ({ onChange }) => (
  <InputSelect
    onClick={onChange}
    placeholder="Status..."
    options={[
      { value: 0, label: "Inativos" },
      { value: 1, label: "Ativos" },
      { value: "", label: "Todos" },
    ]}
  />
);

export default SelectActivity;
