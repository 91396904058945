import React from "react";
import { Line } from "react-chartjs-2";
import { formatAgeLabel, CPS_AGE } from "../utils";

const options = () => {
  return {
    type: "line",
    showLines: true,
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback(value: any) {
              return `${value} MPa`;
            }
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback(value: any) {
              return `${formatAgeLabel(value)}`;
            }
          }
        }
      ]
    },
    legend: {
      display: true,
      position: "bottom",
      usePointStyle: true
    },
    layout: {
      padding: {
        bottom: 20,
        top: 20
      }
    },
    tooltips: {
      mode: "index",
      callbacks: {
        label(tooltipItem: any, data: any) {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";

          if (label) {
            label += ": ";
          }
          label += `${tooltipItem.yLabel} MPa`;
          return label;
        },
        title() {
          return `FCK`;
        }
      }
    }
  };
};

interface Props {
  expected: { age: number; fck?: number }[];
  reached: { age: number; fck?: number }[];
  fck: number;
}

const Chart: React.FC<Props> = props => {
  const getDataSet = () => {
    const dataset: { [key: string]: any }[] = [];

    const expectedData = props.expected.map(item => item.fck);

    dataset.push({
      label: "Esperado",
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "#a0a09f",
      borderColor: "#a0a09f",
      borderCapStyle: "butt",
      borderDash: [10, 5],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#a0a09f",
      pointBackgroundColor: "#a0a09f",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "#a0a09f",
      pointHoverBorderColor: "#a0a09f",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: expectedData
    });

    const reachedData = props.reached.map(item => item.fck);

    dataset.push({
      label: "Atingido",
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "#6f4b35",
      borderColor: "#6f4b35",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#6f4b35",
      pointBackgroundColor: "#6f4b35",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "#6f4b35",
      pointHoverBorderColor: "#6f4b35",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: reachedData
    });

    return dataset;
  };

  return (
    <div className="canvas-size-series">
      <Line
        data={{
          labels: CPS_AGE,
          datasets: getDataSet()
        }}
        options={options()}
      />
    </div>
  );
};

export default Chart;
