import React, { useState, useEffect } from "react";
import * as tools_api from "../../services/api/tools";
import SelectBase from "./SelectBase";

const SelectLayer: React.FC<SelectType<LayerValues>> = props => {
  const [items, setItems] = useState<LayerValues[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    tools_api.getLayers().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items.map(item => ({
      value: item.id,
      label: item.name
    }));
  };

  return (
    <SelectBase
      {...props}
      placeholder="Camada..."
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      getItems={getItems}
      options={options()}
      onClick={props.onChange}
    />
  );
};

export default SelectLayer;
