import React from "react";
import { Row, Col } from "reactstrap";
import {
  InputRow,
  InputText,
  InputSelect,
  InputColumn,
  InputInteger,
  InputCheckbox
} from "../../../../components/Inputs";
import { Field, FormikProps } from "formik";
import { HeaderTitle } from "../../../../components/Headers";
import { formatAgeLabel, CPS_AGE } from "../../Concrete/utils";
import { SmallText } from "../../../../components/Text";

interface Props extends FormikProps<TGrauteControl> {}

const Form: React.FC<Props> = ({ values, setFieldValue }) => {
  const onChangeAgeAmount = (event: any, age: number) => {
    let cpAges = values.graute_age;
    const value = event.target.value || 0;

    const contains = cpAges.some(item => item.age === age);
    if (contains) {
      cpAges = cpAges.map(item => {
        if (item.age === age) {
          return { ...item, quantity: parseInt(value) };
        }

        return item;
      });
    } else {
      cpAges.push({ age: age, quantity: parseInt(value) });
    }

    setFieldValue("graute_age", cpAges);
  };

  const getAgeIndex = (age: number) => {
    let i = -1;
    values.graute_age.forEach((item, index) => {
      if (item.age === age) {
        i = index;
      }
    });

    return i;
  };

  const renderAgesSelector = () => {
    return (
      <>
        <HeaderTitle
          title="Idades e quatidades para ensaio de compressão"
          className="mt-2 pt-2 border-top"
        />
        <Row>
          {CPS_AGE.map(age => (
            <Col sm={3}>
              <InputColumn label={formatAgeLabel(age)}>
                <Field
                  name={`graute_age[${getAgeIndex(age)}].quantity`}
                  component={InputInteger}
                  onChange={(event: any) => onChangeAgeAmount(event, age)}
                />
              </InputColumn>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <Row>
      <Col>
        <InputRow label="Nome" label_col={2}>
          <Field name="name" component={InputText} />
        </InputRow>
        <InputRow label="Contato" label_col={2}>
          <Field name="contact" component={InputText} />
        </InputRow>
        <InputRow label="Equipamentos" label_col={2}>
          <Field name="equipments" component={InputText} />
        </InputRow>
        <InputRow label="Torres" label_col={2}>
          <Field name="towers_names" component={InputText} />
          <SmallText>
            Partes multáveis devem ser subtituidas por 0 (zero) em caso de
            números e por # (hasttag) em caso de letras. Ex.: ALB:A1-A1,
            ALB:#0-#0. Os nomes devem ser separados por ; (ponto e virgula)
          </SmallText>
        </InputRow>
        <InputRow label="Fornecedores" label_col={2}>
          <Field name="providers" component={InputText} />
          <SmallText>
            Os fornecedores devem ser separados por ; (ponto e virgura). Ex.:
            Fosroc;BASF;Pav Mix
          </SmallText>
        </InputRow>
        <InputRow label="FCK (MPa)" label_col={2}>
          <Field name="fck" component={InputText} />
        </InputRow>
        <InputRow label="Norma" label_col={2}>
          <Field
            name="rule_control"
            component={InputSelect}
            options={[
              { value: "ASTM", label: "ASTM C 109 (50mm x 50mm x 50mm)" },
              {
                value: "NBR",
                label: "NBR 13979/UNE-EN 12190 (160mm x 40mm x 40mm)"
              }
            ]}
          />
        </InputRow>
        <InputRow label="Nome do relatório" label_col={2}>
          <Field name="report_name" component={InputText} />
          <SmallText>
            É possível utilizar as seguintes variaveris como nome do relatório:{" "}
            {"{{control_name}}"}, {"{{client}}"}, {"{{work}}"},{" "}
            {"{{today_year}}"}, {"{{today_month}}"}, {"{{today_day}}"},{" "}
            {"{{tower}}"}
          </SmallText>
        </InputRow>
        <InputCheckbox
          name="trace_control"
          label="Controle de traços"
          checked={values.trace_control}
          onClick={() => setFieldValue("trace_control", !values.trace_control)}
        />
        <InputCheckbox
          name="dimension_control"
          label="Análise dimensional dos corpos de prova"
          checked={values.dimensions_control}
          onClick={() =>
            setFieldValue("dimensions_control", !values.dimensions_control)
          }
        />
        <HeaderTitle title="Idades e quantidades de ruptura" />
        {renderAgesSelector()}
      </Col>
    </Row>
  );
};

export default Form;
