import axios from "axios";
import { getCurrentApplication } from "./applications";

const BASE_URL = process.env.REACT_APP_REPORT_ENDPOINT;

const report = (token: string) =>
  axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });

export function insituHolesReport(
  token: string,
  params: {
    contract: number;
    application: string;
    token: string;
  },
  data: {
    places: string[];
    layers: string[];
    date: string[];
    optional_places: string[];
    samples: number[];
  }
) {
  const endpoint = `/insitu/holes/`;
  return report(token).post(endpoint, data, { params });
}

export function sampleSummaryReport(
  token: string,
  params: {
    contract: number;
    application: string;
    token: string;
  },
  data: {
    places: string[];
    layers: string[];
    samples: number[];
  }
) {
  const endpoint = `/soil/summary/`;
  return report(token).post(endpoint, data, { params });
}

export function sampleFullReport(
  token: string,
  params: {
    contract: number;
    application: string;
    token: string;
    cover: boolean;
    granulometry: boolean;
    limits: boolean;
    compaction: boolean;
    cbr: boolean;
    cbr_charts: boolean;
  },
  data: {
    places: string[];
    layers: string[];
    samples: number[];
  }
) {
  const endpoint = `/soil_new/sample/`;
  return report(token).post(endpoint, data, { params });
}

export function openReportPage(key: string) {
  const app = getCurrentApplication();
  window.open(`/${app}/request_report/${key}`, "_blank");
}
