import React from "react";
import { InputGroupAddon, InputGroup, InputGroupText } from "reactstrap";

const InputGroupAppend = ({ label, children }) => (
  <InputGroup size="sm">
    {children}
    <InputGroupAddon addonType="append">
      <InputGroupText>{label}</InputGroupText>
    </InputGroupAddon>
  </InputGroup>
);

export default InputGroupAppend;
