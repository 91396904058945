/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ModalForm } from "src/components/Modal";
import Form from "./Form";
import {
  postCylinders,
  putCylinder,
  getCylinder
} from "src/services/api/cylinder";

const EditCylinder: React.FC<EditType<Cylinder>> = ({
  item,
  dbClick,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();

  useEffect(() => {
    if (dbClick) {
      modalRef.current?.open();
    }
  }, [dbClick]);

  const onSubmit = (values: any) => {
    return putCylinder(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="md"
      title="Editar cilindro"
      buttonLabel="Editar"
      data={item}
      getItemRequest={id => getCylinder(id)}
      form={props => {
        return <Form {...props} />;
      }}
      initialValues={item => item}
      onSubmit={onSubmit}
    />
  );
};

const InsertCylinder: React.FC<InsertType<Cylinder>> = ({ insertedItem }) => {
  const onSubmit = (values: any) => {
    return postCylinders(values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastro de novo cylindro"
      buttonLabel="Novo"
      form={props => {
        return <Form {...props} />;
      }}
      initialValues={() => {
        return {};
      }}
      onSubmit={onSubmit}
    />
  );
};

export { InsertCylinder, EditCylinder };
