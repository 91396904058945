import { IDataTableStyles } from "react-data-table-component";

const style: IDataTableStyles = {
  head: {
    style: { height: "40px" }
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#ccc",
      borderBottomColor: "#ccc",
      minHeight: "0px"
    }
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
      fontWeight: "bold",
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f1f5f7",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF"
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      "&:nth-of-type(n)": {
        backgroundColor: "#6d7077",
        color: "#fff"
      }
    },
    stripedStyle: {
      backgroundColor: "#f1f5f7"
    },
    style: {
      height: "auto",
      minHeight: "auto"
    }
  },
  cells: {
    style: {
      fontSize: "14px",
      minWidth: "0px",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "8px",
      paddingBottom: "8px",
      height: "auto",
      flex: 0
    }
  },

  pagination: {
    style: {
      border: "none"
    }
  }
};

export default style;
