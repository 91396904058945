import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import {
  InputColumn,
  InputValidation,
  InputMoney
} from "../../../../components/Inputs";
import { ButtonSave } from "../../../../components/Buttons";

import * as api from "../../../../services/api/admin";

const initialValues = {
  fuel: 0,
  breakfast: 0,
  lunch: 0,
  dinner: 0,
  lodging: 0,
  voucher: 0
};

const Daily = () => {
  const [settings, setSettings] = useState<DailyValues>(initialValues);

  useEffect(() => {
    api.getDailySetting().then(({ data }) => {
      setSettings(data[0] || {});
    });
  }, []);

  return (
    <Formik
      onSubmit={(values: DailyValues, { setFieldError, setSubmitting }) => {
        api
          .postDailySetting(values)
          .then(({ data }) => {
            setSettings(data);
            setSubmitting(false);
          })
          .catch(({ data }) => {
            Object.keys(data).forEach(key => {
              setFieldError(key, data[key]);
            });
            setSubmitting(false);
          });
      }}
      initialValues={{ ...settings }}
      enableReinitialize={true}
      render={({ errors, isSubmitting }) => (
        <Row>
          <Col sm={4}>
            <Form className="form-horizontal">
              <Row>
                <Col sm={6}>
                  <InputColumn label="Combustível (Km)">
                    <InputMoney name="fuel" required={true} />
                    <InputValidation message={errors.fuel} />
                  </InputColumn>
                  <InputColumn label="Café da manhã">
                    <InputMoney name="breakfast" required={true} />
                    <InputValidation message={errors.breakfast} />
                  </InputColumn>
                  <InputColumn label="Almoço">
                    <InputMoney name="lunch" required={true} />
                    <InputValidation message={errors.lunch} />
                  </InputColumn>
                </Col>
                <Col sm={6}>
                  <InputColumn label="Janta">
                    <InputMoney name="dinner" required={true} />
                    <InputValidation message={errors.dinner} />
                  </InputColumn>
                  <InputColumn label="Alojamento">
                    <InputMoney name="lodging" required={true} />
                    <InputValidation message={errors.lodging} />
                  </InputColumn>
                  <InputColumn label="Caixa fixo">
                    <InputMoney name="voucher" required={true} />
                    <InputValidation message={errors.voucher} />
                  </InputColumn>
                </Col>
              </Row>

              <ButtonSave isSubmitting={isSubmitting}>Cadastrar</ButtonSave>
            </Form>
          </Col>
        </Row>
      )}
    />
  );
};

export default Daily;
