import React from "react";
import { FormikActions } from "formik";
import Form from "./Form";
import { ModalForm } from "src/components/Modal";
import { postUnit, putUnits, getUnit } from "src/services/api/service";

const Insert: React.FC<InsertType<UnitValuesProps>> = ({ insertedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return postUnit(values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastrar nova unidade"
      initialValues={() => {
        return {};
      }}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
    />
  );
};

const Edit: React.FC<EditType<UnitValuesProps>> = ({ item, updatedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return putUnits(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="put"
      size="md"
      data={item ? { id: item } : undefined}
      getItemRequest={id => getUnit(id)}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
      initialValues={item => item}
      title="Editar unidade"
    />
  );
};

export { Insert, Edit };
