import { formatPhone } from "../../../utils/format";

const columns = [
  {
    title: "Nome",
    data: "short_name"
  },
  {
    title: "Nome",
    data: "full_name"
  },
  {
    title: "Contato",
    data: "contact_one",
    render(data: string) {
      return formatPhone(data);
    }
  },
  {
    title: "Endereço",
    data: "address"
  },
  {
    title: "Email",
    data: "email"
  }
];

export default columns;
