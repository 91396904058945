import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { InputColumn } from "src/components/Inputs";
import { ButtonSave } from "src/components/Buttons";
import { Formik, Form as FormikForm, FormikActions } from "formik";
import Form from "./Form";
import { putCompany, getCompanies } from "src/services/api/company";
import { LoadingComponent } from "src/components/Loader";

const Company: React.FC<Props> = () => {
  const [item, setItem] = useState<CompanyValuesProps>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getCompanies()
      .then(({ data }) => setItem(data[0]))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = (values: any, { setSubmitting }: FormikActions<object>) => {
    if (values) {
      putCompany(values.id, values)
        .then(() => {})
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Row>
      <Col sm={8}>
        <InputColumn>
          <Row>
            <Col>
              <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={item!}
                render={props => {
                  return (
                    <LoadingComponent loading={loading}>
                      <FormikForm>
                        <Form {...props} />
                        <ButtonSave isSubmitting={props.isSubmitting} />
                      </FormikForm>
                    </LoadingComponent>
                  );
                }}
              />
            </Col>
          </Row>
        </InputColumn>
      </Col>
    </Row>
  );
};

export default Company;
