import React from "react";
import Select from "react-select";

class SelectMultipleStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  options() {
    return [
      { value: "0", label: "Em aprovação" },
      { value: "1", label: "Aberta" },
      { value: "2", label: "Enviada" },
      { value: "5", label: "Faturada" },
      { value: "4", label: "Recebida parcial" },
      { value: "3", label: "Recebida" },
    ];
  }

  render() {
    return (
      <Select
        options={this.options()}
        placeholder="Status..."
        isMulti
        styles={{
          container: () => ({
            width: "100%",
            padding: "0",
          }),
        }}
        onChange={this.props.onChange}
      />
    );
  }
}

export default SelectMultipleStatus;
