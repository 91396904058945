import React, { useEffect, useState } from "react";
import * as api from "../../services/api/admin";
import SelectBase from "./SelectBase";
import { AxiosResponse } from "axios";

interface Props extends SelectType<WorkFormValues> {
  client: number;
  request: () => Promise<AxiosResponse<ClientFormValues[]>>;
}

const SelectUser: React.FC<Props> = props => {
  const [items, setItems] = useState<User[]>([]);

  useEffect(() => {
    getItems();
  }, [props.client]);

  const getItems = () => {
    api.getUsers().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items.map(item => ({ value: item.id, label: item.full_name }));
  };

  return (
    <SelectBase
      options={options()}
      placeholder="Usuários..."
      onClick={props.onChange}
      newItem={props.newItem}
      deletedItem={props.deletedItem}
      updatedItem={props.updatedItem}
      getItems={getItems}
      isMulti
      {...props}
    />
  );
};

export default SelectUser;
