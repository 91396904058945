import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { Modal } from "../../../components/Modal";
import { ButtonOption } from "../../../components/Buttons";

interface Props {
  tests: { title: string; test: React.ReactElement }[];
  sample?: SoilSample;
}

const TestsModal: React.FC<Props> = ({ tests, sample }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>(
    tests[0].title.toLowerCase()
  );

  const toggle = () => setShowModal(!showModal);

  const setNavItem = (id: string, title: string) => {
    return (
      <NavItem>
        <NavLink
          className={classnames({
            active: currentTab === id.toString()
          })}
          onClick={() => {
            setCurrentTab(id.toString());
          }}
        >
          {title}
        </NavLink>
      </NavItem>
    );
  };

  const setTabPane = (id: string, content: React.ReactElement) => {
    return <TabPane tabId={id}>{content}</TabPane>;
  };

  const testsTabs = () => {
    return (
      <>
        <Nav tabs>
          {tests.map(item => (
            <>{setNavItem(item.title.toLowerCase(), item.title)}</>
          ))}
        </Nav>
        <TabContent activeTab={currentTab}>
          {tests.map(item => (
            <>{setTabPane(item.title.toLowerCase(), item.test)}</>
          ))}
        </TabContent>
      </>
    );
  };

  return (
    <>
      <ButtonOption onClick={toggle} disabled={!sample}>
        Ensaiar amostra
      </ButtonOption>
      <Modal
        title={`${sample?.collection_place}`}
        toggle={toggle}
        size="xl"
        isOpen={showModal}
        className=""
      >
        {testsTabs()}
      </Modal>
    </>
  );
};

export default TestsModal;
