import React from "react";
import { FormikProps, Field } from "formik";
import {
  InputRow,
  InputColumn,
  InputDate,
  InputTime,
  InputSelect,
  InputText,
  InputInteger,
  InputDecimal,
  InputCheckbox,
  InputValidation
} from "../../../../../components/Inputs";
import { Row, Col } from "reactstrap";
import { HeaderTitle } from "../../../../../components/Headers";
import { SelectDiscarded } from "../../../../../components/Selects";
import { SmallText } from "../../../../../components/Text";

interface Props extends FormikProps<TGrauteTrace> {
  control: TGrauteControl;
}

const Form: React.FC<Props> = ({ control, values, errors, setFieldValue }) => {
  const manufacturerOptions = () => {
    return control.providers.split(";").map((item: string) => {
      return { value: item, label: item };
    });
  };

  return (
    <>
      <Row>
        <Col>
          <InputColumn label="Data do traço">
            <Field name="trace_date" component={InputDate} required />
          </InputColumn>
        </Col>

        <Col>
          <InputColumn label="Hora inicial">
            <Field name="start_hour" component={InputTime} required />
          </InputColumn>
        </Col>
      </Row>
      <hr />
      <HeaderTitle title="DADOS DO GRAUTE" />
      <Row>
        <Col>
          <InputColumn label="Marca do graute">
            <Field
              name="manufacturer"
              component={InputSelect}
              required
              isClearable={false}
              options={manufacturerOptions()}
            />
          </InputColumn>
        </Col>

        <Col>
          <InputColumn label="Lote">
            <Field name="lot" component={InputText} required />
          </InputColumn>
        </Col>

        <Col>
          <InputColumn label="Data de validade">
            <Field name="expiration_date" component={InputDate} required />
          </InputColumn>
        </Col>
      </Row>
      <hr />
      <HeaderTitle title="LOCAL DA CONCRETAGEM" />
      <Row>
        <Col>
          <InputColumn label="Torre">
            <Field name="tower" component={InputText} required />
          </InputColumn>
        </Col>

        <Col>
          <InputColumn label="Local">
            <Field name="join" component={InputText} required />
          </InputColumn>
        </Col>
        <Col>
          <InputColumn label="Junta">
            <Field name="link" component={InputText} />
          </InputColumn>
        </Col>
      </Row>
      <hr />
      <HeaderTitle title="DADOS DA MASSADA" />
      <Row>
        <Col>
          <InputColumn label="Sacos de morteiro">
            <Field name="bag_mortero" component={InputInteger} required />
          </InputColumn>
        </Col>

        <Col>
          <InputColumn label="Peso do saco (Kg)">
            <Field
              name="bag_weight"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("bag_weight", value);
              }}
            />
          </InputColumn>
        </Col>

        <Col>
          <InputColumn label="Litros / saco (L)">
            <Field
              name="liters_bag"
              component={InputDecimal}
              precision={2}
              required
              onChange={(value: number) => {
                setFieldValue("liters_bag", value);
              }}
            />
          </InputColumn>
        </Col>
      </Row>

      <hr />
      <HeaderTitle title="CONTROLE DE TEMPERATURA" />
      <Row>
        <Col>
          <InputColumn label="Temp. Ambiente (°C)">
            <Field
              name="environment_temp"
              component={InputDecimal}
              precision={2}
              required
              onChange={(value: number) => {
                setFieldValue("environment_temp", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col>
          <InputColumn label="Temp. do mortero (°C)">
            <Field
              name="mortero_temp"
              component={InputDecimal}
              precision={2}
              required
              onChange={(value: number) => {
                setFieldValue("mortero_temp", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col>
          <InputColumn label="Temp. da massada (°C)">
            <Field
              name="mass_temp"
              component={InputDecimal}
              precision={2}
              required
              onChange={(value: number) => {
                setFieldValue("mass_temp", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col>
          <InputColumn label="Temp. da Água (°C)">
            <Field
              name="water_temp"
              component={InputDecimal}
              precision={2}
              required
              onChange={(value: number) => {
                setFieldValue("water_temp", value);
              }}
            />
          </InputColumn>
        </Col>
      </Row>
      <hr />
      <HeaderTitle title="SLUMP" />
      <Row>
        <Col sm={3}>
          <InputColumn label="Slump Flow 01">
            <Field
              name="slump_test_one"
              component={InputDecimal}
              precision={2}
              onChange={(value: number) => {
                setFieldValue("slump_test_one", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col sm={3}>
          <InputColumn label="Slump Flow 02">
            <Field
              name="slump_test_two"
              component={InputDecimal}
              precision={2}
              onChange={(value: number) => {
                setFieldValue("slump_test_two", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col sm={3}>
          <InputColumn label="Spread Flow">
            <Field
              name="slump"
              component={InputDecimal}
              precision={2}
              onChange={(value: number) => {
                setFieldValue("slump", value);
              }}
            />
          </InputColumn>
        </Col>
      </Row>
      <hr />
      <InputRow label_col={3} label="Hora final">
        <Field name="end_hour" component={InputTime} required />
      </InputRow>
      <InputCheckbox
        label="Coletar traço para ensaio e compressão"
        name="collected"
        checked={values.collected}
        onClick={() => setFieldValue("collected", !values.collected)}
      />

      {values.collected && (
        <InputRow label_col={3} label="Número da série">
          <Field name="serie_number" component={InputInteger} required />
          <InputValidation message={errors?.serie_number} />
          <br />
          <SmallText>
            Número da série para ensaios de ruptura. Os dados da série (FCK,
            quantidade e idade dos CPs) serão os cadastrados no controle
          </SmallText>
        </InputRow>
      )}
      <InputRow label_col={3} label="Descartar traço">
        <Field name="discarded_motive" component={SelectDiscarded} required />
      </InputRow>
    </>
  );
};

export default Form;
