import mixpanel, { Callback, Dict, RequestOptions } from "mixpanel-browser";

const events = {
  CREATED_REPORT: "created_report"
};

export function init() {
  if (process.env.NODE_ENV === "production") {
    mixpanel.init("32edffae6fb33f1102b33d22f07a6e98");
  }
}

function track(
  event_name: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback
) {
  if (process.env.NODE_ENV === "production") {
    mixpanel.track(event_name, properties, optionsOrCallback, callback);
  }
}

export function trackReport(group: string, type: string) {
  track(events.CREATED_REPORT, { group, type });
}
