/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { isNullOrUndefined } from "util";
import {
  contract as contractColumns,
  invoice as invoiceColumn
} from "./columns";
import { HeaderTitle } from "../../../components/Headers";
import { TableList, useData } from "../../../components/Table";
import { getContracts, getContract } from "../../../services/api/contract";
import {
  getBills,
  deleteBill,
  getBill,
  postBills,
  putBill
} from "../../../services/api/bills";
import { getClients } from "../../../services/api/client";
import { LoadingComponent } from "../../../components/Loader";
import { InputRow } from "../../../components/Inputs";
import {
  SelectSubDepartment,
  SelectActivity,
  SelectClient,
  SelectMonth,
  SelectYear
} from "../../../components/Selects";
import { ListButtonRow, ButtonDelete } from "../../../components/Buttons";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Medições";

const Invoice = () => {
  const [items, setItems] = useState<InvoiceValuesProps[]>([]);
  const [contracts, setContracts] = useState<ContractValuesProps[]>([]);
  const [contract, setContract] = useState<ContractValuesProps>();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [client, setClient] = useState();
  const [subDepartment, setSubDepartment] = useState();
  const [activity, setActivity] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBills, setLoadingBills] = useState<boolean>(true);
  const item = useData<InvoiceValuesProps>();
  const editRef = useRef<ModalForm>(null);

  useEffect(() => {
    setLoading(true);
    getContracts(
      subDepartment,
      client,
      "",
      "work,id,start_date,end_date",
      "",
      activity
    )
      .then(({ data }) => {
        const temp = data.sort(
          (a: ContractValuesProps, b: ContractValuesProps) =>
            a.work?.client?.name.localeCompare(b.work?.client?.name || "")
        );
        setContracts(temp);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [activity, client, subDepartment]);

  useEffect(() => {
    setLoadingBills(true);
    if (contract) {
      getBills(
        `${contract.id}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "id,number,start,end,month,year,total,bill_status",
        month,
        year
      )
        .then(({ data }) => setItems(data))
        .finally(() => {
          setLoadingBills(false);
        });
    } else {
      setTimeout(() => {
        setItems([]);
        setLoadingBills(false);
      }, 500);
    }
  }, [contract, month, year]);

  const onSelectContract = (value?: ContractValuesProps) => {
    if (value) getContract(value.id!).then(({ data }) => setContract(data));
    else setContract(undefined);
  };

  const onPost = (values: InvoiceValuesProps) => {
    values.date_reference = `${values.year}-${values.month}-10`;
    return postBills(values).then(({ data }) => item.created.set(data));
  };

  const initialPostValues = () => {
    const services: ServiceInvoiceValuesProps[] = [];

    if (!isNullOrUndefined(contract)) {
      contract.service_contract.forEach(item => {
        services.push({
          description: "",
          billed: true,
          service: item,
          quantity: 0,
          price: item.quantity !== 0 ? item.price / item.quantity : 0
        });
      });
    }

    return {
      services,
      contract: contract ? contract.id : -1
    };
  };

  const insertItem = () => {
    return (
      <ModalForm
        title="Nova medição"
        type="post"
        size="xl"
        data={{}}
        form={props => (
          <>
            {contract && (
              <Form contract={contract} {...props} requestType="post" />
            )}
          </>
        )}
        onSubmit={onPost}
        initialValues={initialPostValues}
        disabled={!contract}
      />
    );
  };

  const onPut = (data: InvoiceValuesProps) => {
    data.bill_status.forecast_receipt =
      data.bill_status.forecast_receipt || null;
    return putBill(data.id!, data).then(({ data }) => item.updated.set(data));
  };

  const initialPutValues = (data: any) => {
    if (contract)
      return {
        ...data,
        bill_status: {
          ...item.selected.item?.bill_status,
          bank: item.selected.item?.bill_status.bank?.id
        }
      };
  };

  const editItem = () => {
    return (
      <ModalForm
        ref={editRef}
        title="Editar medição"
        type="put"
        size="xl"
        form={props => (
          <>
            {contract && item.selected.item && (
              <Form contract={contract} {...props} requestType="put" />
            )}
          </>
        )}
        onSubmit={onPut}
        getItemRequest={(id: number) => getBill(id)}
        initialValues={initialPutValues}
        data={item.selected.item}
      />
    );
  };

  const deleteItem = () => {
    if (item.selected.item) {
      return deleteBill(item.selected.item.id!)
        .then(res => {
          item.deleted.set(item.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }

    return Promise.reject();
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={2}>
          <SelectActivity onChange={setActivity} />
        </Col>
        <Col sm={3}>
          <InputRow label_col="auto" label="Área de serviço">
            <SelectSubDepartment onChange={setSubDepartment} />
          </InputRow>
        </Col>
        <Col sm={3}>
          <InputRow label_col="auto" label="Cliente">
            <SelectClient request={getClients} onChange={setClient} />
          </InputRow>
        </Col>
        <Col sm={2}>
          <InputRow label_col="auto" label="Mês">
            <SelectMonth onChange={setMonth} />
          </InputRow>
        </Col>
        <Col sm={2}>
          <InputRow label_col="auto" label="Ano">
            <SelectYear onChange={setYear} />
          </InputRow>
        </Col>
      </Row>
      <Card className="filter-bar">
        <CardBody>
          <Row>
            <Col sm={5}>
              <HeaderTitle title="Contratos" />
              <LoadingComponent loading={loading}>
                <TableList
                  data={contracts}
                  tableId="contracts"
                  columns={contractColumns}
                  selected={onSelectContract}
                  groupSrc={{
                    dataSrc: "work.client.name"
                  }}
                  searching={false}
                />
              </LoadingComponent>
            </Col>
            <Col sm={7}>
              <HeaderTitle title="Medições" />
              <LoadingComponent loading={loadingBills}>
                <TableList
                  data={items}
                  tableId="invoices"
                  columns={invoiceColumn}
                  selected={item.selected.set}
                  updated={item.updated.item}
                  created={item.created.item}
                  deleted={item.deleted.item}
                  dbClick={selected => {
                    item.selected.set(selected);
                    editRef.current?.open();
                  }}
                  searching={false}
                />
              </LoadingComponent>
              <ListButtonRow
                buttonsLeft={
                  <>
                    {insertItem()}
                    {editItem()}
                  </>
                }
                buttonsRight={
                  <ButtonDelete
                    disabled={!item.selected.item}
                    onClick={deleteItem}
                  />
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default Invoice;
