import React from "react";
import * as api from "../../services/api/admin";
import { InputSelectMultiple } from "../Inputs";
import { Loader } from "../Loader";

class SelectMultipleGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems() {
    api.getGroups().then(res => {
      this.setState({ items: res });
    });
  }

  options() {
    return this.state.items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map((item, index) => (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      ));
  }

  render() {
    return (
      <>
        {this.state.items.length !== 0 ? (
          <InputSelectMultiple {...this.props}>
            {this.options()}
          </InputSelectMultiple>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default SelectMultipleGroups;
