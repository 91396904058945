import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import * as api from "../../../services/api/client";
import { ButtonDelete } from "../../../components/Buttons";
import columns from "./columns";
import { LoadingComponent } from "../../../components/Loader";
import Insert from "./Insert";
import Edit from "./Edit";
import { TableList, useData } from "../../../components/Table";
import { ContainerFluid } from "../../../components/Container";
import Detail from "./Detail";

const TITLE = "Clientes";

const ClientsList = () => {
  const client = useData<ClientFormValues>();

  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<Array<ClientFormValues>>([]);
  const [dbClick, setDbClick] = useState<boolean>(false);

  const getClients = () => {
    setLoading(true);
    api.getClients().then(({ data }) => {
      setClients(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getClients();
  }, []);

  const deleteClient = () => {
    if (
      client.selected.item !== undefined &&
      client.selected.item.id !== undefined
    ) {
      return api
        .deleteClient(client.selected.item.id)
        .then(({ data }) => {
          client.deleted.set(client.selected.item);
          return Promise.resolve(data);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    } else {
      return Promise.resolve();
    }
  };

  return (
    <ContainerFluid title={TITLE}>
      <Row>
        <Col sm={8}>
          <Card>
            <CardBody>
              <LoadingComponent loading={loading}>
                <TableList
                  data={clients}
                  columns={columns}
                  selected={client.selected.set}
                  updated={client.updated.item}
                  created={client.created.item}
                  deleted={client.deleted.item}
                  dbClick={item => {
                    client.selected.set(item);
                    setDbClick(true);
                    setDbClick(false);
                  }}
                  searching={false}
                  wrap
                />
              </LoadingComponent>
              <Row className="mt-2">
                <Col className="button-list">
                  <Insert insertedItem={client.created.set} />
                  <Edit
                    item={client.selected.item!}
                    updatedItem={client.updated.set}
                    dbClick={dbClick}
                  />
                </Col>
                <Col>
                  <Row className="float-right">
                    <Col>
                      <ButtonDelete
                        disabled={!client.selected.item}
                        onClick={deleteClient}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <CardBody>
              <Detail item={client.selected.item} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContainerFluid>
  );
};

export default ClientsList;
