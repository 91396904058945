import React from "react";
import { FormikProps, Field } from "formik";
import { InputColumn, InputText, InputValidation } from "src/components/Inputs";

const Form: React.FC<FormikProps<Equipment>> = ({ errors }) => {
  return (
    <>
      <InputColumn label="Nome">
        <Field name="name" component={InputText} />
        <InputValidation message={errors.name} />
      </InputColumn>
      <InputColumn label="Descrição">
        <Field name="description" component={InputText} />
        <InputValidation message={errors.description} />
      </InputColumn>
    </>
  );
};

export default Form;
