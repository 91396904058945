import React from "react";
import { Row, Col } from "reactstrap";
import {
  HeaderTitle,
  HeaderSectionTitle
} from "../../../../components/Headers";
import CPItem from "../components/CPItem";
import { formatAgeLabel, calculateFck, CPS_AGE } from "../utils";
import { FormikProps } from "formik";
import Chart from "./Chart";
import Alert from "src/components/Alerts";

interface Props extends FormikProps<TConcreteSeries> {
  control: TConcreteControl;
}

const Form: React.FC<Props> = ({ values, setFieldValue }) => {
  const renderAgeCPS = () => {
    let oldAge = -1;
    let showBorder = false;

    const renderTitle = (age: number) => {
      oldAge = age;
      showBorder = false;
      return (
        <Col sm={12}>
          <HeaderSectionTitle title={formatAgeLabel(age)} />
        </Col>
      );
    };

    return (
      <Row>
        {values.concrete_mix[0].stress_test?.concrete_cp.map((cp, index) => {
          return (
            <>
              {oldAge !== cp.age && renderTitle(cp.age)}
              <Col sm={6}>
                <CPItem
                  name={`concrete_mix[0].stress_test.concrete_cp[${index}]`}
                  code={cp.code || 0}
                  fck={calculateFck(cp.load || 0, cp.diameter || 0).toString()}
                  index={index}
                  load={cp.load || 0}
                  showBorder={showBorder}
                  setFieldValue={setFieldValue}
                  approved={cp.approved}
                />
              </Col>
              {(showBorder = true)}
            </>
          );
        })}
      </Row>
    );
  };

  const renderFCK = () => {
    const fcks = values.concrete_mix[0].stress_test?.expected_resistance;

    return (
      <dl className="row">
        {fcks
          ?.sort((a, b) => a.age - b.age)
          .map(item => (
            <>
              <dt className="col-sm-3">{formatAgeLabel(item.age)}</dt>
              <dd className="col-sm-3">{item.expected.toPrecision(3)} MPa</dd>
            </>
          ))}
      </dl>
    );
  };

  const renderChart = () => {
    let temp: { [key: number]: number } = {};
    values.concrete_mix[0].stress_test?.expected_resistance.forEach(item => {
      temp[item.age] = item.expected;
    });

    const expected = CPS_AGE.map(key => {
      return {
        age: key,
        fck: temp[key] || undefined
      };
    });

    const cps = values.concrete_mix[0].stress_test?.concrete_cp;
    temp = {};
    cps?.forEach(item => {
      temp[item.age] = Math.max(
        temp[item.age] || 0,
        calculateFck(item.load || 0, item.diameter || 0)
      );
    });

    const reached = CPS_AGE.map(key => {
      return {
        age: key,
        fck: temp[key] || undefined
      };
    });

    return <Chart expected={expected} reached={reached} fck={values.fck} />;
  };

  return (
    <>
      <Row>
        <Col>
          {values.has_cp_missing_break.length > 0 && (
            <Alert
              color="warning"
              message={`Os seguintes CPs ainda não foram rompidos: ${values.has_cp_missing_break.join(
                ", "
              )}`}
            />
          )}
          {values.has_error_in_test.length > 0 && (
            <Alert
              color="warning"
              message={`Os seguintes CPs apresentaram erro de ensaio: ${values.has_error_in_test.join(
                ", "
              )}`}
            />
          )}
          {!values.fck_reached && (
            <Alert
              color="danger"
              message={`A série não atingiu o FCK especificado`}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={5}>
          <HeaderTitle title="Corpos de prova" />
          {renderAgeCPS()}
        </Col>
        <Col className="border-left pl-2">
          <HeaderTitle title="FCK Esperado" />
          {renderFCK()}
          <HeaderTitle title="Crescimento" />
          {renderChart()}
        </Col>
      </Row>
    </>
  );
};

export default Form;
