import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import moment from "moment";
import { TableList, useData } from "../../../components/Table";
import { cpsToBreakColumns } from "./columns";
import { getCPsToBreak } from "../../../services/api/graute";
import { LoadingComponent } from "../../../components/Loader";
import { InputColumn, InputDate } from "../../../components/Inputs";
import { ListButtonRow } from "../../../components/Buttons";
import TestingBody from "./TestingBody";

const CPsByPeriod = () => {
  const item = useData<TGrauteTestingBody>();
  const [items, setItems] = useState<TGrauteTestingBody[]>([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [maxEnd, setMaxEnd] = useState(
    moment(new Date()).add(7, "days").format("YYYY-MM-DD")
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const getItems = (start: string, end: string) => {
    getCPsToBreak(start, end)
      .then(({ data }) => setItems(data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getItems(startDate, endDate);
  }, [endDate, startDate]);

  const changeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setStartDate(value);

    const diffDays = moment(endDate).diff(moment(value), "days");

    if (diffDays > 7 || diffDays <= 0) {
      setMaxEnd(moment(value).add(7, "days").format("YYYY-MM-DD"));
    } else {
      getItems(value, endDate);
    }
  };

  const changeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setEndDate(value);

    getItems(startDate, value);
  };

  const filterCPs = () => items.filter(item => item.need_be_break);

  return (
    <Card className="filter-bar text-left">
      <CardBody>
        <Row>
          <Col sm={2}>
            <InputColumn label="Data de início">
              <InputDate
                name="date"
                onChange={changeStartDate}
                value={startDate}
                required
              />
              <small />
            </InputColumn>
          </Col>
          <Col sm={2}>
            <InputColumn
              label="Data de fim"
              create={<i id="dates" className="mdi ml-1 mdi-alert-circle" />}
            >
              <InputDate
                name="date"
                onChange={changeEndDate}
                value={endDate}
                min={startDate}
                max={maxEnd}
                required
              />
            </InputColumn>
          </Col>
          <Col sm="auto">
            <Tooltip
              className="m-tooltip-inner"
              placement="right"
              isOpen={tooltipOpen}
              target="dates"
              toggle={toggle}
            >
              Período limitado a uma semana a partir da data de início
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col>
            <LoadingComponent loading={loading}>
              <TableList
                data={filterCPs()}
                columns={cpsToBreakColumns}
                selected={item.selected.set}
                updated={item.updated.item}
                created={item.created.item}
                deleted={item.deleted.item}
                searching={false}
              />
            </LoadingComponent>
          </Col>
        </Row>
        <small>
          * Corpos de prova com idade de 63 dias aparecem para ruptura apenas se
          os CPs de 28 dias não atingiram o FCK da série.
        </small>
        <ListButtonRow
          buttonsLeft={
            <>
              <TestingBody itemToEdit={item.selected.item!} />
            </>
          }
        />
      </CardBody>
    </Card>
  );
};

export default CPsByPeriod;
