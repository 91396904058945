import React from "react";
import { Button } from "reactstrap";

interface Props {
  isSubmitting: boolean;
}

const ButtonSuccess: React.FC<Props> = ({ isSubmitting, ...props }) => (
  <Button color="success" type="submit" size="sm" {...props}>
    <i className="mdi mdi-check-all ml-1 mr-1" />
    Sucesso
  </Button>
);

ButtonSuccess.defaultProps = {
  isSubmitting: false
};

export default ButtonSuccess;
