import React from "react";
import { Row, Col } from "reactstrap";
import { formatAgeLabel } from "../../utils";
import { SmallText, H4 } from "../../../../../components/Text";

interface Props {
  age: number;
  amount: number;
  onIncrease: (age: number) => void;
  onDecrease: (age: number) => void;
}
const IncrementCP: React.FC<Props> = ({
  age,
  amount,
  onIncrease,
  onDecrease
}) => {
  return (
    <Row>
      <Col sm={12} className=" text-sm-center">
        <SmallText>{formatAgeLabel(age)}</SmallText>
        <Row className="justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-sm btn-sm-pd btn-secondary m-0 pr-1 pl-1 pt-0 pb-0 mr-1"
            onClick={() => onDecrease(age)}
          >
            <i className="mdi mdi-minus"></i>
          </button>
          <H4>{`${amount}`}</H4>
          <button
            type="button"
            className="btn btn-sm btn-sm-pd btn-secondary m-0  pr-1 pl-1 pt-0 pb-0 ml-1"
            onClick={() => onIncrease(age)}
          >
            <i className="mdi mdi-plus"></i>
          </button>
        </Row>
      </Col>
    </Row>
  );
};

export default IncrementCP;
