import React from "react";
import { Row, Col } from "reactstrap";
import { Field } from "formik";
import { HeaderTitle } from "../../../../../components/Headers";
import {
  InputColumn,
  InputDecimal,
  InputDate
} from "../../../../../components/Inputs";
import CPCode from "../components/CPCode";
import IncrementCP from "../components/IncrementCP";

interface StressTestProps {
  name: string;
  values: TConcreteStressTest;
  control: TConcreteControl;
  setFieldValue: (name: string, value: any) => void;
}

const StressTest: React.FC<StressTestProps> = ({
  name,
  values,
  control,
  setFieldValue
}) => {
  const onDecrease = (age: number) => {
    const temp = values.concrete_cp;
    let ageIndex: number | undefined = undefined;
    values.concrete_cp.forEach((item, index) => {
      if (item.age === age) {
        ageIndex = index;
      }
    });

    if (ageIndex !== undefined) {
      temp.splice(ageIndex, 1);
      setFieldValue(`${name}.concrete_cp`, temp);
    }
  };

  const onIncrease = (age: number) => {
    const temp = values.concrete_cp;
    temp.push({ age: age, code: undefined });
    setFieldValue(`${name}.concrete_cp`, temp);
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <HeaderTitle title="Ensaio de compressão" />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Row>
            <Col sm={6}>
              <InputColumn label="FCK (MPa)">
                <Field
                  name={`${name}.fck`}
                  component={InputDecimal}
                  onChange={(value: number) => {
                    setFieldValue(`${name}.fck`, value);
                  }}
                  required
                />
              </InputColumn>
            </Col>
            <Col sm={6}>
              <InputColumn label="Data de moldagem">
                <Field
                  name={`${name}.molding_date`}
                  component={InputDate}
                  required
                />
              </InputColumn>
            </Col>
          </Row>
          <HeaderTitle title="Corpos de prova" className="mb-0 mt-2" />
          <Row>
            {values.concrete_cp
              .sort((a, b) => a.age - b.age)
              .map((item, index) => (
                <Col sm={6}>
                  <CPCode
                    name={`${name}.concrete_cp[${index}]`}
                    index={index}
                    values={item}
                    control={control}
                    border={"mt-2"}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              ))}
          </Row>
        </Col>
        <Col sm={4}>
          {control.concrete_age
            .filter(item => item.quantity > 0)
            .sort((a, b) => a.age - b.age)
            .map(({ age }) => (
              <IncrementCP
                age={age}
                amount={
                  values.concrete_cp.filter(item => item.age === age).length
                }
                onDecrease={onDecrease}
                onIncrease={onIncrease}
              />
            ))}
        </Col>
      </Row>
    </>
  );
};

export default StressTest;
