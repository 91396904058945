import React from "react";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";
import { postClient } from "../../../services/api/client";
import { FormikActions } from "formik";

interface Props {
  insertedItem: (item: ClientFormValues) => void;
}

const Insert: React.FC<Props> = ({ insertedItem }) => {
  const onSubmit = (
    values: ClientFormValues,
    { setFieldError }: FormikActions<object>
  ) => {
    return postClient(values)
      .then(({ data }) => {
        insertedItem(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        if (data.error?.type === "integrity_error") {
          setFieldError("integrity_error", "Cliente já cadastrado");
        }

        return Promise.reject();
      });
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastro de novo cliente"
      data={{}}
      form={props => <Form {...props} />}
      initialValues={() => {
        return {};
      }}
      onSubmit={onSubmit}
    />
  );
};

export default Insert;
