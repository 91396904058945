/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ModalForm } from "src/components/Modal";
import Form from "./Form";
import { FormikActions } from "formik";
import { postLot, getLot, putLot } from "src/services/api/block_concrete";
import { initialLot } from "../init";

const EditLot: React.FC<EditType<TBlockConcreteLot>> = ({
  item,
  dbClick,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();

  useEffect(() => {
    if (dbClick) {
      modalRef.current?.open();
    }
  }, [dbClick]);

  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    return putLot(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="lg"
      title="Editar lote"
      data={item}
      getItemRequest={id => getLot(id)}
      form={props => {
        return <Form {...props} />;
      }}
      initialValues={item => {
        return item;
      }}
      onSubmit={onSubmit}
    />
  );
};

interface InsertProps extends InsertType<TBlockConcreteLot> {
  contractId: number;
}

const InsertLot: React.FC<InsertProps> = ({ contractId, insertedItem }) => {
  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    let breakSamples = [];

    for (let i = 0; i < values.d7; i++) {
      breakSamples.push({ age: 7 });
    }
    for (let i = 0; i < values.d14; i++) {
      breakSamples.push({ age: 14 });
    }
    for (let i = 0; i < values.d28; i++) {
      breakSamples.push({ age: 28 });
    }

    values["break_samples"] = breakSamples;

    return postLot(contractId, values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      type="post"
      size="lg"
      title="Cadastro de novo lote"
      buttonLabel="Novo lote"
      form={props => {
        return <Form {...props} />;
      }}
      initialValues={() => {
        return {
          ...initialLot,
          contract: contractId
        };
      }}
      onSubmit={onSubmit}
    />
  );
};

export { InsertLot, EditLot };
