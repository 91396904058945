import React, { useEffect, useState } from "react";
import { Formik, FormikActions } from "formik";
import initialValues from "./init";
import Form from "./Form";
import { useLimitsActions } from "./context";
import * as api from "../../../../../services/api/characterization";

interface Props {
  sample: SoilSample;
  hasUpdated: (sampleId: number) => void;
}

const PhysicalLimit: React.FC<Props> = ({ sample, hasUpdated }) => {
  const [, limitsActions] = useLimitsActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [test, setTest] = useState<PhysicalLimits>();

  useEffect(() => {
    limitsActions.getPhysicalTest(sample.id!)?.then(data => {
      setTest(data);
      setLoading(false);
    });
  }, [limitsActions, sample.id]);

  const onSubmit = (values: any, actions: FormikActions<object>) => {
    if (values.id === undefined) {
      if (values.is_not_liquid) {
        delete values.liquidity_tests;
      }
      if (values.is_not_plastic) {
        delete values.plasticity_tests;
      }
      values = {
        ...values,
        sample: sample.id
      };

      api
        .postLimits(sample.id!, values)
        .then(({ data }: { data: PhysicalLimits }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      api
        .putLimits(values.id, values)
        .then(({ data }: { data: PhysicalLimits }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const formatTest = (test?: PhysicalLimits) => {
    if (test !== undefined) {
      return {
        ...test,
        liquidity_tests: test.liquidity_tests.map(liq => {
          return {
            ...liq,
            capsule_study: {
              ...liq.capsule_study,
              capsule: liq.capsule_study.capsule.id
            }
          };
        }),
        plasticity_tests: test.plasticity_tests.map(plas => {
          return {
            ...plas,
            capsule_study: {
              ...plas.capsule_study,
              capsule: plas.capsule_study.capsule.id
            }
          };
        })
      };
    }

    return undefined;
  };

  return (
    <>
      {loading ? (
        <div>carregando....</div>
      ) : (
        <Formik
          onSubmit={onSubmit}
          initialValues={formatTest(test) || initialValues}
          enableReinitialize
          render={(props: any) => <Form {...props} />}
        />
      )}
    </>
  );
};

export default PhysicalLimit;
