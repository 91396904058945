import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { HeaderTitle } from "../../../../components/Headers";
import { getConcreteSummary } from "../../../../services/api/concrete";
import { Loader } from "../../../../components/Loader";
import { toLocateNumber } from "../../../../utils/format";

type TConcreteItem = {
  age: number;
  quantity: number;
  min_serie: number;
  max_serie: number;
};

const ConcreteAge = ({ item }: { item: TConcreteItem }) => {
  return (
    <tr>
      <td className="text-left">{`CPs com ${item.age} dias`}</td>
      <td>{toLocateNumber(item.quantity, 0, "-")}</td>
      <td>{toLocateNumber(item.min_serie, 0, "-")}</td>
      <td>{toLocateNumber(item.max_serie, 0, "-")}</td>
    </tr>
  );
};

interface Props {
  contract: ContractValuesProps;
  start: string;
  end: string;
}

const Concrete: React.FC<Props> = props => {
  const [summary, setSummary] = useState<any>();

  useEffect(() => {
    getConcreteSummary(
      props.contract.id!,
      props.start,
      props.end,
      "1"
    ).then(({ data }) => setSummary(data));
  }, [props.contract.id, props.end, props.start]);

  return (
    <Row>
      <Col>
        <HeaderTitle title="Controle executado no período" />
        {summary ? (
          <>
            {Object.keys(summary).length > 0 ? (
              <table className="table table-sm text-center align-middle">
                <thead>
                  <tr>
                    <th className="text-left">Descrição</th>
                    <th>Quantidade</th>
                    <th>Série inicial</th>
                    <th>Série final</th>
                  </tr>
                </thead>
                <tbody>
                  {summary.period.ages
                    .sort((a: TConcreteItem, b: TConcreteItem) => {
                      return a.age > b.age;
                    })
                    .map((item: TConcreteItem) => (
                      <ConcreteAge item={item} />
                    ))}
                  <tr>
                    <td className="text-left">
                      <strong>Total de ensaios</strong>
                    </td>
                    <td>{summary.period.tested}</td>
                    <td />
                    <td />
                  </tr>
                </tbody>
              </table>
            ) : (
              <>Controle de concreto não disponível</>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Col>
      <Col>
        <HeaderTitle title="Controle total da obra" />
        {summary ? (
          <>
            {Object.keys(summary).length > 0 ? (
              <table className="table table-sm text-center align-middle">
                <thead>
                  <tr>
                    <th className="text-left">Descrição</th>
                    <th>Quantidade</th>
                    <th>Série inicial</th>
                    <th>Série final</th>
                  </tr>
                </thead>
                <tbody>
                  {summary.contract.ages
                    .sort((a: TConcreteItem, b: TConcreteItem) => {
                      return a.age > b.age;
                    })
                    .map((item: TConcreteItem) => (
                      <ConcreteAge item={item} />
                    ))}
                  <tr>
                    <td className="text-left">
                      <strong>Total de ensaios</strong>
                    </td>
                    <td>{toLocateNumber(summary.contract.total, 0, "-")}</td>
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td className="text-left">
                      <strong>Total de cps no tanque</strong>
                    </td>
                    <td>
                      {toLocateNumber(
                        summary.contract.total - summary.contract.tested,
                        0,
                        "- "
                      )}
                    </td>
                    <td />
                    <td />
                  </tr>
                </tbody>
              </table>
            ) : (
              <>Controle de concreto não disponível</>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
};

export default Concrete;
