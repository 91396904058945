import React, { useEffect, useState } from "react";
import Form from "./Form";
import { ModalForm } from "../../../../components/Modal";
import { getControlById, putControl } from "../../../../services/api/concrete";
import { initialControl } from "../Series/Form/init";
import { postControl } from "../../../../services/api/concrete";
import { ButtonOption } from "../../../../components/Buttons";
import { Container } from "src/components/Container";
import { Card, CardBody } from "reactstrap";
import { Formik } from "formik";
import { LoadingComponent } from "src/components/Loader";
import { useParams } from "react-router";
import { HeaderTitle } from "src/components/Headers";

interface PutControlProps {
  control: TConcreteControl;
  onUpdated: (value: TConcreteControl) => void;
}

const PutControl: React.FC<PutControlProps> = ({ control, onUpdated }) => {
  const onSubmit = (value: TConcreteControl) => {
    if (typeof value.contract !== "number") {
      value = { ...value, contract: value.contract.id! };
    }

    if (value.id !== undefined) {
      return putControl(value.id, value).then(({ data }) => onUpdated(data));
    }

    return Promise.reject();
  };

  return (
    <ModalForm
      type="put"
      size="lg"
      title="Editar controle"
      buttonLabel="Editar controle"
      data={control}
      getItemRequest={(id: number) => getControlById(id)}
      form={props => <Form {...props} />}
      initialValues={data => data}
      onSubmit={onSubmit}
      renderButton={onClick => (
        <ButtonOption className="btn-white" onClick={onClick}>
          Editar controle
        </ButtonOption>
      )}
    />
  );
};

interface PostControlProps {
  contractId: number;
  onCreated: (value: TConcreteControl) => void;
}

const PostControl: React.FC<PostControlProps> = ({ contractId, onCreated }) => {
  const onSubmit = (value: TConcreteControl) => {
    return postControl(contractId, value).then(({ data }) => onCreated(data));
  };

  return (
    <ModalForm
      type="post"
      size="lg"
      title="Cadastro de novo controle"
      buttonLabel="Novo controle"
      data={{}}
      form={props => <Form {...props} />}
      initialValues={() => {
        return {
          ...initialControl,
          contract: contractId
        };
      }}
      onSubmit={onSubmit}
    />
  );
};

const PAGE_TITLE = "Controle de concreto";

const ConcreteControl = () => {
  const [item, setItem] = useState<TConcreteControl>();
  const [loading, setLoading] = useState<boolean>(false);
  let { controlId } = useParams<any>();

  useEffect(() => {
    setLoading(true);
    getControlById(controlId)
      .then(({ data }) => {
        setItem(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container title={PAGE_TITLE}>
      <Card>
        <CardBody>
          {item !== undefined && (
            <LoadingComponent loading={loading}>
              <HeaderTitle
                title="Controle"
                className="mt-2 pt-2 border-bottom"
              />
              <Formik
                onSubmit={() => {}}
                initialValues={item}
                enableReinitialize
                render={props => <Form {...props} />}
              />
            </LoadingComponent>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {item !== undefined && (
            <LoadingComponent loading={loading}>
              <HeaderTitle
                title="Tipos de concreto"
                className="pb-2 pt-2 border-bottom"
              />
            </LoadingComponent>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export { PostControl, PutControl, ConcreteControl };
