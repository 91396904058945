import React, { useState } from "react";
import { Alert as A } from "reactstrap";

interface Props {
  message: string;
  color: "danger" | "success" | "warning";
}

const Alert: React.FC<Props> = ({ message, color }) => {
  const [visible, setVisible] = useState<boolean>(true);

  const onDismiss = () => {
    setVisible(false);
  };

  return (
    <A color={color} isOpen={visible} toggle={onDismiss}>
      {message}
    </A>
  );
};

export default Alert;
