import { createStore, createHook, StoreActionApi } from "react-sweet-state";
import * as api from "../../services/api/contract";

const REQUEST_DELAY = 5 * 1000;

interface State {
  resourcesAllocation?: ContractAllocation;
  loading: boolean;
}

const initialState: State = {
  loading: false
};

const actions = {
  getResourcesAllocation: (contract: number) => async ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (getState().resourcesAllocation) {
      return;
    }

    setState({
      loading: true
    });
    api
      .getAllocation(contract)
      .then(({ data }) => {
        setState({
          resourcesAllocation: data[0]
        });
      })
      .catch(() => {})
      .finally(() => {
        setState({
          loading: false
        });
      });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "contract"
});

const useContractActions = createHook(Store, {
  selector: null
});

const useContractAllocations = createHook(Store, {
  selector: (state: State) => state.resourcesAllocation
});

export { useContractActions, useContractAllocations };
