const initialLot = {
  break_samples: [],
  absorption_samples: [],
  fabrication_date: undefined,
  fbk: undefined,
  width: undefined,
  height: undefined,
  length: undefined,
  provider: "",
  hole_position: undefined,
  amount_acceptance: undefined,
  amount_break: undefined,
  amount_absorption: undefined,
  discarded: false,
  notes: "",
  place: "",
  trace: "",
  invoice: "",
  prism: false,
  lot: undefined,
  collect_date: undefined,
  validation: false,
  calculate_conformity: true,
  group: undefined,
  contract: undefined
};

export { initialLot };
