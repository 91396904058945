const initialLot = {
  break_samples: [],
  absorption_samples: [],
  fabrication_date: "",
  fbk: undefined,
  width: undefined,
  height: undefined,
  length: undefined,
  provider: "",
  hole_position: "",
  amount_acceptance: undefined,
  amount_break: undefined,
  amount_absorption: undefined,
  discarded: false,
  notes: "",
  place: "",
  trace: "",
  invoice: "",
  prism: false,
  lot: "",
  collect_date: "",
  validation: false,
  calculate_conformity: true,
  wall_structure: "",
  contract: undefined
};

export { initialLot };
