const penetrations = [
  {
    penetration: 0.63,
    time: 30
  },
  {
    penetration: 1.27,
    time: 60
  },
  {
    penetration: 1.9,
    time: 90
  },
  {
    penetration: 2.54,
    time: 120
  },
  {
    penetration: 3.17,
    time: 150
  },
  {
    penetration: 3.81,
    time: 180
  },
  {
    penetration: 4.44,
    time: 210
  },
  {
    penetration: 5.08,
    time: 240
  },
  {
    penetration: 6.35,
    time: 300
  },
  {
    penetration: 7.62,
    time: 360
  },
  {
    penetration: 8.89,
    time: 420
  },
  {
    penetration: 10.16,
    time: 480
  },
  {
    penetration: 11.43,
    time: 540
  },
  {
    penetration: 12.7,
    time: 600
  }
];

export { penetrations };
