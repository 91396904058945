import * as crypto from "crypto";

const keys = {
  TOKEN_KEY: "aXoiGozVvLAxWFnr"
};

const dumpToken = (token: any) => {
  localStorage.setItem(keys.TOKEN_KEY, JSON.stringify(token));
};

const dumpTempReportData = (data: ReportStorageData): string => {
  const key = `${crypto.randomBytes(20).toString("hex")}`;
  localStorage.setItem(key, JSON.stringify(data));

  return key;
};

const loadTempReportData = (key: string): ReportStorageData | null => {
  const data = localStorage.getItem(key);
  if (data !== null) {
    return JSON.parse(data);
  }

  return null;
};

export { dumpToken, dumpTempReportData, loadTempReportData };
