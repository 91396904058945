import { Field, Form, Formik, FormikActions } from "formik";
import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ButtonSave, ListButtonRow } from "src/components/Buttons";
import { HeaderTitle } from "src/components/Headers";
import {
  InputPassword,
  InputRow,
  InputText,
  InputValidation
} from "src/components/Inputs";
import { useUser } from "src/context/admin/user";
import { changePassword, editUser } from "src/services/api/admin";

const PersonalData = () => {
  const [user, userActions] = useUser();

  const onSubmit = (values: any, { setSubmitting }: FormikActions<any>) => {
    setSubmitting(true);
    editUser(values.first_name, values.last_name)
      .then(() => {
        userActions.me();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={user.data}
      enableReinitialize
      render={({ isSubmitting }) => (
        <Form>
          <HeaderTitle className="border-bottom" title="Seu Perfil" />
          <InputRow label_col="5" label="Primeiro nome">
            <Field name="first_name" component={InputText} />
          </InputRow>
          <InputRow label_col="5" label="Último nome">
            <Field name="last_name" component={InputText} />
          </InputRow>
          <div className="mb-3" />
          <ListButtonRow
            buttonsRight={<ButtonSave isSubmitting={isSubmitting} />}
          />
        </Form>
      )}
    />
  );
};

const PasswordForm = () => {
  const onSubmit = (
    values: any,
    { setSubmitting, setFieldError, setFieldValue }: FormikActions<any>
  ) => {
    setSubmitting(true);
    changePassword(
      values.new_password,
      values.old_password,
      values.confirm_password
    )
      .then(() => {
        setFieldValue("old_password", "");
        setFieldValue("new_password", "");
        setFieldValue("confirm_password", "");
      })
      .catch(({ data }) => {
        Object.keys(data.message).forEach(key => {
          setFieldError(key, data.message[key]);
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        old_password: "",
        new_password: "",
        confirm_password: ""
      }}
      render={({ errors, isSubmitting }) => (
        <Form className="mt-3">
          <HeaderTitle className={"border-bottom"} title="Senha" />
          <InputRow label_col="5" label="Senha antiga">
            <Field name="old_password" component={InputPassword} required />
            <InputValidation message={errors.old_password} />
          </InputRow>
          <InputRow label_col="5" label="Nova senha">
            <Field name="new_password" component={InputPassword} required />
            <InputValidation message={errors.new_password} />
          </InputRow>
          <InputRow label_col="5" label="Repetir nova senha">
            <Field name="confirm_password" component={InputPassword} required />
            <InputValidation message={errors.confirm_password} />
          </InputRow>
          <div className="mb-3" />
          <ListButtonRow
            buttonsRight={<ButtonSave isSubmitting={isSubmitting} />}
          />
        </Form>
      )}
    />
  );
};

const Profile = () => {
  useEffect(() => {}, []);

  return (
    <Row>
      <Col>
        <PersonalData />
        <PasswordForm />
      </Col>
    </Row>
  );
};

export default Profile;
