import React from "react";
import { Row, Col } from "reactstrap";
import { Form, FormikProps } from "formik";
import ExecutionBar from "../components/ExecutionBar";
import { HeaderTitle } from "../../../../../components/Headers";
import CapsuleStudy, { CapsuleStudyLabels } from "../components/CapsuleStudy";
import { toLocateNumber } from "../../../../../utils/format";
import { InputRow } from "../../../../../components/Inputs";
import {
  useCompaction,
  useGreatHumidity,
  useCompactionActions,
  useHumidities
} from "./context";
import CylinderStudy, { CylinderStudyLabels } from "./components/CylinderStudy";
import Chart from "./Chart";
import { useCbrActions } from "../Cbr/context";

const AvgHumidity = () => {
  const [compaction] = useCompaction();

  return (
    <Row>
      <Col sm={5}>
        <InputRow label="Umidade média (%)" />
      </Col>
      <Col>
        <InputRow
          className="text-center"
          label={toLocateNumber(compaction.avgHumidity * 100, 2, 0)}
        />
      </Col>
    </Row>
  );
};

const CompactionForm: React.FC<FormikProps<Compaction>> = props => {
  const [, compactionActions] = useCompactionActions();
  const [greatHumidity] = useGreatHumidity();
  const [humidities] = useHumidities();
  const [, cbrActions] = useCbrActions();

  const renderCapsuleStudy = (index: number, capsuleValue: any) => (
    <Col>
      <CapsuleStudy
        errors={props.errors}
        name={`hygroscopic_capsules[${index}]`}
        setFieldValue={props.setFieldValue}
        onHumidityChange={(value: number) =>
          compactionActions.setHygroscopicHumidity(value, index)
        }
        values={capsuleValue}
      />
    </Col>
  );

  const renderHygroscopic = () => {
    const { hygroscopic_capsules } = props.values;

    return (
      <>
        <HeaderTitle title="UMIDADE" className="text-center" />
        <Row>
          <Col sm={5}>
            <CapsuleStudyLabels />
          </Col>
          {renderCapsuleStudy(0, hygroscopic_capsules[0])}
          {renderCapsuleStudy(1, hygroscopic_capsules[1])}
        </Row>
        <AvgHumidity />
      </>
    );
  };

  const renderCapsules = () => {
    const { capsules } = props.values;

    return (
      <>
        <HeaderTitle className="text-center" title="Determinação da humidade" />
        <Row>
          <Col sm={3}>
            <CapsuleStudyLabels />
          </Col>
          {[...new Array(5).fill(0)].map((_, index) => (
            <Col>
              <CapsuleStudy
                name={`capsules[${index}]`}
                errors={props.errors}
                setFieldValue={props.setFieldValue}
                onHumidityChange={(value: number) => {
                  compactionActions.setHumidity(value, index);
                  cbrActions.setHumidity(value, index, greatHumidity);
                }}
                values={capsules[index] ? capsules[index] : {}}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  const renderCylinders = () => {
    const { cylinders } = props.values;
    return (
      <>
        <HeaderTitle
          className="text-center"
          title="Determinação da densidade"
        />
        <Row>
          <Col sm={3}>
            <CylinderStudyLabels />
          </Col>
          <>
            {cylinders.map((_, index) => (
              <Col>
                <CylinderStudy
                  name={`cylinders[${index}]`}
                  errors={props.errors}
                  setFieldValue={props.setFieldValue}
                  humidity={humidities[index]}
                  onDryDensityChange={(value: number) =>
                    compactionActions.setDensities(value, index)
                  }
                  values={cylinders[index] ? cylinders[index] : {}}
                />
              </Col>
            ))}
          </>
        </Row>
      </>
    );
  };

  return (
    <Form>
      <ExecutionBar isSubmitting={props.isSubmitting} />
      <Row>
        <Col sm={5}>
          <Row>
            <Col>{renderHygroscopic()}</Col>
          </Row>
          <Row>
            <Col>
              <Chart />
            </Col>
          </Row>
        </Col>
        <Col sm={7}>
          {renderCapsules()}
          {renderCylinders()}
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(
  CompactionForm,
  (props, nextProps) => JSON.stringify(props) === JSON.stringify(nextProps)
);
