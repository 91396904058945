import React from "react";
import { ModalForm } from "../../../../components/Modal";
import { initialControl } from "../init";
import Form from "./Form";
import {
  getControlById,
  putControl,
  postControl
} from "../../../../services/api/graute";
import { ButtonOption } from "../../../../components/Buttons";
import { FormikActions } from "formik";

const EditControl: React.FC<EditType<TGrauteControl>> = ({
  item,
  updatedItem
}) => {
  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    return putControl(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      type="put"
      size="lg"
      title="Editar controle"
      data={item}
      getItemRequest={id => getControlById(id)}
      form={props => <Form {...props} />}
      initialValues={item => item}
      onSubmit={onSubmit}
      renderButton={onClick => (
        <ButtonOption className="btn-white" onClick={onClick}>
          Editar controle
        </ButtonOption>
      )}
    />
  );
};

interface InsertProps extends InsertType<TGrauteControl> {
  contractId: number;
}

const InsertControl: React.FC<InsertProps> = ({ contractId, insertedItem }) => {
  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    return postControl(contractId, values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      type="post"
      size="lg"
      title="Cadastro de novo controle"
      buttonLabel="Novo controle"
      data={{}}
      form={props => <Form {...props} />}
      initialValues={() => {
        return {
          ...initialControl,
          contract: contractId
        };
      }}
      onSubmit={onSubmit}
    />
  );
};

export { InsertControl, EditControl };
