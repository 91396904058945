import React from "react";

const DIV_NAME = "reports";

interface Props {
  color?: "light" | "white";
}

const ReportButton: React.FC<Props> = ({ color }) => {
  return (
    <button
      className={`btn btn-light btn-${color} btn-sm`}
      type="button"
      data-toggle="collapse"
      data-target={`#${DIV_NAME}`}
      aria-expanded="false"
      aria-controls="reports"
    >
      <i className=" mdi mdi-file-document " />
      Relatórios
    </button>
  );
};

ReportButton.defaultProps = {
  color: "light"
};

export default ReportButton;
