import React, { useState, useEffect } from "react";
import SelectBase from "./SelectBase";
import { getBanks } from "../../services/api/bills";

const SelectBank: React.FC<SelectType<BankValues>> = props => {
  const [items, setItems] = useState<BankValues[]>([]);

  const getItems = () => {
    getBanks().then(({ data }) => setItems(data));
  };

  useEffect(() => {
    getItems();
  }, []);

  const option = (item: BankValues) => {
    return {
      value: item.id,
      label: `${item.name} | ${item.office} | ${item.operation} | ${item.account}`
    };
  };

  const options = () => {
    return items.map(item => option(item));
  };

  return (
    <SelectBase
      {...props}
      placeholder="Banco..."
      options={options()}
      getItems={getItems}
      onClick={props.onChange}
      newItem={props.newItem}
      deletedItem={props.deletedItem}
      updatedItem={props.updatedItem}
    />
  );
};

export default SelectBank;
