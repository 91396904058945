import React from "react";
import Input from "./Input";

interface Props {
  field: any;
  form: string;
  name: string;
  required: boolean;
  disabled: boolean;
}

const InputPassword: React.FC<Props> = ({
  field,
  name,
  required,
  disabled
}) => (
  <Input
    name={name}
    type="password"
    disabled={disabled}
    required={required}
    field={field}
  />
);

InputPassword.defaultProps = {
  required: false,
  disabled: false
};

export default InputPassword;
