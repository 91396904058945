import React from "react";
import { Scatter } from "react-chartjs-2";
import { useGranulometry } from "./context";

const X_LABEL = "PENEIRA (mm)";
const Y_LABEL = "PASSANTE (%)";

const options = {
  type: "line",
  showLines: true,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 60,
          stepSize: 10
        },
        scaleLabel: {
          display: true,
          labelString: Y_LABEL
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          callback(value: number) {
            const remain = value / 10 ** Math.floor(Math.log10(value));
            if (remain === 1 || remain === 2) return value;
            return "";
          }
        },
        scaleLabel: {
          display: true,
          labelString: X_LABEL
        },
        display: true,
        type: "logarithmic"
      }
    ]
  },
  legend: {
    display: false,
    position: "bottom",
    usePointStyle: true
  },
  layout: {
    padding: {
      bottom: 30,
      top: 30,
      left: 20
    }
  },
  tooltips: {
    mode: "index"
  }
};

const Chart = () => {
  const [granulometry] = useGranulometry();
  const { strainers } = granulometry;

  const getDataSet = () => {
    const dataset: { [key: string]: any } = [];

    let data: { x: number; y: number }[] = [];

    data = strainers.map(item => {
      return { x: item.gap, y: item.percentage * 100 };
    });

    dataset.push({
      label: `FCK`,
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "coral",
      borderColor: "coral",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data
    });

    return dataset;
  };

  return (
    <div className="canvas-size-gran">
      <Scatter
        data={{
          datasets: getDataSet()
        }}
        options={options}
      />
    </div>
  );
};

export default Chart;
