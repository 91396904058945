import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { LoadingComponent } from "../../../components/Loader";
import { TableList, useData } from "../../../components/Table";
import columns from "./columns";
import {
  getBills,
  deleteBill,
  getBill,
  putBill
} from "../../../services/api/bills";
import { getContract } from "../../../services/api/contract";
import {
  ListButtonRow,
  ButtonDelete,
  ButtonOption
} from "../../../components/Buttons";
import Form from "../Invoice/Form";
import { ModalForm } from "../../../components/Modal";
import InvoiceControlFilter from "./InvoiceControlFilter";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Controle de medições";

const Invoices = () => {
  const [items, setItems] = useState<InvoiceValuesProps[]>([]);
  const [loading, setLoading] = useState(true);
  const item = useData<InvoiceValuesProps>();
  const [contract, setContract] = useState<ContractValuesProps>();
  const editRef = useRef<ModalForm>(null);

  useEffect(() => {
    getBills(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "bill_status,total,month,year,start,end,id,work,client,contract"
    )
      .then(({ data }) => setItems(data))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {});

  const onPut = (data: InvoiceValuesProps) => {
    if (data.id !== undefined) {
      return putBill(data.id, data).then(({ data }) => item.updated.set(data));
    }

    return Promise.reject();
  };

  const initialPutValues = (data: any) => {
    return { ...data };
  };

  const editItem = () => {
    return (
      <ModalForm
        ref={editRef}
        title="Editar medição"
        type="put"
        size="xl"
        form={props => (
          <>
            {contract && item.selected.item && (
              <Form contract={contract} requestType="put" {...props} />
            )}
          </>
        )}
        onSubmit={onPut}
        getItemRequest={(id: number) => getBill(id)}
        initialValues={initialPutValues}
        data={item.selected.item}
      />
    );
  };

  const deleteItem = () => {
    if (item.selected.item) {
      return deleteBill(item.selected.item.id!)
        .then(res => {
          item.deleted.set(item.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }

    return Promise.reject();
  };

  useEffect(() => {
    if (item.selected.item) {
      getContract(item.selected.item.contract).then(({ data }) =>
        setContract(data)
      );
    }
  }, [item.selected.item]);

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <ListButtonRow
                buttonsRight={
                  <ButtonOption
                    className="float-right"
                    data-toggle="collapse"
                    data-target="#filter"
                    aria-expanded="true"
                    aria-controls="filter"
                  >
                    <i className="mdi mdi-filter " /> Filtrar
                  </ButtonOption>
                }
              />
              <InvoiceControlFilter
                onFilter={setItems}
                filtering={setLoading}
              />
              <LoadingComponent loading={loading}>
                <TableList
                  data={items}
                  tableId="invoices"
                  columns={columns}
                  selected={item.selected.set}
                  updated={item.updated.item}
                  created={item.created.item}
                  deleted={item.deleted.item}
                  dbClick={selected => {
                    item.selected.set(selected);
                    editRef.current?.open();
                  }}
                  searching={false}
                />
              </LoadingComponent>
            </Col>
          </Row>
          <ListButtonRow
            buttonsLeft={<>{editItem()}</>}
            buttonsRight={
              <ButtonDelete
                disabled={!item.selected.item}
                onClick={deleteItem}
              />
            }
          />
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default Invoices;
