import React, { useState } from "react";
import { Form, FormikProps, Field } from "formik";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import ExecutionBar from "../components/ExecutionBar";
import { PenetrationRead, PenetrationsHeader } from "./components/Penetration";
import { ExpansionHeader, ExpansionRead } from "./components/Expansion";
import {
  InputRow,
  InputColumn,
  InputDecimal
} from "../../../../../components/Inputs";
import { EditTestAccessPermission } from "../../../../../components/Permissions";
import { toLocateNumber } from "../../../../../utils/format";
import { HeaderTitle } from "../../../../../components/Headers";
import Chart from "./Chart";
import { useCbrActions } from "./context";
import { useGreatHumidity } from "../Compaction/context";

const CbrForm: React.FC<FormikProps<Cbr>> = props => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [, cbrActions] = useCbrActions();
  const [greatHumidity] = useGreatHumidity();

  const renderNavItem = (index: number) => (
    <NavItem key={index}>
      <NavLink
        className={classnames({
          active: currentTab === index
        })}
        onClick={() => {
          setCurrentTab(index);
        }}
      >
        {`Cilindro 0${index + 1}`}
      </NavLink>
    </NavItem>
  );

  const renderPenetrationTabContent = (index: number) => (
    <TabPane tabId={index}>
      <Row>
        <Col>
          <PenetrationsHeader />
          {props.values.cbr_studies[index].penetrations.map(
            (item, penIndex) => (
              <PenetrationRead
                name={`cbr_studies[${index}].penetrations[${penIndex}]`}
                time={item.time}
                penetration={item.penetration}
                setFieldValue={props.setFieldValue}
                value={item.read}
                onChangeDetermined={value => {
                  if (item.time === 120 || item.time === 240) {
                    cbrActions.setCbr(value, index, greatHumidity);
                  }
                }}
              />
            )
          )}
        </Col>
      </Row>
    </TabPane>
  );

  const renderExpansionTabContent = (index: number) => {
    const { expansions } = props.values.cbr_studies[index];

    return (
      <TabPane tabId={index}>
        <Row>
          <Col>
            <InputColumn label="Altura do corpo de prova (mm)">
              <EditTestAccessPermission
                renderNoAccess={() => (
                  <InputRow
                    label={toLocateNumber(
                      props.values.cbr_studies[index].height,
                      2
                    )}
                  />
                )}
              >
                <Field
                  name={`cbr_studies[${index}].height`}
                  component={InputDecimal}
                  onChange={(value: number) => {
                    props.setFieldValue(`cbr_studies[${index}].height`, value);
                  }}
                  required
                />
              </EditTestAccessPermission>
            </InputColumn>
            <ExpansionHeader />
            {expansions.map((item, expIndex) => (
              <ExpansionRead
                name={`cbr_studies[${index}].expansions[${expIndex}]`}
                height={100}
                setFieldValue={props.setFieldValue}
                read={item.read}
                onChangeExpansion={(value: number) => {
                  if (expIndex === expansions.length - 1) {
                    cbrActions.setExpansion(value, index);
                  }
                }}
              />
            ))}
          </Col>
        </Row>
      </TabPane>
    );
  };

  const renderTabs = (content: (index: number) => void, title: string) => {
    return (
      <>
        <HeaderTitle title={title} />
        <Nav tabs>
          {[...new Array(5).fill(0)].map((_, index) => (
            <>{renderNavItem(index)}</>
          ))}
        </Nav>
        <TabContent activeTab={currentTab}>
          {[...new Array(5).fill(0)].map((_, index) => (
            <>{content(index)}</>
          ))}
        </TabContent>
      </>
    );
  };

  return (
    <Form>
      <ExecutionBar isSubmitting={props.isSubmitting} />
      <Row>
        <Col sm={7}>
          {renderTabs(renderPenetrationTabContent, "Ensaios de penetração")}
        </Col>
        <Col sm={5}>
          {renderTabs(renderExpansionTabContent, "Ensaios de expansão")}
          <Chart />
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(
  CbrForm,
  (props, nextProps) => JSON.stringify(props) === JSON.stringify(nextProps)
);
