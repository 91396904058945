import React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";

const mask = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/
];
const maskValidation = /^([0-9]{11})$/;

const validate = (value: string) => {
  let errorMessage;

  if (!maskValidation.test(value)) {
    errorMessage = "CPF inválido";
  }
  return errorMessage;
};

interface Props {
  name: string;
  required: boolean;
  disabled?: boolean;
}

const InputCPF: React.FC<Props> = ({ name, required, disabled }) => (
  <Field
    name={name}
    validate={validate}
    render={({ field, form: { setFieldValue } }: any) => (
      <MaskedInput
        className="form-control form-control-sm"
        {...field}
        disabled={disabled}
        required={required}
        mask={mask}
        onChange={event => {
          let { value } = event.target;
          value = value.replace(/[.]+/g, "");
          value = value.replace("-", "");
          setFieldValue(name, value);
        }}
      />
    )}
  />
);

export default InputCPF;
