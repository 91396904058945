import React from "react";
import { FormikActions } from "formik";
import Form from "./Form";
import { ModalForm } from "src/components/Modal";
import {
  getCalibrationById,
  putCalibrations,
  postCalibrations
} from "src/services/api/characterization";

const InsertCalibration: React.FC<InsertType<PressCalibration>> = ({
  insertedItem
}) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return postCalibrations(values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastrar nova calibração"
      initialValues={() => {
        return {};
      }}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
    />
  );
};

const EditCalibration: React.FC<EditType<PressCalibration>> = ({
  item,
  updatedItem
}) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return putCalibrations(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="put"
      size="md"
      data={item ? { id: item } : undefined}
      getItemRequest={id => getCalibrationById(id)}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
      initialValues={item => item}
      title="Editar calibração"
    />
  );
};

export { InsertCalibration, EditCalibration };
