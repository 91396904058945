import React from "react";
import { Field } from "formik";

interface Props {
  label: string;
  name: string;
  checked: boolean;
  disabled: boolean;
  onClick?: () => void;
}

const InputButtonCheckbox: React.FC<Props> = ({
  label,
  name,
  checked,
  disabled,
  onClick
}) => (
  <div className="btn-group-toggle" onClick={onClick}>
    <label className="btn btn-sm btn-secondary btn-block mb-2" htmlFor={name}>
      <Field
        className="custom-control-input"
        name={name}
        id={name}
        type="checkbox"
        checked={checked}
        onChange={onClick}
        disabled={disabled}
      />
      {label}
    </label>
  </div>
);

InputButtonCheckbox.defaultProps = {
  disabled: false,
  checked: false
};

export default InputButtonCheckbox;
