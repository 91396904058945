import React, { useEffect, useState } from "react";
import * as api from "../../services/api/admin";
import SelectBase from "./SelectBase";

const SelectSubDepartment: React.FC<SelectType<
  SubDepartmentFormValues
>> = props => {
  const [items, setItems] = useState<Array<SubDepartmentFormValues>>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    api.getSubDepartments().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({ value: item.id, label: item.name }));
  };

  return (
    <SelectBase
      placeholder="Sub departamento..."
      options={options()}
      onClick={props.onChange}
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      getItems={getItems}
      {...props}
    />
  );
};

export default SelectSubDepartment;
