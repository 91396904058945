import api, { sendFile } from "./api";
import axios from "axios";

export function setCurrentApplication(slug: string) {
  localStorage.setItem("current_application", slug);
}

export function getCurrentApplication() {
  return localStorage.getItem("current_application");
}

export function getApplication(id: number) {
  const endpoint = `/apps/${id}/`;
  return api.get(endpoint);
}

export function getMyApplication() {
  const endpoint = `/apps/my/`;
  return api.get(endpoint);
}

export function putApplication(id: number, data: any) {
  const endpoint = `/apps/${id}/`;
  return api.put(endpoint, data);
}

export function getApplications() {
  const endpoint = "/apps/";
  return api.get(endpoint);
}

export function postApplications(data: any) {
  const endpoint = "/apps/";
  return api.post(endpoint, data);
}

export function sendFiles(id: number, data: any, progress?: any) {
  const endpoint = `/apps/${id}/files/`;

  return sendFile(endpoint, data, progress);
}

export function deleteFiles(id: number, data: any) {
  const endpoint = `/apps/${id}/files/`;

  return api.delete(endpoint, data);
}

export function createAccount(data: any) {
  const endpoint = `create_account/`;

  return api.post(endpoint, data);
}

export function cnpjInfo(cnpj: string) {
  const endpoint = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;

  return axios.get(endpoint);
}
