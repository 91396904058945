import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Formik, Form, Field } from "formik";
import {
  InputColumn,
  InputValidation,
  InputInteger
} from "../../../../components/Inputs";
import { ButtonSave } from "../../../../components/Buttons";
import * as api from "../../../../services/api/admin";

const initialValues = {
  aso_warning_time: 0
};

const GeneralSetting = () => {
  const [settings, setSettings] = useState<AdminGeneralValues>(initialValues);

  useEffect(() => {
    api.getDailySetting().then(({ data }) => {
      setSettings(data[0] || {});
    });
  }, []);

  return (
    <Formik
      onSubmit={(
        values: AdminGeneralValues,
        { setFieldError, setSubmitting }
      ) => {
        api
          .postDailySetting(values)
          .then(({ data }) => setSettings(data))
          .catch(({ data }) => {
            Object.keys(data).forEach(key => {
              setFieldError(key, data[key]);
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
      initialValues={{ ...settings }}
      enableReinitialize
      render={({ errors, isSubmitting }) => (
        <Form className="form-horizontal">
          <Row>
            <Col sm={6}>
              <InputColumn label="Vencimento do ASO (Mês)">
                <Field
                  name="aso_warning_time"
                  required
                  component={InputInteger}
                />
                <InputValidation message={errors.aso_warning_time} />
              </InputColumn>
            </Col>
          </Row>

          <ButtonSave isSubmitting={isSubmitting} />
        </Form>
      )}
    />
  );
};

export default GeneralSetting;
