import axios, { AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const TOKEN_KEY = "aXoiGozVvLAxWFnr";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token.access}`;
  }

  return config;
});

// Add a response interceptor
api.interceptors.response.use(
  (
    response: AxiosResponse<any>
  ): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
    return response;
  },
  (error: any): any => {
    return Promise.reject(error.response);
  }
);

const login = async (login: string, password: string) => {
  const endpoint = `login/`;

  return api
    .post(endpoint, { username: login, password })
    .then(({ data }) => {
      api.defaults.headers.common.Authorization = `Bearer ${data.token.access}`;

      return Promise.resolve(data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

const logout = () => {
  const endpoint = `logout/`;
  const data = { refresh_token: getToken().refresh };

  return api
    .post(endpoint, data)
    .then(({ data }) => {
      localStorage.removeItem(TOKEN_KEY);
      return Promise.resolve(data);
    })
    .catch(res => {
      return Promise.reject(res);
    });
};

const resetPasswordEmail = (email: string) => {
  const endpoint = `password_reset/`;

  return api.post(endpoint, { email });
};

const validateToken = (token: string) => {
  const endpoint = `password_reset/validate_token/`;

  return api.post(endpoint, { token });
};

const confirmNewPassword = (token: string, password: string) => {
  const endpoint = `password_reset/confirm/`;

  return api.post(endpoint, { token, password });
};

const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return JSON.parse(token);
  }

  return token;
};

const isAuthenticated = () => {
  return getToken() !== null;
};

export {
  login,
  logout,
  getToken,
  isAuthenticated,
  validateToken,
  confirmNewPassword,
  resetPasswordEmail
};
