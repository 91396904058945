import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import * as math from "../../../../../utils/math";
import * as api from "../../../../../services/api/characterization";

interface State {
  strainers: TStrainer[];
  percentages: {
    gap: number;
    percentage: number;
    projected: number;
    withheld: number;
    accrued: number;
  }[];
  loading: boolean;
  count: number;
}

const initialStrainers = (): TStrainer[] => {
  return [];
};

const initialState: State = {
  loading: false,
  strainers: initialStrainers(),
  percentages: [],
  count: 0
};

const actions = {
  getGranulometry: (sampleId: number) => async ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (getState().loading) {
      return;
    }

    setState({
      loading: true
    });

    return api
      .getGranulometry(sampleId)
      .then(({ data }: { data: Granulometry[] }) => {
        if (data.length > 0) {
          return data[0];
        }

        return undefined;
      })
      .finally(() => setState({ loading: false }));
  },

  setPercentagePassing: (values: Strainer, totalWeight: number) => async ({
    setState
  }: StoreActionApi<State>) => {
    setState({
      percentages: math.getBgsAccruedPercentage(values, totalWeight)
    });
  },

  setProject: (value: number, gap: number) => async ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    let { percentages } = getState();
    percentages = percentages.map(item => {
      if (item.gap === gap) {
        return {
          ...item,
          projected: value
        };
      }

      return item;
    });

    setState({
      percentages
    });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "bgs"
});

const BGSSubscriber = createSubscriber(Store);
const useBGS = createHook(Store);

export { BGSSubscriber, useBGS };
