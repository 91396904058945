import React, { useState, useEffect } from "react";
import * as api from "../../services/api/admin";
import SelectBase from "./SelectBase";

const SelectDepartment: React.FC<SelectType<DepartmentFormValues>> = props => {
  const [items, setItems] = useState<Array<DepartmentFormValues>>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    api.getDepartments().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({
        value: item.id,
        label: item.name
      }));
  };

  return (
    <SelectBase
      options={options()}
      placeholder="Departamento..."
      onClick={props.onChange}
      getItems={getItems}
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      {...props}
    />
  );
};

export default SelectDepartment;
