import api from "./api";

export const ASCENDENTE = "asc";
export const DESCENDENTE = "des";

export function getBills(
  contract?: string,
  client?: string,
  work?: string,
  startMonth?: string,
  startYear?: string,
  endMonth?: string,
  endYear?: string,
  startDate?: string,
  endDate?: string,
  status?: string,
  quantity?: string,
  ordering?: string,
  fields?: string,
  month?: string,
  year?: string
) {
  const endpoint = `/invoices/`;

  const params = {
    contract: contract,
    client: client,
    work: work,
    start_month: startMonth,
    start_year: startYear,
    end_month: endMonth,
    end_year: endYear,
    start_date: startDate,
    end_date: endDate,
    status: status,
    quantity: quantity,
    ordering: ordering,
    fields: fields,
    month: month,
    year: year
  };

  return api.get(endpoint, { params });
}

export function postBills(data: any) {
  const endpoint = "/invoices/";
  return api.post(endpoint, data);
}

export function getBill(id: number) {
  const endpoint = `/invoices/${id}/`;
  return api.get(endpoint);
}

export function deleteBill(id: number) {
  const endpoint = `/invoices/${id}/`;
  return api.delete(endpoint);
}

export function putBill(id: number, data: any) {
  const endpoint = `/invoices/${id}/`;
  return api.put(endpoint, data);
}

export function getUnits() {
  const endpoint = "/invoices/units/";
  return api.get(endpoint);
}

export function postUnits(data: any) {
  const endpoint = "/invoices/units/";
  return api.post(endpoint, data);
}

export function putUnits(id: number, data: any) {
  const endpoint = `/invoices/units/${id}/`;
  return api.put(endpoint, data);
}

export function runBill(contract: number, start: string, end: string) {
  const endpoint = `/invoices/run/${contract}/?start=${start}&end=${end}`;
  return api.get(endpoint);
}

export function summaryBill(
  contract: number,
  start: string = "",
  end: string = ""
) {
  const endpoint = `/invoices/summary/${contract}/?start=${start}&end=${end}`;
  return api.get(endpoint);
}

export function getBanks() {
  const endpoint = `/invoices/banks/`;
  return api.get(endpoint);
}

export function getBank(id: number) {
  const endpoint = `/invoices/banks/${id}/`;
  return api.get(endpoint);
}

export function postBank(data: any) {
  let endpoint = `/invoices/banks/`;
  return api.post(endpoint, data);
}

export function deleteBank(id: number) {
  let endpoint = `/invoices/banks/${id}/`;
  return api.delete(endpoint);
}

export function putBank(id: number, data: any) {
  let endpoint = `/invoices/banks/${id}/`;
  return api.put(endpoint, data);
}

export function getStatistics() {
  const endpoint = `/invoices/statistics/`;
  return api.get(endpoint);
}
