import React, { useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import EmployeeForm from "./EmployeeForm";
import { getEmployee, putEmployee } from "../../../../services/api/admin";
import { LoadingComponent } from "../../../../components/Loader";

const EmployeePUT: React.FC<PUTFormModal<EmployeeFormType>> = props => {
  const [employee, setEmployee] = useState<EmployeeFormType>();
  const [loading, setLoading] = useState<boolean>(true);

  const getItem = useCallback(() => {
    if (props.item.id !== undefined) {
      getEmployee(props.item.id).then(({ data }) => {
        setEmployee(data);
        setLoading(false);
      });
    }
  }, [props.item.id]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  return (
    <LoadingComponent loading={loading}>
      <>
        {employee !== undefined && (
          <Formik
            onSubmit={(values: EmployeeFormType, { setFieldError }) => {
              if (values.id !== undefined) {
                putEmployee(values.id, values)
                  .then(({ data }) => {
                    props.updatedItem(data);
                    props.toggle();
                  })
                  .catch(({ data }) => {
                    Object.keys(data).forEach(key => {
                      setFieldError(key, data[key]);
                    });
                  });
              }
            }}
            enableReinitialize
            initialValues={{
              ...employee,
              job_title:
                employee.job_title && typeof employee.job_title !== "number"
                  ? employee.job_title.id
                  : undefined,
              documents: employee.documents || []
            }}
            render={props => <EmployeeForm {...props} />}
          />
        )}
      </>
    </LoadingComponent>
  );
};

export default EmployeePUT;
