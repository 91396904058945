import React from "react";
import { Card, CardBody } from "reactstrap";
import { ItemToManager } from "../../../components/ItemContent";
import { ContainerFluid } from "../../../components/Container";
import Capsules from "./Capsules";
import Cylinders from "./Cylinders";
import Equipments from "./Equipments";
import Layers from "./Layers";
import Materials from "./Materials";
import Sands from "./Sands";
import Cbrs from "./CbrCalibrations";
import Funnels from "./Funnel";
import ResourcesAllocation from "./ResourcesAllocation";

const PAGE_TITLE = "Opcões de laboratório";

const TechSettings = () => {
  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Card>
        <CardBody>
          <ItemToManager title="Capsulas" notes="">
            <Capsules />
          </ItemToManager>
          <hr />

          <ItemToManager
            title="Cilindros"
            notes="Equipamentos para uso em ensaios de compactação de solos e ensaios de densidade 'in-situ' pelo método Hilf"
          >
            <Cylinders />
          </ItemToManager>

          <hr />
          <ItemToManager title="Equipamentos" notes="">
            <Equipments />
          </ItemToManager>

          <hr />
          <ItemToManager title="Camadas" notes="">
            <Layers />
          </ItemToManager>
          <hr />
          <ItemToManager title="Materiais" notes="">
            <Materials />
          </ItemToManager>
          <hr />
          <ItemToManager
            title="Funis"
            notes="Evite alterar os dados de um item já cadastrado. A alteração de seus dados terão influência em ensaios já executados."
          >
            <Funnels />
          </ItemToManager>
          <hr />
          <ItemToManager
            title="Areias"
            notes="Evite alterar os dados de um item já cadastrado. A alteração de seus dados terão influência em ensaios já executados."
          >
            <Sands />
          </ItemToManager>
          <hr />
          <ItemToManager
            title="Constante de CBR"
            notes="Após inserida não poderá ser editada. Para alterar exclua a constante cadastrada. Só será permitido remover uma constante se ela ainda não foi usada em um ensaio de CBR. A data de calibração será usada para escolher a constante correta dada a data de execução do ensaio."
          >
            <Cbrs />
          </ItemToManager>
          <hr />
          <ItemToManager title="Alocação de recursos" notes="">
            <ResourcesAllocation />
          </ItemToManager>
          <hr />
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default TechSettings;
