import React from "react";
import { Row, Col } from "reactstrap";
import Granulometry from "./Granulometry";
import GranulometryBGS from "./GranulometryBGS";
import Compaction from "./Compaction";
import PhysicalLimit from "./PhysicalLimit";
import Cbr from "./Cbr";
import { HeaderTitle } from "../../../../components/Headers";
import { formatSimpleDate, toLocateNumber } from "../../../../utils/format";

interface Props {
  sample?: SoilSample;
}

const Sample: React.FC<Props> = ({ sample }) => {
  return (
    <>
      {sample && (
        <>
          <Row>
            <Col>
              <HeaderTitle title="INFORMAÇÕES DA AMOSTRA" />
              <hr />
              <Row>
                <Col sm={6}>
                  <dl className="row">
                    <dt className="col-sm-4">Código da amostra</dt>
                    <dd className="col-sm-8">{sample.code}</dd>

                    <dt className="col-sm-4">Tipo do material</dt>
                    <dd className="col-sm-8">
                      {sample.material_type ? sample.material_type.name : ""}
                    </dd>

                    <dt className="col-sm-4">Data de finalização</dt>
                    <dd className="col-sm-8">-</dd>
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <HeaderTitle title="INFORMAÇÕES DA COLETA" />
              <hr />
              <Row>
                <Col sm={6}>
                  <dl className="row">
                    <dt className="col-sm-4">Local da coleta</dt>
                    <dd className="col-sm-8">
                      {sample.collection_place || "-"}
                    </dd>

                    <dt className="col-sm-4">Trecho da coleta</dt>
                    <dd className="col-sm-8">
                      {sample.collection_stretch || "-"}
                    </dd>

                    <dt className="col-sm-4">Camada</dt>
                    <dd className="col-sm-8">
                      {sample.layer ? sample.layer.name : "-"}
                    </dd>

                    <dt className="col-sm-4">Latitude</dt>
                    <dd className="col-sm-8">{sample.latitude || "-"}</dd>

                    <dt className="col-sm-4">Estaca</dt>
                    <dd className="col-sm-8">{sample.stake || "-"}</dd>

                    <dt className="col-sm-4">Equipamento de coleta</dt>
                    <dd className="col-sm-8">
                      {sample.equipment ? sample.equipment.name : ""}
                    </dd>
                  </dl>
                </Col>
                <Col sm={6}>
                  <dl className="row">
                    <dt className="col-sm-4">Data da coleta</dt>
                    <dd className="col-sm-8">
                      {formatSimpleDate(sample.collection_date) || "-"}
                    </dd>

                    <dt className="col-sm-4">Profundidade</dt>
                    <dd className="col-sm-8">{sample.depth || "-"}</dd>

                    <dt className="col-sm-4">Faixa</dt>
                    <dd className="col-sm-8">{sample.band || "-"}</dd>

                    <dt className="col-sm-4">Longitude</dt>
                    <dd className="col-sm-8">{sample.longitude || "-"}</dd>

                    <dt className="col-sm-4">Furo</dt>
                    <dd className="col-sm-8">{sample.hole || "-"}</dd>

                    <dt className="col-sm-4">Saco</dt>
                    <dd className="col-sm-8">{sample.bag || "-"}</dd>
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <HeaderTitle title="RESUMO DA AMOSTRA" />
              {/* <hr /> */}
              {sample.summary ? (
                <table className="table table-sm text-center">
                  <thead>
                    <tr>
                      <th colSpan={4}>Ensaios físicos</th>
                      <th colSpan={2}>Classificação</th>
                      <th colSpan={5}>Compactação em laboratório</th>
                    </tr>
                    <tr>
                      <th>LL (%)</th>
                      <th>LP (%)</th>
                      <th>IP</th>
                      <th>IG</th>
                      <th>HRB</th>
                      <th>UNIFICADO</th>
                      <th>Nº GOLPES</th>
                      <th>máx (g/cm³)</th>
                      <th>h ótima (%)</th>
                      <th>Exp. (%)</th>
                      <th>I.S.C (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary.liquidity_limit * 100),
                          2,
                          "NL"
                        )}
                      </td>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary.plasticity_limit * 100),
                          2,
                          "NP"
                        )}
                      </td>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary.plasticity_index * 10),
                          2,
                          "NL/NP"
                        )}
                      </td>
                      <td>{sample.summary.group_index}</td>
                      <td>{sample.summary.hrb}</td>
                      <td>{sample.summary.unificada}</td>
                      <td>{sample.proctor || "-"}</td>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary?.great_density),
                          3,
                          "-"
                        )}
                      </td>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary.great_humidity * 100),
                          2,
                          "-"
                        )}
                      </td>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary.expansion * 100),
                          2,
                          "-"
                        )}
                      </td>
                      <td>
                        {toLocateNumber(
                          Math.max(0, sample.summary.isc * 100),
                          3,
                          "-"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p className=" text-center text-muted font-14">
                  Nenhum ensaio executado para apresentação do resumo
                </p>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export { Granulometry, GranulometryBGS, PhysicalLimit, Compaction, Cbr };
export default Sample;
