import React, { useEffect, useState } from "react";
import * as api from "../../services/api/admin";
import SelectBase from "./SelectBase";

interface Props extends SelectType<EmployeeFormType> {}

const SelectEmployee: React.FC<Props> = ({
  newItem,
  deletedItem,
  updatedItem,
  onChange,
  ...props
}) => {
  const [items, setItems] = useState<EmployeeFormType[]>([]);
  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    api.getEmployees("", api.ACTIVE).then(({ data }) => setItems(data));
  };

  const options = () => {
    return items
      .sort((a, b) => a.short_name!.localeCompare(b.short_name!))
      .map(item => ({ value: item.id!, label: item.short_name! }));
  };

  const onSelectEmployee = (event: any) => {
    const id = event;

    let employee = {};
    if (id) {
      employee = items.filter(c => c.id === id)[0];
    }
    onChange && onChange(employee);
  };

  return (
    <SelectBase
      placeholder="Funcionário..."
      onClick={onSelectEmployee}
      options={options()}
      newItem={newItem}
      deletedItem={deletedItem}
      updatedItem={updatedItem}
      getItems={getItems}
      {...props}
    />
  );
};

export default SelectEmployee;
