import { formatSimpleDate, toLocateNumber } from "../../../utils/format";

const contract = [
  { title: "#", data: "id" },
  { title: "Cliente", data: "work.client.name", visible: false },
  { title: "Obra", data: "work.name" }
];

const invoice = [
  { title: "#", data: "number" },
  {
    title: "Início",
    data: "start",
    render(data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Fim",
    data: "end",
    render(data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Mês",
    data: "month"
  },
  { title: "Ano", data: "year" },
  {
    title: "Total",
    data: "total",
    render(data: string) {
      return toLocateNumber(data, 2, "R$ 0,00", "R$ ");
    }
  },
  {
    title: "Status",
    data: "bill_status.status",
    render(data: string) {
      return data === "0"
        ? "Em aprovação"
        : data === "1"
        ? "Aberta"
        : data === "2"
        ? "Enviado"
        : data === "3"
        ? "Recebido"
        : data === "4"
        ? "Recebido parcial"
        : data === "5"
        ? "Faturado"
        : "-";
    }
  }
];

export { contract, invoice };
