/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { HeaderTitle } from "../../../../components/Headers";
import { getDailies } from "../../../../services/api/admin";
import { formatSimpleDate } from "../../../../utils/format";
import moment from "moment";
var locale = window.navigator.language;
moment.locale(locale);

interface Props {
  contract: ContractValuesProps;
  start: string;
  end: string;
}

type DailySummary = {
  day: string;
  employee: EmployeeFormType;
  service: ServiceContractValuesProps;
};

const Daily: React.FC<Props> = props => {
  const [dailies, setDailies] = useState<DailySummary[]>([]);

  useEffect(() => {
    getDailies(
      props.start,
      props.end,
      `${props.contract.id}`
    ).then(({ data }) => setDailies(data));
  }, []);

  return (
    <Row>
      <Col>
        <HeaderTitle title="Controle executado no período" />
        <table className="table table-sm text-center align-middle">
          <thead>
            <tr>
              <th>Data</th>
              <th>Dia da semana</th>
              <th>Funcionário</th>
              <th>Serviço</th>
            </tr>
          </thead>
          <tbody>
            {dailies.map(item => (
              <tr>
                <td>{formatSimpleDate(item.day)}</td>
                <td>{moment(new Date(item.day)).format("dddd")}</td>
                <td>{item.employee.short_name}</td>
                <td>{item.service.service.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default Daily;
