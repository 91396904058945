import api, { BASE_URL } from "./api";

export const FIRED = "1";
export const ACTIVE = "0";
export const IS_TEAM_LEADER = 1;
export const NOT_TEAM_LEADER = 0;
export const IS_NOT_CLIENT = 0;
export const IS_CLIENT = 1;
export const TEAM_CONTROL = 1;
export const NOT_TEAM_CONTROL = 0;
export const DAILY_CONTROL = 1;
export const NOT_DAILY_CONTROL = 0;

export function getDepartments() {
  const endpoint = "/admin_details/departments/";
  return api.get(endpoint);
}

export function postDepartment(data: any) {
  const endpoint = "/admin_details/departments/";
  return api.post(endpoint, data);
}

export function getDepartment(id: number) {
  const endpoint = `/admin_details/departments/${id}/`;
  return api.get(endpoint);
}

export function deleteDepartment(id: number) {
  const endpoint = `/admin_details/departments/${id}/`;
  return api.delete(endpoint);
}

export function putDepartment(id: number, data: any) {
  const endpoint = `/admin_details/departments/${id}/`;
  return api.put(endpoint, data);
}

export function getSubDepartments() {
  const endpoint = "/admin_details/sub_departments/";
  return api.get(endpoint);
}

export function postSubDepartment(data: any) {
  const endpoint = "/admin_details/sub_departments/";
  return api.post(endpoint, data);
}

export function getSubDepartment(id: number) {
  const endpoint = `/admin_details/sub_departments/${id}/`;
  return api.get(endpoint);
}

export function deleteSubDepartment(id: number) {
  const endpoint = `/admin_details/sub_departments/${id}/`;
  return api.delete(endpoint);
}

export function putSubDepartment(id: number, data: any) {
  const endpoint = `/admin_details/sub_departments/${id}/`;
  return api.put(endpoint, data);
}

export function getJobTitles() {
  const endpoint = "/employees/job_titles/";
  return api.get(endpoint);
}

export function postJobTitle(data: any) {
  const endpoint = "/employees/job_titles/";
  return api.post(endpoint, data);
}

export function getJobTitle(id: number) {
  const endpoint = `/employees/job_titles/${id}/`;
  return api.get(endpoint);
}

export function deleteJobTitle(id: number) {
  const endpoint = `/employees/job_titles/${id}/`;
  return api.delete(endpoint);
}

export function putJobTitle(id: number, data: any) {
  const endpoint = `/employees/job_titles/${id}/`;
  return api.put(endpoint, data);
}

export function getDocuments() {
  const endpoint = "/employees/documents/";
  return api.get(endpoint);
}

export function postDocument(data: any) {
  const endpoint = "/employees/documents/";
  return api.post(endpoint, data);
}

export function getDocument(id: number) {
  const endpoint = `/employees/documents/${id}/`;
  return api.get(endpoint);
}

export function putDocument(id: number, data: any) {
  const endpoint = `/employees/documents/${id}/`;
  return api.put(endpoint, data);
}

export function getEmployees(
  team_leader = "",
  fired = "",
  sub_department = "",
  start_date = "",
  end_date = "",
  daily_control = ""
) {
  const endpoint =
    `/employees/?team_leader=${team_leader}` +
    `&fired=${fired}` +
    `&sub_department=${sub_department}` +
    `&start_date=${start_date}` +
    `&end_date=${end_date}` +
    `&daily_control=${daily_control}`;
  return api.get(endpoint);
}

export function postEmployee(data: any) {
  const endpoint = "/employees/";
  return api.post(endpoint, data);
}

export function getEmployee(id: number) {
  const endpoint = `/employees/${id}/`;
  return api.get(endpoint);
}

export function putEmployee(id: number, data: any) {
  const endpoint = `/employees/${id}/`;
  return api.put(endpoint, data);
}

export function deleteEmployee(id: number) {
  const endpoint = `/employees/${id}/`;
  return api.delete(endpoint);
}

export function getDailies(
  start_date: string = "",
  end_date: string = "",
  contract: string = ""
) {
  const endpoint = `/employees/daily/?start_date=${start_date}&end_date=${end_date}&contract=${contract}`;
  return api.get(endpoint);
}

export function postDaily(data: any) {
  const endpoint = "/employees/daily/";
  return api.post(endpoint, data);
}

export function getDaily(id: number) {
  const endpoint = `/employees/daily/${id}/`;
  return api.get(endpoint);
}

export function putDaily(id: number, data: any) {
  const endpoint = `/employees/daily/${id}/`;
  return api.put(endpoint, data);
}

export function getUsers(is_client = "") {
  const endpoint = `/users/?is_client=${is_client}`;
  return api.get<User[]>(endpoint);
}

export function postUser(data: any) {
  const endpoint = `/users/`;
  return api.post(endpoint, data);
}

export function registerUser(data: any) {
  const endpoint = `/users/register/`;
  return api.post(endpoint, data);
}

export function putUser(id: number, data: any) {
  const endpoint = `/users/${id}/`;
  return api.put(endpoint, data);
}

export function deleteUser(id: number) {
  const endpoint = `/users/${id}/`;
  return api.delete(endpoint);
}

export function revokeAccess(data: any) {
  const endpoint = `/users/revoke/`;
  return api.post(endpoint, data);
}

export function getUser(id: number) {
  const endpoint = `/users/${id}/`;
  return api.get(endpoint);
}

export function verifyAndInviteUser(email: string) {
  const endpoint = `/users/give_access/?email=${email}`;
  return api.get(endpoint);
}

export function confirmUser(currentPassword: string, newPassword: string) {
  const endpoint = `/users/confirm/`;
  return api.post(endpoint, {
    current_password: currentPassword,
    new_password: newPassword
  });
}

export function getGroups() {
  const endpoint = "/users/groups/";
  return api.get(endpoint);
}

export function postGroup(data: any) {
  const endpoint = "/users/groups/";
  return api.post(endpoint, data);
}

export function putGroup(id: number, data: any) {
  const endpoint = `/users/groups/${id}/`;
  return api.put(endpoint, data);
}

export function getDailySetting() {
  const endpoint = "/employees/daily_settings/";
  return api.get(endpoint);
}

export function postDailySetting(data: any) {
  const endpoint = "/employees/daily_settings/";
  return api.post(endpoint, data);
}

export function getPermissions() {
  const endpoint = "/users/permissions/all/";
  return api.get(endpoint);
}

export function getWeeklyExpense(date: any) {
  const endpoint = `/employees/weekly_expense/?date=${date}`;
  return api.get(endpoint);
}

export function postWeeklyExpense(data: any) {
  const endpoint = `/employees/weekly_expense/`;
  return api.post(endpoint, data);
}

export function getDailyWeekly(date: any) {
  const endpoint = `/employees/daily_weekly/?date=${date}`;
  return api.get(endpoint);
}

export function me() {
  const endpoint = `${BASE_URL}me/`;
  return api.get(endpoint);
}

export function editUser(firstName: string, lastName: string) {
  const endpoint = `${BASE_URL}me/`;
  return api.put(endpoint, { first_name: firstName, last_name: lastName });
}

export function changePassword(
  newPassword: string,
  oldPassword: string,
  confirmPassword: string
) {
  const endpoint = "users/password/";
  const data = {
    new_password: newPassword,
    old_password: oldPassword,
    confirm_password: confirmPassword
  };

  return api.put(endpoint, data);
}
