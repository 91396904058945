/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik, FormikActions } from "formik";
import initialValues from "./init";
import Form from "./Form";
import { useCbrActions } from "./context";

import * as api from "../../../../../services/api/characterization";

interface Props {
  sample: SoilSample;
  hasUpdated: (sampleId: number) => void;
}

const Cbr: React.FC<Props> = ({ sample, hasUpdated }) => {
  const [, cbrActions] = useCbrActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [test, setTest] = useState<Cbr>();

  useEffect(() => {
    cbrActions.getCbrTest(sample.id!)?.then(data => {
      setTest(data);
      setLoading(false);
    });
  }, [sample.id]);

  const onSubmit = (values: any, actions: FormikActions<object>) => {
    values = {
      ...values,
      sample: sample.id
    };

    if (values.id === undefined) {
      api
        .postCbr(sample.id!, values)
        .then(({ data }: { data: Cbr }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      api
        .putCbr(values.id, values)
        .then(() => {
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <div>carregando....</div>
      ) : (
        <Formik
          onSubmit={onSubmit}
          initialValues={test || initialValues}
          enableReinitialize
          render={(props: any) => <Form {...props} />}
        />
      )}
    </>
  );
};

export default Cbr;
