import React, { useEffect, useRef } from "react";

interface Props {
  field: any;
  name: string;
  required: boolean;
  disabled: boolean;
  type: string;
  step?: number;
  id?: string;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: any) => void;
}

const Input: React.FC<Props> = ({
  field,
  name,
  required = false,
  disabled = false,
  type = "text",
  step = 0,
  id,
  onChange,
  onKeyDown,
  ...props
}) => {
  const mRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.autoFocus) {
      mRef.current?.focus();
    }
  }, [props.autoFocus]);

  return (
    <>
      <input
        {...field}
        ref={mRef}
        className="form-control form-control-sm"
        type={type}
        disabled={disabled}
        required={required}
        step={step}
        id={id}
        onChange={
          (onChange && onChange) || (field && field.onChange && field.onChange)
        }
        onKeyDown={onKeyDown}
        {...props}
      />
    </>
  );
};

Input.defaultProps = {
  required: false,
  disabled: false,
  type: "text",
  step: 0
};

export default Input;
