import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { ContainerFluid } from "../../../components/Container";
import {
  getApplications,
  setCurrentApplication
} from "../../../services/api/applications";
import * as Sentry from "@sentry/react";

const PAGE_TITLE = "Aplicações";

const Applications = () => {
  const [applications, setApplications] = useState<ApplicationValues[]>([]);

  useEffect(() => {
    getApplications().then(({ data }) => setApplications(data));
  }, []);

  const onSelectApplication = (app: ApplicationValues) => {
    setCurrentApplication(app.slug);

    Sentry.setExtras({
      slug: app.slug,
      name: app.name
    });
  };

  const renderApplication = (app: ApplicationValues) => (
    <Card>
      <CardBody>
        <div className="mb-3" style={{ height: 70 }}>
          <img
            src={
              app.company_logo ||
              `${process.env.PUBLIC_URL}/static/assets/images/default_logo.png`
            }
            alt="logo"
            className="rounded app-img"
          ></img>
        </div>

        <a
          href={`${app.slug}/panel`}
          className="stretched-link"
          onClick={() => onSelectApplication(app)}
        >
          <h4 className="mb-1 font-20">{app.name.toUpperCase()}</h4>
        </a>
      </CardBody>
    </Card>
  );

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row className="justify-content-center text-center m-auto w-75">
        <Col sm={12}>
          <div className="text-center w-75 m-auto">
            <span>
              <img
                src={process.env.PUBLIC_URL + "/static/assets/images/logo.svg"}
                alt=""
                height="70"
              />
            </span>
          </div>
          <div className="text-center p-2">
            <p className="text-muted mb-4 mt-3">
              Escolha um de seus aplicativos
            </p>
          </div>
        </Col>
        {applications.map(item => (
          <Col key={item.id} sm={5}>
            {renderApplication(item)}
          </Col>
        ))}
      </Row>
    </ContainerFluid>
  );
};
export default Applications;
