/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Form, Formik } from "formik";
import {
  InputCheckbox,
  InputColumn,
  InputSelect
} from "../../../components/Inputs";

import {
  SelectMultipleSubDepartment,
  SelectMonth,
  SelectYear,
  SelectMultipleStatus,
  SelectMultipleClient,
  SelectMultipleWork
} from "../../../components/Selects";
import { getClients } from "../../../services/api/client";
import { getWorks } from "../../../services/api/work";
import { getBills } from "../../../services/api/bills";
import { ButtonOption } from "../../../components/Buttons";
import { fastToken } from "src/services/api/core";
import { invoiceControlReport } from "src/services/reports";
import { useParams } from "react-router-dom";

const REPORT_TYPE = {
  CLIENT_REPORT: 1,
  DEPARTMENT_REPORT: 2,
  PREVISION_REPORT: 3
};

interface Props {
  onFilter: (values: InvoiceValuesProps[]) => void;
  filtering: (value: boolean) => void;
}

const InvoiceControlFilter: React.FC<Props> = ({ onFilter, filtering }) => {
  const [client, setClient] = useState("");
  const [work, setWork] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [subDepartments, setSubDepartments] = useState("");
  const [status, setStatus] = useState("");
  const [newVersion, setNewVersion] = useState<boolean>(true);
  const [, setReportType] = useState();
  const { appSlug } = useParams<{ appSlug: string }>();

  useEffect(() => {
    filterBills();
  }, [
    client,
    work,
    startMonth,
    startYear,
    endYear,
    endMonth,
    subDepartments,
    status
  ]);

  const filterBills = () => {
    filtering(true);
    getBills(
      undefined,
      client,
      work,
      startMonth,
      startYear,
      endMonth,
      endYear,
      "",
      "",
      status
    )
      .then(({ data }) => onFilter(data))
      .finally(() => filtering(false));
  };

  const onChangeClient = (value: any) => {
    value = value.map((item: any) => item.value);
    setClient(value.join(",") || "");
  };

  const onChangeWork = (value: any) => {
    value = value.map((item: any) => item.value);
    setWork(value || "");
  };

  const onChangeStartMonth = (value: string) => {
    setStartMonth(value || "");
  };

  const onChangeStartYear = (value: string) => {
    setStartYear(value || "");
  };

  const onChangeEndMonth = (value: string) => {
    setEndMonth(value || "");
  };

  const onChangeEndYear = (value: string) => {
    setEndYear(value || "");
  };

  const onChangeStatus = (value: any) => {
    const options = value.map((option: any) => option.value);

    setStatus(options.join(","));
  };

  const onChangeDepartment = (value: any) => {
    const options = value.map((option: any) => option.value);

    setSubDepartments(options.join(",") || "");
  };

  const getReportURL = () => {
    fastToken().then(({ data }) => {
      invoiceControlReport(
        appSlug,
        data.access,
        client,
        work,
        startMonth,
        startYear,
        endMonth,
        endYear,
        subDepartments,
        status
      );
    });
  };

  return (
    <div className="collapse" id="filter">
      <Formik
        initialValues={{}}
        onSubmit={() => {}}
        render={() => (
          <Form>
            <Row>
              <Col sm={2}>
                <InputColumn label="Área de serviço">
                  <SelectMultipleSubDepartment onChange={onChangeDepartment} />
                </InputColumn>
              </Col>

              <Col sm={2}>
                <InputColumn label="Status">
                  <SelectMultipleStatus onChange={onChangeStatus} />
                </InputColumn>
              </Col>
              <Col sm={2}>
                <InputColumn label="Cliente">
                  <SelectMultipleClient
                    request={getClients}
                    onChange={onChangeClient}
                  />
                </InputColumn>
                <InputColumn label="Obra">
                  <SelectMultipleWork
                    client={client}
                    request={getWorks}
                    onChange={onChangeWork}
                  />
                </InputColumn>
              </Col>

              <Col sm={2}>
                <InputColumn label="Início">
                  <SelectMonth onChange={onChangeStartMonth} />
                </InputColumn>
                <InputColumn>
                  <SelectYear
                    onChange={onChangeStartYear}
                    disabled={!startMonth}
                  />
                </InputColumn>
              </Col>
              <Col sm={2}>
                <InputColumn label="Fim">
                  <SelectMonth onChange={onChangeEndMonth} />
                </InputColumn>
                <InputColumn>
                  <SelectYear onChange={onChangeEndYear} disabled={!endMonth} />
                </InputColumn>
              </Col>
              <Col sm={2}>
                <InputColumn label="Opções">
                  <InputSelect
                    onChange={setReportType}
                    required
                    options={[
                      {
                        value: REPORT_TYPE.CLIENT_REPORT,
                        label: "Relatório por cliente"
                      },
                      {
                        value: REPORT_TYPE.DEPARTMENT_REPORT,
                        label: "Relatório por departamento"
                      },
                      {
                        value: REPORT_TYPE.PREVISION_REPORT,
                        label: "Relatório de previsão"
                      }
                    ]}
                  />
                </InputColumn>
                <InputCheckbox
                  name="newVersion"
                  label="Usar nova versão"
                  checked={newVersion}
                  onClick={() => setNewVersion(!newVersion)}
                />
                <ButtonOption
                  className="btn-block btn-outline-secondary"
                  type="submit"
                  onClick={getReportURL}
                >
                  Gerar relatório
                </ButtonOption>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default InvoiceControlFilter;
