/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import {
  InputColumn,
  InputText,
  InputValidation
} from "../../../components/Inputs";
import { useUser } from "../../../context/admin/user";
import { useHistory } from "react-router-dom";
import { Container } from "src/components/Container";
import { Form, Formik, FormikActions, Field } from "formik";

const Login = () => {
  const history = useHistory();

  const [, userActions] = useUser();

  const onSubmit = (
    values: { email: string; password: string },
    { setSubmitting, setFieldError }: FormikActions<any>
  ) => {
    if (values.email.length > 0 && values.password.length > 0) {
      setSubmitting(true);
      userActions
        .login(values.email, values.password)
        .then(() => {
          history.replace({ pathname: "/" });
        })
        .catch(() => {
          setFieldError("email", "Usuário ou senha incorretos");
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Container title="Entrar">
      <Row className="justify-content-center">
        <Col sm={4} lg={4}>
          <div className="text-center w-75 pb-4 m-auto">
            <span>
              <img
                src={process.env.PUBLIC_URL + "/static/assets/images/logo.svg"}
                alt=""
                height="40"
              />
            </span>
          </div>
          <Card className="pl-2 pr-2">
            <CardBody>
              <Row>
                <Col>
                  <div className="text-center mb-3">
                    <h4>Entrar na sua conta</h4>
                  </div>
                  <Formik
                    onSubmit={onSubmit}
                    initialValues={{ email: "", password: "" }}
                    render={({ values, errors, isSubmitting }) => (
                      <Form>
                        <InputColumn label="Endereço de email">
                          <Field
                            component={InputText}
                            name="email"
                            type="email"
                            autoFocus
                            required
                          />
                        </InputColumn>
                        <InputColumn label="Senha" className="mt-2">
                          <Field
                            component={InputText}
                            name="password"
                            type="password"
                            required
                          />
                          <InputValidation message={errors.email} />
                        </InputColumn>
                        <Button
                          className="mt-2"
                          color="primary"
                          size="sm"
                          block
                          isSubmitting={true}
                          disabled={
                            isSubmitting ||
                            values.email.length === 0 ||
                            values.password.length === 0
                          }
                        >
                          Entrar
                        </Button>
                      </Form>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p className="mt-2 mb-1">
                    <label className="text-sm-right text-bold">
                      <u>
                        <a href="reset_password" className="text-dark">
                          Esqueceu sua senha?
                        </a>
                      </u>
                    </label>
                  </p>
                </Col>
                <Col sm={12}>
                  <p className="mb-1">
                    Você ainda não tem uma conta?{" "}
                    <label>
                      <u>
                        <a href="register" className="text-dark">
                          Cadastre-se
                        </a>
                      </u>
                    </label>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
