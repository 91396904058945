import React from "react";
import { useUser } from "../../context/admin/user";
import { useParams } from "react-router-dom";

export const useCheckPermissions = (allowedPermissions: string[]) => {
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();
  if (user.data) {
    const allPermissions = user.data.applications[appSlug].permissions;
    if (allowedPermissions.length === 0) {
      return false;
    }

    let has_permission = false;

    Object.keys(allPermissions).forEach(key => {
      if (
        allowedPermissions.some(item => {
          return item === key;
        })
      ) {
        has_permission =
          allPermissions[key as keyof TUserPermissions] || has_permission;
      }
    });
    return has_permission;
  }

  return false;
};

interface AccessPermissionProps {
  allowedPermissions: string[];
  children: React.ComponentType<any> | React.ReactElement | null;
  renderNoAccess?: () => React.ComponentType<any> | React.ReactElement | null;
}

const AccessPermission = ({
  allowedPermissions,
  children,
  renderNoAccess
}: AccessPermissionProps) => {
  const permitted = useCheckPermissions(allowedPermissions);

  const render = () => {
    if (permitted) {
      return children;
    }

    if (renderNoAccess) return renderNoAccess();

    return null;
  };

  return <>{render()}</>;
};

AccessPermission.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null
};

export default AccessPermission;
