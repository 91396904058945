import moment from "moment";

const initialControl = {
  graute_age: [],
  name: "",
  contact: "",
  equipments: "",
  fck: undefined,
  width: undefined,
  height: undefined,
  length: undefined,
  diameter: undefined,
  dimensions_control: false,
  temp_control: true,
  auto_serie_number: true,
  auto_tb_number: true,
  trace_control: true,
  manual_code: true,
  rule_control: "",
  holder_distance: undefined,
  providers: "",
  towers_names: "",
  report_name: "",
  bags_weights: undefined,
  contract: undefined
};

const initialTestingBody = {
  rupture_date: "",
  delayed: false,
  bulk: 0,
  specific_weight: 0,
  out_days: 0,
  fck: 0,
  below_expected: false,
  work: "",
  code: 0,
  age: 0,
  load: 0,
  weight: 0,
  discarded: false,
  discard_reason: "string",
  test_date: null,
  approved: false,
  rupture_form: "N",
  def_segregation: false,
  def_bicheiras: false,
  def_color_change: false,
  def_chopped: false,
  def_diameter: false,
  def_verticality: false,
  def_others: false,
  control: 0,
  stress_test: 0,
  serie: 0
};

const initialMixtures: TConcreteMix = {
  bill: 0,
  water_increment: 0,
  volume: 0,
  environment_temp: 0,
  water_temp: 0,
  concrete_temp: 0,
  car_label: "",
  provider: "",
  slump_test: undefined,
  slump_flow_test: undefined,
  air_test: undefined,
  segregation_test: undefined,
  jring_test: undefined,
  stress_test: {
    status: 0,
    boletim: 0,
    molding_date: moment(new Date()).format("YYYY-MM-DD"),
    fck: 0,
    concrete_cp: [],
    expected_resistance: []
  },
  has_slump: false,
  has_slump_flow: false,
  has_jring: false,
  has_segregation_test: false,
  has_pressure: false,
  has_air: false
};

const initialSeries = {
  number: undefined,
  graute_cp: [],
  molding_date: "",
  fck: undefined,
  boletim: 0,
  place: "",
  optional_place: "",
  internal_comments: "",
  technical_commets: "",
  lab_arrival: "",
  verified: false,
  trace: "",
  discarded_motive: null,
  concrete_type: "",
  control: 0,
  fck_reached: false
};

export { initialControl, initialMixtures, initialSeries, initialTestingBody };
