import React, { ReactNode } from "react";
import { Button } from "reactstrap";

interface Props {
  className?: string;
  children?: ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  onClick?: (event?: any) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const ButtonSecondary: React.FC<Props> = ({
  className,
  size,
  children,
  ...props
}) => (
  <Button
    className={`btn btn-block ${className}`}
    color="light"
    type="button"
    size={size}
    {...props}
  >
    {children || "Clique aqui"}
  </Button>
);

ButtonSecondary.defaultProps = {
  className: "",
  size: "sm",
  disabled: false,
  type: "button"
};

export default ButtonSecondary;
