/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik, FormikActions } from "formik";
import initialValues from "./init";
import Form from "./Form";
import { useCompactionActions } from "./context";
import * as api from "../../../../../services/api/characterization";

interface Props {
  sample: SoilSample;
  hasUpdated: (sampleId: number) => void;
}

const Compaction: React.FC<Props> = ({ sample, hasUpdated }) => {
  const [, compactionActions] = useCompactionActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [test, setTest] = useState<Compaction>();

  useEffect(() => {
    compactionActions.getCompactionTest(sample.id!)?.then(data => {
      setTest(data);
      setLoading(false);
    });
  }, [sample.id]);

  const onSubmit = (values: any, actions: FormikActions<object>) => {
    values = {
      ...values,
      capsules: [...values.capsules, ...values.hygroscopic_capsules],
      sample: sample.id
    };

    if (values.id === undefined) {
      api
        .postCompaction(sample.id!, values)
        .then(({ data }: { data: Compaction }) => {
          data = {
            ...data,
            hygroscopic_capsules: data.capsules.filter(
              item => item.is_hygroscopic
            ),
            capsules: data.capsules.filter(item => !item.is_hygroscopic)
          };
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      api
        .putCompaction(values.id, values)
        .then(({ data }: { data: Compaction }) => {
          data = {
            ...data,
            hygroscopic_capsules: data.capsules.filter(
              item => item.is_hygroscopic
            ),
            capsules: data.capsules.filter(item => !item.is_hygroscopic)
          };

          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const formatTest = (test?: Compaction) => {
    if (test !== undefined) {
      return {
        ...test,
        hygroscopic_capsules: test.hygroscopic_capsules.map(item => {
          return {
            ...item,
            capsule_study: {
              ...item.capsule_study,
              capsule: item.capsule_study.capsule.id
            }
          };
        }),
        capsules: test.capsules
          .sort((a, b) => a.number - b.number)
          .map(item => {
            return {
              ...item,
              capsule_study: {
                ...item.capsule_study,
                capsule: item.capsule_study.capsule.id
              }
            };
          }),
        cylinders: test.cylinders
          .sort((a, b) => a.number - b.number)
          .map(item => {
            return {
              ...item,
              cylinder_study: {
                ...item.cylinder_study,
                cylinder: item.cylinder_study.cylinder.id
              }
            };
          })
      };
    }

    return undefined;
  };

  return (
    <>
      {loading ? (
        <div>carregando....</div>
      ) : (
        <Formik
          onSubmit={onSubmit}
          initialValues={formatTest(test) || initialValues}
          enableReinitialize
          render={(props: any) => <Form {...props} />}
        />
      )}
    </>
  );
};

export default Compaction;
