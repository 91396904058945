/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Field } from "formik";
import { InputDecimal, InputMoney } from "../../../../components/Inputs";
import { toLocateNumber } from "../../../../utils/format";

interface Props {
  service: ServiceInvoiceValuesProps;
  previousService?: ServiceInvoiceValuesProps;
  name: string;
  errors: object;
  index: number;
  summary: SummaryBill;
  value: ServiceInvoiceValuesProps;
  setFieldValue: (field: string, value: any) => void;
  insert: (index: number, value: any) => void;
  remove: (index: number) => void;
}

const Service: React.FC<Props> = ({
  service,
  name,
  summary,
  value,
  setFieldValue,
}) => {
  const { service: contractService } = service;
  const [accumulated] = useState(summary.quantity);
  const [unitaryPrice, setUnitaryPrice] = useState<number>(0);

  // This is a hack to show unitary price when the filed are mounted
  useEffect(() => {
    const price = value.quantity ? value.price / value.quantity : value.price;
    setFieldValue(`${name}.unitary_price`, price);
    setUnitaryPrice(price);
  }, []);

  const onChangeBilledPrice = (price: number) => {
    setFieldValue(`${name}.price`, price);
    const _unitaryPrice = value.quantity ? price / value.quantity : price;
    setFieldValue(`${name}.unitary_price`, _unitaryPrice);
    setUnitaryPrice(_unitaryPrice);
  };

  const onChangeUnitPrice = (price: number) => {
    setFieldValue(`${name}.unitary_price`, price);
    setUnitaryPrice(price);
    setFieldValue(`${name}.price`, price * value.quantity);
  };

  const onChangeQuantity = (quantity: number) => {
    setFieldValue(`${name}.quantity`, quantity || 0);
    setFieldValue(`${name}.price`, (quantity || 0) * unitaryPrice);
  };

  return (
    <Row className="mb-1">
      <Col sm={5}>{contractService.service.name}</Col>
      <Col className="text-sm-center align-self-center">
        {contractService.service.unit
          ? contractService.service.unit.abbreviation
          : ""}
      </Col>
      <Col className="text-sm-center align-self-center">
        {toLocateNumber(summary.quantity, 2)}
      </Col>
      <Col className="align-self-center">
        <Field
          name={`${name}.quantity`}
          component={InputDecimal}
          onChange={onChangeQuantity}
        />
      </Col>
      <Col className="text-sm-center align-self-center">{accumulated}</Col>
      <Col className="align-self-center">
        <InputMoney
          name={`${name}.unitary_price`}
          handleValue={onChangeUnitPrice}
        />
      </Col>
      <Col className="align-self-center">
        <InputMoney name={`${name}.price`} handleValue={onChangeBilledPrice} />
      </Col>
      <Col sm="auto">
        <div className="checkbox checkbox-single">
          <Field
            name={`${name}.billed`}
            id="billed"
            type="checkbox"
            checked={value.billed}
            onClick={() => {
              setFieldValue(`${name}.billed`, !value.billed);
            }}
          />
          <label />
        </div>
      </Col>
    </Row>
  );
};

const ServiceTitle = () => {
  return (
    <Row>
      <Col sm={5}>
        <label>Serviço</label>
      </Col>
      <Col className="text-sm-center">
        <label>Und.</label>
      </Col>
      <Col className="text-sm-center">
        <label>Qtd. acum. anterior</label>
      </Col>
      <Col className="text-sm-center">
        <label>Qtd. atual</label>
      </Col>
      <Col className="text-sm-center">
        <label>Qtd. acumulada</label>
      </Col>
      <Col className="text-sm-center">
        <label>Valor unit.</label>
      </Col>
      <Col className="text-sm-center">
        <label>Subtotal</label>
      </Col>
      <Col sm="auto" className="text-sm-center">
        <i className="mdi  mdi mdi-eye" />
      </Col>
    </Row>
  );
};

const ServiceTypeTitle = ({ title }: { title: string }) => (
  <p className="mb-1 font-weight-bold">{title}</p>
);

interface ServiceFooterProps {
  total: number;
}

const ServiceFooter: React.FC<ServiceFooterProps> = ({ total }) => (
  <Row>
    <Col sm={5} />
    <Col className="text-sm-center" />
    <Col className="text-sm-center" />
    <Col className="text-sm-center" />
    <Col className="text-sm-center" />
    <Col className="text-right">
      <strong>TOTAL</strong>
    </Col>
    <Col className="text-center" style={{ marginRight: "42px" }}>
      {toLocateNumber(total, 2, "0,00", "R$ ")}
    </Col>
  </Row>
);

export { Service, ServiceTitle, ServiceTypeTitle, ServiceFooter };
