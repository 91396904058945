import React from "react";
import { Col } from "reactstrap";
import { Field } from "formik";
import { formatAgeLabel } from "../../utils";
import { InputCPCode } from "../../../../../components/Inputs";

interface Props {
  name: string;
  index: number;
  border: string;
  values: TConcreteTestingBody | TGrauteTestingBody;
  control: TConcreteControl | TGrauteControl;
  setFieldValue: (key: string, value: any) => any;
}

const CPCode: React.FC<Props> = ({ values, index, border, name, control }) => (
  <div
    className={
      "form-group row justify-content-center align-items-center border-bottom border-top mt-0 mb-0 pb-2 pt-2 " +
      border
    }
  >
    <Col sm={5}>{formatAgeLabel(values.age)}</Col>
    <Col sm={7}>
      {control.auto_tb_number ? (
        <Field
          className="form-control form-control-sm"
          type="text"
          name={`${name}.code`}
          value={(index + 1).toString().padStart(6, "0")}
        />
      ) : (
        <InputCPCode name={`${name}.code`} required />
      )}
    </Col>
    <div className="dropdown-divider" />
  </div>
);

export default CPCode;
