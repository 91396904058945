import React, { ReactNode } from "react";
import { Col, Label, FormGroup } from "reactstrap";

interface Props {
  label: string;
  label_col?: number | string;
  className?: string;
  labelClassName?: string;
  create?: ReactNode;
  children?: ReactNode;
}

const InputRow: React.FC<Props> = ({
  label,
  label_col = 12,
  className = "",
  labelClassName = "",
  create,
  children
}) => (
  <FormGroup className={`row ${className}`}>
    {label && (
      <Label key="1" sm={label_col} className={labelClassName}>
        {label}
        {create}
      </Label>
    )}
    {children && <Col>{children}</Col>}
  </FormGroup>
);

InputRow.defaultProps = {
  label_col: 12,
  className: "",
  labelClassName: ""
};

export default InputRow;
