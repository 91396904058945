import React from "react";
import { HeaderTitle } from "../../../components/Headers";
import { formatPhone } from "../../../utils/format";

interface Props {
  employee?: EmployeeFormType;
}

const EmployeeDetail: React.FC<Props> = ({ employee }) => (
  <>
    {employee !== undefined ? (
      <>
        <HeaderTitle title={employee.short_name || ""} />
        <dl className="row">
          <dt className="col-sm-5 text-right">Nome:</dt>
          <dd className="col-sm-7">{employee.full_name}</dd>

          <dt className="col-sm-5 text-right">Endereço:</dt>
          <dd className="col-sm-7">{employee.address}</dd>

          <dt className="col-sm-5 text-right">Email:</dt>
          <dd className="col-sm-7">{employee.email}</dd>

          <dt className="col-sm-5 text-right">Contato um:</dt>
          <dd className="col-sm-7">{formatPhone(employee.contact_one)}</dd>
          <dt className="col-sm-5 text-right">Contato dois:</dt>
          <dd className="col-sm-7">{formatPhone(employee.contact_two)}</dd>
        </dl>
      </>
    ) : (
      <p>Selecione um funcionário</p>
    )}
  </>
);

export default EmployeeDetail;
