import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useData, TableList } from "../../../components/Table";
import { LoadingComponent } from "../../../components/Loader";
import columns from "./columns";
import * as api from "../../../services/api/service";
import { InputRow } from "../../../components/Inputs";
import { SelectSubDepartment, SelectUnit } from "../../../components/Selects";
import { ListButtonRow, ButtonDelete } from "../../../components/Buttons";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Serviços";

const Service = () => {
  const item = useData<ServiceValuesProps>();
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Array<ServiceValuesProps>>([]);
  const [unit, setUnit] = useState<string>("");
  const [subDepartment, setSubDepartment] = useState<string>("");
  const [dbClick, setDbClick] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    api
      .getServices(subDepartment, unit)
      .then(({ data }) => setItems(data))
      .finally(() => setLoading(false));
  }, [unit, subDepartment]);

  const onChangeUnit = (value: string) => {
    setUnit(value || "");
  };

  const onChangeSubDepartment = (value: string) => {
    setSubDepartment(value || "");
  };

  const initialValues = (data?: ServiceValuesProps) => {
    if (data)
      return {
        ...data,
        unit: data.unit ? data.unit.id : -1,
        sub_department: data.sub_department ? data.sub_department.id : -1
      };

    return {};
  };

  const onPut = (values: ServiceValuesProps) => {
    return api
      .putServices(values.id!, values)
      .then(({ data }) => item.updated.set(data));
  };

  const onPost = (values: ServiceValuesProps) => {
    return api.postServices(values).then(({ data }) => item.created.set(data));
  };

  const editItem = () => (
    <ModalForm
      title="Editar servico"
      type="put"
      form={props => <Form {...props} />}
      onSubmit={onPut}
      getItemRequest={(id: number) => api.getService(id)}
      initialValues={initialValues}
      data={item.selected.item}
      dbClick={dbClick}
    />
  );

  const insertItem = () => (
    <ModalForm
      title="Novo servico"
      type="post"
      form={props => <Form {...props} />}
      data={{}}
      onSubmit={onPost}
      initialValues={() => {
        return {};
      }}
    />
  );

  const deleteItem = () => {
    if (item.selected.item) {
      return api
        .deleteService(item.selected.item.id!)
        .then(({ data }) => {
          item.deleted.set(item.selected.item);
          return Promise.resolve(data);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }

    return Promise.reject();
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={3}>
          <InputRow label_col={"auto"} label="Área do serviço">
            <SelectSubDepartment onChange={onChangeSubDepartment} />
          </InputRow>
        </Col>
        <Col sm={3}>
          <InputRow label_col={"auto"} label="Unidade">
            <SelectUnit onChange={onChangeUnit} />
          </InputRow>
        </Col>
      </Row>
      <Card className="filter-bar">
        <CardBody>
          <LoadingComponent loading={loading}>
            <TableList
              data={items}
              columns={columns}
              selected={item.selected.set}
              updated={item.updated.item}
              created={item.created.item}
              deleted={item.deleted.item}
              dbClick={selected => {
                item.selected.set(selected);

                setDbClick(true);
                setDbClick(false);
              }}
              searching={true}
              wrap
            />
          </LoadingComponent>
          <ListButtonRow
            marginTop="mt-2"
            buttonsLeft={
              <>
                {insertItem()}
                {editItem()}
              </>
            }
            buttonsRight={
              <ButtonDelete
                disabled={!item.selected.item}
                onClick={deleteItem}
              />
            }
          />
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default Service;
