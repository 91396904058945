import api from "./api";

export function getEquipments() {
  const endpoint = "/tools/equipments/";
  return api.get(endpoint);
}

export function postEquipments(data: any) {
  const endpoint = "/tools/equipments/";
  return api.post(endpoint, data);
}

export function putEquipments(id: number, data: any) {
  const endpoint = `/tools/equipments/${id}/`;
  return api.put(endpoint, data);
}

export function getEquipment(id: number) {
  const endpoint = `/tools/equipments/${id}/`;
  return api.get(endpoint);
}

export function deleteEquipments(id: number) {
  const endpoint = `/tools/equipments/${id}/`;
  return api.delete(endpoint);
}

export function getFunnel(id: number) {
  const endpoint = `/tools/funnels/${id}/`;
  return api.get(endpoint);
}

export function getFunnels() {
  const endpoint = "/tools/funnels/";
  return api.get(endpoint);
}

export function postFunnels(data: any) {
  const endpoint = "/tools/funnels/";
  return api.post(endpoint, data);
}

export function putFunnels(id: number, data: any) {
  const endpoint = `/tools/funnels/${id}/`;
  return api.put(endpoint, data);
}

export function deleteFunnel(id: number) {
  const endpoint = `/tools/funnels/${id}/`;
  return api.delete(endpoint);
}

export function getLayers() {
  const endpoint = "/tools/layers/";
  return api.get(endpoint);
}

export function postLayers(data: any) {
  const endpoint = "/tools/layers/";
  return api.post(endpoint, data);
}

export function getLayer(id: number) {
  const endpoint = `/tools/layers/${id}/`;
  return api.get(endpoint);
}

export function putLayers(id: number, data: any) {
  const endpoint = `/tools/layers/${id}/`;
  return api.put(endpoint, data);
}

export function deleteLayers(id: number) {
  const endpoint = `/tools/layers/${id}/`;
  return api.delete(endpoint);
}

export function getMaterials() {
  const endpoint = "/tools/materials/";
  return api.get(endpoint);
}

export function postMaterials(data: any) {
  const endpoint = "/tools/materials/";
  return api.post(endpoint, data);
}

export function putMaterials(id: number, data: any) {
  const endpoint = `/tools/materials/${id}/`;
  return api.put(endpoint, data);
}

export function getMaterial(id: number) {
  const endpoint = `/tools/materials/${id}/`;
  return api.get(endpoint);
}

export function deleteMaterial(id: number) {
  const endpoint = `/tools/materials/${id}/`;
  return api.delete(endpoint);
}

export function getSand(id: number) {
  const endpoint = `/tools/sands/${id}/`;
  return api.get(endpoint);
}

export function getSands() {
  const endpoint = "/tools/sands/";
  return api.get(endpoint);
}

export function postSands(data: any) {
  const endpoint = "/tools/sands/";
  return api.post(endpoint, data);
}

export function putSands(id: number, data: any) {
  const endpoint = `/tools/sands/${id}/`;
  return api.put(endpoint, data);
}

export function deleteSand(id: number) {
  const endpoint = `/tools/sands/${id}/`;
  return api.delete(endpoint);
}

export function getCalibrations() {
  const endpoint = "/tools/calibrations/";
  return api.get(endpoint);
}

export function postCalibrations(data: any) {
  const endpoint = "/tools/calibrations/";
  return api.post(endpoint, data);
}

export function putCalibrations(id: number, data: any) {
  const endpoint = `/tools/calibrations/${id}/`;
  return api.put(endpoint, data);
}

export function postPress(data: any) {
  let endpoint = `/cbrs/press/`;
  return api.post(endpoint, data);
}

export function getPress(id: number) {
  let endpoint = `/cbrs/press/${id}/`;
  return api.get(endpoint);
}

export function putPress(id: number, data: any) {
  let endpoint = `/cbrs/press/${id}/`;
  return api.put(endpoint, data);
}

export function deletePress(id: number) {
  let endpoint = `/cbrs/press/${id}/`;
  return api.delete(endpoint);
}
