/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode } from "react";
import { Formik, FormikProps, Form, FormikActions } from "formik";
import { Modal } from "../../components/Modal";
import {
  ButtonInsert,
  ButtonOption,
  ButtonSave
} from "../../components/Buttons";
import { LoadingComponent } from "../../components/Loader";

interface IProps {
  data?: any;
  disabled?: boolean;
  type: "post" | "put";
  title: string;
  size?: "sm" | "md" | "lg" | "mxl" | "xl";
  dbClick?: boolean;
  buttonLabel?: string;
  buttonSaveLabel?: string;
  show?: boolean;
  showButton?: boolean;
  form: (props: FormikProps<any>) => ReactNode;
  onSubmit: (values: any, formikActions: FormikActions<object>) => Promise<any>;
  getItemRequest?: (id: number) => Promise<any>;
  initialValues: (data?: any) => object;
  renderButton?: (
    onClick: () => void,
    disabled: boolean
  ) => React.ComponentType<any> | React.ReactElement | null;
  onClosed?: () => void;
  onButtonClick?: () => void;
}

interface IState {
  showModal: boolean;
  item: any;
  loading: boolean;
}

class ModalFormClass extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
      item: undefined,
      loading: true
    };
  }

  static defaultProps = {
    dbClick: false,
    size: "lg",
    disabled: false,
    showButton: true,
    buttonSaveLabel: "Salvar"
  };

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  requestItem = () => {
    const { data, getItemRequest } = this.props;

    if (getItemRequest && data) {
      this.setState({ loading: true });
      getItemRequest(data.id)
        .then(({ data }) => {
          this.setState({ item: data });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  open = () => {
    this.requestItem();
    const { onButtonClick } = this.props;
    onButtonClick && onButtonClick();
    this.setState({
      showModal: true
    });
  };

  close = () => {
    this.setState({
      showModal: false
    });
  };

  getButton = () => {
    const { type, renderButton, data, buttonLabel, disabled } = this.props;
    return type === "put" ? (
      <>
        {renderButton ? (
          renderButton(this.open.bind(this), !data)
        ) : (
          <ButtonOption onClick={this.open.bind(this)} disabled={!data}>
            {buttonLabel}
          </ButtonOption>
        )}
      </>
    ) : (
      <>
        {renderButton ? (
          renderButton(this.open.bind(this), !data)
        ) : (
          <ButtonInsert onClick={this.open.bind(this)} disabled={disabled}>
            {buttonLabel}
          </ButtonInsert>
        )}
      </>
    );
  };

  render() {
    const {
      showButton,
      title,
      size,
      onClosed,
      onSubmit,
      initialValues,
      form,
      buttonSaveLabel
    } = this.props;
    const { showModal, loading, item } = this.state;
    return (
      <>
        {showButton && this.getButton()}
        <Modal
          title={title}
          toggle={this.toggle.bind(this)}
          size={size || "lg"}
          isOpen={showModal}
          className=""
          onClosed={onClosed}
        >
          <LoadingComponent loading={loading}>
            <Formik
              onSubmit={(values, formikActions: FormikActions<object>) => {
                onSubmit(values, formikActions)
                  .then(() => this.toggle())
                  .catch(() => {})
                  .finally(() => {
                    formikActions.setSubmitting(false);
                  });
              }}
              enableReinitialize
              initialValues={initialValues(item)}
              render={props => (
                <Form>
                  {form(props)}
                  <div className="mb-2" />
                  <ButtonSave isSubmitting={props.isSubmitting}>
                    {buttonSaveLabel}
                  </ButtonSave>
                </Form>
              )}
            />
          </LoadingComponent>
        </Modal>
      </>
    );
  }
}

export default ModalFormClass;
