import React from "react";
import { FormikProps, Field } from "formik";
import {
  InputColumn,
  InputValidation,
  InputDecimal,
  InputText
} from "src/components/Inputs";

const Form: React.FC<FormikProps<SandValues>> = ({ errors, setFieldValue }) => {
  return (
    <>
      <InputColumn label="Número">
        <Field name="number" component={InputText} required={true} />
        <InputValidation message={errors.number} />
      </InputColumn>
      <InputColumn label="Densidade (g/cm³)">
        <Field
          name="density"
          component={InputDecimal}
          required={true}
          onChange={(value: number) => setFieldValue("density", value)}
        />

        <InputValidation message={errors.density} />
      </InputColumn>
    </>
  );
};

export default Form;
