import React from "react";
import Input from "./Input";

interface Props {
  field?: any;
  form?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: any) => void;
}

const InputInteger: React.FC<Props> = ({
  field,
  name,
  form,
  required,
  disabled,
  onChange,
  onKeyDown,
  ...props
}) => (
  <Input
    name={name || ""}
    type="number"
    disabled={!!disabled}
    required={!!required}
    field={field}
    onChange={onChange}
    onKeyDown={onKeyDown}
    {...props}
  />
);

InputInteger.defaultProps = {
  required: false,
  disabled: false
};

export default InputInteger;
