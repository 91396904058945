import React from "react";
import { Card, CardBody } from "reactstrap";
import { ItemToManager } from "../../../components/ItemContent";
import Department from "./Department";
import SubDepartment from "./SubDeparment";
import JobTitle from "./JobTitle";
import Daily from "./Daily";
import GeneralSetting from "./Geral";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Configurações";

const Settings = () => {
  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Card>
        <CardBody>
          <ItemToManager title="Departamento" notes="">
            <Department />
          </ItemToManager>
          <hr />
          <ItemToManager title="Sub departamento" notes="">
            <SubDepartment />
          </ItemToManager>

          <hr />
          <ItemToManager title="Funções" notes="">
            <JobTitle />
          </ItemToManager>
          <hr />
          <ItemToManager title="Diária" notes="">
            <Daily />
          </ItemToManager>
          <hr />
          <ItemToManager title="Outras configurações" notes="">
            <GeneralSetting />
          </ItemToManager>
          <hr />
          {/* <ItemToManager title="Relatórios e documentos" notes="">
            <Application />
          </ItemToManager>
          <hr /> */}

          {/* 
            <hr />
            <ItemToManager title="Documentos">
              <ListDocument />
            </ItemToManager>
            <hr />
            <ItemToManager title="Diária">
              <ListSetting />
            </ItemToManager>
            <hr />
            <ItemToManager title="Configurações gerais">
              <ListGeneralSetting />
            </ItemToManager>
            <hr /> */}
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default Settings;
