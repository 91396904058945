import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Container } from "src/components/Container";
import ContractFilterBar from "src/components/Selects/ContractFilterBar";
import * as clientApi from "src/services/api/client";
import * as contractApi from "src/services/api/contract";
import TechControl from "src/pages/tech_control/components/TechControl";
import { TextMuted } from "src/components/Text";
import DropFileUpload from "./DropFileUpload";
import FilesList from "./FilesList";
import { useUser } from "src/context/admin/user";

const PAGE_TITLE = "Laudos e relatórios";

const Files = () => {
  const [user] = useUser();
  const [contractId, setContractId] = useState<number>();
  const [workId, setWorkId] = useState<number>();
  const [shouldUpdateFiles, setShouldUpdateFiles] = useState<boolean>(false);

  useEffect(() => {
    if (contractId !== undefined && contractId !== null) {
      contractApi.getContract(contractId).then(({ data }) => {
        setWorkId(data.work.id);
      });
    } else {
      setWorkId(undefined);
    }
  }, [contractId]);

  const getClients = () => {
    return clientApi.getClients("", "1", "id,name");
  };

  const getContracts = (clientId: number) => {
    return contractApi.getContracts("", `${clientId}`, "", "", "", "1");
  };

  const renderBeforeControl = () => {
    return (
      <div className="text-center">
        <TextMuted>Uma obra deve ser selecionada</TextMuted>
      </div>
    );
  };

  const renderClientArea = () => {
    return (
      <>
        {!user.data?.is_client && (
          <Row className="text-left">
            <Col sm={12}>
              <DropFileUpload
                contractId={workId!}
                shouldUpdateFiles={setShouldUpdateFiles}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12}>
            <FilesList contractId={workId} shouldUpdate={shouldUpdateFiles} />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Container title={PAGE_TITLE}>
      <ContractFilterBar
        clientRequest={getClients}
        contractRequest={getContracts}
        onContractChange={setContractId}
      />
      <TechControl
        contract={workId}
        renderControl={renderClientArea}
        renderBeforeControl={renderBeforeControl}
      />
    </Container>
  );
};

export default Files;
