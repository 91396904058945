import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import * as api from "../../services/api/cylinder";

interface State {
  data: Cylinder[];
  loading: boolean;
}

const initialState: State = {
  data: [],
  loading: false
};

const actions = {
  fetchCapsules: (type?: "insitu" | "sample") => async ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (getState().loading) return;

    setState({ loading: true });

    api
      .getCylinders(type)
      .then(({ data }) => {
        setState({
          data,
          loading: false
        });
      })
      .catch(() => setState({ loading: false }));
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "cylinders"
});

const CylindersSubscriber = createSubscriber(Store);
const useCylinders = createHook(Store);

export { CylindersSubscriber, useCylinders };
