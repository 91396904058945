import React from "react";
import { InputSelect } from "../Inputs";

const SelectXlsxType: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: "C", label: "Concreto" },
      { value: "S", label: "Solos" },
      { value: "I", label: "Insitu" },
      { value: "IN", label: "Medição" }
    ];
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Tipo..."
      onClick={props.onChange}
    />
  );
};

export default SelectXlsxType;
