import React, { ReactNode } from "react";
import { Button } from "reactstrap";

interface Props {
  className?: string;
  children?: ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  onClick?: (event?: any) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  isSubmitting?: boolean;
}

const ButtonOption: React.FC<Props> = ({
  className,
  size,
  children,
  isSubmitting,
  ...props
}) => (
  <Button
    className={className}
    color="light"
    type={"button"}
    size={size}
    {...props}
  >
    {children || "Editar"}
    {isSubmitting && <i className="mdi mdi-spin mdi-loading ml-1" />}
  </Button>
);

ButtonOption.defaultProps = {
  className: "",
  size: "sm",
  disabled: false,
  type: "button",
  isSubmitting: false
};

export default ButtonOption;
