import React from "react";
import { HeaderTitle } from "src/components/Headers";

interface Props {
  lot?: TBlockCeramicLot;
}

const LotSummary: React.FC<Props> = ({ lot }) => {
  const isConformity = (value: boolean) => {
    return value ? (
      <div className="badge font-14 bg-soft-success text-success p-1">
        Conforme
      </div>
    ) : (
      <div className="badge font-14 bg-soft-danger text-danger p-1">
        Não conforme
      </div>
    );
  };

  return (
    <>
      <HeaderTitle title="Conformidade do lote" />
      {lot ? (
        <dl className="row">
          <dt className="col-sm-7">Largura (L)</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_width)}</dd>

          <dt className="col-sm-7">Altura (H)</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_height)}</dd>

          <dt className="col-sm-7">Comprimento (C)</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_length)}</dd>

          <dt className="col-sm-7">Septos</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_septum)}</dd>

          <dt className="col-sm-7">Paredes externas</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_external_wall)}</dd>

          <dt className="col-sm-7">Desvio do esquadro</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_deviantion)}</dd>

          <dt className="col-sm-7">Planeza das faces</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_plan)}</dd>

          <dt className="col-sm-7">Lote</dt>
          <dd className="col-sm-5">{isConformity(lot.conf_lot)}</dd>
        </dl>
      ) : (
        "Um lote deve ser selecionado"
      )}
    </>
  );
};

export default LotSummary;
