import moment from "moment";

const initialControl = {
  concrete_age: [],
  fck_growth: {},
  name: "",
  contact: "",
  fck: undefined,
  width: 0,
  height: 200,
  length: 0,
  diameter: 100,
  dimensions_control: false,
  temp_control: false,
  auto_serie_number: true,
  auto_tb_number: false,
  is_prismatic: false,
  mix_amount: 1,
  stress_test: 1,
  slump_test: 1,
  slump_flow_test: 1,
  jring_test: 0,
  segregation_test: 0,
  air_test: 0,
  onSite: false,
  obs01:
    "CONTROLE POR AMOSTRAGEM TOTAL - ITEM 6.2.3.1, CONDIÇÃO DE USINAGEM ITEM 5.6.3.1 (NBR 12655)",
  obs02:
    "RASTREABILIDADE DOS CORPOS DE PROVA SÃO DE RESPONSABILIDADE DO CLIENTE",
  obs03:
    "ESTE CERTIFICADO TEM SIGNIFICAÇÃO RESTRITA E DIZ RESPEITO SOMENTE AS AMOSTRAS ENVIDAS PELO CLIENTE",
  equipments: "PRENSA HIDRAULICA MANUAL DIGITAL - 150T",
  use_rules_growth: true,
  type: "T",
  unique_cp_codes: true,
  statistic_control: true
};

const initialTestingBody = {
  rupture_date: "",
  delayed: false,
  bulk: 0,
  specific_weight: 0,
  out_days: 0,
  fck: 0,
  below_expected: false,
  work: "",
  code: 0,
  age: 0,
  load: 0,
  weight: 0,
  discarded: false,
  discard_reason: "string",
  test_date: null,
  approved: false,
  rupture_form: "N",
  def_segregation: false,
  def_bicheiras: false,
  def_color_change: false,
  def_chopped: false,
  def_diameter: false,
  def_verticality: false,
  def_others: false,
  control: 0,
  stress_test: 0,
  serie: 0
};

const initialMixtures: TConcreteMix = {
  bill: 0,
  water_increment: 0,
  water_gap: 0,
  specific_weight: 0,
  volume: 0,
  environment_temp: 0,
  water_temp: 0,
  concrete_temp: 0,
  car_label: "",
  provider: "",
  slump_test: undefined,
  slump_flow_test: undefined,
  air_test: undefined,
  segregation_test: undefined,
  jring_test: undefined,
  stress_test: {
    status: 0,
    molding_date: moment(new Date()).format("YYYY-MM-DD"),
    fck: 0,
    concrete_cp: [],
    expected_resistance: []
  },
  has_slump: false,
  has_slump_flow: false,
  has_jring: false,
  has_segregation_test: false,
  has_pressure: false,
  has_air: false
};

const initialSeries = {
  number: 0,
  concrete_mix: [],
  molding_date: "2020-01-01",
  fck: 0,
  boletim: 0,
  place: "",
  optional_place: "",
  internal_comments: "",
  technical_commets: "",
  lab_arrival: "",
  verified: false,
  trace: "",
  discarded_motive: null,
  concrete_type: "",
  control: 0,
  has_cp_missing_break: [],
  has_error_in_test: [],
  fck_reached: false
};

export { initialControl, initialMixtures, initialSeries, initialTestingBody };
