/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Container } from "../../../components/Container";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Formik, Form, Field, FormikActions } from "formik";
import { InputColumn, InputText, InputValidation } from "src/components/Inputs";
import * as api from "src/services/api/admin";
import { logout } from "../../../services/api/auth";
import { useHistory } from "react-router-dom";
import { useUser } from "src/context/admin/user";

type TConfirmPassword = {
  current_password?: string;
  new_password?: string;
  confirm_password?: string;
};
const Confirm = () => {
  const history = useHistory();
  const [user] = useUser();

  useEffect(() => {
    if (user.data?.is_confirmed) {
      history.push("/login");
    }
  }, []);

  const onSubmit = (
    values: TConfirmPassword,
    { setFieldError, setSubmitting }: FormikActions<any>
  ) => {
    api
      .confirmUser(values.current_password || "", values.new_password || "")
      .then(() => {
        logout().then(() => {
          history.push("/login");
        });
      })
      .catch(() => {
        setFieldError("current_password", "Senha atual não confere");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  async function handleLogout() {
    logout().then(() => {
      history.push("/login");
    });
  }

  const renderForm = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          current_password: undefined,
          new_password: undefined,
          confirm_password: undefined
        }}
        render={({ values, errors, isSubmitting }) => (
          <Form>
            <InputColumn label="Senha atual">
              <Field
                name="current_password"
                component={InputText}
                required
                type="password"
              />
              <InputValidation message={errors.current_password} />
            </InputColumn>
            <InputColumn label="Nova senha">
              <Field
                name="new_password"
                component={InputText}
                required
                type="password"
              />
            </InputColumn>
            <InputColumn label="Confirmar nova senha">
              <Field
                name="confirm_password"
                component={InputText}
                required
                type="password"
              />
              <InputValidation
                message={
                  values.new_password &&
                  values.confirm_password &&
                  values.new_password !== values.confirm_password
                    ? "As senhas não são iguais"
                    : ""
                }
              />
            </InputColumn>
            <Button
              className="mt-2"
              color="primary"
              size="sm"
              block
              isSubmitting={true}
              type="submit"
              disabled={
                isSubmitting ||
                !values.new_password ||
                !values.confirm_password ||
                values.new_password !== values.confirm_password ||
                !values.current_password
              }
            >
              Confirmar e continuar
            </Button>
            <p className="mt-2 mb-1">
              <label className="text-sm-right text-bold">
                <u>
                  <a className="text-dark" onClick={handleLogout}>
                    Sair e voltar para o login
                  </a>
                </u>
              </label>
            </p>
          </Form>
        )}
      />
    );
  };

  return (
    <Container title="Trocar senha">
      <Row className="justify-content-center">
        <Col sm={4} lg={4}>
          <div className="text-center w-75 pb-4 m-auto">
            <span>
              <img
                src={process.env.PUBLIC_URL + "/static/assets/images/logo.svg"}
                alt=""
                height="40"
              />
            </span>
          </div>
          <Card className="pl-2 pr-2">
            <CardBody>
              <Row>
                <Col>
                  <div className=" mb-1">
                    <h4>Confirmar sua conta!</h4>
                  </div>
                  <p className="text-muted mb-3">
                    Para seu primeiro acesso é necessário trocar sua senha.
                  </p>
                  {renderForm()}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Confirm;
