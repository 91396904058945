import React from "react";
import moment from "moment";
import * as p from "../../../utils/precisions";
import { toLocateNumber, formatSimpleDate } from "../../../utils/format";
import { IDataTableColumn } from "react-data-table-component";
import { useUser } from "src/context/admin/user";

export const columnsSeriesBase = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Nº",
    data: "number"
  },
  {
    title: "Local",
    data: "place"
  },
  {
    title: "Moldagem",
    data: "molding_date",
    render: (data: string) => {
      return formatSimpleDate(data);
    }
  },
  {
    title: "FCK",
    data: "fck"
  },
  {
    title: "Boletim",
    data: "boletim"
  }
];

const temp = [
  ...columnsSeriesBase,
  {
    title: "",
    data: "approved",
    render(data: boolean, _type: any, row: any) {
      if (
        row.has_cp_missing_break.length > 0 ||
        row.has_error_in_test.length > 0
      ) {
        return '<i id="dates" class="mdi mdi-18px mdi-alert-circle text-warning" />';
      } else if (!row.fck_reached || !data) {
        return '<i id="dates" class="mdi mdi-18px mdi-alert-circle text-danger" />';
      } else {
        return '<i id="dates" class="mdi mdi-18px mdi-check-circle text-success" />';
      }
    }
  }
];
export const columnsSeries = temp;

export const columnsSeriesClient = columnsSeriesBase;

export const columnsTB = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Código",
    data: "code"
  },
  {
    title: "Data ruptura",
    data: "rupture_date",
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  },
  {
    title: "Idade",
    data: "age",
    render(data: number) {
      return data < 24 ? `${data}h` : `${data / 24}d`;
    }
  },
  {
    title: "Carga",
    data: "load",
    render: (data: number) => {
      return toLocateNumber(data, p.CP_LOAD, "-");
    }
  },
  {
    title: "FCK (MPa)",
    data: "fck",
    render: (data: number) => {
      return toLocateNumber(data, p.FCK, "-");
    }
  },
  {
    title: "Rompido",
    data: "id",
    visible: false
  },
  {
    title: "H (mm)",
    data: "height",
    render: (data: number) => {
      return toLocateNumber(data, p.HEIGHT, "0,00");
    }
  },
  {
    title: "D (mm)",
    data: "diameter",
    render: (data: number) => {
      return toLocateNumber(data, p.DIAMETER, "0,00");
    }
  },
  {
    title: "Peso (Kg)",
    data: "weight",
    render: (data: number) => {
      return toLocateNumber(data, p.WEIGHT, "0,00");
    }
  }
];

export const columnsTBtoBreak = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Código",
    data: "code"
  },
  {
    title: "Obra",
    data: "work"
  },
  {
    title: "Data ruptura",
    data: "rupture_date",
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  },
  {
    title: "Idade",
    data: "age",
    render(data: number) {
      return data < 24 ? `${data}h` : `${data / 24}d`;
    }
  },
  {
    title: "Carga",
    data: "load",
    render: (data: number) => {
      return toLocateNumber(data, p.CP_LOAD, "-");
    }
  },
  {
    title: "FCK (MPa)",
    data: "fck",
    render: (data: number) => {
      return toLocateNumber(data, p.FCK, "-");
    }
  },
  {
    title: "Rompido",
    data: "id",
    visible: false
  },
  {
    title: "H (mm)",
    data: "height",
    render: (data: number) => {
      return toLocateNumber(data, p.HEIGHT, "0,00");
    }
  },
  {
    title: "D (mm)",
    data: "diameter",
    render: (data: number) => {
      return toLocateNumber(data, p.DIAMETER, "0,00");
    }
  },
  {
    title: "Peso (Kg)",
    data: "weight",
    render: (data: number) => {
      return toLocateNumber(data, p.WEIGHT, "0,00");
    }
  }
];

const useColumnsSeries = (): IDataTableColumn<TConcreteSeries>[] => {
  const [user] = useUser();

  return [
    {
      name: "Nº",
      selector: "number",
      sortable: true,
      width: "70px"
    },
    {
      name: "Local",
      selector: "place",
      sortable: true,
      wrap: true,
      grow: 1,
      style: {
        flex: "1 !important"
      },
      format: ({ place }) => {
        if (place.length > 100) {
          return `${place.substring(0, 100)}...`;
        }

        return place;
      }
    },
    {
      name: "Molding",
      selector: "molding_date",
      sortable: true,
      width: "110px",
      format: ({ molding_date }) => {
        return formatSimpleDate(molding_date);
      }
    },
    {
      name: "FCK",
      selector: "fck",
      sortable: true,
      width: "70px"
    },
    {
      name: "Boletim",
      selector: "boletim",
      sortable: true,
      width: "70px"
    },
    {
      name: "",
      selector: "approved",
      omit: !!user.data?.is_client,
      cell: row => {
        if (
          row.has_cp_missing_break.length > 0 ||
          row.has_error_in_test.length > 0
        ) {
          return (
            <i
              id="dates"
              className="mdi mdi-18px mdi-alert-circle text-warning"
            />
          );
        } else if (!row.fck_reached || !row.approved) {
          return (
            <i
              id="dates"
              className="mdi mdi-18px mdi-alert-circle text-danger"
            />
          );
        } else {
          return (
            <i
              id="dates"
              className="mdi mdi-18px mdi-check-circle text-success"
            />
          );
        }
      },
      width: "30px",
      style: {
        padding: 0
      }
    }
  ];
};

export { useColumnsSeries };
