import React from "react";
import { Row, Col } from "reactstrap";
import { Field, FormikProps } from "formik";
import {
  InputColumn,
  InputDecimal,
  InputRow
} from "../../../../components/Inputs";
import {
  formatCPCode,
  formatSimpleDate,
  toLocateNumber
} from "../../../../utils/format";
import { formatAgeLabel } from "../../Concrete/utils";
import { calculateFck } from "../utils";

interface Props extends FormikProps<TGrauteTestingBody> {
  series?: TGrauteSeries;
  control?: TGrauteControl;
}

const Form: React.FC<Props> = ({ values, series, control, setFieldValue }) => {
  return (
    <Row>
      <Col>
        <dl className="row">
          <dt className="col-sm-4">Código</dt>
          <dd className="col-sm-8">{formatCPCode(values.code)}</dd>
          <dt className="col-sm-4">Moldagem</dt>
          <dd className="col-sm-8">{formatSimpleDate(series?.molding_date)}</dd>
          <dt className="col-sm-4">Idade</dt>
          <dd className="col-sm-8">{formatAgeLabel(values.age)}</dd>
          <dt className="col-sm-4">Data para ruptura</dt>
          <dd className="col-sm-8">{formatSimpleDate(values.rupture_date)}</dd>
          <dt className="col-sm-4">
            <h4>Atrazo (dias)</h4>
          </dt>
          <dd className="col-sm-8">
            <h4>{values.delayed ? Math.abs(values.out_days || 0) : 0}</h4>
          </dd>
        </dl>

        <Row>
          <Col>
            <InputColumn label="Largura (mm)">
              <Field
                component={InputDecimal}
                name="width"
                disabled={!control?.dimensions_control}
                onChange={(value: number) => {
                  setFieldValue("width", value);
                }}
              />
            </InputColumn>
          </Col>
          <Col>
            <InputColumn label="Comprimento (mm)">
              <Field
                component={InputDecimal}
                name="length"
                disabled={!control?.dimensions_control}
                onChange={(value: number) => {
                  setFieldValue("length", value);
                }}
              />
            </InputColumn>
          </Col>
          <Col>
            <InputColumn label="Altura (mm)">
              <Field
                component={InputDecimal}
                name="height"
                disabled={!control?.dimensions_control}
                onChange={(value: number) => {
                  setFieldValue("height", value);
                }}
              />
            </InputColumn>
          </Col>
        </Row>
        <InputRow label="Carga">
          <Field
            component={InputDecimal}
            name="load"
            required
            onChange={(value: number) => {
              setFieldValue("load", value);
            }}
          />
        </InputRow>
        <dl className="row">
          <dt className="col-sm-4">
            <h3>FCK (MPa)</h3>
          </dt>
          <dd className="col-sm-8">
            <h2>
              {toLocateNumber(
                calculateFck(
                  values.load || 0,
                  values.width || 0,
                  values.height || 0,
                  values.is_flexion
                ),
                3,
                "-"
              )}
            </h2>
          </dd>
        </dl>
      </Col>
    </Row>
  );
};

export default Form;
