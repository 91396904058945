import React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";

// const mask = [/[a-zA-z]/, /[1-9]{1,2}/];
const maskValidation = /^([a-zA-Z]{1,2}[1-9]{1}[0-9]{0,1})$/;

const validate = (value: string) => {
  let errorMessage;
  if (!maskValidation.test(value)) {
    errorMessage = "Celular inválida";
  }
  return errorMessage;
};

const mask = (rawValue: string) => {
  // Match com uma letra
  if ((rawValue || "").match(/^[a-zA-Z]{1}$/)) {
    return [/[a-zA-z]/, /[a-zA-z]/];
  }
  // Match com duas letras
  else if ((rawValue || "").match(/^[a-zA-Z]{2}$/)) {
    return [/[a-zA-z]/, /[a-zA-z]/, /[1-9]/];
  }
  // Match com uma letra e um número
  else if ((rawValue || "").match(/^[a-zA-Z]{1}[1-9]{1}$/)) {
    return [/[a-zA-z]/, /[1-9]/, /[1-9]/];
  }
  // Match com uma letra e dois números
  else if ((rawValue || "").match(/^[a-zA-Z]{1}[1-9]{1}[0-9]{1}$/)) {
    return [/[a-zA-z]/, /[1-9]/, /[1-9]/];
  }
  // Match com duas letras e um número
  else if ((rawValue || "").match(/^[a-zA-Z]{2}[1-9]{1}$/)) {
    return [/[a-zA-z]/, /[a-zA-z]/, /[1-9]/, /[0-9]/];
  }
  // Match com duas letras e dois números
  else if ((rawValue || "").match(/^[a-zA-Z]{2}[1-9]{1}[0-9]{1}$/)) {
    return [/[a-zA-z]/, /[a-zA-z]/, /[1-9]/, /[0-9]/];
  }

  // Padrão match com duas letras e dois números
  return [/[a-zA-z]/, /[a-zA-z]/, /[1-9]/, /[0-9]/];
};

interface Props {
  name: string;
  required: boolean;
  disabled?: boolean;
}

const InputXlsxCell: React.FC<Props> = ({ name, required, disabled }) => (
  <Field
    name={name}
    validate={validate}
    render={({ field, form: { setFieldValue } }: any) => (
      <MaskedInput
        {...field}
        className="form-control form-control-sm"
        disabled={disabled}
        required={required}
        mask={mask}
        guide={false}
        onChange={event => {
          let { value } = event.target;
          setFieldValue(name, value);
        }}
      />
    )}
  />
);

export default InputXlsxCell;
