import React from "react";

interface Props {
  message?: any;
}

const InputValidation: React.FC<Props> = ({ message }) => (
  <div className="invalid-feedback">{message ? message.toString() : ""}</div>
);

InputValidation.defaultProps = {
  message: ""
};

export default InputValidation;
