import React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";

const mask = [/[a-zA-z]/, /[a-zA-z]/];
const maskValidation = /^([a-zA-Z]{1,2})$/;

const validate = (value: string) => {
  let errorMessage;
  if (!maskValidation.test(value)) {
    errorMessage = "Celula inválida";
  }
  return errorMessage;
};

interface Props {
  name: string;
  required: boolean;
  disabled?: boolean;
}

const InputCellColumn: React.FC<Props> = ({ name, required, disabled }) => (
  <Field
    name={name}
    validate={validate}
    render={({ field, form: { setFieldValue } }: any) => (
      <MaskedInput
        {...field}
        className="form-control form-control-sm"
        disabled={disabled}
        required={required}
        mask={mask}
        guide={false}
        onChange={event => {
          let { value } = event.target;
          setFieldValue(name, value);
        }}
      />
    )}
  />
);

export default InputCellColumn;
