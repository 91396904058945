/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, FieldArray, Form, Formik, FormikActions } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  ButtonInsert,
  ButtonOption,
  ButtonSave,
  ListButtonRow
} from "src/components/Buttons";
import { HeaderTitle } from "src/components/Headers";
import {
  InputInteger,
  InputRow,
  InputSelect,
  InputText,
  InputXlsxCell
} from "src/components/Inputs";
import { SelectXlsxType, SelectXlsxVariables } from "src/components/Selects";
import { TextMuted } from "src/components/Text";
import { postXlsx, putXlsx, sendXlsxFile, xlsx } from "src/services/api/core";
import { Container } from "../../../components/Container";
import { TableContent, TableRow } from "./Table";

const PAGE_TITLE = "Configurar planilhas";

interface SectionProps {
  name: string;
  data: XlsxConfig;
  index: number;
  group?: "C" | "I" | "S";
  insert: () => void;
  remove: () => void;
}

interface CellProps {
  name: string;
  group?: "C" | "I" | "S";
  data: XlsxCell;
  remove: () => void;
}

const LoopCell: React.FC<CellProps> = ({ name, group, remove }) => (
  <TableRow name={name} remove={remove} group={group} loop type="column" />
);

const LoopSection: React.FC<SectionProps> = ({ name, data, group, remove }) => (
  <>
    <HeaderTitle title={"Dados repetidos por linha"} />
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <Button
              type="button"
              className="btn btn-sm btn-white waves-effect p-0 border-0"
              onClick={remove}
            >
              <i className="mdi mdi-delete font-18" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <InputRow label="Linha inicial" label_col={5}>
              <Field
                component={InputInteger}
                name={`${name}.start_line`}
                required
              />
            </InputRow>
          </Col>
          <Col sm={3}>
            <InputRow label="Pular linhas" label_col={5}>
              <Field
                component={InputInteger}
                name={`${name}.offset`}
                required
              />
            </InputRow>
          </Col>
        </Row>
        <TableContent />
        <FieldArray
          name={`${name}.xlsx_cell`}
          render={({ insert, remove }) => (
            <>
              {(data.xlsx_cell || []).map((cell: XlsxCell, index: number) => {
                return (
                  <LoopCell
                    name={`${name}.xlsx_cell[${index}]`}
                    data={cell}
                    remove={() => remove(index)}
                    group={group}
                  />
                );
              })}

              <ButtonOption
                className="btn-block"
                type="button"
                onClick={() => {
                  insert((data.xlsx_cell || []).length, {});
                }}
              >
                Nova coluna
              </ButtonOption>
            </>
          )}
        />
      </CardBody>
    </Card>
  </>
);

const FixedCell: React.FC<CellProps> = ({ name, group, remove }) => (
  <TableRow name={name} group={group} remove={remove} type="cell" />
);

const FixedSection: React.FC<SectionProps> = ({
  name,
  data,
  group,
  remove
}) => (
  <>
    <HeaderTitle title={"Dados fíxos"} />
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <Button
              type="button"
              className="btn btn-sm btn-white waves-effect p-0 border-0"
              onClick={remove}
            >
              <i className="mdi mdi-trash-can font-18" />
            </Button>
          </Col>
        </Row>
        <TableContent />
        <FieldArray
          name={`${name}.xlsx_cell`}
          render={({ insert, remove }) => (
            <>
              {(data.xlsx_cell || []).map((cell: XlsxCell, index: number) => {
                return (
                  <FixedCell
                    name={`${name}.xlsx_cell[${index}]`}
                    data={cell}
                    remove={() => remove(index)}
                    group={group}
                  />
                );
              })}
              <ButtonOption
                className="btn-block"
                type="button"
                onClick={() => {
                  insert((data.xlsx_cell || []).length, {});
                }}
              >
                Nova coluna
              </ButtonOption>
            </>
          )}
        />
      </CardBody>
    </Card>
  </>
);

const XlsxReport = () => {
  const [xlsxData, setXlsxData] = useState<Xlsx[]>([]);
  const [selectedXlsx, setSelectedXlsx] = useState<Xlsx>();
  const [fileName, setFileName] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>();

  useEffect(() => {
    xlsx().then(({ data }) => {
      setXlsxData(data);
    });
  }, []);

  const selectReport = () => {
    const options = xlsxData.map(item => ({
      label: item.name,
      value: item
    }));

    return (
      <Row>
        <Col sm={3}>
          <InputRow label="Relatórios (selecione para editar)">
            <InputSelect
              options={options}
              onClick={(xlsx: Xlsx) => {
                setSelectedXlsx(xlsx);
                setFileName((xlsx?.file || "").split("/").pop());
              }}
            />
          </InputRow>
        </Col>
      </Row>
    );
  };

  const formatName = (name?: string) => {
    if (name !== undefined && name.length > 20) {
      return `${name.slice(0, 12)}...${name.slice(
        Math.max(12, name.length - 12),
        name.length
      )}`;
    }

    return name;
  };

  const onProgress = (event: any) => {
    if (event) {
      setUploadProgress(event.loaded / event.total);
    }
  };

  const formatFileLabel = () => {
    if (uploadProgress) {
      return `Enviando: ${Math.round(uploadProgress * 100)}%`;
    }
    return formatName(fileName) || "Escolha o arquivo...";
  };

  const renderHeader = (
    index: number,
    disableFixedSection: boolean,
    hasFile: boolean,
    insert: (index: number, value: any) => void,
    setFieldValue: (name: string, valeu: any) => void
  ) => (
    <>
      <Row>
        <Col sm={5}>
          <InputRow label="Nome" label_col={3}>
            <Field component={InputText} name={"name"} required />
          </InputRow>
          <InputRow label="Arquivo Excel" label_col={3}>
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile04"
              accept=".xls,.xlsx"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.currentTarget.files) {
                  const file = event.currentTarget.files[0];
                  setFileName(file.name);
                  sendXlsxFile({ file }, onProgress).then(data => {
                    setFieldValue("file", data.uri);
                    setUploadProgress(undefined);
                  });
                }
              }}
              required={!hasFile}
            />
            <label
              className="custom-file-label ml-2 mr-2"
              htmlFor="inputGroupFile04"
            >
              {formatFileLabel()}
            </label>
          </InputRow>
          <InputRow label="Tipo" label_col={3}>
            <Field component={SelectXlsxType} name="group" required />
          </InputRow>
        </Col>
      </Row>
      <Row className="mt-4 mb-3">
        <Col>
          <ListButtonRow
            buttonsLeft={
              <>
                <Button
                  className="border-0"
                  type="button"
                  size="sm"
                  color={"primary"}
                  outline
                  onClick={() =>
                    insert(index, {
                      config_type: "R",
                      xlsx_cell: [],
                      offset: 0
                    })
                  }
                >
                  <i className="mdi mdi-plus mr-1" />
                  Seção repetitiva
                </Button>
                <Button
                  className="border-0"
                  type="button"
                  size="sm"
                  color={"primary"}
                  outline
                  onClick={() =>
                    insert(index, { config_type: "F", xlsx_cell: [] })
                  }
                  disabled={disableFixedSection}
                >
                  <i className="mdi mdi-plus mr-1" />
                  Dados fixos
                </Button>
              </>
            }
          />
        </Col>
      </Row>
    </>
  );

  const onSubmit = (value: Xlsx, { setSubmitting }: FormikActions<Xlsx>) => {
    setSubmitting(true);

    if (value.id !== undefined) {
      putXlsx(value.id, value)
        .then(({ data }) => {
          setSelectedXlsx(data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      postXlsx(value)
        .then(({ data }) => {
          setSelectedXlsx(data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const processInitialData = (xlsx: Xlsx) => {
    xlsx.xlsx_config.forEach(config => {
      config.xlsx_cell.sort((a, b) => {
        const sumA = a.cell.split("").reduce((p, c) => p + c.charCodeAt(0), 0);
        const sumB = b.cell.split("").reduce((p, c) => p + c.charCodeAt(0), 0);

        return sumA - sumB;
      });
    });

    return xlsx;
  };

  return (
    <Container title={PAGE_TITLE}>
      <h1 className="mt-4, mb-3" style={{ fontWeight: "lighter" }}>
        Seus relatórios
      </h1>
      {selectReport()}
      <div className="dropdown-divider mt-4 mb-4" />
      {selectedXlsx === undefined || selectedXlsx === null ? (
        <div className="text-center">
          <TextMuted>
            Escolha um relatório para editar ou crie um novo
          </TextMuted>
          <ButtonInsert
            onClick={() => {
              setSelectedXlsx({
                xlsx_config: [],
                name: undefined,
                file: undefined,
                group: undefined
              });
            }}
          >
            Novo relatório
          </ButtonInsert>
        </div>
      ) : (
        <Formik
          initialValues={processInitialData(selectedXlsx)}
          onSubmit={onSubmit}
          enableReinitialize
          render={({ values, isSubmitting, setFieldValue }) => (
            <Form className="mb-4">
              <h2 className="mt-4, mb-3" style={{ fontWeight: "lighter" }}>
                {values.id !== undefined
                  ? "Editar relatório"
                  : "Novo relatório"}
              </h2>
              <FieldArray
                name="xlsx_config"
                render={({ insert, remove }) => (
                  <>
                    {renderHeader(
                      (values?.xlsx_config || []).length,
                      values.xlsx_config.filter(
                        item => item.config_type === "F"
                      ).length > 0,
                      values.file !== undefined && values.file?.length > 0,
                      insert,
                      setFieldValue
                    )}
                    {(values?.xlsx_config || []).map(
                      (section: XlsxConfig, index: number) => {
                        if (section.config_type === "F") {
                          return (
                            <FixedSection
                              name={`xlsx_config[${index}]`}
                              data={section}
                              insert={() => insert(index + 1, {})}
                              remove={() => {
                                remove(index);
                              }}
                              index={index}
                              group={values.group}
                            />
                          );
                        } else if (section.config_type === "R") {
                          return (
                            <LoopSection
                              name={`xlsx_config[${index}]`}
                              data={section}
                              insert={() => {
                                insert(index + 1, {});
                              }}
                              remove={() => {
                                remove(index);
                              }}
                              index={index}
                              group={values.group}
                            />
                          );
                        }

                        return null;
                      }
                    )}
                  </>
                )}
              />
              <ButtonSave isSubmitting={isSubmitting} disabled={isSubmitting} />
            </Form>
          )}
        />
      )}
    </Container>
  );
};

export default XlsxReport;
