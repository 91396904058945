import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import { isAuthenticated } from "./services/api/auth";
import ClientsList from "./pages/admin/Client";
import WorkList from "./pages/admin/Work";
import User from "./pages/admin/User";
import EmployeeList from "./pages/admin/Employee";
import Settings from "./pages/admin/Settings";
import Concrete from "./pages/tech_control/Concrete";
import { ConcreteControl } from "./pages/tech_control/Concrete/Control";
import { useUser } from "./context/admin/user";
import Density from "./pages/tech_control/Density";
import Applications from "./pages/admin/Applications";
import MenuBar from "./components/MenuBar";
import Characterization from "./pages/tech_control/characterization";
import Service from "./pages/control/Service";
import Contract from "./pages/control/Contract";
import Invoice from "./pages/control/Invoice";
import Invoices from "./pages/control/InvoiceControl";
import Usage from "./pages/control/Usage";
import Graute from "./pages/tech_control/Graute";
import BlockConcrete from "./pages/tech_control/BlockConcrete";
import BlockCeramic from "./pages/tech_control/BlockCeramic";
import Floor from "./pages/tech_control/Floor";
import TechSettings from "./pages/tech_control/Settings";
import ControlSettings from "./pages/control/Settings";
import Files from "./pages/client/Files";
import { ResetPassword } from "./pages/auth/Password";
import CheckEmail from "./pages/auth/Password/CheckEmail";
import { setApplication } from "./services/api/api";
import Confirm from "./pages/auth/Confirm";
import Logout from "./pages/auth/Logout";
import { getApplications } from "./services/api/applications";
import Panel from "./pages/Panel";
import XlsxReport from "./pages/tech_control/XlsxReport";
import { default as CoreSettings } from "./pages/core/Settings";
import useFlags from "./hooks/useFlags";
import CharacterizationControl from "./pages/tech_control/characterization/Control";
import ReportRequest from "./pages/core/reports";
import PrivacyPolicy from "./pages/core/PrivacyPolicy";

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [, userActions] = useUser();

  useEffect(() => {
    if (isAuthenticated()) {
      userActions
        .me()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      getApplications().then(({ data }) => {
        userActions.setApplications(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRouter = (props: any) => {
    if (!isAuthenticated()) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }
    if (!loading && Component !== undefined) {
      return <Component {...props} />;
    } else {
      return <div />;
    }
  };

  return <Route {...rest} render={renderRouter} />;
};

const AppRoute = () => {
  const match = useRouteMatch();
  const { appSlug } = useParams<{ appSlug: string }>();
  const { isMatech } = useFlags();

  setApplication(appSlug);

  return (
    <>
      <MenuBar />
      <Route exact path={`${match.path}/clients`} component={ClientsList} />
      <Route exact path={`${match.path}/works`} component={WorkList} />
      <Route exact path={`${match.path}/users`} component={User} />
      <Route exact path={`${match.path}/employees`} component={EmployeeList} />
      <Route exact path={`${match.path}/admin_options`} component={Settings} />
      <Route exact path={`${match.path}/concrete`} component={Concrete} />
      <Route
        exact
        path={`${match.path}/concrete/control/:controlId`}
        component={ConcreteControl}
      />
      <Route exact path={`${match.path}/graute`} component={Graute} />
      <Route exact path={`${match.path}/density`} component={Density} />
      <Route
        exact
        path={`${match.path}/samples`}
        component={Characterization}
      />
      <Route
        exact
        path={`${match.path}/samples/control/:controlId`}
        component={CharacterizationControl}
      />
      <Route exact path={`${match.path}/contracts`} component={Contract} />
      {isMatech && (
        <>
          <Route exact path={`${match.path}/services`} component={Service} />
          <Route exact path={`${match.path}/invoices`} component={Invoice} />
          <Route
            exact
            path={`${match.path}/invoice_control`}
            component={Invoices}
          />
        </>
      )}
      <Route exact path={`${match.path}/usage`} component={Usage} />
      <Route
        exact
        path={`${match.path}/block_concrete`}
        component={BlockConcrete}
      />
      <Route
        exact
        path={`${match.path}/block_ceramic`}
        component={BlockCeramic}
      />
      <Route exact path={`${match.path}/floor`} component={Floor} />
      <Route
        exact
        path={`${match.path}/tech_options`}
        component={TechSettings}
      />
      <Route exact path={`${match.path}/reports`} component={XlsxReport} />
      <Route
        exact
        path={`${match.path}/control_options`}
        component={ControlSettings}
      />
      <Route exact path={`${match.path}/client_side`} component={Files} />
      <Route exact path={`${match.path}/panel`} component={Panel} />
      <Route exact path={`${match.path}/settings`} component={CoreSettings} />
    </>
  );
};

const NotAppRoute = () => {
  const match = useRouteMatch();
  const { appSlug } = useParams<{ appSlug: string }>();

  setApplication(appSlug);

  return (
    <>
      <Route exact path={`${match.path}`} component={ReportRequest} />
    </>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          component={(props: any) =>
            isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            ) : (
              <Login {...props} />
            )
          }
        />
        <Route
          path="/register"
          component={(props: any) =>
            isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            ) : (
              <Register {...props} />
            )
          }
        />
        <Route exact path="/reset_password" component={() => <CheckEmail />} />
        <Route exact path="/set_password" component={() => <ResetPassword />} />
        <Route
          exact
          path="/privacy-policy"
          component={() => <PrivacyPolicy />}
        />
        <PrivateRoute exact path="/" component={Applications} />
        <PrivateRoute exact path="/confirm" component={Confirm} />
        <PrivateRoute exact path="/logout" component={Logout} />
        <PrivateRoute
          exact
          path={"/:appSlug/request_report/:reportHash/"}
          component={NotAppRoute}
        />
        <PrivateRoute component={AppRoute} path={"/:appSlug"} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
