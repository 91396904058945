import React, { useState } from "react";
import { ButtonInsert } from "../../../components/Buttons";
import EmployeePOST from "./crud/EmployeePOST";
import { Modal } from "../../../components/Modal";

const Insert: React.FC<InsertType<EmployeeFormType>> = props => {
  const [showModal, setShowModal] = useState(false);

  const toggle = () => setShowModal(!showModal);

  return (
    <>
      <ButtonInsert onClick={toggle} />
      <Modal
        {...props}
        title="Cadastrar funcionário"
        toggle={toggle}
        size="lg"
        isOpen={showModal}
        className=""
      >
        <EmployeePOST {...props} toggle={toggle} />
      </Modal>
    </>
  );
};

export default Insert;
