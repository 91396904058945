import React from "react";
import { InputSelect } from "../Inputs";

const SelectMonth: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: 1, label: "Janeiro" },
      { value: 2, label: "Fevereiro" },
      { value: 3, label: "Março" },
      { value: 4, label: "Abril" },
      { value: 5, label: "Maio" },
      { value: 6, label: "Junho" },
      { value: 7, label: "Julho" },
      { value: 8, label: "Agosto" },
      { value: 9, label: "Setembro" },
      { value: 10, label: "Outubro" },
      { value: 11, label: "Novembro" },
      { value: 12, label: "Dezembro" }
    ];
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Mês..."
      onClick={props.onChange}
    />
  );
};

export default SelectMonth;
