/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useData, TableList } from "../../../../components/Table";
import * as api from "../../../../services/api/graute";
import { columnsGroupsTrace, columnsTraces } from "../columns";
import { HeaderTitle } from "../../../../components/Headers";
import { LoadingComponent } from "../../../../components/Loader";
import { ListButtonRow, ButtonDelete } from "../../../../components/Buttons";
import { InsertTrace, EditTrace } from "./Form";

type TPlace = { place: string; join: string; tower: string };

interface Props {
  control: TGrauteControl;
  contract?: ContractValuesProps;
}

const Traces: React.FC<Props> = ({ control }) => {
  const trace = useData<TGrauteTrace>();
  const [traces, setTraces] = useState<TGrauteTrace[]>([]);
  const place = useData<TPlace>();
  const [places, setPlaces] = useState<TPlace[]>([]);
  const [loadingPlaces, setLoadingPlaces] = useState<boolean>(false);
  const [loadingTraces, setLoadingTraces] = useState<boolean>(false);
  const [dbClickEdit, setDbClickEdit] = useState<boolean>(false);

  useEffect(() => {
    getPlaces();
  }, [control]);

  useEffect(() => {
    if (place.selected.item !== undefined) {
      getTraces();
    } else {
      setTraces([]);
    }
  }, [place.selected.item]);

  const handleDataTracePlaces = (data: { join: string; tower: string }[]) => {
    const newData: TPlace[] = [];
    data.forEach(item => {
      const place = `${item.tower} - ${item.join}`;

      if (!newData.some(c => c.place === place))
        newData.push({
          place,
          join: item.join,
          tower: item.tower
        });
    });

    return newData;
  };

  const getPlaces = () => {
    if (control.id) {
      setLoadingPlaces(true);
      api.getDistinctPlaces(control.id, "", "").then(({ data }) => {
        setPlaces(handleDataTracePlaces(data["towers_joins"]));
        setTraces([]);
        trace.selected.set(undefined);
        setLoadingPlaces(false);
      });
    }
  };

  const getTraces = () => {
    const { item } = place.selected;
    setLoadingTraces(true);
    api
      .getTraces("", "", item?.tower, item?.join, `${control.id!}`)
      .then(({ data }) => {
        setTraces(data);
        trace.selected.set(undefined);
        setLoadingTraces(false);
      });
  };

  const renderPlaces = () => {
    return (
      <>
        <HeaderTitle title="Locais dos traços" />
        <LoadingComponent loading={loadingPlaces}>
          <TableList
            tableId="tracePlaces"
            data={places}
            columns={columnsGroupsTrace}
            selected={place.selected.set}
            searching={true}
          />
        </LoadingComponent>
        <ListButtonRow
          buttonsLeft={
            <InsertTrace control={control!} insertedItem={() => {}} />
          }
          marginTop="mt-2"
        />
      </>
    );
  };

  const onDeleteTrace = () => {
    if (trace.selected.item && trace.selected.item.id !== undefined) {
      return api
        .deleteTrace(trace.selected.item.id)
        .then(({ data }) => {
          trace.deleted.set(trace.selected.item);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  const renderTraces = () => {
    return (
      <>
        <HeaderTitle title="Traços" />
        <LoadingComponent loading={loadingTraces}>
          <TableList
            tableId="traces"
            data={traces}
            columns={columnsTraces}
            selected={trace.selected.set}
            updated={trace.updated.item}
            created={trace.created.item}
            deleted={trace.deleted.item}
            searching={true}
            dbClick={item => {
              trace.selected.set(item);
              setDbClickEdit(true);
              setDbClickEdit(false);
            }}
          />
        </LoadingComponent>
        <ListButtonRow
          buttonsLeft={
            <EditTrace
              item={trace.selected.item!}
              updatedItem={trace.updated.set}
              control={control!}
              showModal={dbClickEdit}
            />
          }
          buttonsRight={
            <ButtonDelete
              disabled={!trace.selected.item}
              onClick={onDeleteTrace}
            />
          }
          marginTop="mt-2"
        />
      </>
    );
  };

  return (
    <Card className="filter-bar-search">
      <CardBody>
        <Row>
          <Col sm={5}>{renderPlaces()}</Col>
          <Col sm={7}>{renderTraces()}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Traces;
