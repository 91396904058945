import api from "./api";

export const ASCENDENTE = "asc";
export const DESCENDENTE = "des";

export function getLots(contractId: number, fields: string = "") {
  let endpoint = `/concrete_block/lots/${contractId}/?fields=${fields}`;
  return api.get(endpoint);
}

export function getLot(id: number) {
  let endpoint = `/concrete_block/lot/${id}/`;
  return api.get(endpoint);
}

export function putLot(id: number, data: any) {
  let endpoint = `/concrete_block/lot/${id}/`;
  return api.put(endpoint, data);
}

export function postLot(id: number, data: any) {
  let endpoint = `/concrete_block/lots/${id}/`;
  return api.post(endpoint, data);
}

export function deleteLot(id: number) {
  let endpoint = `/concrete_block/lot/${id}/`;
  return api.delete(endpoint);
}

export function getSamples(lotId: number) {
  let endpoint = `/concrete_block/break_samples/${lotId}/`;
  return api.get(endpoint);
}

export function getSample(id: number) {
  let endpoint = `/concrete_block/break_sample/${id}/`;
  return api.get(endpoint);
}

export function putBreakSample(id: number, data: any) {
  let endpoint = `/concrete_block/break_sample/${id}/`;
  return api.put(endpoint, data);
}
