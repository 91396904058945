import api from "./api";

export function getServices(subDepartment: string = "", unit: string = "") {
  const endpoint = `/services/?sub_departments=${subDepartment}&unit=${unit}`;
  return api.get(endpoint);
}

export function postServices(data: any) {
  const endpoint = "/services/";
  return api.post(endpoint, data);
}

export function getService(id: number) {
  const endpoint = `/services/${id}/`;
  return api.get(endpoint);
}

export function deleteService(id: number) {
  const endpoint = `/services/${id}/`;
  return api.delete(endpoint);
}

export function putServices(id: number, data: any) {
  const endpoint = `/services/${id}/`;
  return api.put(endpoint, data);
}

export function getUnits() {
  const endpoint = "/services/units/";
  return api.get(endpoint);
}

export function getUnit(id: number) {
  const endpoint = `/services/units/${id}/`;
  return api.get(endpoint);
}

export function postUnit(data: any) {
  const endpoint = "/services/units/";
  return api.post(endpoint, data);
}

export function putUnits(id: number, data: any) {
  const endpoint = `/services/units/${id}/`;
  return api.put(endpoint, data);
}

export function deleteUnit(id: number) {
  const endpoint = `/services/units/${id}/`;
  return api.delete(endpoint);
}
