import { strainers } from "./strainers";

const capsuleStudy = {
  id: undefined,
  wet_soil_weight: undefined,
  dry_soil_weight: undefined,
  capsule_weight: undefined,
  capsule: undefined
};

const initialValues = {
  capsule_studies: [
    {
      id: undefined,
      is_hygroscopic: true,
      capsule_study: capsuleStudy
    },
    {
      id: undefined,
      is_hygroscopic: true,
      capsule_study: capsuleStudy
    }
  ],
  strainers: strainers.map(item => {
    return {
      id: undefined,
      strainer: {
        id: undefined,
        withheld_weight: undefined,
        gap: item.gap,
        projected: undefined
      },
      is_bgs: false
    };
  }),
  review: 0,
  notes: "",
  execution_date: "",
  wet: false,
  total_gross_weight: undefined,
  partial_gross_weight: undefined,
  total_wet_weight: undefined,
  partial_wet_weight: undefined,
  total_weight: undefined,
  band: "",
  is_bgs: false,
  sample: undefined,
  executor: undefined,
  total_capsule: undefined,
  partial_capsule: undefined
};

export default initialValues;
