/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from "react";
import { Button } from "reactstrap";

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  compact?: boolean;
  onClick: () => void;
}

const ButtonInsert: React.FC<Props> = ({
  children,
  disabled,
  compact,
  ...props
}) => (
  <>
    {!compact ? (
      <Button
        color="primary"
        type="button"
        size="sm"
        disabled={disabled}
        {...props}
      >
        {children || "Novo"}
      </Button>
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      // eslint-disable-next-line no-script-url
      <a {...props} href="javascript:void(0);" className="action-icon">
        <i className="mdi mdi-pencil mdi-18px" />
      </a>
    )}
  </>
);

ButtonInsert.defaultProps = {
  compact: false,
  disabled: false
};

export default ButtonInsert;
