import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/react";

import { getToken } from "./auth";
import { getCurrentApplication } from "./applications";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const BASE_REPORT_URL = `${
  process.env.REACT_APP_REPORT_ENDPOINT
}${getCurrentApplication()}/`;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token.access}`;
  }

  return config;
});

const setApplication = (slug: string) => {
  api.interceptors.request.use(async config => {
    config.baseURL = `${BASE_URL}${slug}/`;

    return config;
  });
};

// Add a response interceptor
api.interceptors.response.use(
  (
    response: AxiosResponse<any>
  ): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
    return response;
  },
  (error: any): any => {
    Sentry.captureException(error);
    if (error?.response?.status === 403) {
      localStorage.removeItem("aXoiGozVvLAxWFnr");
    }
    return Promise.reject(error.response);
  }
);

const sendFile = (
  endpoint: string,
  data: any,
  getProgress?: (progressEvent: any) => void
) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: getProgress
  };

  return api
    .put(endpoint, formData, config)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export default api;
export { sendFile, BASE_URL, BASE_REPORT_URL, setApplication };
