import React from "react";
import PropTypes from "prop-types";
import { isNullOrUndefined } from "util";
// import $ from "jquery";
const { $ } = window;

function getTable(table_id) {
  const table = $(`#${table_id}_wrapper`).find("table").DataTable();

  return table;
}

function insertItem(itens, table_id) {
  const a = [];
  a.push(itens);

  const table = $(`#${table_id}_wrapper`).find("table").DataTable();

  table.rows.add(a);
  table.draw();
}

function updateItem(item, table_id) {
  const table = $(`#${table_id}_wrapper`).find("table").DataTable();

  table.rows().every(function () {
    const oldRowData = this.data();
    if (oldRowData.id === item.id) {
      this.data(item);
    }
    this.invalidate();

    return true;
  });
}

function recharge(itens, table_id) {
  const table = getTable(table_id);

  table.clear().draw();
  table.rows.add(itens);
  table.draw();
}

function deleteItem(item, table_id) {
  const table = getTable(table_id);

  table.rows().every(function () {
    const oldRowData = this.data();
    if (oldRowData !== undefined && oldRowData.id === item.id) {
      this.remove();
      return true;
    }
    this.invalidate();

    return false;
  });

  table.draw();
}

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.fillTable(this.props.data);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.updatedItem !== null && nextProps.updatedItem !== undefined) {
      updateItem(nextProps.updatedItem, this.props.tableId);
    }

    if (
      !isNullOrUndefined(nextProps.newItem) &&
      (this.props.newItem || {}).id !== nextProps.newItem.id
    ) {
      insertItem(nextProps.newItem, this.props.tableId);
    }

    if (
      !isNullOrUndefined(nextProps.deletedItem) &&
      (this.props.deletedItem || {}).id !== nextProps.deletedItem.id
    ) {
      deleteItem(nextProps.deletedItem, this.props.tableId);
    }

    if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
      recharge(nextProps.data, this.props.tableId);
    }
    return true;
  }

  componentWillUnmount() {
    $(`#${this.props.tableId}.dataTables_wrapper`)
      .find("table")
      .DataTable()
      .destroy(true);
  }

  fillTable(data) {
    const thisComp = this;

    const table = $(`#${thisComp.props.tableId}`).DataTable({
      data,
      keys: {
        keys: [13 /* ENTER */, 38 /* UP */, 40 /* DOWN */]
      },
      columns: thisComp.props.columns,
      ordering: thisComp.props.ordering,
      searching: thisComp.props.searching,
      paging: thisComp.props.paging,
      responsive: thisComp.props.responsive,
      scrollCollapse: thisComp.props.scrollCollapse || false,
      scrollY: thisComp.props.scrollY,
      scrollX: thisComp.props.scrollX || false,
      info: thisComp.props.info || false,
      rowGroup: thisComp.props.groupSrc,
      order: thisComp.props.order || [],
      // select:true,
      pageResize: true,
      language: {
        url:
          "//cdn.datatables.net/plug-ins/1.10.21/i18n/Portuguese-Brasil.json",
        paginate: {
          previous: "<i className='mdi mdi-chevron-left'>",
          next: "<i className='mdi mdi-chevron-right'>"
        }
      },
      drawCallback() {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      }
    });

    // Handle event when cell gains focus
    $(`#${thisComp.props.tableId}`).on("key-refocus.dt key-focus.dt", function (
      e,
      datatable,
      cell
    ) {
      if ($(table.row(cell.index().row).node()).hasClass("selected")) {
        // $(table.row(cell.index().row).node()).removeClass("selected");
        // var data = table.row(cell.index().row).data();
        table.row(cell.index().row).deselect();
        thisComp.props.selectedItem(undefined);
      } else {
        // $(table.row(cell.index().row).node()).addClass("selected");

        const data = table.row(cell.index().row).data();
        table.row(cell.index().row).select();
        thisComp.props.selectedItem(data);

        // thisComp.props.selectedMultiple(table.rows({ selected: true }).data());
      }

      if (thisComp.props.selectedMultiple)
        thisComp.props.selectedMultiple(table.rows({ selected: true }).data());
    });

    // Handle event when cell looses focus
    $(`#${thisComp.props.tableId}`).on("key-blur.dt", function (
      e,
      datatable,
      cell
    ) {
      // Deselect highlighted row
      $(this).addClass("selected");
      if (!thisComp.props.select)
        $(table.row(cell.index().row).node()).removeClass("selected");
    });

    // Handle key event that hasn't been handled by KeyTable
    $(`#${thisComp.props.tableId}`).on("key.dt", function (
      e,
      datatable,
      key,
      cell,
      originalEvent
    ) {
      // If ENTER key is pressed
      if (key === 13) {
        // Get highlighted row data
        const data = table.row(cell.index().row).data();
        thisComp.props.selectedItem(data);
      }
    });

    // $(`#${thisComp.props.tableId} tbody`).on("click", "tr", function() {
    //   if ($(this).hasClass("selected")) {
    //     thisComp.props.selectedItem(undefined);
    //     $(this).removeClass("selected");
    //   } else {
    //     var data = table.row(this).data();

    //     if (data) {
    //       thisComp.props.selectedItem(data);

    //       table.$("tr.selected").removeClass("selected");
    //       $(this).addClass("selected");
    //     }
    //   }
    // });

    if (this.props.onDoubleClick) {
      $(`#${thisComp.props.tableId} tbody`).on("dblclick", "tr", function () {
        const data = table.row(this).data();
        // thisComp.props.selectedToUpdate(data.id);
        thisComp.props.onDoubleClick(data);
      });
    }
  }

  render() {
    return (
      <div>
        <table
          id={this.props.tableId}
          ref="clientlist"
          className={`table table-sm table-striped table-hover pageResize ${
            this.props.wrap ? "" : "nowrap"
          }`}
          width="100%"
        />
      </div>
    );
  }
}

Table.propTypes = {
  selected: PropTypes.func,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  handleData: PropTypes.func,
  newItem: PropTypes.object,
  updateItem: PropTypes.object,
  deletedItem: PropTypes.object,
  data: PropTypes.array,
  columns: PropTypes.array,
  ordering: PropTypes.bool,
  searching: PropTypes.bool,
  paging: PropTypes.bool,
  responsive: PropTypes.bool,
  scrollCollapse: PropTypes.bool,
  scrollY: PropTypes.string,
  scrollX: PropTypes.bool,
  info: PropTypes.bool,
  // groupSrc: PropTypes.object || PropTypes.bool,
  order: PropTypes.arrayOf(PropTypes.array)
};

export default Table;
