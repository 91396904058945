/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import * as clientApi from "../../../services/api/client";
import * as contractApi from "../../../services/api/contract";
import * as soilApi from "../../../services/api/characterization";
import { DataTable, useData } from "../../../components/Table";
import columns from "./columns";
import ContractFilterBar from "../../../components/Selects/ContractFilterBar";
import TechControl from "../components/TechControl";
import { ModalForm } from "../../../components/Modal";
import ControlForm from "./ControlForm";
import {
  ButtonDelete,
  ButtonInsert,
  ButtonOption,
  ListButtonRow
} from "../../../components/Buttons";
import SampleDetail from "./components/SampleDetail";
import { ReportButton, ReportRow } from "../../../components/Report";
import ReportBar from "./ReportBar";
import SampleForm from "./SampleForm";
import TestsModal from "./TestsModal";
import Sample, {
  Granulometry,
  GranulometryBGS,
  PhysicalLimit,
  Compaction,
  Cbr
} from "./Sample";
import SampleArrivalForm from "./SampleArrivalForm";
import { HeaderTitle } from "../../../components/Headers";
import { ContainerFluid } from "../../../components/Container";
import { EditTestAccessPermission, CreateTestAccessPermission } from "src/components/Permissions";
import { useUser } from "src/context/admin/user";
import { useContractActions } from "src/context/admin/contract";
import { useHistory } from "react-router";

const PAGE_TITLE = "Caracterização de solos";

const Characterization = () => {
  const DEFAULT_PAGE_SIZE = 100;
  const history = useHistory();
  const [user] = useUser();
  const sample = useData<SoilSample>();
  const [control, setControl] = useState<SoilControl>();
  const [contractId, setContractId] = useState<number>();
  const [samples, setSamples] = useState<TPage<SoilSample>>();
  const [loadingSamples, setLoadingSamples] = useState<boolean>(false);
  const [loadingControl, setLoadingControl] = useState<boolean>(false);
  const [selectedSamples, setSelectedSamples] = useState<SoilSample[]>([]);
  const [, contractActions] = useContractActions();

  useEffect(() => {
    getControl();
    if (contractId) {
      contractActions.getResourcesAllocation(contractId);
    }
  }, [contractId]);

  useEffect(() => {
    getSamples(1, DEFAULT_PAGE_SIZE);
    sample.selected.set(undefined);
  }, [control]);

  const filteredColumns = useMemo(() => {
    const codeTypeRemove = control?.code_control ? "code" : "control_code";

    return columns.filter(item => item.selector !== codeTypeRemove);
  }, [control]);

  const getClients = () => {
    return clientApi.getClients("soil", "1", "id,name");
  };

  const getContracts = (clientId: number) => {
    return contractApi.getContracts("", `${clientId}`, "", "", "soil");
  };

  const getControl = () => {
    if (contractId) {
      setLoadingControl(true);
      soilApi
        .getControl(contractId)
        .then(({ data }: { data: SoilControl[] }) => {
          if (data.length > 0) {
            setControl(data[0]);
          } else {
            setControl(undefined);
          }
        })
        .finally(() => setLoadingControl(false));
    }
  };

  const getSamples = (
    page: number,
    pageSize: number,
    layers?: string[],
    places?: string[]
  ) => {
    if (control && contractId) {
      setLoadingSamples(true);
      soilApi
        .filterSamples(
          contractId,
          "id,code,control_code,collection_place,arrival_date,progress,finalized_date,material_type,summary,has_granulometry_wet,has_granulometry_dry,has_granulometry_bgs,has_physical_limits,has_compaction,has_cbr",
          page,
          pageSize,
          places,
          layers
        )
        .then(({ data }) => setSamples(data))
        .finally(() => setLoadingSamples(false));
    }
  };

  const onPostControl = (values: SoilControl) => {
    return soilApi
      .postControl(contractId!, values)
      .then(({ data }) => setControl(data));
  };

  const insertControl = () => (
    <ModalForm
      type="post"
      size="md"
      title="Novo controle de caracterização"
      form={props => <ControlForm {...props} />}
      getItemRequest={(id: number) => soilApi.getControl(id)}
      initialValues={() => {
        return {
          contract: contractId
        };
      }}
      onSubmit={onPostControl}
      renderButton={onClick => (
        <ButtonInsert onClick={onClick}>Novo controle</ButtonInsert>
      )}
    />
  );

  const onPutControl = (values: SoilControl) => {
    const data = {
      ...values,
      contract: values.contract.id
    };

    return soilApi
      .putControl(values.id!, data)
      .then(({ data }) => setControl(data));
  };

  const editControl = () => (
    <CreateTestAccessPermission>
      <ModalForm
        type="put"
        size="md"
        title="Editar control de caracterização"
        form={props => <ControlForm {...props} />}
        getItemRequest={(id: number) => soilApi.getControlId(id)}
        initialValues={(data: SoilControl) => data}
        data={control}
        onSubmit={onPutControl}
        renderButton={onClick => (
          <ButtonOption
            className="btn btn-block btn-outline-secondary"
            onClick={onClick}
          >
            Editar controle
          </ButtonOption>
        )}
      />
    </CreateTestAccessPermission>
  );

  const onClickEditControl = () => {
    history.push(`samples/control/${control?.id}/`);
  };

  const renderControl = () => (
    <Row>
      <Col sm={9}>{renderSampleList()}</Col>
      <Col sm={3}>
        <SampleDetail summary={sample.selected.item?.summary} />
        <CreateTestAccessPermission>
          <ButtonOption
            className={"btn btn-block btn-outline-secondary"}
            onClick={onClickEditControl}
          >
            Editar control
          </ButtonOption>
        </CreateTestAccessPermission>
      </Col>
    </Row>
  );

  const onPostSample = (values: SoilSample) => {
    return soilApi
      .postSamples(values.contract, values)
      .then(({ data }) => sample.created.set(data));
  };

  const insertSample = () => (
  <CreateTestAccessPermission>
    <ModalForm
      type="post"
      size="xl"
      title="Nova amostra de solos"
      data={{}}
      form={props => <SampleForm {...props} control={control} type="post" />}
      getItemRequest={(id: number) => soilApi.getSample(id)}
      initialValues={(data: SoilSample) => {
        return {
          ...data,
          control: control?.id,
          contract: contractId
        };
      }}
      onSubmit={onPostSample}
    />
    </CreateTestAccessPermission>
  );

  const onPutSample = (values: SoilSample) => {
    const data = {
      ...values,
      press: values.press
    };

    return soilApi.putSample(values.id!, data).then(({ data }) => {
      sample.updated.set(data);
      sample.selected.set(data);
    });
  };

  const editSample = () => (
    <CreateTestAccessPermission>
      <ModalForm
        type="put"
        size="xl"
        title="Editar amostra de solos"
        form={props => <SampleForm {...props} control={control} type="put" />}
        getItemRequest={(id: number) => soilApi.getSample(id)}
        initialValues={(data?: SoilSample) => {
          if (data) {
            return {
              ...data,
              material_type: data.material_type?.id,
              layer: data.layer?.id,
              equipment: data.equipment?.id,
              press: data.press?.id,
              project: data.project?.id || null,
              proctor: data.proctor === "0" ? "" : data.proctor
            };
          }

          return {};
        }}
        data={sample.selected.item}
        onSubmit={onPutSample}
        // onClosed={() => soilApi.getSample(sample.selected.item?.id).then(sample.selected.set)}
      />
    </CreateTestAccessPermission>
  );

  const onPostArrivalSample = (values: any) => {
    return soilApi
      .postArrival(values)
      .then(({ data }: { data: SoilSample[] }) => {
        data.forEach(item => {
          sample.created.set(item);
        });
      });
  };

  const arrivalSample = () => (
    <CreateTestAccessPermission>
      <ModalForm
        type="put"
        size="md"
        title="Cadastrar chegada de amostra"
        form={props => <SampleArrivalForm {...props} />}
        initialValues={() => {
          return {
            control: control?.id,
            contract: control?.contract.id
          };
        }}
        data={{}}
        onSubmit={onPostArrivalSample}
        renderButton={onClick => (
          <ButtonOption onClick={onClick}>Chegada de amostra</ButtonOption>
        )}
      />
    </CreateTestAccessPermission>
  );

  const onTestUpdated = (sampleId: number) => {
    soilApi.getSample(sampleId).then(({ data }: { data: SoilSample }) => {
      sample.updated.set(data);
      sample.selected.set(data);
    });
  };

  const showTests = () => {
    const selectGranulometryType = () => {
      if (sample.selected.item?.has_granulometry_bgs) {
        return (
          <GranulometryBGS
            sample={sample.selected.item!}
            hasUpdated={onTestUpdated}
          />
        );
      }

      return (
        <Granulometry
          sample={sample.selected.item!}
          hasUpdated={onTestUpdated}
        />
      );
    };

    const tests = [
      {
        title: "Detalhes",
        test: <Sample sample={sample.selected.item} />
      }
    ];

    if (
      sample.selected.item?.has_granulometry_bgs ||
      sample.selected.item?.has_granulometry_wet ||
      sample.selected.item?.has_granulometry_dry
    ) {
      tests.push({
        title: "Granulometria",
        test: selectGranulometryType()
      });
    }
    if (sample.selected.item?.has_physical_limits) {
      tests.push({
        title: "Limites físicos",
        test: (
          <PhysicalLimit
            sample={sample.selected.item!}
            hasUpdated={onTestUpdated}
          />
        )
      });
    }
    if (sample.selected.item?.has_compaction) {
      tests.push({
        title: "Compactação",
        test: (
          <Compaction
            sample={sample.selected.item!}
            hasUpdated={onTestUpdated}
          />
        )
      });
    }
    if (sample.selected.item?.has_cbr) {
      tests.push({
        title: "CBR",
        test: <Cbr sample={sample.selected.item!} hasUpdated={onTestUpdated} />
      });
    }

    return <CreateTestAccessPermission><TestsModal tests={tests} sample={sample.selected.item} /></CreateTestAccessPermission>;
  };

  const deleteSample = () => {
    if (sample.selected.item?.id !== undefined) {
      return soilApi
        .removeSample(sample.selected.item.id)
        .then(res => {
          sample.deleted.set(sample.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }
    return Promise.reject();
  };

  const onSelectMultiple = (item: SoilSample, selected: boolean) => {
    if (selected) {
      selectedSamples.push(item);
    } else {
      setSelectedSamples(
        selectedSamples.filter(sample => sample.id !== item.id)
      );
    }
  };

  const renderSampleList = () => (
    <Card className="filter-bar-search">
      <CardBody>
        <ListButtonRow
          buttonsLeft={<HeaderTitle title="Amostras" className="ml-2" />}
          buttonsRight={
            <>
              {user.data?.email !== "labinfrafdv2@infratech.eng.br" && (
                <ReportButton />
              )}
            </>
          }
        />
        {control && control.id && contractId && (
          <ReportRow>
            <ReportBar
              contractId={contractId}
              samples={selectedSamples.map(item => item.id!)}
              onFilter={({ layers, places }) =>
                getSamples(1, DEFAULT_PAGE_SIZE, layers, places)
              }
            />
          </ReportRow>
        )}
        <DataTable
          columns={filteredColumns}
          data={samples?.results || []}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          progressPending={loadingSamples}
          pagination
          paginationTotalRows={samples?.count || 0}
          comparator={(a, b) => a.code! - b.code!}
          onSelect={sample.selected.set}
          keyExtractor={(item: SoilSample) => `${item.id}`}
          onChangePage={getSamples}
          updated={sample.updated.item}
          deleted={sample.deleted.item}
          created={sample.created.item}
          selectMultipleRows
          onSelectMultiple={onSelectMultiple}
        />
        <ListButtonRow
          marginTop="mt-2"
          buttonsLeft={
            <>
              {insertSample()}
              {editSample()}
              {arrivalSample()}
              {showTests()}
            </>
          }
          buttonsRight={
            <>
              <EditTestAccessPermission>
                <ButtonDelete
                  disabled={!sample.selected.item}
                  onClick={deleteSample}
                />
              </EditTestAccessPermission>
            </>
          }
        />
      </CardBody>
    </Card>
  );

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <>
        <ContractFilterBar
          clientRequest={getClients}
          contractRequest={getContracts}
          onContractChange={setContractId}
        />
        <TechControl
          contract={contractId}
          control={control}
          renderControl={renderControl}
          loadingControl={loadingControl}
          insertControl={insertControl}
        />
      </>
    </ContainerFluid>
  );
};

export default Characterization;
