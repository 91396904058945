import React from "react";

interface Props {
  width: string | number;
  src: string;
}

const ImageThumbnail: React.FC<Props> = props => {
  return (
    <img
      src={props.src}
      alt=""
      className="img-fluid img-thumbnail"
      width={props.width}
    />
  );
};

ImageThumbnail.defaultProps = {
  width: 200,
  src: ""
};

export { ImageThumbnail };
