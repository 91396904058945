/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { TableList, useData } from "src/components/Table";
import { LoadingComponent } from "src/components/Loader";
import { useCapsules } from "src/context/tech_control/capsule";
import { toLocateNumber } from "src/utils/format";
import { ListButtonRow, ButtonDelete } from "src/components/Buttons";
import { InsertCapsule, EditCapsule } from "./Form";
import { deleteCapsule } from "src/services/api/capsule";

const columns = [
  { title: "Nº", data: "number" },
  {
    title: "Peso (g)",
    data: "weight",
    render: function (data: number) {
      return toLocateNumber(data, 3);
    }
  }
];

const Capsules = () => {
  const [capsules, capsulesActions] = useCapsules();
  const [dbClick, setDbClick] = useState<boolean>(false);
  const item = useData<Capsule>();

  useEffect(() => {
    capsulesActions.fetchCapsules();
  }, []);

  const onDelete = () => {
    if (item.selected.item && item.selected.item.id !== undefined) {
      return deleteCapsule(item.selected.item.id)
        .then(({ data }) => {
          item.deleted.set(item.selected.item);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  return (
    <Row>
      <Col sm={8}>
        <LoadingComponent loading={capsules.loading}>
          <TableList
            tableId="capsules"
            data={capsules.data}
            columns={columns}
            selected={item.selected.set}
            updated={item.updated.item}
            created={item.created.item}
            deleted={item.deleted.item}
            searching={false}
            scrollY={"200px"}
            dbClick={item => {
              item.selected.set(item);
              setDbClick(true);
              setDbClick(false);
            }}
          />
        </LoadingComponent>
        <ListButtonRow
          buttonsLeft={
            <>
              <InsertCapsule insertedItem={item.created.set} />
              <EditCapsule
                item={item.selected.item!}
                updatedItem={item.updated.set}
                dbClick={dbClick}
              />
            </>
          }
          buttonsRight={
            <ButtonDelete disabled={!item.selected.item} onClick={onDelete} />
          }
          marginTop="mt-2"
        />
      </Col>
    </Row>
  );
};

export default Capsules;
