const capsuleStudy = {
  id: undefined,
  wet_soil_weight: undefined,
  dry_soil_weight: undefined,
  capsule_weight: undefined,
  capsule: undefined
};

const initialValues = {
  id: undefined,
  liquidity_tests: [
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    }
  ],
  plasticity_tests: [
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    },
    {
      id: undefined,
      capsule_study: capsuleStudy,
      stroke: undefined
    }
  ],
  liquidity_limit: 0,
  plasticity_limit: 0,
  trendline: {
    y1: 0,
    y2: 0,
    "25": 0
  },
  review: 0,
  notes: "",
  execution_date: "",
  is_not_plastic: false,
  is_not_liquid: false,
  application: undefined,
  sample: undefined,
  executor: undefined
};

export default initialValues;
