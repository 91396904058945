import React from "react";
import { Scatter } from "react-chartjs-2";
// @ts-ignore
import everpolate from "everpolate";
import {
  MAX_ISC_PERCENTAGE,
  MIN_ISC_PERCENTAGE,
  MAX_HUMIDITY_PERCENTAGE,
  MIN_HUMIDITY_PERCENTAGE,
  MIN_EXPANSION_PERCENTAGE,
  MAX_EXPANSION_PERCENTAGE,
  HUMIDITY_PRECISON,
  ISC_PRECISON,
  EXPANSION_PRECISON
} from "../../../../../utils/precisions";
import { useCbr } from "./context";
import { useCompaction } from "../Compaction/context";

const options = (dataset: any[]) => {
  let min_humidity = dataset.reduce((a, b) => {
    return Math.min(a.x || a, b.x);
  });

  let max_humidity = dataset.reduce((a, b) => {
    return Math.max(a.x || a, b.x);
  });

  max_humidity = Math.ceil(max_humidity);
  min_humidity = Math.floor(min_humidity);

  const chartOptions = {
    type: "line",
    showLines: true,
    responsive: true,
    maintainAspectRatio: false,
    cubicInterpolationMode: "monotone",
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMax: 2,
            suggestedMin: 0
          },
          scaleLabel: {
            display: true,
            labelString: "ISC (%)"
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            min: min_humidity,
            max: max_humidity
          },
          scaleLabel: {
            display: true,
            labelString: "TEOR DE UMIDADE (%)"
          }
        }
      ]
    },
    legend: {
      display: true,
      position: "bottom",
      usePointStyle: true
    },
    layout: {
      padding: {
        bottom: 30,
        top: 30,
        left: 0
      }
    },
    tooltips: {
      mode: "index"
    }
  };

  return chartOptions;
};

const Chart = () => {
  const [cbr] = useCbr();
  const [compaction] = useCompaction();

  const normalizeHumidity = (value: number) => {
    value = Math.max(value, MIN_HUMIDITY_PERCENTAGE);
    value = Math.min(value, MAX_HUMIDITY_PERCENTAGE);

    return parseFloat(value.toFixed(HUMIDITY_PRECISON * 2));
  };

  const normalizeIsc = (value: number) => {
    value = Math.max(value, MIN_ISC_PERCENTAGE);
    value = Math.min(value, MAX_ISC_PERCENTAGE);

    return parseFloat(value.toFixed(ISC_PRECISON * 2));
  };

  const normalizeExpansion = (value: number) => {
    value = Math.max(value, MIN_EXPANSION_PERCENTAGE);
    value = Math.min(value, MAX_EXPANSION_PERCENTAGE);

    return parseFloat(value.toFixed(EXPANSION_PRECISON * 2));
  };

  const getDataSet = () => {
    const iscDataPoints = cbr.cbrs.map((item, index) => {
      const _humidity: number = normalizeHumidity(compaction.humidities[index]);

      const isc: number = normalizeIsc(item);

      return {
        x: _humidity * 100,
        y: isc * 100
      };
    });

    const getDataLine = (x: number[], y: number[]) => {
      const min_x = x.reduce((a, b) => {
        return Math.min(a, b);
      });
      const max_x = x.reduce((a, b) => {
        return Math.max(a, b);
      });
      const delta = (max_x - min_x) / 30;

      const pointsToEvaluate = [min_x];
      for (let i = min_x; i < max_x; i += delta) {
        pointsToEvaluate.push(i);
      }
      pointsToEvaluate.push(max_x);

      y = everpolate.polynomial(pointsToEvaluate, x, y);
      return pointsToEvaluate.map((x, index) => {
        return {
          x: x.toFixed(4),
          y: y[index].toFixed(4)
        };
      });
    };

    const iscDataline = getDataLine(
      iscDataPoints.map(c => {
        return c.x;
      }),
      iscDataPoints.map(c => {
        return c.y;
      })
    );

    const data_great_isc = [
      {
        x: 0,
        y: cbr.maxIsc * 100
      },
      {
        x: cbr.greatHumidity * 100,
        y: cbr.maxIsc * 100
      },
      {
        x: cbr.greatHumidity * 100,
        y: 0
      }
    ];

    const dataExpansion = cbr.expansions.map((item, index) => {
      const _humidity: number = normalizeHumidity(compaction.humidities[index]);

      const expansion: number = normalizeExpansion(item) * 100;

      return {
        x: _humidity * 100,
        y: expansion
      };
    });

    const dataset: { [key: string]: any } = [];

    dataset.push({
      label: `ISC`,
      fill: false,
      backgroundColor: "blue",
      showLine: false,
      borderColor: "blue",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "black",
      pointBackgroundColor: "black",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "black",
      pointHoverBorderColor: "black",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: iscDataPoints,
      cubicInterpolationMode: "default"
    });

    dataset.push({
      label: `ISC (Polinomio) `,
      fill: false,
      showLine: true,
      backgroundColor: "blue",
      borderColor: "blue",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointRadius: 0,
      data: iscDataline,
      cubicInterpolationMode: "default",
      hiddenLegend: true
    });

    dataset.push({
      label: "ISC (umidade ótima)",
      fill: false,
      lineTension: 0,
      showLine: true,
      backgroundColor: "coral",
      borderColor: "coral",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "coral",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: data_great_isc
    });

    dataset.push({
      label: "Expansão",
      fill: false,
      lineTension: 0,
      showLine: true,
      backgroundColor: "brown",
      borderColor: "brown",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "brown",
      pointBackgroundColor: "brown",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "brown",
      pointHoverBorderColor: "brown",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: dataExpansion
    });

    return dataset;
  };

  const dataset = getDataSet();

  return (
    <div className="canvas-size-comp">
      <Scatter
        data={{
          datasets: dataset
        }}
        options={options(dataset[0].data)}
      />
    </div>
  );
};

export default Chart;
