import React from "react";
import { Field } from "formik";
import { Row, Col } from "reactstrap";
import { EditTestAccessPermission } from "../../../../../components/Permissions";
import { InputRow, InputDate } from "../../../../../components/Inputs";
import { SelectEmployee } from "../../../../../components/Selects";
import { ButtonSave } from "../../../../../components/Buttons";

interface Props {
  isSubmitting: boolean;
}

const ExecutionBar: React.FC<Props> = ({ isSubmitting, children }) => (
  <Row className="mb-2">
    <EditTestAccessPermission>
      <Col sm={3}>
        <InputRow label="Executante" label_col="auto">
          <Field name="executor" component={SelectEmployee} required />
        </InputRow>
      </Col>
    </EditTestAccessPermission>
    <Col sm={4}>
      <InputRow label_col={5} label="Data de execução">
        <Field name="execution_date" component={InputDate} required />
      </InputRow>
    </Col>
    <Col>{children}</Col>
    <Col className="text-right">
      <EditTestAccessPermission>
        <ButtonSave isSubmitting={isSubmitting} />
      </EditTestAccessPermission>
    </Col>
  </Row>
);

export default ExecutionBar;
