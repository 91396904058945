import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import { login } from "../../services/api/auth";
import { me } from "../../services/api/admin";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { dumpToken } from "src/services/storage";
import { ENVIRONMENT, __PROD__ } from "src/config";

interface State {
  data: UserAuth | null;
  applications: ApplicationValues[];
  loading: boolean;
}

const initialState: State = {
  data: null,
  loading: false,
  applications: []
};

const actions = {
  login:
    (email: string, password: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      if (getState().loading) {
        return;
      }

      setState({ loading: true });
      return login(email, password)
        .then(data => {
          dumpToken(data.token);

          return Promise.resolve(data);
        })
        .catch(err => {
          return Promise.reject(err);
        })
        .finally(() => setState({ loading: false }));
    },

  me:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      return me().then(({ data }) => {
        Sentry.setUser({
          email: data.email,
          id: data.id.toString(),
          username: data.email
        });

        if (process.env.NODE_ENV === "production") {
          mixpanel.identify(data.id.toString());
          mixpanel.people.set({
            $email: data.email,
            USER_ID: data.id.toString(),
            $first_name: data.first_name,
            $last_name: data.last_name,
            $name: `${data.first_name} ${data.last_name}`
          });
          mixpanel.register({
            environment: ENVIRONMENT
          });
        }

        setState({
          data
        });

        return data;
      });
    },

  setApplications:
    (applications: ApplicationValues[]) =>
    ({ setState }: StoreActionApi<State>) => {
      setState({
        applications
      });
    }
};

const Store = createStore({
  actions,
  initialState,
  name: "user"
});

const useUser = createHook(Store);
const UserSubscriber = createSubscriber(Store);

export { useUser, UserSubscriber };
