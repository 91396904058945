import React from "react";
import { Scatter } from "react-chartjs-2";
import moment from "moment";

const options = (monthNumber: number) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            // Include a dollar sign in the ticks
            callback(value: number) {
              return `R$ ${value}`;
            }
          },
          scaleLabel: {
            display: true
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            min: 1,
            max: moment().daysInMonth(),
            stepSize: 3,
            // Include a dollar sign in the ticks
            callback(value: any) {
              moment.locale("pt-br");
              const month = moment.monthsShort()[monthNumber].toUpperCase();
              return `${value} ${month}`;
            }
          }
        }
      ]
    },
    legend: {
      display: false,
      position: "bottom",
      usePointStyle: true
    },
    layout: {
      padding: {
        bottom: 20,
        top: 20
      }
    },
    tooltips: {
      mode: "index",
      callbacks: {
        label(tooltipItem: any, data: any) {
          let label = data.datasets[tooltipItem.datasetIndex].label || "";

          if (label) {
            label += ": ";
          }
          label += `R$ ${tooltipItem.yLabel}`;
          return label;
        },
        title(tooltipItem: any) {
          const month = moment.monthsShort()[monthNumber].toUpperCase();
          return `${tooltipItem[0].xLabel} ${month}`;
        }
      }
    }
  };
};

interface Props {
  soilModule: number;
  soil: { [key: string]: number };
  holes: { [key: string]: number };
  concreteModule: number;
  concrete: { [key: string]: number };
  month: number;
}

const Chart: React.FC<Props> = props => {
  const getDataSet = () => {
    const dataset: { [key: string]: any }[] = [];

    let sum: number = props.soilModule;

    const soilData: { x: number; y: number }[] = Object.keys(props.soil)
      .sort((a, b) => a.localeCompare(b))
      .map(key => {
        sum += props.soil[key];
        sum += props.holes[key];

        const date = key.split("-")[2];

        return { x: parseInt(date), y: sum };
      });

    dataset.push({
      label: "Solos",
      fill: false,
      showLine: true,
      lineTension: 0,
      backgroundColor: "#fcba03",
      borderColor: "#fcba03",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#fcba03",
      pointBackgroundColor: "#fcba03",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "#fcba03",
      pointHoverBorderColor: "#fcba03",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: soilData
    });

    sum = props.concreteModule;

    const concreteData: { x: number; y: number }[] = Object.keys(props.concrete)
      .sort((a, b) => a.localeCompare(b))
      .map(key => {
        sum += props.concrete[key];
        const date = key.split("-")[2];

        return { x: parseInt(date), y: sum };
      });

    dataset.push({
      label: "Concreto",
      fill: false,
      showLine: true,
      lineTension: 0,
      backgroundColor: "#4287f5",
      borderColor: "#4287f5",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#4287f5",
      pointBackgroundColor: "#4287f5",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "#4287f5",
      pointHoverBorderColor: "#4287f5",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: concreteData
    });

    return dataset;
  };

  return (
    <div className="canvas-size-lim">
      <Scatter
        data={{
          datasets: getDataSet()
        }}
        options={options(props.month)}
      />
    </div>
  );
};

export default Chart;
