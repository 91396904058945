import React from "react";
import Select from "react-select";
import * as api from "../../services/api/work";

class SelectMultipleWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: null, items: [] };
  }

  componentDidMount() {
    this.setState(
      {
        client: this.props.client
      },
      () => {
        this.getWorks();
      }
    );
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.client !== undefined &&
      JSON.stringify(nextProps.client) !== JSON.stringify(this.props.client)
    ) {
      this.setState({ client: nextProps.client });
    }
    return true;
  }

  getWorks() {
    api.getWorks().then(({ data }) => {
      this.setState({ items: data });
    });
  }

  options() {
    return this.state.items
      .filter(item => this.state.client.includes(item.client.id))
      .map(item => ({ value: item.id, label: item.name }));
  }

  render() {
    return (
      <Select
        options={this.options()}
        placeholder="Obras..."
        isMulti
        styles={{
          container: () => ({
            width: "100%",
            padding: "0"
          })
        }}
        onChange={this.props.onChange}
      />
    );
  }
}

export default SelectMultipleWork;
