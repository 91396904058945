import React from "react";

interface Props {
  field: any;
  form: string;
  name: string;
  required: boolean;
  disabled: boolean;
}

const InputTextArea: React.FC<Props> = ({
  field,
  form,
  required,
  disabled,
  ...props
}) => (
  <textarea
    className="form-control form-control-sm"
    disabled={disabled}
    required={required}
    {...field}
    {...props}
  />
);

InputTextArea.defaultProps = {
  required: false,
  disabled: false
};

export default InputTextArea;
