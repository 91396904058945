/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useState } from "react";
import {
  default as CoreDataTable,
  IDataTableProps
} from "react-data-table-component";
import { Col, Row } from "reactstrap";
import { search } from "src/utils/algorithms";
import { InputText } from "../Inputs";
import { Loader } from "../Loader";
import style from "./style";

interface Props<T> extends IDataTableProps<T> {
  defaultPageSize?: number;
  updated?: T;
  created?: T;
  deleted?: T;
  filterable?: boolean;
  selectMultipleRows?: boolean;
  filterMethod?: (param: string, item: T) => boolean;
  keyExtractor?: (item: T) => string;
  comparator: (a: T, b: T) => number;
  onChangePage?: (page: number, perPage: number) => void;
  onSelect?: Dispatch<T | undefined>;
  onSelectMultiple?: (item: T, selected: boolean) => void;
}

const DataTable = <T extends TSelectData>(props: Props<T>) => {
  const [data, setData] = useState(props.data);
  const [filterText, setFilterText] = useState<string>("");
  const [currentSelected, setCurrentSelected] = useState<T>();
  const [pageSize, setPageSize] = useState<number>(
    props.defaultPageSize || 100
  );

  const filterMethod = (seek: string, item: T) =>
    JSON.stringify(item).includes(seek);

  const filteredItems = () => {
    const method = props.filterMethod || filterMethod;
    return data.filter(item => method(filterText, item));
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (props.created) {
      const newData = [...data];
      newData.push(props.created);

      setData(newData);
    }
  }, [props.created]);

  useEffect(() => {
    const newData = [...data];
    if (props.updated) {
      const index = search(data, props.updated, props.comparator);
      if (index > -1) {
        newData[index] = {
          ...props.updated,
          isSelected: newData[index].isSelected
        };
        setData(newData);
      }
    }
  }, [props.updated]);

  useEffect(() => {
    const newData = [...data];
    if (props.deleted) {
      const index = search(newData, props.deleted, props.comparator);
      if (index > -1) {
        newData.splice(index, 1);
        setData(newData);
        if (
          currentSelected &&
          props.comparator &&
          props.comparator(currentSelected, props.deleted) === 0
        ) {
          setCurrentSelected(undefined);
        }
      }
    }
  }, [props.deleted]);

  const handlePerRowsChange = async (perPage: number, page: number) => {
    props.onChangePage && props.onChangePage(page, pageSize);
    setPageSize(perPage);
  };

  const handlePageChange = async (page: number) => {
    props.onChangePage && props.onChangePage(page, pageSize);
  };

  const handleRowClicked = (row: any) => {
    const newData = [...data];

    // Deselect current selected row if the selected row is different
    let index = -1;
    if (
      currentSelected !== undefined &&
      props.comparator &&
      props.comparator(currentSelected, row) !== 0
    ) {
      index = search(data, currentSelected, props.comparator);
      if (
        currentSelected !== undefined &&
        index > -1 &&
        !props.selectMultipleRows
      ) {
        newData[index] = { ...currentSelected, isSelected: false };
      }
    }

    // Change the state of the new row
    if (data !== undefined && props.onSelect) {
      index = search(data, row, props.comparator);
      if (index > -1) {
        const selected = { ...newData[index] };
        if (selected.isSelected) {
          props.onSelect(undefined);
          props.onSelectMultiple && props.onSelectMultiple(selected, false);
        } else {
          props.onSelect(row);
          props.onSelectMultiple && props.onSelectMultiple(selected, true);
        }

        newData[index] = { ...selected, isSelected: !selected.isSelected };
      }

      setData(newData);
    }
    setCurrentSelected(row);
  };

  const renderProgressComponent = () => (
    <div className="tableLoader">
      <Loader />
    </div>
  );

  const renderNoDataComponent = () => (
    <div className="tableLoader">Nenhum registro encontrado</div>
  );

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;

    setFilterText(query);
  };

  const renderSubHeaderComponent = () => (
    <Row className="align-items-center">
      <Col sm={"auto"}>Pesquisar</Col>
      <Col sm={"auto"} className="pr-0">
        <InputText
          name="table-search"
          disabled={false}
          required={false}
          onChange={onFilterChange}
        />
      </Col>
    </Row>
  );

  return (
    <CoreDataTable
      {...props}
      data={filteredItems()}
      progressComponent={renderProgressComponent()}
      paginationServer
      fixedHeader
      fixedHeaderScrollHeight={"calc(100vh - 380px)"}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationRowsPerPageOptions={[20, 50, 100, 200]}
      paginationPerPage={pageSize}
      customStyles={style}
      highlightOnHover
      pointerOnHover
      selectableRowSelected={row => !!row.isSelected}
      onRowClicked={handleRowClicked}
      selectableRowsHighlight
      noHeader
      persistTableHead
      striped
      paginationComponentOptions={{
        rowsPerPageText: "Items por página",
        rangeSeparatorText: "de"
      }}
      noDataComponent={renderNoDataComponent()}
      subHeader={props.filterable}
      subHeaderComponent={renderSubHeaderComponent()}
    />
  );
};

export default DataTable;
