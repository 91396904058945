import React from "react";
import Select from "react-select";

interface Props extends SelectType<any> {
  minYear?: number;
  minMonth?: number;
}

const SelectMonthYear: React.FC<Props> = props => {
  const options = () => {
    let minDate = 0;
    if (props.minYear) {
      minDate = props.minYear * 100;
    }

    if (props.minYear && props.minMonth) {
      minDate += props.minMonth;
    }

    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ];

    const today = new Date();
    let month = today.getMonth();
    const year = today.getFullYear();

    let m = 12;
    const options: { value: [number, number]; label: string }[] = [];
    while (m > 0) {
      const index = month >= 0 ? month : month + 12;
      const newYear = month >= 0 ? year : year - 1;

      if (newYear * 100 + index + 1 >= minDate) {
        options.push({
          value: [index + 1, newYear],
          label: `${months[index]} de ${newYear}`
        });
      }
      month--;
      m--;
    }

    return options;
  };

  return (
    <Select
      options={options()}
      styles={{
        container: () => ({
          width: "100%",
          padding: "0"
        })
      }}
      onChange={props.onChange}
    />
  );
};

export default SelectMonthYear;
