import api from "./api";

export function getUsage(month: string = "", year: string = "") {
  const endpoint = `/apps/my/bill/?month=${month}&year=${year}`;
  return api.get(endpoint);
}

export function getPlatformPrices(month: string = "", year: string = "") {
  const endpoint = `/apps/my/prices/?month=${month}&year=${year}`;
  return api.get(endpoint);
}
