export const VOLUME = 2;
export const WATER_TEMPERATURE = 2;
export const CONCRETE_TEMPERATURE = 2;
export const ENVIRONMENT_TEMPERATURE = 2;
export const SLUMP = 2;
export const SLUMP_FLOW = 2;
export const AIR_TEST = 2;
export const SELF_COMPACTING_TEST = 2;
export const JRING_TEST = 2;
export const FCK = 3;
export const CP_LOAD = 2;
export const WEIGHT = 2;
export const SPECIFIC_WEIGHT = 2;
export const HEIGHT = 2;
export const DIAMETER = 2;
export const HUMIDITY_PRECISON = 2;
export const ISC_PRECISON = 2;
export const EXPANSION_PRECISON = 2;
export const MAX_HUMIDITY_PERCENTAGE = 1;
export const MIN_HUMIDITY_PERCENTAGE = 0;
export const MAX_ISC_PERCENTAGE = 2;
export const MIN_ISC_PERCENTAGE = 0;
export const MAX_EXPANSION_PERCENTAGE = 1;
export const MIN_EXPANSION_PERCENTAGE = 0;
export const MAX_DENSITY = 10;
export const MIN_DENSITY = 0;
export const DENSITY_PRECISION = 3;
