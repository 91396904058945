import React, { useEffect, useState } from "react";
import { Loader } from "../Loader";
import SelectBase from "./SelectBase";
import { AxiosResponse } from "axios";

interface Props {
  request: () => Promise<AxiosResponse<ClientFormValues[]>>;
  onChange: (value: any) => void;
  newItem?: ClientFormValues;
  deletedItem?: ClientFormValues;
  updatedItem?: ClientFormValues;
  selectedItem?: ClientFormValues;
}

const SelectClient: React.FC<Props> = ({
  newItem,
  deletedItem,
  updatedItem,
  selectedItem,
  request,
  onChange,
  ...props
}) => {
  const [items, setItems] = useState<ClientFormValues[]>([]);

  const getItems = () => {
    request().then(({ data }) => setItems(data));
  };

  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = () => {
    return items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({ value: item.id, label: item.name }));
  };

  const selectedOption = () => {
    return options().filter(item => item.value === selectedItem?.id)[0];
  };

  return (
    <>
      {items ? (
        <SelectBase
          placeholder="Cliente..."
          onClick={onChange}
          options={options()}
          newItem={newItem}
          deletedItem={deletedItem}
          updatedItem={updatedItem}
          getItems={getItems}
          selectedOption={selectedOption()}
          {...props}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SelectClient;
