import React from "react";
import { Row, Col } from "reactstrap";
import { Field } from "formik";
import { HeaderTitle } from "../../../../../components/Headers";
import {
  InputColumn,
  InputText,
  InputDecimal,
  InputInteger,
  InputDate
} from "../../../../../components/Inputs";
import StressTest from "./StressTest";

interface Props {
  name: string;
  values: TConcreteMix;
  control: TConcreteControl;
  setFieldValue: (name: string, value: any) => void;
}

const MixPost: React.FC<Props> = ({ name, values, control, setFieldValue }) => {
  return (
    <Row>
      <Col sm={6}>
        <Row>
          <Col sm={12}>
            <HeaderTitle title={"Dados da mistura"} />
          </Col>
          <Col sm={4}>
            <InputColumn label="Carro">
              <Field
                name={`${name}.car_label`}
                step={0.2}
                component={InputText}
                required
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Nota fiscal">
              <Field
                name={`${name}.bill`}
                step={0.2}
                component={InputText}
                required
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Fornecedora">
              <Field
                name={`${name}.provider`}
                step={0.2}
                component={InputText}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Volume (m³)">
              <Field
                name={`${name}.volume`}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.volume`, value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Folga de água/gelo">
              <Field
                name={`${name}.water_gap`}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.water_gap`, value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Adicional de água/gelo">
              <Field
                name={`${name}.water_increment`}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.water_increment`, value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Massa específica (g/cm³)">
              <Field
                name={`${name}.specific_weight`}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.specific_weight`, value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label={"Temp. ambiente"}>
              <Field
                name={`${name}.environment_temp`}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.environment_temp`, value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label={"Temp. do concreto"}>
              <Field
                name={`${name}.concrete_temp`}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.concrete_temp`, value);
                }}
              />
            </InputColumn>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <HeaderTitle title={"Ensaios"} className="mt-2" />
          </Col>
          <Col sm={4}>
            <InputColumn label="Slump (mm)">
              <Field
                name={`${name}.slump_test`}
                step={0.2}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.slump_test`, value);
                }}
                required={control.slump_test > 0}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Slump Flow (mm)">
              <Field
                name={`${name}.slump_flow_test`}
                step={0.2}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.slump_flow_test`, value);
                }}
                required={control.slump_flow_test > 0}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Ar incorporado (%)">
              <Field
                name={`${name}.air_test`}
                step={0.2}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.air_test`, value);
                }}
                required={control.air_test > 0}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Anel J (mm)">
              <Field
                name={`${name}.jring_test`}
                step={0.2}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.jring_test`, value);
                }}
                required={control.jring_test > 0}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <InputColumn label="Segregação H2/H1">
              <Field
                name={`${name}.segregation_test`}
                step={0.2}
                component={InputDecimal}
                onChange={(value: number) => {
                  setFieldValue(`${name}.segregation_test`, value);
                }}
                required={control.segregation_test > 0}
              />
            </InputColumn>
          </Col>
        </Row>
      </Col>
      <Col sm={6}>
        {control.stress_test > 0 && (
          <StressTest
            control={control}
            name={`${name}.stress_test`}
            values={values.stress_test!}
            setFieldValue={setFieldValue}
          />
        )}
      </Col>
    </Row>
  );
};

const MixPut: React.FC<Props> = ({ name, setFieldValue }) => {
  return (
    <Row>
      <Col sm={4}>
        <InputColumn label={"Carro"}>
          <Field name={`${name}.car_label`} component={InputText} required />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Nota fiscal"}>
          <Field name={`${name}.bill`} component={InputText} required />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Fornecedora"}>
          <Field name={`${name}.provider`} component={InputText} />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Volume"}>
          <Field
            name={`${name}.volume`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.volume`, value);
            }}
            required
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Folga de água/gelo"}>
          <Field
            name={`${name}.water_gap`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.water_gap`, value);
            }}
            required
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Adicional de água/gelo"}>
          <Field
            name={`${name}.water_increment`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.water_increment`, value);
            }}
            required
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Temp. ambiente"}>
          <Field
            name={`${name}.environment_temp`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.environment_temp`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Temp. da água"}>
          <Field
            name={`${name}.water_temp`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.water_temp`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Massa específ. (g/cm³)"}>
          <Field
            name={`${name}.specific_weight`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.specific_weight`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Temp. do concreto"}>
          <Field
            name={`${name}.concrete_temp`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.concrete_temp`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Boletim"}>
          <Field
            name={`${name}.stress_test.boletim`}
            component={InputInteger}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`${name}.stress_test.boletim`, event.target.value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={12}>
        <HeaderTitle title={"Ensaios"} className="mt-2" />
      </Col>
      <Col sm={4}>
        <InputColumn label={"Slump"}>
          <Field
            name={`${name}.slump_test`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.slump_test`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Slump Flow"}>
          <Field
            name={`${name}.slump_flow_test`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.slump_flow_test`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Ar incorporado"}>
          <Field
            name={`${name}.air_test`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.air_test`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Anel J"}>
          <Field
            name={`${name}.jring_test`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.jring_test`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Adensamento"}>
          <Field
            name={`${name}.segregation_test`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.segregation_test`, value);
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"Data de moldagem"}>
          <Field
            name={`${name}.stress_test.molding_date`}
            component={InputDate}
            onChange={(value: any) => {
              setFieldValue(
                `${name}.stress_test.molding_date`,
                value.target.value
              );
            }}
          />
        </InputColumn>
      </Col>
      <Col sm={4}>
        <InputColumn label={"FCK"}>
          <Field
            name={`${name}.stress_test.fck`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.stress_test.fck`, value);
            }}
          />
        </InputColumn>
      </Col>
    </Row>
  );
};
export { MixPost, MixPut };
