/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { InputSelect } from "../Inputs";
import { useCapsules } from "../../context/tech_control/capsule";
import { LoadingComponent } from "../Loader";
import { useContractAllocations } from "../../context/admin/contract";

interface Props extends SelectType<Capsule> {
  isMulti?: boolean;
  filter?: boolean;
}

const SelectCapsule: React.FC<Props> = props => {
  const [capsules, capsulesActions] = useCapsules();
  const [allocations] = useContractAllocations();

  useEffect(() => {
    capsulesActions.fetchCapsules();
  }, []);

  const options = () => {
    return capsules.data
      .filter(
        item =>
          !props.filter ||
          allocations === undefined ||
          allocations?.capsules.includes(item.id)
      )
      .map(item => ({
        value: item.id,
        label: item.number
      }));
  };

  const onChangeCapsule = (item: any) => {
    if (!Array.isArray(item)) {
      item = capsules.data.filter(c => c.id === item);
      if (item.length > 0) {
        props.onChange(item[0]);
      }
    } else {
      props.onChange(item);
    }
  };

  return (
    <LoadingComponent loading={capsules.loading}>
      <InputSelect
        options={options()}
        placeholder="Capsula..."
        {...props}
        onClick={onChangeCapsule}
        isClearable={false}
      />
    </LoadingComponent>
  );
};

SelectCapsule.defaultProps = {
  isMulti: false,
  filter: true
};

export default SelectCapsule;
