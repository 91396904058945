import React from "react";
import { Row, Col } from "reactstrap";
import {
  InputColumn,
  InputCPCode,
  InputValidation
} from "../../../../components/Inputs";
import { FormikProps } from "formik";

const FetchForm: React.FC<FormikProps<any>> = ({ errors }) => {
  return (
    <Row>
      <Col sm={12}>
        <InputColumn label="Código">
          <InputCPCode name="code" required />
          <InputValidation message={errors.code} />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default FetchForm;
