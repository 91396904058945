import React from "react";
import { useParams } from "react-router";
import { useUser } from "src/context/admin/user";

const useFlags = () => {
  const { appSlug } = useParams<{ appSlug: string }>();
  const [user] = useUser();

  const isDouglas = user.data?.email === "douglasdacruzz@gmail.com";

  return {
    isMatech: appSlug === "rtfxqqjw3ajm" || isDouglas
  };
};

export default useFlags;
