/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import {
  getSeries,
  getCPs,
  deleteSerie,
  deleteCP
} from "../../../../services/api/graute";
import { HeaderTitle } from "../../../../components/Headers";
import { LoadingComponent } from "../../../../components/Loader";
import { useData, TableList } from "../../../../components/Table";
import { seriesColumns, cpsColumns } from "../columns";
import { ListButtonRow, ButtonDelete } from "../../../../components/Buttons";
import { InsertSeries, EditSeries } from "./Form";
import TestingBody from "../TestingBody";

interface Props {
  control: TGrauteControl;
}

const Series: React.FC<Props> = ({ control }) => {
  const series = useData<TGrauteSeries>();
  const [allSeries, setAllSeries] = useState<TGrauteSeries[]>([]);
  const [loadingSeries, setLoadingSeries] = useState<boolean>(false);
  const [loadingCPs, setLoadingCPs] = useState<boolean>(false);
  const cp = useData<TGrauteTestingBody>();
  const [cps, setCps] = useState<TGrauteTestingBody[]>([]);
  const [dbClickSeries, setDbClickSeries] = useState<boolean>(false);
  const [dbClickCP, setDbClickCP] = useState<boolean>(false);

  useEffect(() => {
    setLoadingSeries(true);
    getSeries(
      control.id!,
      "id,number,tower,place,molding_date,conf_break_resistance,conf_flexion_resistance,approved"
    ).then(({ data }) => {
      setAllSeries(data);
      setLoadingSeries(false);
    });
  }, [control]);

  useEffect(() => {
    const { item } = series.selected;
    if (item !== undefined && item.id !== undefined) {
      setLoadingCPs(true);
      getCPs(item.id).then(({ data }) => {
        setCps(data);
        setLoadingCPs(false);
      });
    } else {
      setCps([]);
    }
  }, [series.selected.item]);

  const onDeleteSeries = () => {
    if (series.selected.item && series.selected.item.id !== undefined) {
      return deleteSerie(series.selected.item.id)
        .then(({ data }) => {
          series.deleted.set(series.selected.item);
          setCps([]);
          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  const renderSeries = () => {
    return (
      <>
        <HeaderTitle title={"Séries"} />
        <LoadingComponent loading={loadingSeries}>
          <TableList
            tableId="series"
            data={allSeries}
            columns={seriesColumns}
            selected={series.selected.set}
            updated={series.updated.item}
            created={series.created.item}
            deleted={series.deleted.item}
            searching={true}
            groupSrc={{ dataSrc: "tower" }}
            dbClick={item => {
              series.selected.set(item);
              setDbClickSeries(true);
              setDbClickSeries(false);
            }}
          />
        </LoadingComponent>
        <ListButtonRow
          buttonsLeft={
            <>
              <InsertSeries
                control={control!}
                insertedItem={series.created.set}
              />
              <EditSeries
                item={series.selected.item!}
                control={control!}
                updatedItem={(item: any) => {
                  series.updated.set(item);
                }}
                showModal={dbClickSeries}
              />
            </>
          }
          buttonsRight={
            <ButtonDelete
              disabled={!series.selected.item}
              onClick={onDeleteSeries}
            />
          }
          marginTop="mt-2"
        />
      </>
    );
  };

  const onDeleteCP = () => {
    if (cp.selected.item && cp.selected.item.id !== undefined) {
      return deleteCP(cp.selected.item.id)
        .then(({ data }) => {
          cp.deleted.set(cp.selected.item);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  const renderCPs = () => {
    return (
      <>
        <HeaderTitle title={"Corpos de prova"} />
        <LoadingComponent loading={loadingCPs}>
          <TableList
            tableId="cps"
            data={cps}
            columns={cpsColumns}
            selected={cp.selected.set}
            updated={cp.updated.item}
            created={cp.created.item}
            deleted={cp.deleted.item}
            searching={true}
            groupSrc={{
              dataSrc: "is_flexion",
              startRender(_rows: any, data: boolean) {
                return data ? "Flexão" : "Compressão";
              }
            }}
            dbClick={item => {
              cp.selected.set(item);
              setDbClickCP(true);
              setDbClickCP(false);
            }}
          />
        </LoadingComponent>
        <ListButtonRow
          buttonsLeft={
            <TestingBody
              itemToEdit={cp.selected.item!}
              updatedItem={cp.updated.set}
              showModal={dbClickCP}
            />
          }
          buttonsRight={
            <ButtonDelete disabled={!cp.selected.item} onClick={onDeleteCP} />
          }
          marginTop="mt-2"
        />
      </>
    );
  };

  return (
    <Card className="filter-bar">
      <CardBody>
        <Row>
          <Col sm={6}>{renderSeries()}</Col>
          <Col sm={6}>{renderCPs()}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Series;
