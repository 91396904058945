import moment from "moment";
import api, { sendFile } from "./api";
import qs from "qs";

export function getControl(contractId: number) {
  const endpoint = `/soil_samples/${contractId}/controls/`;
  return api.get(endpoint);
}

export function getControlId(controlId: number) {
  const endpoint = `/soil_samples/control/${controlId}/`;
  return api.get(endpoint);
}

export function postControl(contractId: number, data: any) {
  const endpoint = `/soil_samples/${contractId}/controls/`;
  return api.post(endpoint, data);
}

export function putControl(controlId: number, data: any) {
  const endpoint = `/soil_samples/control/${controlId}/`;
  return api.put(endpoint, data);
}

export function getSamples(
  contractId: number,
  fields = "",
  page: number,
  pageSize: number,
  layers: string[] = [],
  places: string[] = [],
) {
  const endpoint = `/soil_samples/${contractId}/samples/`;
  const params = {
    page,
    page_size: pageSize,
    fields,
    layers,
    places,
  };
  return api.get<TPage<SoilSample>>(endpoint, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function postSamples(contractId: number, data: any) {
  const endpoint = `/soil_samples/${contractId}/samples/`;
  return api.post(endpoint, data);
}

export function filterSamples(
  contractId: number,
  fields: string,
  page: number,
  pageSize: number,
  places?: string[],
  layers?: string[],
  ids?: number[],
) {
  const endpoint = `/soil_samples/${contractId}/samples_filter/`;
  const params = {
    page,
    page_size: pageSize,
    fields,
  };
  const data = {
    places,
    layers,
    samples: ids,
  };
  return api.post<TPage<SoilSample>>(endpoint, data, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

export function getSample(id: number) {
  const endpoint = `/soil_samples/${id}/`;
  return api.get(endpoint);
}

export function putSample(id: number, data: any) {
  const endpoint = `/soil_samples/${id}/`;
  return api.put(endpoint, data);
}

export function removeSample(id: number) {
  const endpoint = `/soil_samples/${id}/`;
  return api.delete(endpoint);
}

export function postArrival(data: any) {
  const endpoint = `/soil_samples/arrival/`;
  return api.post(endpoint, data);
}

export function getGranulometry(sampleId: number) {
  const endpoint = `/granulometrys/${sampleId}/test_new/`;
  return api.get(endpoint);
}

export function postGranulometry(sampleId: number, data: any) {
  const endpoint = `/granulometrys/${sampleId}/test/`;
  return api.post(endpoint, data);
}

export function putGranulometry(id: number, data: any) {
  const endpoint = `/granulometrys/${id}/`;
  return api.put(endpoint, data);
}

export function getLimits(sampleId: number) {
  const endpoint = `/limits/${sampleId}/test_new/`;
  return api.get(endpoint);
}

export function postLimits(sampleId: number, data: any) {
  const endpoint = `/limits/${sampleId}/test/`;
  return api.post(endpoint, data);
}

export function putLimits(id: number, data: any) {
  const endpoint = `/limits/${id}/`;
  return api.put(endpoint, data);
}

export function getCompactions(sampleId: number) {
  const endpoint = `/compaction/${sampleId}/test_new/`;
  return api.get(endpoint);
}

export function postCompaction(sampleId: number, data: any) {
  const endpoint = `/compaction/${sampleId}/test/`;
  return api.post(endpoint, data);
}

export function getCompaction(sampleId: number) {
  const endpoint = `/compaction/${sampleId}/test_new/`;

  return api.get(endpoint);
}

export function putCompaction(id: number, data: any) {
  const endpoint = `/compaction/${id}/`;
  return api.put(endpoint, data);
}

export function getCbrs(sampleId: number) {
  const endpoint = `/cbrs/${sampleId}/test_new/`;
  return api.get(endpoint);
}

export function postCbr(sampleId: number, data: any) {
  const endpoint = `/cbrs/${sampleId}/test/`;
  return api.post(endpoint, data);
}

export function getCbr(sampleId: number) {
  const endpoint = `/cbrs/${sampleId}/test_new/`;

  return api.get(endpoint);
}

export function putCbr(id: number, data: any) {
  const endpoint = `/cbrs/${id}/`;
  return api.put(endpoint, data);
}

export function getPress() {
  const endpoint = `/cbrs/press/`;
  return api.get(endpoint);
}

export function getPressId(id: number) {
  const endpoint = `/cbrs/press/${id}/`;
  return api.get(endpoint);
}

export function getCalibrationById(id: number) {
  let endpoint = `/cbrs/calibrations/${id}/`;
  return api.get(endpoint);
}

export function getCalibration(date?: string) {
  if (date === undefined) {
    date = moment(new Date()).local().format("YYYY-MM-DD");
  }
  const endpoint = `/cbrs/calibrations?date=${date}/`;

  return api.get(endpoint);
}

export function postCalibrations(data: any) {
  let endpoint = "/cbrs/calibrations/";
  return api.post(endpoint, data);
}

export function putCalibrations(id: number, data: any) {
  let endpoint = `/cbrs/calibrations/${id}/`;
  return api.put(endpoint, data);
}

export function getSummarySamples(
  contractId: number,
  startDate = "",
  endDate = "",
) {
  const endpoint =
    `/soil_samples/summary/${contractId}/` +
    `?start=${startDate}` +
    `&end=${endDate}`;
  return api.get(endpoint);
}

export function postPhoto(
  id: number,
  data: any,
  onProgress?: (e: any) => void,
) {
  const endpoint = `/soil_samples/${id}/photo/`;

  return sendFile(endpoint, data, onProgress);
}

export function getProjects(controlId: number) {
  const endpoint = `/soil_samples/control/${controlId}/projects/`;

  return api.get(endpoint);
}

export function postProject(controlId: number, data: any) {
  const endpoint = `/soil_samples/control/${controlId}/projects/`;

  return api.post(endpoint, data);
}

export function getProject(controlId: number, projectId: number) {
  const endpoint = `/soil_samples/control/${controlId}/projects/${projectId}/`;

  return api.get(endpoint);
}

export function putProject(controlId: number, projectId: number, data: any) {
  const endpoint = `/soil_samples/control/${controlId}/projects/${projectId}/`;

  return api.put(endpoint, data);
}
