import React from "react";
import SelectBase from "./SelectBase";

const SelectGroup: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: "user", label: "Customizado" },
      { value: "manager", label: "Geral" },
      { value: "technician", label: "Técnico laboratorista" },
      { value: "technician_soil", label: "Técnico laboratorista de solos" },
      {
        value: "technician_concrete",
        label: "Técnico laboratorista de concreto"
      },
      { value: "technician_manager", label: "Gerente de laboratório" },
      { value: "financial_manager", label: "Financeiro" },
      { value: "account_manager", label: "Gerente de contas" },
      { value: "rh_manager", label: "Administrativo" }
    ];
  };

  return (
    <SelectBase
      placeholder="Grupo de acesso..."
      onClick={props.onChange}
      getItems={() => {}}
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      options={options()}
      {...props}
    />
  );
};

export default SelectGroup;
