import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ContractFilterBar from "../../../components/Selects/ContractFilterBar";
import * as clientApi from "../../../services/api/client";
import * as contractApi from "../../../services/api/contract";
import * as insituApi from "../../../services/api/density";
import TechControl from "../components/TechControl";
import AcceptancesDetail from "./components/AcceptancesDetail";
import { useData, TableList } from "../../../components/Table";
import columns from "./columns";
import { LoadingComponent } from "../../../components/Loader";
import {
  ListButtonRow,
  ButtonDelete,
  ButtonOption,
  ButtonInsert
} from "../../../components/Buttons";
import { ModalForm } from "../../../components/Modal";
import HoleForm from "./HoleForm";
import ControlForm from "./ControlForm";
import ReportBar from "./ReportBar";
import { ReportRow, ReportButton } from "../../../components/Report";
import { EditTestAccessPermission } from "../../../components/Permissions";
import { ContainerFluid } from "../../../components/Container";
import { useUser } from "src/context/admin/user";

const PAGE_TITLE = 'Densidade "in-situ"';

const Density = () => {
  const [user] = useUser();
  const [control, setControl] = useState<DensityControlValues>();
  const [contractId, setContractId] = useState<number>();
  const [loadingControl, setLoadingControl] = useState(false);
  const [loadingHoles, setLoadingHoles] = useState(false);
  const [holes, setHoles] = useState<DensityHoleValues[]>([]);
  const hole = useData<DensityHoleValues>();
  const editRef = useRef<ModalForm>(null);

  const getClients = useCallback(() => {
    return clientApi.getClients("soil", "1", "id,name");
  }, []);

  const getContracts = useCallback((clientId: number) => {
    return contractApi.getContracts("", `${clientId}`, "", "", "soil", "1");
  }, []);

  const getControl = useCallback(() => {
    if (contractId) {
      setLoadingControl(true);
      insituApi
        .getControls(contractId)
        .then(({ data: controls }) => {
          if (controls.length > 0) {
            setControl(controls[0]);
          } else {
            setControl(undefined);
          }
        })
        .finally(() => setLoadingControl(false));
    }
  }, [contractId]);

  useEffect(() => {
    getControl();
  }, [getControl]);

  const onPostControl = (values: DensityControlValues) => {
    if (contractId !== undefined) {
      values = {
        ...values,
        acceptances: values.acceptances.map(item => {
          return {
            ...item,
            acceptance: item.acceptance / 100
          };
        })
      };

      return insituApi
        .postControl(contractId, values)
        .then(({ data }) => setControl(data));
    }
    return Promise.reject();
  };

  const insertControl = () => (
    <ModalForm
      title="Novo controle densidade 'in-situ'"
      type="post"
      onSubmit={onPostControl}
      getItemRequest={(id: number) => insituApi.getControl(id)}
      initialValues={() => {
        return {
          contract: contractId,
          acceptances: []
        };
      }}
      form={props => (
        <>{contractId && <ControlForm contractId={contractId} {...props} />}</>
      )}
      renderButton={onClick => (
        <ButtonInsert onClick={onClick}>Novo ensaio</ButtonInsert>
      )}
    />
  );

  const onPutControl = (values: DensityControlValues) => {
    values = {
      ...values,
      acceptances: values.acceptances.map(item => {
        return {
          ...item,
          acceptance: item.acceptance / 100
        };
      })
    };

    if (values.id !== undefined) {
      return insituApi
        .putControl(values.id, values)
        .then(({ data }) => setControl(data));
    }

    return Promise.reject();
  };

  const editControl = () => (
    <EditTestAccessPermission>
      <ModalForm
        title="Novo controle densidade 'in-situ'"
        type="put"
        size="md"
        data={control}
        onSubmit={onPutControl}
        getItemRequest={(id: number) => insituApi.getControl(id)}
        initialValues={(data: DensityControlValues) => {
          if (data) {
            return {
              ...data,
              material_type: data.material_type?.id,
              acceptances: data.acceptances?.map(item => {
                return {
                  id: item.id,
                  name: item.layer.name,
                  layer: item.layer.id,
                  acceptance: item.acceptance * 100
                };
              })
            };
          }

          return {};
        }}
        form={props => (
          <>
            {contractId && <ControlForm contractId={contractId} {...props} />}
          </>
        )}
        renderButton={onClick => (
          <ButtonOption
            className="btn btn-block btn-outline-secondary"
            onClick={onClick}
          >
            Editar controle
          </ButtonOption>
        )}
      />
    </EditTestAccessPermission>
  );

  const getHoles = useCallback(
    (
      layers?: string[],
      places?: string[],
      dates?: string[],
      optionalPlaces?: string[],
      samples?: number[]
    ) => {
      if (control !== undefined && control.id !== undefined) {
        setLoadingHoles(true);
        insituApi
          .getHoles(
            control.id,
            "",
            "",
            "",
            "id,date,number,place,optional_place,description,sample_code,layer,compaction_rate,method,approved",
            layers,
            places,
            dates,
            optionalPlaces,
            samples
          )
          .then(({ data }) => setHoles(data))
          .finally(() => setLoadingHoles(false));
      }
    },
    [control]
  );

  useEffect(() => {
    getHoles();
  }, [getHoles]);

  const onPost = (values: DensityHoleValues) => {
    if (control !== undefined && control.id !== undefined) {
      values.humidity /= 100;
      values.sample_humidity /= 100;
      return insituApi
        .postHole(control.id, values)
        .then(({ data }) => hole.created.set(data));
    }

    return Promise.reject();
  };

  const insertHole = () => (
    <EditTestAccessPermission>
      <ModalForm
        title="Novo furo"
        type="post"
        onSubmit={onPost}
        data={{}}
        initialValues={() => {
          return { insitu: control ? control.id : -1, method: "S" };
        }}
        form={props => (
          <>
            {control && contractId && (
              <HoleForm
                contractId={contractId}
                controlId={control.id!}
                {...props}
              />
            )}
          </>
        )}
      />
    </EditTestAccessPermission>
  );

  const onPut = (values: DensityHoleValues) => {
    values.humidity /= 100;
    values.sample_humidity /= 100;
    return insituApi
      .putHole(values.id, values)
      .then(({ data }) => hole.updated.set(data));
  };

  const initialValues = (data: DensityHoleValues) => {
    if (data)
      return {
        ...data,
        humidity: data.humidity * 100,
        sample_humidity: data.sample_humidity * 100,
        funnel: data.funnel?.id,
        sand: data.sand?.id,
        layer: data.layer.id,
        cylinder: data.cylinder?.id
      };

    return {};
  };

  const editHole = () => (
    <EditTestAccessPermission>
      <ModalForm
        ref={editRef}
        title="Editar furo"
        type="put"
        onSubmit={onPut}
        getItemRequest={(id: number) => insituApi.getHole(id)}
        initialValues={initialValues}
        data={hole.selected.item}
        form={props => (
          <HoleForm
            contractId={contractId!}
            controlId={control?.id!}
            selectedItem={hole.selected.item}
            {...props}
          />
        )}
      />
    </EditTestAccessPermission>
  );

  const deleteHole = () => {
    if (hole.selected.item) {
      return insituApi
        .deleteHole(hole.selected.item.id)
        .then(res => {
          hole.deleted.set(hole.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }
    return Promise.reject();
  };

  const renderHoleList = () => {
    return (
      <Card className="filter-bar">
        <CardBody>
          {user.data?.email !== "labinfrafdv2@infratech.eng.br" && (
            <ListButtonRow buttonsRight={<ReportButton />} />
          )}
          {control && control.id && (
            <ReportRow>
              <ReportBar
                controlId={control.id}
                contractId={contractId || -1}
                onFilter={({
                  layers,
                  places,
                  dates,
                  samples,
                  optionalPlaces
                }) => getHoles(layers, places, dates, optionalPlaces, samples)}
              />
            </ReportRow>
          )}
          <LoadingComponent loading={loadingHoles}>
            <TableList
              data={holes}
              columns={columns}
              selected={hole.selected.set}
              updated={hole.updated.item}
              created={hole.created.item}
              deleted={hole.deleted.item}
              searching={false}
              dbClick={item => {
                hole.selected.set(item);
                editRef.current?.open();
              }}
            />
          </LoadingComponent>
          <ListButtonRow
            marginTop="mt-2"
            buttonsLeft={
              <>
                {insertHole()}
                {editHole()}
              </>
            }
            buttonsRight={
              <EditTestAccessPermission>
                <ButtonDelete
                  disabled={!hole.selected.item}
                  onClick={deleteHole}
                />
              </EditTestAccessPermission>
            }
          />
        </CardBody>
      </Card>
    );
  };

  const renderAcceptances = () => {
    return (
      <>
        {control && (
          <AcceptancesDetail
            acceptances={control.acceptances}
            material={control.material_type}
          />
        )}
      </>
    );
  };

  const renderControl = () => (
    <Row className="text-left">
      <Col sm={3}>
        {renderAcceptances()}
        {editControl()}
      </Col>
      <Col sm={9}>{renderHoleList()}</Col>
    </Row>
  );

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <>
        <ContractFilterBar
          clientRequest={getClients}
          contractRequest={getContracts}
          onContractChange={setContractId}
        />
        <TechControl
          contract={contractId}
          control={control}
          renderControl={renderControl}
          loadingControl={loadingControl}
          insertControl={insertControl}
        />
      </>
    </ContainerFluid>
  );
};

export default Density;
