/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { InputColumn, InputSelect } from "../../../components/Inputs";
import { getHoles } from "../../../services/api/density";
import { formatSimpleDate } from "src/utils/format";
import { customInsituXlsx, densityV2 } from "src/services/reports";
import { checkFeature, fastToken, xlsx } from "src/services/api/core";
import { useParams } from "react-router-dom";
import { ButtonOption } from "src/components/Buttons";
import { dumpTempReportData } from "src/services/storage";
import { openReportPage } from "src/services/api/report";
import { trackReport } from "src/services/track";

const REPORT_TYPES = {
  DEFAULT: 1,
  DEFAULT_V2: 2,
};

interface Props {
  controlId: number;
  contractId: number;
  onFilter: (filter: {
    layers?: string[];
    places?: string[];
    dates?: string[];
    optionalPlaces?: string[];
    samples?: number[];
  }) => void;
}

type TReportOption = { type: "default" | "custom"; id: number };

const ReportBar: React.FC<Props> = ({ controlId, contractId, onFilter }) => {
  const { appSlug } = useParams<{ appSlug: string }>();
  const [layers, setLayers] = useState<Set<string>>(new Set());
  const [places, setPlaces] = useState<Set<string>>(new Set());
  const [optionalPlaces, setOptionalPlaces] = useState<Set<string>>(new Set());
  const [dates, setDates] = useState<Set<string>>(new Set());
  const [samples, setSamples] = useState<Set<{ id: number; code: number }>>(
    new Set(),
  );
  const [group, setGroup] = useState<string>("");
  const [reportPlaces, setReportPlaces] = useState<string[]>([]);
  const [reportOptionalPlaces, setReportOptionalPlaces] = useState<string[]>(
    [],
  );
  const [reportLayers, setReportLayers] = useState<string[]>([]);
  const [reportDates, setReportDates] = useState<string[]>([]);
  const [reportSamples, setReportSamples] = useState<number[]>([]);
  const [attachNotes, setAttachNotes] = useState<boolean>(true);
  const [reportType, setReportType] = useState<TReportOption>();
  const [customReports, setCustomReports] = useState<Xlsx[]>([]);
  const [submittingReport, setSubmittingReport] = useState<boolean>(false);

  useEffect(() => {
    xlsx("I", "name,id").then(({ data }) => {
      setCustomReports(data);
    });
    reportOptions();
  }, []);

  useEffect(() => {
    const uniqueCode = new Set<number>();
    const places: Set<string> = new Set();
    const optionalPlaces: Set<string> = new Set();
    const layers: Set<string> = new Set();
    const dates: Set<string> = new Set();
    const samples: Set<{ id: number; code: number }> = new Set();
    getHoles(
      controlId,
      "",
      "",
      "",
      "place,optional_place,layer,date,sample_code,sample",
    ).then(({ data }) => {
      data.forEach((item: any) => {
        places.add(item.place);
        layers.add(item.layer.name);
        dates.add(item.date);

        if (item.optional_place.length > 0) {
          optionalPlaces.add(item.optional_place);
        }

        if (!uniqueCode.has(item.sample_code)) {
          samples.add({ code: item.sample_code, id: item.sample });
          uniqueCode.add(item.sample_code);
        }
      });

      setLayers(layers);
      setPlaces(places);
      setOptionalPlaces(optionalPlaces);
      setDates(dates);
      setSamples(samples);
    });
  }, [controlId]);

  const layerOptions = () => {
    return Array.from(layers)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
  };

  const placeOptions = () => {
    return Array.from(places)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => {
        return {
          value: item,
          label: item,
        };
      });
  };

  const optionalPlaceOptions = () => {
    return Array.from(optionalPlaces)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => {
        return {
          value: item,
          label: item,
        };
      });
  };

  const dateOptions = () => {
    return Array.from(dates).map((item) => {
      return {
        value: item,
        label: formatSimpleDate(item),
      };
    });
  };

  const sampleOptions = () => {
    return Array.from(samples).map((item) => {
      return {
        value: item.id,
        label: item.code,
      };
    });
  };

  const setPlaceValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportPlaces(options);

    onFilter({
      places: options,
      layers: reportLayers,
      dates: reportDates,
      optionalPlaces: reportOptionalPlaces,
      samples: reportSamples,
    });
  };

  const setOptionalPlaceValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportOptionalPlaces(options);

    onFilter({
      places: reportPlaces,
      layers: reportLayers,
      dates: reportDates,
      optionalPlaces: options,
      samples: reportSamples,
    });
  };

  const setLayerValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportLayers(options);

    onFilter({
      places: reportPlaces,
      layers: options,
      dates: reportDates,
      optionalPlaces: reportOptionalPlaces,
      samples: reportSamples,
    });
  };

  const setDateValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportDates(options);

    onFilter({
      places: reportPlaces,
      layers: reportPlaces,
      dates: options,
      optionalPlaces: reportOptionalPlaces,
      samples: reportSamples,
    });
  };

  const setSampleValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportSamples(options);

    onFilter({
      places: reportPlaces,
      layers: reportPlaces,
      dates: reportDates,
      optionalPlaces: reportOptionalPlaces,
      samples: options,
    });
  };

  const getReportURL = () => {
    if (reportType?.type === "default") {
      if (reportType.id === REPORT_TYPES.DEFAULT) {
        trackReport("insitu", "holes");
        const reportStorageData: ReportStorageData = {
          type: "insitu",
          params: {
            contract: contractId,
            application: appSlug,
          },
          data: {
            places: reportPlaces,
            layers: reportLayers,
            date: reportDates,
            optional_places: reportOptionalPlaces,
            samples: reportSamples,
          },
        };

        const key = dumpTempReportData(reportStorageData);
        openReportPage(key);
      } else if (reportType.id === REPORT_TYPES.DEFAULT_V2) {
        trackReport("insitu", "holes_v2");
        fastToken().then(({ data }) => {
          densityV2(
            reportPlaces,
            reportLayers,
            reportDates,
            reportOptionalPlaces,
            reportSamples,
            appSlug,
            data.access,
            controlId,
            contractId,
          );
        });
      }
    } else if (reportType?.type === "custom") {
      trackReport("insitu", "custom_xlsx");
      customInsituXlsx(
        controlId,
        reportType.id,
        reportPlaces,
        reportLayers,
        reportDates,
      )
        .then(({ data }) => {
          window.open(data.file, "_blank");
        })
        .finally(() => setSubmittingReport(false));
    }
  };

  const [options, setOptions] = useState<any>([]);
  const reportOptions = async () => {
    const _options = [];

    try {
      await checkFeature("go_report_density");
      console.log("oxi");
      _options.push({
        value: { type: "default", id: REPORT_TYPES.DEFAULT_V2 },
        label: "Relatório de furos",
      });
    } catch (e) {
      _options.push({
        value: { type: "default", id: REPORT_TYPES.DEFAULT },
        label: "Relatório de furos",
      });
      _options.push({
        value: { type: "default", id: REPORT_TYPES.DEFAULT_V2 },
        label: "Relatório de furos (teste)",
      });
    }

    setOptions(_options);

    //customReports.forEach((item) => {
    //  options.push({
    //    value: { type: "custom", id: item.id || -1 },
    //    label: item.name || "",
    //  });
    //});
  };

  return (
    <Row>
      <Col sm={3}>
        <InputColumn label="Local/estaca">
          <Select
            placeholder="Local..."
            options={placeOptions()}
            isMulti
            styles={{
              container: () => ({
                width: "100%",
                padding: "0",
              }),
            }}
            onChange={setPlaceValues}
          />
        </InputColumn>
        <InputColumn>
          <Select
            placeholder="Complemento..."
            options={optionalPlaceOptions()}
            isMulti
            styles={{
              container: () => ({
                width: "100%",
                padding: "0",
              }),
            }}
            onChange={setOptionalPlaceValues}
          />
        </InputColumn>
      </Col>
      <Col sm={3}>
        <InputColumn label="Camada/amostra">
          <Select
            placeholder="Camada..."
            options={layerOptions()}
            isMulti
            styles={{
              container: () => ({
                width: "100%",
                padding: "0",
              }),
            }}
            onChange={setLayerValues}
          />
        </InputColumn>
        <InputColumn>
          <Select
            placeholder="Amostra..."
            options={sampleOptions()}
            isMulti
            styles={{
              container: () => ({
                width: "100%",
                padding: "0",
              }),
            }}
            onChange={setSampleValues}
          />
        </InputColumn>
      </Col>
      <Col sm={3}>
        <InputColumn label="Data">
          <Select
            options={dateOptions()}
            isMulti
            styles={{
              container: () => ({
                width: "100%",
                padding: "0",
              }),
            }}
            onChange={setDateValues}
          />
        </InputColumn>
      </Col>
      <Col sm={3}>
        <InputColumn label="Tipo de relatório">
          <InputSelect
            onClick={(value: any) => {
              setReportType(value);
            }}
            options={options}
            required
          />
        </InputColumn>
        <ButtonOption
          className="btn-block"
          onClick={getReportURL}
          disabled={reportType === undefined || reportType === null}
          isSubmitting={submittingReport}
        >
          Gerar relatório
        </ButtonOption>
      </Col>
      <Col sm={3} className="mb-1">
        <div className="form-group mb-0">
          <div className="custom-control custom-checkbox">
            <input
              name="attach_notes"
              className="custom-control-input"
              id="attach_notes"
              type="checkbox"
              value=""
              checked={attachNotes}
              onChange={() => setAttachNotes(!attachNotes)}
            />
            <label className="custom-control-label" htmlFor="attach_notes">
              Anexar observações
            </label>
          </div>
        </div>
      </Col>
      <Col sm={3} className="mb-1">
        <div className="form-group mb-0">
          <div className="custom-control custom-checkbox">
            <input
              name="group_sand"
              className="custom-control-input"
              id="group_sand"
              type="checkbox"
              value=""
              checked={group === "sand"}
              onChange={() => setGroup(group === "sand" ? "" : "sand")}
            />
            <label className="custom-control-label" htmlFor="group_sand">
              Agrupar por areia e funil
            </label>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ReportBar;
