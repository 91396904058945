const columns = [
  {
    title: "#",
    data: "id"
  },
  {
    title: "Nome",
    data: "name"
  },
  {
    title: "Cliente",
    data: "client.name"
  },
  {
    title: "Contato",
    data: "contact_name"
  }
];

export default columns;
