import React from "react";
import { HeaderTitle } from "../../../../components/Headers";
import { toLocateNumber } from "../../../../utils/format";

interface Props {
  acceptances: DensityAcceptanceValues[];
  material: MaterialValue;
}

const AcceptancesDetail: React.FC<Props> = ({ acceptances, material }) => (
  <>
    <HeaderTitle title="Coeficientes de aceitação" />
    <dl className="row">
      <dt className="col-sm-6">Material:</dt>
      <dd className="col-sm-6">{material?.name || ""}</dd>
      {acceptances
        .sort((a, b) => a.layer.name.localeCompare(b.layer.name))
        .map(item => (
          <React.Fragment key={item.id}>
            <dt className="col-sm-6">{item.layer.name}</dt>
            <dd className="col-sm-6">
              {toLocateNumber(item.acceptance * 100, 2, "-", "", "%")}
            </dd>
          </React.Fragment>
        ))}
    </dl>
  </>
);

AcceptancesDetail.defaultProps = {
  acceptances: []
};

export default AcceptancesDetail;
