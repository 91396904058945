import { formatSimpleDate } from "../../../utils/format";

const columns = [
  {
    title: "#",
    data: "id"
  },
  {
    title: "Obra",
    data: "work.name"
  },
  {
    title: "Descrição",
    data: "description_title"
  },
  {
    title: "Início",
    data: "start_date",
    render: function (data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Fim",
    data: "end_date",
    render: function (data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Responsável",
    data: "manager"
  },
  {
    title: "Status",
    data: "end_date",
    render: function (data: string) {
      if (new Date(data).getTime() < Date.now()) {
        return '<span class="badge badge-danger">Inativo</span>';
      } else {
        return '<span class="badge badge-success">Ativo</span>';
      }
    }
  }
];

export default columns;
