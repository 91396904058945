import React, { ReactNode } from "react";
import { Modal as M, ModalHeader, ModalBody } from "reactstrap";

interface ModalProps {
  title: string;
  className?: string;
  isOpen: boolean;
  size: "sm" | "md" | "lg" | "xl" | "mxl";
  backdrop?: boolean;
  children: ReactNode;
  toggle: () => void;
  onClosed?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  title,
  className,
  isOpen,
  size,
  backdrop,
  children,
  toggle,
  onClosed
}) => (
  <M
    centered
    isOpen={isOpen}
    toggle={toggle}
    className={className}
    size={size}
    keyboard={false}
    backdrop={backdrop}
    onClosed={onClosed}
  >
    {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
    <ModalBody>{children}</ModalBody>
  </M>
);

Modal.defaultProps = {
  backdrop: true
};

export default Modal;
