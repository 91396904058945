import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { Field } from "formik";
import { isNullOrUndefined } from "util";
import { InputMoney, InputDecimal } from "../../../../components/Inputs";
import { SelectUnit, SelectService } from "../../../../components/Selects";
import * as api from "../../../../services/api/service";

interface Props {
  name: string;
  errors: object;
  index: number;
  setFieldValue: (field: string, value: any) => void;
  insert: (index: number, value: any) => void;
  remove: (index: number) => void;
  subDepartment: SubDepartmentFormValues;
}

const Service: React.FC<Props> = ({
  name,
  index,
  setFieldValue,
  insert,
  remove,
  subDepartment
}) => {
  const [, setPrice] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [, setDiscount] = useState<number>(0);
  const [service, setService] = useState<ServiceValuesProps>();

  const calculateDiscount = (
    totalPrice: number,
    quantity: number,
    unitaryPrice: number
  ) => {
    return (1 - totalPrice / quantity / unitaryPrice) * 100;
  };

  const onChangePrice = (totalPrice: number) => {
    setPrice(totalPrice);

    if (!isNullOrUndefined(service)) {
      const { unitary_price } = service;

      const _discount =
        calculateDiscount(unitary_price, quantity, totalPrice) * 100;
      setFieldValue(`${name}.discount`, _discount.toFixed(2));

      setDiscount(_discount);
    }
  };

  const onChangeQuantity = (quantity: number) => {
    setFieldValue(`${name}.quantity`, quantity);
    setQuantity(quantity);

    if (!isNullOrUndefined(service)) {
      const unitaryPrice = service.unitary_price;

      const totalPrice = unitaryPrice * quantity;
      setFieldValue(`${name}.price`, totalPrice);

      const _discount = calculateDiscount(totalPrice, quantity, unitaryPrice);
      setFieldValue(`${name}.discount`, _discount.toFixed(2));

      setDiscount(_discount);
      setPrice(totalPrice);
    }
  };

  const onChangeService = (service: number) => {
    api.getService(service).then(({ data }: { data: ServiceValuesProps }) => {
      setService(data);
      if (data.unit) setFieldValue(`${name}.unit`, data.unit.id);
      setFieldValue(`${name}.quantity`, "");
      setFieldValue(`${name}.discount`, "");
      setFieldValue(`${name}.price`, "");
    });
  };

  const insertService = () => {
    insert(index + 1, {});
  };

  const removeService = () => {
    remove(index);
  };

  return (
    <Row className="mb-1">
      <Col sm={6}>
        <Field
          name={`${name}.service`}
          component={SelectService}
          subDepartments={[subDepartment.id]}
          onChange={onChangeService}
        />
      </Col>
      <Col>
        <Field
          name={`${name}.unit`}
          component={SelectUnit}
          isClearable={false}
        />
      </Col>
      <Col>
        <Field
          name={`${name}.quantity`}
          component={InputDecimal}
          onChange={onChangeQuantity}
          required
        />
      </Col>
      <Col>
        <Field
          name={`${name}.discount`}
          component={InputDecimal}
          disabled
          required
        />
      </Col>
      <Col>
        <InputMoney
          name={`${name}.price`}
          handleValue={onChangePrice}
          required
        />
      </Col>

      <Col sm={1} className="text-sm-right">
        <Button
          type="button"
          outline
          color="secondary"
          size="sm"
          className="waves-effect"
          onClick={insertService}
        >
          <i className="mdi mdi-plus text-muted" />
        </Button>

        {index > 0 && (
          <Button
            type="button"
            outline
            color="secondary"
            size="sm"
            className="waves-effect"
            onClick={removeService}
          >
            <i className="mdi mdi-trash-can text-muted" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

const ServiceTitle = () => {
  return (
    <Row>
      <Col className="col-sm-6">
        <label>Serviço</label>
      </Col>
      <Col className="text-sm-center">
        <label>Und.</label>
      </Col>
      <Col className="text-sm-center">
        <label>Qtd.</label>
      </Col>
      <Col className="text-sm-center">
        <label>Desconto</label>
      </Col>
      <Col className="text-sm-center">
        <label>Subtotal</label>
      </Col>
      <Col sm={1} className="text-sm-center" />
    </Row>
  );
};

const ServiceTypeTitle = ({ title }: { title: string }) => (
  <p className="mb-1 font-weight-bold">{title}</p>
);

export { Service, ServiceTitle, ServiceTypeTitle };
