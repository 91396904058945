/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, FormikActions } from "formik";
import Form from "./Form";
import initialValues from "./init";
import * as api from "../../../../../services/api/characterization";
import { useGranulometryActions } from "./context";

interface GranulometryProps {
  sample: SoilSample;
  hasUpdated: (sampleId: number) => void;
}

const Granulometry: React.FC<GranulometryProps> = ({ sample, hasUpdated }) => {
  const [, granulometryActions] = useGranulometryActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [test, setTest] = useState<Granulometry>();
  useEffect(() => {
    granulometryActions.getGranulometry(sample.id!).then(data => {
      setTest(data);
      setLoading(false);
    });
  }, [sample.id]);

  const onSubmit = (values: any, actions: FormikActions<object>) => {
    if (values.id === undefined) {
      values = {
        ...values,
        sample: sample.id
      };

      api
        .postGranulometry(sample.id!, values)
        .then(({ data }: { data: Granulometry }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      api
        .putGranulometry(values.id, values)
        .then(({ data }: { data: Granulometry }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const formatTest = (test?: Granulometry) => {
    if (test) {
      return {
        ...test,
        total_capsule: test.total_capsule.id,
        partial_capsule: test.partial_capsule?.id,
        capsule_studies: test.capsule_studies.map(item => {
          return {
            ...item,
            capsule_study: {
              ...item.capsule_study,
              capsule: item.capsule_study.capsule.id
            }
          };
        })
      };
    }

    return undefined;
  };

  return (
    <>
      {loading ? (
        <div>carregando....</div>
      ) : (
        <Formik
          onSubmit={onSubmit}
          initialValues={formatTest(test) || initialValues}
          enableReinitialize
          render={(props: any) => <Form {...props} />}
        />
      )}
    </>
  );
};

export default Granulometry;
