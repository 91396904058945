import { FormikHandlers } from "formik";
import React from "react";
import Input from "./Input";

interface Props {
  field?: {
    onChange: FormikHandlers["handleChange"];
    onBlur: FormikHandlers["handleBlur"];
    value: any;
    name: string;
  };
  name: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputTime: React.FC<Props> = ({
  field,
  name,
  required = false,
  disabled = false,
  onChange
}) => (
  <Input
    name={name}
    type="time"
    disabled={disabled}
    required={required}
    field={field}
    onChange={onChange}
  />
);

export default InputTime;
