import { formatSimpleDate, toLocateNumber } from "src/utils/format";

const columnsLot = [
  { title: "Lote", data: "lot" },
  {
    title: "Fabricação",
    data: "fabrication_date",
    render: function (data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Conformidade",
    data: "conf_lot",
    render: function (data: boolean) {
      if (data) {
        return '<span class="badge badge-primary">Conforme</span>';
      } else {
        return '<span class="badge badge-danger">Não conforme</span>';
      }
    }
  }
];

const columnsSamples = [
  { title: "#", data: "code" },
  { title: "Idade", data: "age" },
  {
    title: "Carga",
    data: "load",
    render: function (data: number) {
      return toLocateNumber(data, 3, "-");
    }
  },
  {
    title: "FBK (MPa)",
    data: "resistance",
    render: function (data: number) {
      return toLocateNumber(data, 3, "-");
    }
  },
  {
    title: "Conformidade",
    data: "conformity",
    render: function (data: boolean) {
      if (data) {
        return '<span class="badge badge-primary">Conforme</span>';
      } else {
        return '<span class="badge badge-danger">Não conforme</span>';
      }
    }
  }
];

export { columnsLot, columnsSamples };
