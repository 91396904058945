import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import * as math from "../../../../../utils/math";
import * as api from "../../../../../services/api/characterization";

interface State {
  cbrs: number[];
  maxIsc: number;
  greatHumidity: number;
  humidities: number[];
  expansions: number[];
  loading: boolean;
  constant: number;
}

const initialState: State = {
  cbrs: [0, 0, 0, 0, 0],
  maxIsc: 0,
  greatHumidity: 0,
  humidities: [0, 0, 0, 0, 0],
  expansions: [0, 0, 0, 0, 0],
  constant: 1,
  loading: false
};

const actions = {
  getCbrTest: (sampleId: number) => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (getState().loading) {
      return;
    }

    setState({
      loading: true
    });

    return api
      .getCbr(sampleId)
      .then(({ data }) => {
        if (data.length > 0) {
          const test = data[0];

          setState({
            constant: test.calibration
          });

          return test;
        }

        return null;
      })
      .finally(() => setState({ loading: false }));
  },

  setCbr: (value: number, index: number, greatHumidity: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { cbrs } = getState();
    const temp = cbrs;
    temp[index] = value;

    setState({
      cbrs: temp
    });

    dispatch(actions.setMaxPoint(greatHumidity));
  },

  setHumidity: (value: number, index: number, greatHumidity: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { humidities } = getState();
    const temp = humidities;
    temp[index] = value;

    setState({
      humidities: temp
    });

    dispatch(actions.setMaxPoint(greatHumidity));
  },

  setExpansion: (value: number, index: number) => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    const { expansions } = getState();
    const temp = expansions;
    temp[index] = value;

    setState({
      expansions: temp
    });
  },

  setMaxPoint: (greatHumidity: number) => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    setState({
      greatHumidity,
      maxIsc: math.maxDensity(
        greatHumidity,
        getState().humidities,
        getState().cbrs
      )
    });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "cbr"
});

const CbrSubscriber = createSubscriber(Store);
const useCbr = createHook(Store);
const useConstant = createHook(Store, {
  selector: (state: State) => state.constant
});
const useCbrActions = createHook(Store, {
  selector: null
});
const useLoading = createHook(Store, {
  selector: (state: State) => state.loading
});

export { CbrSubscriber, useCbr, useCbrActions, useConstant, useLoading };
