import React, { useEffect, useRef } from "react";
import InputNumber from "rc-input-number";

interface Props {
  field: {
    value: any;
    name: string;
  };
  required: boolean;
  disabled: boolean;
  precision: number;
  onChange: (value: number) => void;
  prefix: string;
  sufix: string;
  readonly: boolean;
  autoFocus?: boolean;
}

const InputDecimal: React.FC<Props> = ({
  field,
  required = false,
  disabled = false,
  precision = 3,
  onChange,
  prefix = "",
  sufix = "",
  readonly = false,
  autoFocus
}) => {
  const mRef = useRef<InputNumber>(null);

  useEffect(() => {
    if (autoFocus) {
      mRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <InputNumber
      ref={mRef}
      className="decimal-input decimal-input-sm"
      name={field.name}
      value={field.value}
      decimalSeparator=","
      required={required}
      min={0}
      precision={precision}
      disabled={disabled}
      step={1 / Math.pow(10, precision)}
      onChange={(value: any) => onChange && onChange(value)}
      formatter={(value: any) => `${prefix}${value}${sufix}`}
      readonly={readonly}
    />
  );
};

InputDecimal.defaultProps = {
  required: false,
  disabled: false,
  precision: 3,
  prefix: "",
  sufix: "",
  readonly: false
};

export default InputDecimal;
