import React from "react";
import { ModalForm } from "../../../../components/Modal";
import Form from "./Form";
import { getSeries, putSeries } from "../../../../services/api/concrete";
import { EditTestAccessPermission } from "../../../../components/Permissions";

interface Props {
  item?: TConcreteSeries;
  control: TConcreteControl;
  onUpdated: (item: TConcreteSeries) => void;
}

const SeriesAnalyse: React.FC<Props> = ({ item, control, onUpdated }) => {
  const onSubmit = (values: TConcreteSeries) => {
    if (values.id !== undefined) {
      // Mapeia as misturas
      values.concrete_mix = values.concrete_mix.map(mix => {
        //Se a mistura tiver teste de ruptura
        if (mix.stress_test !== undefined) {
          // Mapeia os CPS
          mix.stress_test.concrete_cp = mix.stress_test.concrete_cp.map(cp => {
            // Aprova os CPs
            return {
              ...cp,
              approved: cp.load !== undefined && cp.load > 0
            };
          });

          return mix;
        }

        return mix;
      });

      return putSeries(values.id, values).then(({ data }) => onUpdated(data));
    }

    return Promise.reject();
  };

  return (
    <EditTestAccessPermission>
      <ModalForm
        type="put"
        size="lg"
        title="Analisar série"
        buttonLabel="Analisar"
        buttonSaveLabel="Aprovar e liberar"
        data={item}
        getItemRequest={(id: number) => getSeries(id)}
        form={props => <Form {...props} control={control} />}
        initialValues={data => data}
        onSubmit={onSubmit}
      />
    </EditTestAccessPermission>
  );
};

export default SeriesAnalyse;
