import moment from "moment";

export function formatSimpleDate(date, out = "") {
  if (date) return moment(date).local().format("DD/MM/YYYY");
  return out;
}

export function formatSimpleDateHour(date) {
  if (date) return moment(date).local().format("DD/MM/YY HH:mm");
  return "";
}

export function formatSerieCode(value) {
  return value.toString().padStart(5, "0.000");
}

export function formatCPCode(value) {
  return value.toString().padStart(7, "000.000");
}

export function toDatetimeLocal(date) {
  const ten = function (i) {
    return (i < 10 ? "0" : "") + i;
  };
  const YYYY = date.getFullYear();
  const MM = ten(date.getMonth() + 1);
  const DD = ten(date.getDate());
  const HH = ten(date.getHours());
  const II = ten(date.getMinutes());
  const SS = ten(date.getSeconds());
  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}Z`;
}

export function timeToDatetimeLocal(time) {
  const date = new Date();
  const ten = function (i) {
    return (i < 10 ? "0" : "") + i;
  };
  const YYYY = date.getFullYear();
  const MM = ten(date.getMonth() + 1);
  const DD = ten(date.getDate());

  return `${YYYY}-${MM}-${DD}T${time}:00Z`;
}

export const formatterMoney = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2
});

export function formatPorcentage(number) {
  return `${(number * 100).toFixed(2)} %`;
}

export const formatPhone = data => {
  if (!data) return "";

  data = data.replace(/\D/g, ""); // Remove tudo o que não é dígito
  data = data.replace(/^(\d{2})(\d)/g, "($1) $2"); // Coloca parênteses em volta dos dois primeiros dígitos
  data = data.replace(/(\d)(\d{4})$/, "$1-$2"); // Coloca hífen entre o quarto e o quinto dígitos
  return data;
};

export const formatCPF = data => {
  data = data.replace(/\D/g, ""); // Remove tudo o que não é dígito
  data = data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4"); // Coloca parênteses em volta dos dois primeiros dígitos
  return data;
};

export const formatCNPJ = data => {
  if (data === null || data === undefined) return "";

  data = data.replace(/\D/g, ""); // Remove tudo o que não é dígito
  data = data.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  ); // Coloca parênteses em volta dos dois primeiros dígitos
  return data;
};

export const toLocateNumber = (
  number,
  precision,
  zero_out,
  prefix = "",
  sufix = ""
) => {
  number = number || 0;

  if (typeof number === "string") return number;
  if (zero_out && number === 0) return zero_out;

  return (
    prefix +
    number.toLocaleString(navigator.language, {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision
    }) +
    sufix
  );
};

export const getMonth = monthNumber => {
  return monthNumber;
};

export const reduceString = (string, length) => {
  return string.length > length
    ? `${string.substring(0, length / 2)}...${string.substring(
        string.length,
        string.length - length / 2
      )}`
    : string;
};

export const trimString = (data, length) => {
  if (data.length > length) {
    return `${data.slice(0, length)}...`;
  }
  return data;
};
