/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "../../../components/Container";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, FormikActions } from "formik";
import { InputText, InputColumn } from "../../../components/Inputs";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { validateToken, confirmNewPassword } from "../../../services/api/auth";

const ResetPassword = () => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [failMessage, setFailMessage] = useState<string>("");
  const [validToken, setValidToken] = useState<"loading" | "valid" | "invalid">(
    "loading"
  );
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const token = query.get("token");
    if (token !== null) {
      validateToken(token)
        .then(() => setValidToken("valid"))
        .catch(() => setValidToken("invalid"));
    } else {
      setValidToken("invalid");
    }
  }, []);

  const onSubmit = (values: any, _actions: FormikActions<any>) => {
    const token = query.get("token");
    if (token !== null) {
      confirmNewPassword(token, values.password)
        .then(() => {
          setSuccessMessage("Sua senha foi alterada!");
        })
        .catch(() => {
          setFailMessage("Não foi possível alterar sua senha.");
        });
    }
  };

  const renderForm = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{}}
        render={() => {
          return (
            <Form>
              <InputColumn label="Nova senha">
                <Field
                  name="password"
                  type="password"
                  component={InputText}
                  required
                />
              </InputColumn>
              <InputColumn label="Confirmar nova senha">
                <Field
                  name="repeat_password"
                  type="password"
                  component={InputText}
                  required
                />
              </InputColumn>
              {successMessage.length > 0 && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {failMessage.length > 0 && (
                <div className="alert alert-warning" role="alert">
                  {successMessage}
                </div>
              )}
              <Button
                className="mt-2"
                color="primary"
                size="sm"
                block
                type="submit"
              >
                Atualizar
              </Button>
            </Form>
          );
        }}
      />
    );
  };

  const render = () => {
    if (validToken === "loading") {
      return "loading";
    } else if (validToken === "invalid") {
      return (
        <p>
          <strong>Link de redefinição expirou ou é inválido.</strong>
        </p>
      );
    } else {
      return renderForm();
    }
  };

  return (
    <Container title="Trocar senha">
      <Row className="justify-content-center">
        <Col sm={4} lg={4}>
          <div className="text-center w-75 pb-4 m-auto">
            <span>
              <img
                src={process.env.PUBLIC_URL + "/static/assets/images/logo.svg"}
                alt=""
                height="40"
              />
            </span>
          </div>
          <Card className="pl-2 pr-2">
            <CardBody>
              <Row>
                <Col>
                  {render()}
                  <p className="mt-3 mb-1">
                    <label className="text-sm-right text-bold">
                      <u>
                        <a href="/login" className="text-dark">
                          Voltar para o login
                        </a>
                      </u>
                    </label>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
