import React from "react";

interface Props {
  children:
    | React.ComponentType<any>
    | React.ReactElement
    | React.ReactElement[]
    | string
    | string[]
    | null;
}

const TextMuted: React.FC<Props> = ({ children }) => (
  <p className="text-muted font-14">{children}</p>
);

const SmallText: React.FC<Props> = ({ children }) => (
  <small className="text-sm-right">{children}</small>
);

const H4: React.FC<Props> = ({ children }) => (
  <h4 className="mt-0 mb-0">{children}</h4>
);

export { TextMuted, SmallText, H4 };
