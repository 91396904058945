import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useData, TableList } from "../../../components/Table";
import { LoadingComponent } from "../../../components/Loader";
import columns from "./columns";
import * as contract_api from "../../../services/api/contract";
import * as client_api from "../../../services/api/client";
import * as admin_api from "../../../services/api/admin";
import { InputRow } from "../../../components/Inputs";
import {
  SelectSubDepartment,
  SelectClient,
  SelectWork
} from "../../../components/Selects";
import { ListButtonRow, ButtonDelete } from "../../../components/Buttons";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Contratos";

const Contract = () => {
  const item = useData<ContractValuesProps>();
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Array<ContractValuesProps>>([]);
  const [client, setClient] = useState<number>();
  const [work, setWork] = useState();
  const [subDepartment, setSubDepartment] = useState();
  const [subDepartments, setSubDepartments] = useState<
    SubDepartmentFormValues[]
  >([]);
  const [dbClick, setDbClick] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    contract_api
      .getContracts(subDepartment, `${client || ""}`, work)
      .then(({ data }) => setItems(data))
      .finally(() => setLoading(false));
  }, [work, subDepartment, client]);

  useEffect(() => {
    admin_api.getSubDepartments().then(({ data }) => setSubDepartments(data));
  }, []);

  const rebaseServices = (values: any) => {
    const data = values;

    data.service_contract = [];
    subDepartments.forEach(item => {
      const key = `services_${item.id}`;
      if (data[key]) {
        data.service_contract = [...data.service_contract, ...data[key]];
      }
    });

    return data;
  };

  const onPut = (values: ContractValuesProps) => {
    const data = rebaseServices(values);

    return contract_api
      .putContract(values.id!, data)
      .then(({ data }) => item.updated.set(data));
  };

  const onPost = (values: ContractValuesProps) => {
    const data = rebaseServices(values);

    return contract_api
      .postContract(data)
      .then(({ data }) => item.created.set(data));
  };

  const initialValues = (data?: ContractValuesProps) => {
    if (data) {
      const services = {};
      data.service_contract.forEach(item => {
        if (item.service.sub_department) {
          const key = `services_${item.service.sub_department.id}`;

          if (Object.keys(services).indexOf(key) < 0) {
            (services as { [key: string]: any })[key] = [];
          }

          (services as { [key: string]: any })[key].push({
            ...item,
            pk: item.id,
            service: item.service.id,
            unit: item.service.unit ? item.service.unit.id : -1
          });
        }
      });

      return {
        ...data,
        client: data.work && data.work.client ? data.work.client.id : -1,
        work: data.work ? data.work.id : -1,
        company: data.company,
        ...services
      };
    }

    return {};
  };

  const editItem = () => {
    return (
      <ModalForm
        title="Editar contrato"
        type="put"
        size="xl"
        form={props => <Form {...props} />}
        onSubmit={onPut}
        getItemRequest={(id: number) => contract_api.getContract(id)}
        initialValues={initialValues}
        data={item.selected.item}
        dbClick={dbClick}
      />
    );
  };

  const insertItem = () => {
    return (
      <ModalForm
        title="Novo contrato"
        type="post"
        size="xl"
        data={{}}
        form={props => <Form {...props} />}
        onSubmit={onPost}
        initialValues={() => {
          return {};
        }}
      />
    );
  };

  const deleteItem = () => {
    if (item.selected.item) {
      return contract_api
        .deleteContract(item.selected.item.id!)
        .then(res => {
          item.deleted.set(item.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }

    return Promise.reject();
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={3}>
          <InputRow label_col="auto" label="Área de serviço">
            <SelectSubDepartment onChange={setSubDepartment} />
          </InputRow>
        </Col>
        <Col sm={3}>
          <InputRow label_col="auto" label="Cliente">
            <SelectClient
              request={client_api.getClients}
              onChange={setClient}
            />
          </InputRow>
        </Col>
        <Col sm={3}>
          <InputRow label_col="auto" label="Obra">
            <SelectWork
              client={client!}
              request={contract_api.getContracts}
              onChange={setWork}
            />
          </InputRow>
        </Col>
      </Row>
      {/* <Service /> */}
      <Card className="filter-bar">
        <CardBody>
          <LoadingComponent loading={loading}>
            <TableList
              data={items}
              columns={columns}
              selected={item.selected.set}
              updated={item.updated.item}
              created={item.created.item}
              deleted={item.deleted.item}
              dbClick={selected => {
                item.selected.set(selected);

                setDbClick(true);
                setDbClick(false);
              }}
              searching
            />
          </LoadingComponent>
          <ListButtonRow
            marginTop="mt-2"
            buttonsLeft={
              <>
                {insertItem()}
                {editItem()}
              </>
            }
            buttonsRight={
              <ButtonDelete
                disabled={!item.selected.item}
                onClick={deleteItem}
              />
            }
          />
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default Contract;
