import React, { useState, Dispatch } from "react";
import Table from "./Table";

interface Props {
  columns: any;
  data: Array<object>;
  selected?: Dispatch<any | undefined>;
  updated?: any;
  created?: any;
  deleted?: any;
  dbClick?: (item: any) => void;
  searching?: boolean;
  select?: boolean;
  selectMultiple?: (item: any) => void;
  responsive?: boolean;
  groupSrc?: any;
  tableId?: string;
  wrap?: boolean;
  scrollY?: string;
}

const TableList: React.FC<Props> = ({
  columns,
  data,
  selected,
  updated,
  created,
  deleted,
  dbClick,
  searching,
  select,
  selectMultiple,
  responsive,
  groupSrc,
  tableId,
  scrollY,
  wrap
}) => {
  return (
    <Table
      tableId={tableId}
      columns={columns}
      data={data}
      selectedItem={selected}
      updatedItem={updated}
      newItem={created}
      deletedItem={deleted}
      onDoubleClick={dbClick}
      paging={false}
      responsive={responsive && true}
      searching={searching && true}
      ordering
      scrollX
      scrollY={scrollY}
      scrollCollapse={true}
      info={false}
      select={select && true}
      selectedMultiple={selectMultiple}
      groupSrc={groupSrc}
      wrap={wrap}
    />
  );
};

TableList.defaultProps = {
  tableId: "table",
  wrap: false
};

function createState<T>(initialState?: T) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [item, set] = useState<T | undefined>(initialState);
  return {
    item,
    set
  };
}

function useData<T = undefined>(initialState?: T) {
  return {
    selected: createState<T>(initialState),
    updated: createState<T>(initialState),
    created: createState(initialState),
    deleted: createState(initialState)
  };
}

export { useData, TableList };
