import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "util";
import * as tools_api from "../../services/api/tools";
import { toLocateNumber } from "../../utils/format";
import SelectBase from "./SelectBase";

const SelectFunnel: React.FC<SelectType<FunnelValues>> = props => {
  const [items, setItems] = useState<FunnelValues[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    tools_api.getFunnels().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items.map(item => ({
      value: item.id,
      label: `${item.number} | ${toLocateNumber(
        item.sand_weight,
        2,
        0,
        "",
        "g"
      )}`
    }));
  };

  const onChange = (value: any) => {
    let item = value;
    if (!isNullOrUndefined(value) && props.onChange) {
      item = items.filter(c => c.id === value)[0];
      props.onChange(item);
    } else {
      props.onChange(undefined);
    }
  };

  return (
    <SelectBase
      {...props}
      placeholder="Funil..."
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      getItems={getItems}
      options={options()}
      onClick={onChange}
    />
  );
};

export default SelectFunnel;
