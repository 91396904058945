import React from "react";
import { Formik } from "formik";
import EmployeeForm from "./EmployeeForm";
import { postEmployee } from "../../../../services/api/admin";

const EmployeePOST: React.FC<POSTFormModal<EmployeeFormType>> = props => {
  return (
    <Formik
      onSubmit={(
        values: EmployeeFormType,
        { setFieldError, setSubmitting }
      ) => {
        postEmployee(values)
          .then(({ data }) => {
            props.insertedItem(data);
            props.toggle();
          })
          .catch(({ data }) => {
            Object.keys(data).forEach(key => {
              setFieldError(key, data[key]);
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
      initialValues={{ cpf: "" }}
      render={props => <EmployeeForm {...props} />}
    />
  );
};

export default EmployeePOST;
