/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getSamples } from "../../services/api/characterization";
import SelectBase from "./SelectBase";

interface Props extends SelectType<any> {
  contractId: number;
}

const SelectSample: React.FC<Props> = props => {
  const [items, setItems] = useState<any[]>([]);

  const getItems = () => {
    getSamples(
      props.contractId,
      "id,layer,code,summary",
      1,
      1000
    ).then(({ data }) => setItems(data.results));
  };

  useEffect(() => {
    getItems();
  }, [props.contractId]);

  const options = () => {
    return items.map(item => ({
      value: item.id,
      label: `${item.code} | ${item.layer ? item.layer.name : "-"}`
    }));
  };

  const onChange = (value: any) => {
    const sample = items.filter(c => c.id === value);
    props.onChange(sample[0]);
  };

  return (
    <SelectBase
      {...props}
      placeholder="Amostra..."
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      getItems={getItems}
      options={options()}
      onClick={onChange}
    />
  );
};

export default SelectSample;
