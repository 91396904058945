import React from "react";
import { Scatter } from "react-chartjs-2";
//@ts-ignore
import everpolate from "everpolate";
import { useLimits } from "./context";

const options = {
  type: "line",
  showLines: true,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 60,
          stepSize: 10
        },
        scaleLabel: {
          display: true,
          labelString: "UMIDADE (%)"
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          min: 0,
          max: 60
        },
        scaleLabel: {
          display: true,
          labelString: "NÚMERO DE GOLPES"
        }
      }
    ]
  },
  legend: {
    display: false,
    position: "bottom",
    usePointStyle: true
  },
  layout: {
    padding: {
      bottom: 30,
      top: 30,
      left: 20
    }
  },
  tooltips: {
    mode: "index"
  }
};

const Chart = () => {
  const [limits] = useLimits();

  const getDataSet = () => {
    const dataset: { [key: string]: any } = [];

    const data = limits.liquidityTest.map(item => {
      return { x: item.stroke, y: item.humidity * 100 };
    });

    const stroke25 = everpolate.polynomial(
      25,
      data.map(item => {
        return item.x;
      }),
      data.map(item => {
        return item.y;
      })
    )[0];

    dataset.push({
      label: "Limite de liquidez",
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "green",
      borderColor: "green",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "green",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "green",
      pointHoverBorderColor: "green",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data
    });

    dataset.push({
      label: "25 Golpes",
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "coral",
      borderColor: "coral",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: [
        {
          x: 0,
          y: stroke25
        },
        {
          x: 25,
          y: stroke25
        },
        {
          x: 25,
          y: 0
        }
      ]
    });

    return dataset;
  };

  return (
    <div className="canvas-size-lim">
      <Scatter
        data={{
          datasets: getDataSet()
        }}
        options={options}
      />
    </div>
  );
};

export default Chart;
