import React from "react";
import AccessPermission, { useCheckPermissions } from "./AccessPermission";

const permissions = ["can_create_tests"];

interface Props {
  children: React.ComponentType<any> | React.ReactElement | null;
  renderNoAccess?: () => React.ComponentType<any> | React.ReactElement;
}

const CreateTestAccessPermission: React.FC<Props> = ({
  children,
  renderNoAccess
}) => (
  <AccessPermission
    allowedPermissions={permissions}
    renderNoAccess={renderNoAccess}
  >
    {children}
  </AccessPermission>
);

const useCanCreateTestPermission = () => {
  return useCheckPermissions(permissions);
};

export { CreateTestAccessPermission, useCanCreateTestPermission };
