import React from "react";
import { Row, Col } from "reactstrap";
import { HeaderTitle } from "../../../../../components/Headers";
import {
  InputColumn,
  InputValidation,
  InputInteger,
  InputDate,
  InputDecimal,
  InputText,
  InputSelect
} from "../../../../../components/Inputs";
import { Field, FormikProps } from "formik";
import IncrementCP from "../../../Concrete/Series/components/IncrementCP";
import CPCode from "../../../Concrete/Series/components/CPCode";

const ages = [12, 24, 72, 168, 336, 672, 1512];

interface Props extends FormikProps<TGrauteSeries> {
  control: TGrauteControl;
}

const FormPost: React.FC<Props> = ({
  values,
  errors,
  control,
  setFieldValue
}) => {
  const onDecrease = (name: string, age: number) => {
    const temp = values.graute_cp;
    let ageIndex: number | undefined = undefined;
    values.graute_cp.forEach((item, index) => {
      if (item.age === age) {
        ageIndex = index;
      }
    });

    if (ageIndex !== undefined) {
      temp.splice(ageIndex, 1);
      setFieldValue(`${name}.concrete_cp`, temp);
    }
  };

  const onIncrease = (name: string, age: number) => {
    const temp = values.graute_cp;
    temp.push({ age: age, code: 0 });
    setFieldValue(`${name}.concrete_cp`, temp);
  };

  const providerOptions = () => {
    return control.providers.split(";").map(item => {
      return { value: item, label: item };
    });
  };

  return (
    <Row>
      <Col sm={4}>
        <HeaderTitle title="Informações da série" />
        <InputColumn label="Número da série">
          <Field name="number" component={InputInteger} required />
          <InputValidation message={errors.number} />
        </InputColumn>
        <InputColumn label="Data de moldagem">
          <Field name="molding_date" component={InputDate} required />
        </InputColumn>
        <InputColumn label="Chegada ao laboratório">
          <Field
            name="lab_arrival"
            component={InputDate}
            min={values.molding_date}
            required
          />
        </InputColumn>
        {control.rule_control === "NBR" && (
          <InputColumn label="FCK flexão (MPa)">
            <Field
              name="fck_flexion"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("fck_flexion", value);
              }}
            />
          </InputColumn>
        )}
        <InputColumn label="FCK compressão (MPa)">
          <Field
            name="fck"
            component={InputDecimal}
            required
            onChange={(value: number) => {
              setFieldValue("fck", value);
            }}
          />
        </InputColumn>
        {/* <hr /> */}
        <HeaderTitle title="DADOS DO GRAUTE" />
        <InputColumn label="Marca do graute">
          <Field
            name="provider"
            component={InputSelect}
            required
            options={providerOptions()}
          />
        </InputColumn>

        {/* <hr /> */}
        {/* <HeaderTitle title="LOCAL DA CONCRETAGEM" /> */}
        <InputColumn label="Torre">
          <Field name="tower" component={InputText} required />
        </InputColumn>
        <InputColumn label="Local">
          <Field name="join" component={InputText} required />
        </InputColumn>
        <InputColumn label="Junta">
          <Field name="link" component={InputText} />
        </InputColumn>
      </Col>
      <Col sm={8}>
        <Row>
          <Col>
            {control.rule_control === "NBR" ? (
              <>
                <HeaderTitle title="Quantidades e idades para ensaio de flexão" />
              </>
            ) : (
              <HeaderTitle title="Quantidades e idades para ensaio de compressão" />
            )}

            <Row>
              <Col sm={8}>
                <Row>
                  {values.graute_cp
                    .sort((a, b) => {
                      return a.age - b.age;
                    })
                    .map((item, index) => (
                      <Col sm={6}>
                        <CPCode
                          name={`graute_cp[${index}]`}
                          key={index}
                          index={index}
                          control={control}
                          values={item}
                          border={""}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>
              <Col sm={4}>
                {ages.map((age, index) => (
                  <IncrementCP
                    key={index}
                    age={age}
                    onIncrease={value => onIncrease("graute_cp", value)}
                    onDecrease={value => onDecrease("graute_cp", value)}
                    amount={
                      values.graute_cp.filter(item => item.age === age).length
                    }
                  />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormPost;
