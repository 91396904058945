import api from "./api";

const getCapsules = () => {
  const endpoint = "/capsules/";

  return api.get(endpoint);
};

const getCapsule = (id: number) => {
  let endpoint = `/capsules/${id}/`;
  return api.get(endpoint);
};

const postCapsules = (data: any) => {
  let endpoint = "/capsules/";
  return api.post(endpoint, data);
};

const putCapsules = (id: number, data: any) => {
  let endpoint = `/capsules/${id}/`;
  return api.put(endpoint, data);
};

const deleteCapsule = (id: number) => {
  let endpoint = `/capsules/${id}/`;
  return api.delete(endpoint);
};

export { getCapsules, getCapsule, postCapsules, putCapsules, deleteCapsule };
