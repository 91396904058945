import React, { ReactNode } from "react";
import { Button } from "reactstrap";

interface Props {
  isSubmitting: boolean;
  submitForm?: () => void;
  children?: ReactNode | string;
  disabled?: boolean;
}

const ButtonSave: React.FC<Props> = ({
  isSubmitting,
  submitForm,
  children,
  disabled,
  ...props
}) => (
  <>
    {submitForm ? (
      <Button
        color="primary"
        onClick={submitForm}
        size="sm"
        {...props}
        disabled={disabled}
      >
        {children}
        {isSubmitting && <i className="mdi mdi-spin mdi-loading ml-1" />}
      </Button>
    ) : (
      <Button
        color="primary"
        type="submit"
        size="sm"
        {...props}
        disabled={disabled}
      >
        {children}
        {isSubmitting && <i className="mdi mdi-spin mdi-loading ml-1" />}
      </Button>
    )}
  </>
);

ButtonSave.defaultProps = {
  isSubmitting: false,
  disabled: false,
  children: "Salvar"
};

export default ButtonSave;
