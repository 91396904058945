/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import { FormikProps, Field, FieldArray } from "formik";
import classnames from "classnames";
import { formatSimpleDate } from "../../../utils/format";
import * as bills_api from "../../../services/api/bills";
import { HeaderSectionTitle } from "../../../components/Headers";
import {
  InputDate,
  InputColumn,
  InputTextArea,
  InputMoney,
  InputText,
  InputCheckbox,
  InputDecimal,
} from "../../../components/Inputs";
import { ButtonOption, ListButtonRow } from "../../../components/Buttons";
import {
  SelectYear,
  SelectMonth,
  SelectBank,
} from "../../../components/Selects";
import { Service, ServiceTitle, ServiceFooter } from "./components/Service";
import Details from "./Details";
import Report from "./components/Report";

interface Props extends FormikProps<InvoiceValuesProps> {
  contract: ContractValuesProps;
  requestType: "post" | "put";
}

const Form: React.FC<Props> = ({
  setFieldValue,
  contract,
  errors,
  values,
  requestType,
}) => {
  const [lastBill, setLastBill] = useState<InvoiceValuesProps>();
  const [summary, setSummary] = useState<SummaryBill[]>([]);
  const [activeTab, setActiveTab] = useState<string>("1");

  useEffect(() => {
    if (contract !== undefined && contract.id !== undefined) {
      getLastBill();
      bills_api.summaryBill(contract.id).then(({ data }) => setSummary(data));
    }
  }, [contract]);

  const toggle = (tab: string) => {
    activeTab !== tab && setActiveTab(tab);
  };

  const getLastBill = () => {
    if (contract !== undefined && contract.id !== undefined) {
      bills_api
        .getBills(
          `${contract.id}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "1",
          bills_api.DESCENDENTE,
        )
        .then(({ data }) => {
          bills_api.summaryBill(contract.id!).then(() => {
            if (data.length > 0) {
              setLastBill(data[0]);
            }
          });
        });
    }
  };

  const contractInformation = () => {
    return (
      <dl className="row">
        <dt className="col-2">Obra</dt>
        <dd className="col-9">
          {contract && contract.work ? contract.work.name : ""}
        </dd>

        <dt className="col-2">Início do contrato</dt>
        <dd className="col-9">
          {formatSimpleDate(contract && contract.start_date)}
        </dd>

        <dt className="col-2">Código do pedido</dt>
        <dd className="col-2">
          <Field name="order" component={InputText} />
        </dd>
      </dl>
    );
  };

  const invoiceInformation = () => {
    return (
      <Row>
        <Col>
          <HeaderSectionTitle title="Período de medição" />
          <Row>
            <label className="col-sm-3 col-form-label">Medição anterior</label>
            <label className="col-sm-4 col-form-label text-center">
              {formatSimpleDate(lastBill ? lastBill.start : undefined, "-")}
            </label>
            <label className="col-sm-1 col-form-label text-center">à</label>
            <label className="col-sm-4 col-form-label text-center">
              {formatSimpleDate(lastBill ? lastBill.end : undefined, "-")}
            </label>
          </Row>
          <Row className="mt-2">
            <label className="col-sm-3 col-form-label">Medição atual</label>
            <Col sm="4">
              <Field
                name="start"
                component={InputDate}
                required
                onChange={(e: any) => {
                  const { value } = e.target;
                  setFieldValue("start", value);
                }}
              />
            </Col>
            <label className="col-sm-1 col-form-label text-center">à</label>
            <Col sm="4">
              <Field
                name="end"
                component={InputDate}
                min={values.start}
                required
                onChange={(e: any) => {
                  const { value } = e.target;
                  setFieldValue("end", value);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={3}>
          <HeaderSectionTitle title="Mês/Ano de referência" />
          <InputColumn label="Ano">
            <Field
              name="year"
              component={SelectYear}
              isClearable={false}
              required
            />
          </InputColumn>
          <InputColumn label="Mês" className="mt-2">
            <Field
              name="month"
              component={SelectMonth}
              isClearable={false}
              required
            />
          </InputColumn>
        </Col>
      </Row>
    );
  };

  const executeBill = () => {
    const { services } = values;

    bills_api
      .runBill(contract.id!, values.start, values.end)
      .then(({ data }) => {
        data.forEach((service_bill: any) => {
          services.forEach((service) => {
            if (service_bill.service_contract === service.service.id) {
              service.quantity = service_bill.quantity;
              service.price = service_bill.full_price;
            }
          });
        });

        setFieldValue("services", services);
      });
  };

  const invoiceActions = () => {
    return (
      <ListButtonRow
        marginTop="mt-2"
        marginButtom="mb-2"
        buttonsLeft={
          <>
            <ButtonOption
              onClick={executeBill}
              disabled={!values.start || !values.end}
            >
              Executar medição
            </ButtonOption>
            <Details
              contract={contract}
              start={values.start}
              end={values.end}
            />
          </>
        }
      />
    );
  };

  const invoiceServices = () => {
    return (
      <>
        <ServiceTitle />
        <FieldArray
          name="services"
          render={({ insert, remove, name }) => {
            return values.services.map((item, index) => (
              <Service
                key={item.id}
                index={index}
                value={values.services[index]}
                service={item}
                setFieldValue={setFieldValue}
                name={`${name}[${index}]`}
                errors={errors}
                insert={insert}
                remove={remove}
                summary={
                  summary.filter(
                    (c) => c.service_contract === item.service.id,
                  )[0] || {
                    service_contract: -1,
                    quantity: 0,
                    total: 0,
                  }
                }
              />
            ));
          }}
        />
      </>
    );
  };

  const resume = () => {
    let total: number = 0;
    values.services.forEach((item) => {
      total += item.price;
    });

    total *= 1 + values.taxes / 100;

    return <ServiceFooter total={total} />;
  };

  const disableBilled = () => {
    const { bill_status } = values;
    return !bill_status.sent_date;
  };

  const disableReceipt = () => {
    const { bill_status } = values;
    return (
      bill_status.invoice === "" ||
      bill_status.net_value === 0 ||
      !bill_status.net_date
    );
  };

  const requiredNetStatus = () => {
    const { bill_status } = values;

    return (
      bill_status.invoice !== "" ||
      bill_status.net_value !== 0 ||
      !!bill_status.net_date
    );
  };

  const invoiceTrack = () => {
    return (
      <>
        <Row>
          <Col sm={4}>
            <HeaderSectionTitle title="Enviada" />
            <InputColumn label="Data">
              <Field name="bill_status.sent_date" component={InputDate} />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <HeaderSectionTitle title="Faturada" />
            <InputColumn label="Data">
              <Field
                name="bill_status.net_date"
                component={InputDate}
                min={values.bill_status.sent_date}
                disabled={disableBilled()}
                required={requiredNetStatus()}
              />
            </InputColumn>
            <InputColumn label="Nota fiscal">
              <Field
                name="bill_status.invoice"
                component={InputText}
                disabled={disableBilled()}
                required={requiredNetStatus()}
              />
            </InputColumn>
            <InputColumn label="Valor líquido">
              <InputMoney
                name="bill_status.net_value"
                disabled={disableBilled()}
                required={requiredNetStatus()}
              />
            </InputColumn>
          </Col>
          <Col sm={4}>
            <HeaderSectionTitle title="Recebida" />
            <InputColumn label="Data">
              <Field
                name="bill_status.forecast_receipt"
                component={InputDate}
                min={values.bill_status.net_date}
                disabled={disableReceipt()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const date = event.target.value;
                  if (date.length === 0) {
                    setFieldValue("bill_status.bank", null);
                    setFieldValue("bill_status.receipt_value", 0);
                    setFieldValue("bill_status.partial_receipt", false);
                  }
                  setFieldValue("bill_status.forecast_receipt", date);
                }}
              />
            </InputColumn>
            <InputColumn label="Banco">
              <Field
                name="bill_status.bank"
                component={SelectBank}
                disabled={disableReceipt()}
              />
            </InputColumn>
            <InputColumn label="Valor recebido">
              <InputMoney
                name="bill_status.receipt_value"
                disabled={disableReceipt()}
              />
            </InputColumn>
            <InputCheckbox
              label="Recebimento parcial"
              name="bill_status.partial_receipt"
              checked={values.bill_status.partial_receipt}
              onClick={() =>
                setFieldValue(
                  "bill_status.partial_receipt",
                  !values.bill_status.partial_receipt,
                )
              }
            />
          </Col>
        </Row>

        <HeaderSectionTitle title="Observação" />
        <Field component={InputTextArea} name="notes" />
      </>
    );
  };

  return (
    <>
      {contractInformation()}
      <hr />
      <Row>
        <Col sm={7}>{invoiceInformation()}</Col>
        {requestType === "put" && values.id && (
          <Col sm={3}>
            <Report billId={values.id} contractId={contract.id!} />
          </Col>
        )}
      </Row>
      {invoiceActions()}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => toggle("1")}
          >
            Serviços
          </NavLink>
        </NavItem>
        {requestType === "put" && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => toggle("2")}
            >
              Acompanhar recebimento
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab} className="mt-2">
        <TabPane tabId="1">
          {invoiceServices()}

          <Row
            className="mb-1"
            style={{
              borderTop: "1px solid #ccc",
              paddingTop: "12px",
              marginTop: "12px",
              paddingBottom: "12px",
            }}
          >
            <Col sm={10} style={{ marginRight: "56px" }}>
              Imposto (%)
            </Col>
            <Col className="align-self-center" style={{ marginRight: "42px" }}>
              <Field
                name={`taxes`}
                component={InputDecimal}
                onChange={(v: string) => setFieldValue("taxes", v)}
              />
            </Col>
          </Row>
          {resume()}
        </TabPane>
        {requestType === "put" && <TabPane tabId="2">{invoiceTrack()}</TabPane>}
      </TabContent>
    </>
  );
};

export default Form;
