import { formatSimpleDateHour } from "../../../utils/format";

const columns = [
  {
    title: "#",
    data: "full_name"
  },
  {
    title: "Email",
    data: "email"
  },
  {
    title: "Último acesso",
    data: "last_login",
    render(data: string) {
      return formatSimpleDateHour(data);
    }
  },
  {
    title: "Acesso",
    data: "is_client",
    render(data: boolean) {
      if (!data) {
        return '<span class="badge badge-warning">Interno</span>';
      }
      return '<span class="badge badge-info">Cliente</span>';
    }
  },
  {
    title: "Status",
    data: "is_active",
    render(data: boolean) {
      if (!data) {
        return '<span class="badge badge-secondary">Inativo</span>';
      }
      return '<span class="badge badge-success">Ativo</span>';
    }
  },
  {
    title: "Cadastro",
    data: "is_confirmed",
    render(data: boolean) {
      if (!data) {
        return '<span class="badge badge-secondary">Aguardando</span>';
      }
      return '<span class="badge badge-success">Confirmado</span>';
    }
  }
];

export default columns;
