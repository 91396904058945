import React from "react";
import { HeaderTitle } from "src/components/Headers";
import { TextMuted } from "src/components/Text";

interface Props {
  item?: WorkFormValues;
}

const Detail: React.FC<Props> = ({ item }) => {
  return (
    <>
      {item !== undefined ? (
        <>
          <HeaderTitle title={item.name} />
          <dl className="row">
            <dt className="col-sm-5 text-right">Cliente:</dt>
            <dd className="col-sm-7">{item.client?.name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-5 text-right">Nome:</dt>
            <dd className="col-sm-7">{item.name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-5 text-right">Registro:</dt>
            <dd className="col-sm-7">{item.register_code}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-5 text-right">Endereço:</dt>
            <dd className="col-sm-7">{item.address}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-5 text-right">Contato:</dt>
            <dd className="col-sm-7">{item.contact_name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-5 text-right">Telefone um:</dt>
            <dd className="col-sm-7">{item.contact_phone_one}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-5 text-right">Telefone dois:</dt>
            <dd className="col-sm-7">{item.contact_phone_one}</dd>
          </dl>
        </>
      ) : (
        <div className="text-center">
          <TextMuted>Uma obra deve ser selecionada</TextMuted>
        </div>
      )}
    </>
  );
};

export default Detail;
