import React from "react";
import { Row, Col, Label } from "reactstrap";
import { Field } from "formik";
import { InputCellColumn, InputXlsxCell } from "src/components/Inputs";
import { SelectXlsxVariables } from "src/components/Selects";

interface Props {}

const TableContent: React.FC<Props> = () => {
  return (
    <Row className="bg-light pb-1 pt-1">
      <Col sm={1}>
        <Label className="mb-0">Coluna</Label>
      </Col>
      <Col>
        <Label className="mb-0">Dado</Label>
      </Col>
    </Row>
  );
};

interface TableRowProps {
  name: string;
  type: "cell" | "column";
  group?: "I" | "C" | "S";
  loop?: boolean;
  remove: () => void;
}

let TableRow: React.FC<TableRowProps> = ({
  name,
  type,
  group,
  loop,
  remove
}) => {
  return (
    <Row>
      <Col sm={1} className="pl-2 p-1">
        {type === "column" ? (
          <InputCellColumn name={`${name}.cell`} required />
        ) : (
          <InputXlsxCell name={`${name}.cell`} required />
        )}
      </Col>
      <Col className="p-1">
        <Field
          component={SelectXlsxVariables}
          name={`${name}.data`}
          isClearable={false}
          multiple={loop}
          group={group}
        />
      </Col>
      <Col sm="auto" className="pr-2 p-1">
        <button
          type="button"
          className="btn btn-sm btn-white border-0 waves-effect"
          onClick={remove}
        >
          <i className="mdi mdi-delete"></i>
        </button>
      </Col>
    </Row>
  );
};

TableRow.defaultProps = {
  loop: false
};

TableRow = React.memo(
  TableRow,
  (prevProps, nextProps) => prevProps.group === nextProps.group
);

export { TableContent, TableRow };
