import React from "react";
import Select from "react-select";

class SelectMultipleClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: null, items: [] };
  }

  handleChange(selectedOption) {
    this.setState({ selectedOption });
  }

  componentDidMount() {
    this.getClients();
  }

  getClients() {
    this.props.request().then(({ data }) => {
      this.setState({ items: data });
    });
  }

  options() {
    return this.state.items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({ value: item.id, label: item.name }));
  }

  render() {
    return (
      <Select
        options={this.options()}
        isMulti
        styles={{
          container: () => ({
            width: "100%",
            padding: "0"
          })
        }}
        onChange={this.props.onChange}
      />
    );
  }
}

export default SelectMultipleClient;
