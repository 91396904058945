import React, { ReactNode } from "react";
import Loader from "./Loader";

interface Props {
  loading: boolean;
  children: ReactNode;
}

const LoadingComponent: React.FC<Props> = ({ loading, children }) => (
  <>{loading ? <Loader /> : <>{children}</>}</>
);

export default LoadingComponent;
