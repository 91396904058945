import React, { useState, ReactNode } from "react";
import { Button, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "../Modal";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  disabled: boolean;
  className?: string;
  isSubmitting?: boolean;
  onClick?: () => Promise<any>;
  children?: ReactNode;
}

const ButtonDelete: React.FC<Props> = ({
  disabled,
  className,
  isSubmitting,
  children,
  onClick
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggle = () => setShowModal(!showModal);

  return (
    <>
      <Button
        color="danger"
        type="button"
        size="sm"
        outline
        disabled={disabled}
        onClick={toggle}
        className="border-0"
      >
        {children || "Remover"}
        {isSubmitting && <i className="mdi mdi-spin mdi-loading ml-1" />}
      </Button>
      <ToastContainer />
      <Modal
        isOpen={showModal}
        toggle={toggle}
        className={className}
        size="sm"
        title="Excluir"
      >
        <p> Realmente deseja excluir este item?</p>
        <Row>
          <Col>
            <div className="button-list">
              <Button
                size="sm"
                color="danger"
                onClick={() => {
                  if (onClick !== undefined) {
                    onClick()
                      .then(() => {
                        toast.success("Excluido com sucesso.");
                      })
                      .catch(() => {
                        toast.error(
                          "Não foi possível excluir. Este item está em uso."
                        );
                      });
                  }
                  toggle();
                }}
              >
                Sim
              </Button>
              <Button
                size="sm"
                color="light"
                onClick={() => {
                  toggle();
                }}
              >
                Não
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

ButtonDelete.defaultProps = {
  isSubmitting: false,
  className: ""
};

export default ButtonDelete;
