import React from "react";
import { InputSelect } from "../Inputs";

type Option = { value: number; label: number };

const SelectYear: React.FC<SelectType<any>> = props => {
  const options = (): Option[] => {
    const date = new Date();
    const options: Option[] = [];
    for (let i = 2018; i <= date.getFullYear() + 1; i++) {
      options.push({ value: i, label: i });
    }

    return options;
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Ano..."
      onClick={props.onChange}
    />
  );
};

export default SelectYear;
