import React from "react";
import { Scatter } from "react-chartjs-2";
import { isNullOrUndefined } from "util";
import { useBGS } from "./context";

const options = {
  type: "line",
  showLines: true,
  spanGaps: true,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 60,
          stepSize: 10
        },
        scaleLabel: {
          display: true,
          labelString: "PASSANTE (%)"
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          callback: function (value: number) {
            var remain = value / Math.pow(10, Math.floor(Math.log10(value)));
            if (remain === 1 || remain === 2) return value;
            return "";
          }
        },
        scaleLabel: {
          display: true,
          labelString: "PENEIRA (mm)"
        },
        display: true,
        type: "logarithmic"
      }
    ]
  },
  legend: {
    display: false,
    position: "bottom",
    usePointStyle: true
  },
  layout: {
    padding: {
      bottom: 30,
      top: 30,
      left: 20
    }
  },
  tooltips: {
    mode: "index"
  }
};

const Chart = ({ bands }: any) => {
  const [bgs] = useBGS();

  const getDataSet = () => {
    let dataset: { [key: string]: any } = [];

    let data = bgs.percentages.map(item => {
      return {
        x: item.gap,
        y: item.percentage * 100
      };
    });

    let min = bands.map((item: any) => {
      return {
        x: item.gap,
        y: !isNullOrUndefined(item.percentage[0])
          ? item.percentage[0] * 100
          : null
      };
    });

    let max = bands.map((item: any) => {
      return {
        x: item.gap,
        y: !isNullOrUndefined(item.percentage[1])
          ? item.percentage[1] * 100
          : null
      };
    });

    let toleranceMin = bands.map((item: any) => {
      let projected =
        bgs.percentages.find(c => c.gap === item.gap)?.projected || 0;
      return {
        x: item.gap,
        y: !isNullOrUndefined(item.percentage[0])
          ? Math.max(projected - item.percentage[2] * 100, 0)
          : null
      };
    });

    let toleranceMax = bands.map((item: any) => {
      let projected =
        bgs.percentages.find(c => c.gap === item.gap)?.projected || 0;

      return {
        x: item.gap,
        y: !isNullOrUndefined(item.percentage[1])
          ? Math.min(projected + item.percentage[3] * 100, 100)
          : null
      };
    });

    dataset.push({
      label: `Cuva BGS`,
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "gray",
      borderColor: "gray",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: data
    });

    dataset.push({
      label: `Min`,
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "brown",
      borderColor: "brown",
      borderCapStyle: "butt",
      borderDash: [5, 15],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: min,
      spanGaps: true
    });

    dataset.push({
      label: `Tolerância mínima`,
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "coral",
      borderColor: "coral",
      borderCapStyle: "butt",
      borderDash: [5, 15],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: toleranceMin,
      spanGaps: true
    });

    dataset.push({
      label: `Máx`,
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "brown",
      borderColor: "brown",
      borderCapStyle: "butt",
      borderDash: [5, 15],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: max
    });

    dataset.push({
      label: `Tolerância máxima`,
      showLine: true,
      fill: false,
      lineTension: 0,
      backgroundColor: "coral",
      borderColor: "coral",
      borderCapStyle: "butt",
      borderDash: [5, 15],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "coral",
      pointBackgroundColor: "white",
      pointBorderWidth: 2,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: "coral",
      pointHoverBorderColor: "coral",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      pointStyle: "circle",
      data: toleranceMax,
      spanGaps: true
    });

    return dataset;
  };

  return (
    <div className="canvas-size-bgs">
      <Scatter
        data={{
          datasets: getDataSet()
        }}
        options={options}
      />
    </div>
  );
};

export default Chart;
