// @ts-ignore
import everpolate from "everpolate";

const humidity = (waterWeight: number, soilWeight: number) => {
  return waterWeight / soilWeight || 0;
};

const waterWeight = (wetSoilWeight: number, drySoilWeight: number): number => {
  return wetSoilWeight - drySoilWeight;
};

const soilWeight = (drySoilWeight: number, capsuleWeight: number): number => {
  return drySoilWeight - capsuleWeight || 0;
};

const avgHumidity = (humidities: number[]) => {
  return humidities.reduce((a, b) => a + b, 0) / humidities.length;
};

const withheldWeightN10 = (strainers: any) => {
  let withheldWeightN10 = 0;
  strainers.forEach((strainer: any) => {
    if (strainer.gap >= 2) {
      withheldWeightN10 += strainer.withheld_weight || 0;
    }
  });

  return withheldWeightN10;
};

const wetWeightN10 = (totalWetWeight: number, withheldWeightN10: number) => {
  return totalWetWeight - withheldWeightN10;
};

const dryWeightN10 = (_avgHumidity: number, wetWeightN10: number) => {
  return (wetWeightN10 / (_avgHumidity * 100 + 100)) * 100;
};

const totalDryWeight = (withheldWeightN10: number, dryWeightN10: number) => {
  return withheldWeightN10 + dryWeightN10;
};

const partialDryWeight = (partialGrossWeight: number, avgHumidity: number) => {
  return (partialGrossWeight / (avgHumidity * 100 + 100)) * 100;
};

const accumulatedWeight = (
  strainers: TStrainer[],
  totalDryWeight: number,
  partialDryWeight: number
) => {
  let percentage = 0;
  let K1 = 0;

  if (totalDryWeight !== 0) {
    K1 = 1 / totalDryWeight;
  }

  strainers.forEach((strainer, index) => {
    let currentAccumulated = 0;

    if (strainer.gap === 50.8) {
      currentAccumulated = totalDryWeight;
    } else if (strainer.gap >= 2) {
      currentAccumulated = strainers[index - 1].accumulated;
    } else if (strainer.gap === 1.2) {
      currentAccumulated = partialDryWeight;
    } else {
      currentAccumulated = strainers[index - 1].accumulated;
    }

    currentAccumulated -= strainer.withheld_weight;

    strainer.accumulated = currentAccumulated;

    if (strainer.gap > 1.2) {
      percentage = strainer.accumulated * K1;
    } else {
      let K2 = 0;

      if (partialDryWeight !== 0) {
        K2 = strainers[6].percentage / partialDryWeight;
        percentage = strainer.accumulated * K2;
      }
    }
    strainer.percentage = percentage;
  });

  return strainers;
};

const percentagePassing = async (
  strainers: TStrainer[],
  totalDryWeight: number,
  partialDryWeight: number
) => {
  let percentage = 0;
  let K1 = 0;

  if (totalDryWeight !== 0) {
    K1 = 1 / totalDryWeight;
  }

  strainers.forEach(item => {
    if (item.gap > 1.2) {
      percentage = item.accumulated * K1;
    } else {
      let K2 = 0;

      if (partialDryWeight !== 0) {
        K2 = strainers[6].percentage / partialDryWeight;
        percentage = item.accumulated * K2;
      }
    }
    item.percentage = percentage;
  });

  return strainers;
};

const getBgsPercentageWithHeld = (strainers: any, total: number) => {
  return strainers.map((item: any) => {
    return {
      ...item.strainer,
      withheld: item.strainer.withheld_weight / total || 0
    };
  });
};

const getBgsAccruedPercentage = (strainers: any, total: number) => {
  const withheld = getBgsPercentageWithHeld(strainers, total);
  return strainers.map((_: any, index: number) => {
    let accrued = 0;
    for (let i = 0; i <= index; i++) {
      accrued += withheld[i].withheld;
    }

    return {
      ...withheld[index],
      accrued: accrued,
      percentage: 1 - accrued
    };
  });
};

const getBgsPercentagePassing = (strainers: any, total: number) => {
  const accrued = getBgsAccruedPercentage(strainers, total);
  return accrued.map((item: any) => {
    return {
      ...item,
      percentage: 1 - item.accrued
    };
  });
};

const wetBulkDensity = (weightDrySoil: number, cylinderVolume: number) => {
  return weightDrySoil / cylinderVolume || 0;
};

const dryBulkDensity = (wetDensity: number, humidity: number) => {
  return wetDensity / (1 + humidity) || 0;
};

const greatHumidity = (
  humidityMin: number,
  humidityMax: number,
  funcPoly: (x: number) => number
) => {
  const delta = 0.01;
  let xMin = humidityMin;
  let xMax = humidityMax;
  let xa = 0;
  let xb = 0;

  while (Math.abs(xMin - xMax) > delta) {
    xa = xMax - 0.618 * (xMax - xMin);
    xb = xMin + 0.618 * (xMax - xMin);

    if (funcPoly(xa) > funcPoly(xb)) {
      xMax = xb;
      xb = xMin + 0.618 * (xMax - xMin);
    } else {
      xMin = xa;
      xa = xMax - 0.618 * (xMax - xMin);
    }
  }

  return Math.fround((xa + xb) / 2);
};

const maxDensity = (
  greatHumidity: number,
  humidities: number[],
  densities: number[]
) => {
  return everpolate.polynomial(greatHumidity, humidities, densities);
};

const readDetermined = (read: number, cbrConst: number) => {
  return read * cbrConst;
};

const penetrationPercentage = (time: number, readDetermined: number) => {
  const percentage =
    time === 120
      ? readDetermined / 70
      : time === 240
      ? readDetermined / 105
      : 0;

  return percentage || 0;
};

const expansionDifference = (read: number) => {
  return Math.abs(1 - read);
};

const expansion = (difference: number, height: number) => {
  const expansion = difference / (height || 1);

  return expansion;
};

export {
  humidity,
  waterWeight,
  soilWeight,
  avgHumidity,
  withheldWeightN10,
  wetWeightN10,
  dryWeightN10,
  totalDryWeight,
  partialDryWeight,
  accumulatedWeight,
  percentagePassing,
  getBgsAccruedPercentage,
  getBgsPercentagePassing,
  getBgsPercentageWithHeld,
  wetBulkDensity,
  dryBulkDensity,
  greatHumidity,
  maxDensity,
  readDetermined,
  penetrationPercentage,
  expansion,
  expansionDifference
};
