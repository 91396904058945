import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
// @ts-ignore
import everpolate from "everpolate";
import * as math from "../../../../../utils/math";
import * as api from "../../../../../services/api/characterization";

interface State {
  hygroscopicHumidities: number[];
  humidities: number[];
  avgHumidity: number;
  dryDensities: number[];
  greatHumidity: number;
  maxDensity: number;
  loading: boolean;
}

const initialState: State = {
  hygroscopicHumidities: [0, 0],
  humidities: [0, 0, 0, 0, 0],
  avgHumidity: 0,
  dryDensities: [0, 0, 0, 0, 0],
  loading: false,
  greatHumidity: 0,
  maxDensity: 0
};

const actions = {
  getCompactionTest: (sampleId: number) => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (getState().loading) {
      return;
    }

    setState({
      loading: true
    });

    return api
      .getCompaction(sampleId)
      .then(({ data }: { data: Compaction[] }) => {
        if (data.length > 0) {
          let test = data[0];

          test = {
            ...test,
            hygroscopic_capsules: test.capsules.filter(
              item => item.is_hygroscopic
            ),
            capsules: test.capsules.filter(item => !item.is_hygroscopic)
          };

          return test;
        }

        return undefined;
      })
      .finally(() => setState({ loading: false }));
  },
  setHygroscopicHumidity: (value: number, index: number) => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    const { hygroscopicHumidities } = getState();
    const temp = hygroscopicHumidities;
    temp[index] = value;

    setState({
      hygroscopicHumidities: temp,
      avgHumidity: math.avgHumidity(temp)
    });
  },

  setHumidity: (value: number, index: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { humidities } = getState();
    const temp = humidities;
    temp[index] = value;

    setState({
      humidities: temp
    });

    dispatch(actions.setMaxPoint());
  },

  setDensities: (value: number, index: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { dryDensities } = getState();
    const temp = dryDensities;
    temp[index] = value;

    setState({
      dryDensities: temp
    });

    dispatch(actions.setMaxPoint());
  },

  setMaxPoint: () => ({ setState, getState }: StoreActionApi<State>) => {
    const humidityMin = getState().humidities.reduce(
      (a, b) => Math.min(a, b),
      999
    );
    const humidityMax = getState().humidities.reduce(
      (a, b) => Math.max(a, b),
      0
    );
    const greatHumidity = math.greatHumidity(humidityMin, humidityMax, x => {
      return (
        everpolate.polynomial(
          x,
          getState().humidities,
          getState().dryDensities
        )[0] || 0
      );
    });

    setState({
      greatHumidity,
      maxDensity: math.maxDensity(
        greatHumidity,
        getState().humidities,
        getState().dryDensities
      )
    });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "compaction"
});

const CompactionSubscriber = createSubscriber(Store);
const useCompaction = createHook(Store);
const useCompactionActions = createHook(Store, {
  selector: null
});
const useGreatHumidity = createHook(Store, {
  selector: (state: State) => state.greatHumidity
});
const useHumidities = createHook(Store, {
  selector: (state: State) => state.humidities
});
const useLoading = createHook(Store, {
  selector: (state: State) => state.loading
});

export {
  CompactionSubscriber,
  useCompaction,
  useCompactionActions,
  useGreatHumidity,
  useHumidities,
  useLoading
};
