import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import SelectClient from "./SelectClient";
import SelectContract from "./SelectContract";

interface Props {
  clientRequest?: any;
  contractRequest: (clientId: number) => Promise<any>;
  onContractChange: (contractId?: number) => void;
}

const ContractFilterBar: React.FC<Props> = ({
  clientRequest,
  contractRequest,
  onContractChange
}) => {
  const [clientId, setClientId] = useState<number>();

  const onChangeClient = (value: number) => {
    onContractChange(undefined);
    setClientId(value);
  };

  return (
    <Row>
      <Col sm={6}>
        <SelectClient request={clientRequest} onChange={onChangeClient} />
      </Col>
      <Col sm={6}>
        <SelectContract
          request={contractRequest}
          client={clientId}
          onChange={onContractChange}
        />
      </Col>
    </Row>
  );
};

export default ContractFilterBar;
