/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  useConcreteRoutes,
  useInternalManagerRoutes,
  useSoilRoutes,
  useAdministrativeRoutes,
  useTechSettingsRoutes,
  useClientOptions
} from "./menuRoutes";
import { useUser } from "../../context/admin/user";
import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import { logout } from "../../services/api/auth";

interface SubmenuProps {
  submenu: { href: string; name: string; show?: boolean }[];
}

const Submenu: React.FC<SubmenuProps> = ({ submenu }) => {
  const match = useRouteMatch();

  return (
    <>
      {submenu.map((item, index) => (
        <>
          {item.show ? (
            <li key={index}>
              <Link to={`${match.url}/${item.href}`}>{item.name}</Link>
            </li>
          ) : (
            <div />
          )}
        </>
      ))}
    </>
  );
};

const MenuBar = () => {
  const [user] = useUser();
  const history = useHistory();
  const match = useRouteMatch();
  const { appSlug } = useParams<{ appSlug: string }>();
  const [showConcreteSection, concreteRoutes] = useConcreteRoutes();
  const [showSoilSection, soilRoutes] = useSoilRoutes();
  const [
    showManagerSection,
    internalManagerRoutes
  ] = useInternalManagerRoutes();
  const [showTechSettingsRoute, techSettingsRoutes] = useTechSettingsRoutes();
  const [
    showAdministrativeRoutes,
    administrativeRoutes
  ] = useAdministrativeRoutes();
  const [showReportAccess] = useClientOptions();

  async function handleLogout() {
    logout().then(() => {
      history.push("/login");
    });
  }

  const renderCompanyLogo = () => {
    const app = user.applications.find(item => item?.slug === appSlug);
    if (app?.company_logo) {
      return (
        <img
          src={app.company_logo}
          style={{ width: "auto", height: "30px" }}
          alt=""
          height="40"
        />
      );
    }

    return null;
  };

  return (
    <header id="topnav">
      <div className="navbar-custom">
        <div className="container-fluid">
          <ul className="list-unstyled topnav-menu float-right mb-0">
            <li className="dropdown notification-list">
              <a className="navbar-toggle nav-link">
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>

              <a
                className="nav-link dropdown-toggle nav-user mr-0"
                data-toggle="dropdown"
                href=""
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                {renderCompanyLogo()}
                <small className="pro-user-name ml-1">
                  {`${user.data?.first_name} ${user.data?.last_name}`}
                  <i className="mdi mdi-chevron-down"></i>
                </small>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown ">
                <Link
                  className="dropdown-item notify-item"
                  to={`${match.url}/settings/`}
                >
                  <i className="fe-user"></i>
                  <span>Minha conta</span>
                </Link>

                <div className="dropdown-divider" />
                <a className="dropdown-item notify-item" onClick={handleLogout}>
                  <i className="fe-log-out"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>

          <div className="logo-box">
            <a href="/" className="logo text-center">
              <span className="logo-lg">
                <img
                  src={
                    process.env.PUBLIC_URL + "/static/assets/images/logo.svg"
                  }
                  alt=""
                  height="40"
                />
              </span>
              <span className="logo-sm">
                <img
                  src={
                    process.env.PUBLIC_URL + "/static/assets/images/logo.svg"
                  }
                  alt=""
                  height="32"
                />
              </span>
            </a>
          </div>

          <div id="navigation">
            <ul className="navigation-menu">
              {showAdministrativeRoutes && (
                <li className="has-submenu">
                  <a href="#">
                    Administrativo<i className="mdi mdi-chevron-down"></i>
                  </a>
                  <ul className="submenu">
                    <Submenu submenu={administrativeRoutes} />
                  </ul>
                </li>
              )}

              <li className="has-submenu">
                <a href="#">
                  Controle Tecnológico<i className="mdi mdi-chevron-down"></i>
                </a>
                <ul className="submenu">
                  {showSoilSection && (
                    <li className="has-submenu">
                      <a href="#">
                        Solos <div className="arrow-down"></div>
                      </a>
                      <ul className="submenu">
                        <Submenu submenu={soilRoutes} />
                      </ul>
                    </li>
                  )}
                  {showConcreteSection && (
                    <li className="has-submenu">
                      <a href="#">
                        Concreto <div className="arrow-down"></div>
                      </a>
                      <ul className="submenu">
                        <Submenu submenu={concreteRoutes} />
                      </ul>
                    </li>
                  )}
                  {showTechSettingsRoute && (
                    <Submenu submenu={techSettingsRoutes} />
                  )}
                </ul>
              </li>

              {showManagerSection && (
                <li className="has-submenu">
                  <a href="#">
                    Gestão Interna<i className="mdi mdi-chevron-down"></i>
                  </a>
                  <ul className="submenu">
                    <Submenu submenu={internalManagerRoutes} />
                  </ul>
                </li>
              )}

              {showReportAccess && (
                <li className="has-submenu">
                  {/* <a href="/client_side">Laudos e relatórios</a> */}
                  <Link to={`${match.url}/client_side`}>
                    Laudos e relatórios
                  </Link>
                </li>
              )}
            </ul>

            <div className="float-right mt-3">
              <span className="logo-lg">
                <img src="" alt="" height="10" />
              </span>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MenuBar;
