import React from "react";
import { InputSelect } from "../Inputs";

class SelectServiceControl extends React.Component {
  options() {
    return [
      { value: "N", label: "Controle unitário" },
      { value: "DC", label: "Controle de diário de funcionário" },
      { value: "MC", label: "Controle mensal de funcionário" },
      { value: "SSC", label: "Controle de amostras de solos" },
      { value: "CC", label: "Controle de corpos de prova de concreto" },
      {
        value: "BCC",
        label: "Controle de corpos de prova de bloco de concreto"
      },
      { value: "BCEC", label: "Controle de corpos de prova de bloco ceramico" },
      { value: "FC", label: "Controle de corpos de prova de pisos" }
    ];
  }

  render() {
    return (
      <InputSelect
        {...this.props}
        options={this.options()}
        placeholder="Tipo de controle"
      />
    );
  }
}

export default SelectServiceControl;
