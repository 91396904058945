import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Field, FormikActions } from "formik";
import { HeaderTitle } from "../../../../../components/Headers";
import {
  InputColumn,
  InputInteger,
  InputDate,
  InputText,
  InputTextArea,
  InputTime
} from "../../../../../components/Inputs";
import { SelectConcreteType } from "../../../../../components/Selects";
import { MixPost, MixPut } from "./Mix";
import { ModalForm } from "../../../../../components/Modal";
import {
  getLastSeries,
  postSeries,
  getSeries,
  putSeries
} from "../../../../../services/api/concrete";
import { initialMixtures, initialSeries } from "./init";
import { LoadingComponent } from "../../../../../components/Loader";
import {
  CreateTestAccessPermission,
  EditTestAccessPermission
} from "../../../../../components/Permissions";
import Alert from "../../../../../components/Alerts";
import { SmallText } from "src/components/Text";

interface FormPutProps extends FormikProps<TConcreteSeries> {
  control: TConcreteControl;
}

const FormPut: React.FC<FormPutProps> = ({
  control,
  values,
  setFieldValue
}) => {
  const renderMix = () => {
    return (
      <MixPut
        values={values.concrete_mix[0]}
        control={control}
        setFieldValue={setFieldValue}
        name="concrete_mix[0]"
      />
    );
  };

  return (
    <Row>
      <Col sm={3} className="border-right">
        <Row>
          <Col sm={12}>
            <InputColumn label="Número">
              <Field name="number" component={InputInteger} required />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Data de chegada">
              <Field name="lab_arrival" component={InputDate} required />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Hora de chegada">
              <Field
                name="lab_arrival_time"
                component={InputTime}
                onChange={(e: any) => {
                  const value = e.target.value === "" ? null : e.target.value;
                  setFieldValue("lab_arrival_time", value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Local">
              <Field name="place" component={InputText} required />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Detalhe do local">
              <Field name="optional_place" component={InputText} />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Traço">
              <Field name="trace" component={InputText} />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Tipo do cimento">
              <Field name="concrete_type" component={SelectConcreteType} />
            </InputColumn>
          </Col>
        </Row>
      </Col>
      <Col sm={3} className="border-right">
        <Row>
          <Col sm={12}>
            <InputColumn label="Início da concretagem">
              <Field
                name="concreting_start_at"
                component={InputTime}
                onChange={(e: any) => {
                  const value = e.target.value === "" ? null : e.target.value;
                  setFieldValue("concreting_start_at", value);
                }}
              />
            </InputColumn>
            <InputColumn label="Fim da concretagem">
              <Field
                name="concreting_end_at"
                component={InputTime}
                onChange={(e: any) => {
                  const value = e.target.value === "" ? null : e.target.value;
                  setFieldValue("concreting_end_at", value);
                }}
              />
            </InputColumn>
          </Col>
          <Col sm={12}>
            <InputColumn label="Comentários técnicos">
              <Field name="technical_commets" component={InputTextArea} />
              <SmallText>
                Esta observação aparecerá no relatório de compresão
              </SmallText>
            </InputColumn>
          </Col>
        </Row>
      </Col>
      <Col sm={6}>{renderMix()}</Col>
    </Row>
  );
};

interface PutSeriesProps {
  item?: TConcreteSeries;
  control: TConcreteControl;
  onUpdated: (item: TConcreteSeries) => void;
}

const PutSeries: React.FC<PutSeriesProps> = ({ control, item, onUpdated }) => {
  const onSubmit = (values: TConcreteSeries) => {
    if (values.id !== undefined) {
      return putSeries(values.id, values).then(({ data }) => onUpdated(data));
    }

    return Promise.reject();
  };

  return (
    <EditTestAccessPermission>
      <ModalForm
        type="put"
        size="xl"
        title="Editar série"
        data={item}
        getItemRequest={(id: number) => getSeries(id)}
        form={props => <FormPut {...props} control={control!} />}
        initialValues={data => data}
        onSubmit={onSubmit}
      />
    </EditTestAccessPermission>
  );
};

interface FormPostProps extends FormikProps<TConcreteSeries> {
  control: TConcreteControl;
}

const FormPost: React.FC<FormPostProps> = ({
  control,
  values,
  errors,
  setFieldValue
}) => {
  const renderSeriesData = () => {
    return (
      <Row>
        <Col sm={12}>
          {errors.cp_code && <Alert color="danger" message={errors.cp_code} />}
        </Col>
        <Col sm={12}>
          <HeaderTitle title="Dados da série" />
        </Col>
        {!control.auto_serie_number && (
          <Col sm={4}>
            <InputColumn label="Número da série">
              <Field name="number" component={InputInteger} required />
            </InputColumn>
          </Col>
        )}
        <Col sm={4}>
          <InputColumn label="Local de referência">
            <Field name="place" component={InputText} required />
          </InputColumn>
        </Col>
        <Col sm={4}>
          <InputColumn label="Complemento">
            <Field name="optional_place" component={InputText} required />
          </InputColumn>
        </Col>
        <Col sm={2}>
          <InputColumn label="Data de chegada">
            <Field name="lab_arrival" component={InputDate} required />
          </InputColumn>
        </Col>
        <Col sm={2}>
          <InputColumn label="Hora de chegada">
            <Field
              name="lab_arrival_time"
              component={InputTime}
              onChange={(e: any) => {
                const value = e.target.value === "" ? null : e.target.value;
                setFieldValue("lab_arrival_time", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col sm={4}>
          <InputColumn label="Traço">
            <Field name="trace" component={InputText} />
          </InputColumn>
        </Col>
        <Col sm={4}>
          <InputColumn label="Tipo do cimento">
            <Field
              name="concrete_type"
              component={SelectConcreteType}
              required={control.use_rules_growth}
            />
          </InputColumn>
        </Col>
        <Col sm={2}>
          <InputColumn label="Início da concretagem">
            <Field
              name="concreting_start_at"
              component={InputTime}
              onChange={(e: any) => {
                const value = e.target.value === "" ? null : e.target.value;
                setFieldValue("concreting_start_at", value);
              }}
            />
          </InputColumn>
        </Col>
        <Col sm={2}>
          <InputColumn label="Fim da concretagem">
            <Field
              name="concreting_end_at"
              component={InputTime}
              onChange={(e: any) => {
                const value = e.target.value === "" ? null : e.target.value;
                setFieldValue("concreting_end_at", value);
              }}
            />
          </InputColumn>
        </Col>
      </Row>
    );
  };

  const renderMix = () => {
    return (
      <MixPost
        control={control}
        values={values.concrete_mix[0]}
        name={`concrete_mix[${0}]`}
        setFieldValue={setFieldValue}
      />
    );
  };

  return (
    <>
      <Row className="mb-2 pb-2 border-bottom">
        <Col>{renderSeriesData()}</Col>
      </Row>
      <Row>
        <Col>{renderMix()}</Col>
      </Row>
    </>
  );
};

interface PostSeriesProps {
  control: TConcreteControl;
  onCreated: (item: TConcreteSeries) => void;
}

const PostSeries: React.FC<PostSeriesProps> = ({ control, onCreated }) => {
  const [initialData, setInitialData] = useState<TConcreteSeries>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestInitialData = () => {
    if (control.id !== undefined) {
      setLoading(true);
      // Request last series
      getLastSeries(control.id).then(lastSeries => {
        if (Object.keys(lastSeries).length > 0) {
          // Map last series mixtures to new series
          var mixtures = lastSeries.concrete_mix.map((mix: TConcreteMix) => {
            const car = initialMixtures;
            if (car.stress_test !== undefined) {
              car.provider = mix.provider;
              car.stress_test.fck = mix.stress_test?.fck || 0;
              car.stress_test.molding_date =
                mix.stress_test?.molding_date || "";
              car.stress_test.concrete_cp = (
                mix.stress_test?.concrete_cp || []
              ).map(cp => {
                return { age: cp.age, code: undefined };
              });
            }

            return car;
          });

          setInitialData({
            ...initialSeries,
            lab_arrival: lastSeries.lab_arrival || "",
            control: control.id!,
            concrete_mix: mixtures
          });
        } else {
          let mixtures: TConcreteMix[] = [];
          for (let i = 0; i < control.mix_amount; i++) {
            const car: TConcreteMix = initialMixtures;

            if (car.stress_test !== undefined) {
              car.stress_test.concrete_cp = [];
              control.concrete_age.forEach(cp => {
                for (let j = 0; j < cp.quantity; j++) {
                  car.stress_test?.concrete_cp.push({
                    age: cp.age,
                    code: undefined
                  });
                }
              });
            }

            mixtures.push(car);
          }

          setInitialData({
            ...initialSeries,
            control: control.id!,
            concrete_mix: mixtures
          });
        }

        setLoading(false);
      });
    }
  };

  const onOpen = () => {
    requestInitialData();
  };

  const onPostSeries = (
    values: TConcreteSeries,
    { setFieldError }: FormikActions<object>
  ) => {
    if (control.id !== undefined) {
      return postSeries(control.id, values)
        .then(({ data }) => onCreated(data))
        .catch(err => {
          Object.keys(err.data.message).forEach(key => {
            setFieldError(key, err.data.message[key]);
          });

          return Promise.reject(err);
        });
    }

    return Promise.reject();
  };

  return (
    <CreateTestAccessPermission>
      <ModalForm
        type="post"
        size="xl"
        title="Cadastro de nova série"
        data={initialData || {}}
        form={props => {
          return (
            <LoadingComponent loading={loading}>
              <FormPost {...props} control={control!} />
            </LoadingComponent>
          );
        }}
        initialValues={() => {
          return initialData || {};
        }}
        onSubmit={onPostSeries}
        onButtonClick={onOpen}
      />
    </CreateTestAccessPermission>
  );
};

export { PostSeries, PutSeries };
