import React from "react";
import AccessPermission, { useCheckPermissions } from "./AccessPermission";

const permissions = ["can_edit_tests"];

interface Props {
  children: React.ComponentType<any> | React.ReactElement | null;
  renderNoAccess?: () => React.ComponentType<any> | React.ReactElement | null;
}

const EditTestAccessPermission: React.FC<Props> = ({
  children,
  renderNoAccess
}) => (
  <AccessPermission
    allowedPermissions={permissions}
    renderNoAccess={renderNoAccess}
  >
    {children}
  </AccessPermission>
);

const useHasEditTestPermission = () => {
  return useCheckPermissions(permissions);
};

export { EditTestAccessPermission, useHasEditTestPermission };
