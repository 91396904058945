/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { InputColumn, InputText } from "../../../components/Inputs";
import { Container } from "src/components/Container";
import { Formik, Form, Field, FormikActions } from "formik";
import { createAccount } from "src/services/api/applications";
import { Redirect } from "react-router-dom";
import { useUser } from "src/context/admin/user";

const Register = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [, userActions] = useUser();

  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    createAccount(values)
      .then(() => {
        userActions.login(values.email, values.password).then(() => {
          setSuccess(true);
        });
      })
      .catch(({ data }) => {
        setFieldError("email", data.error);
      });
  };

  if (success) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const form = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={{
          first_name: undefined,
          last_name: undefined,
          email: undefined,
          password: undefined,
          company: undefined
        }}
        render={({ errors }) => (
          <Form>
            <Row>
              <Col sm={12}>
                <div className="text-center mb-3">
                  <h4>Criar a sua conta</h4>
                </div>
                <InputColumn label={"Nome"}>
                  <Field
                    name="first_name"
                    required
                    component={InputText}
                    placeholder="Nome"
                  />
                </InputColumn>
                <InputColumn label={"Sobrenome"}>
                  <Field
                    name="last_name"
                    required
                    component={InputText}
                    placeholder="Sobrenome"
                  />
                </InputColumn>
                <InputColumn label={"Email"}>
                  <Field
                    name="email"
                    type="email"
                    required
                    component={InputText}
                    placeholder="seu@email.com"
                  />
                </InputColumn>
                <InputColumn label={"Senha"}>
                  <Field
                    name="password"
                    required
                    component={InputText}
                    placeholder="Senha"
                  />
                </InputColumn>
                <InputColumn label={"Nome da empresa"}>
                  <Field
                    name="company"
                    required
                    component={InputText}
                    placeholder="ex. Acme ltda."
                  />
                </InputColumn>
                <small className="text-danger">{errors.email}</small>
                <Button className="mt-2" color="primary" size="sm" block>
                  Registrar
                </Button>
              </Col>
              <Col sm={12}>
                <p className="mb-1 mt-2">
                  Tem uma conta?{" "}
                  <label>
                    <u>
                      <a href="#" className="text-dark">
                        Conecte-se
                      </a>
                    </u>
                  </label>
                </p>
              </Col>
            </Row>
          </Form>
        )}
      />
    );
  };
  return (
    <Container title="Registrar">
      <Row className="justify-content-center">
        <Col sm={4} lg={4}>
          <div className="text-center w-75 pb-4 m-auto">
            <span>
              <img
                src={process.env.PUBLIC_URL + "/static/assets/images/logo.svg"}
                alt=""
                height="40"
              />
            </span>
          </div>
          <Card className="pl-2 pr-2">
            <CardBody>{form()}</CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
