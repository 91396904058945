import React from "react";
import { FormikProps, Field } from "formik";
import { InputColumn, InputText, InputValidation } from "src/components/Inputs";
import { SelectDepartment } from "src/components/Selects";

const Form: React.FC<FormikProps<SubDepartmentFormValues>> = ({ errors }) => {
  return (
    <>
      <InputColumn label="Nome">
        <Field name="name" component={InputText} required={true} />
        <InputValidation message={errors.name} />
      </InputColumn>
      <InputColumn label="Departamento">
        <Field name="department" component={SelectDepartment} required={true} />
        <InputValidation message={errors.department} />
      </InputColumn>
    </>
  );
};

export default Form;
