import React from "react";
import { InputSelect } from "../Inputs";

const SelectDiscarded: React.FC<SelectType<any>> = (props) => {
  const options = () => {
    return [
      { value: "N", label: "Não descartado" },
      { value: "TEMP", label: "Temperatura" },
      { value: "LUB", label: "Lubrificação" },
      { value: "LEAK", label: "Vazamento" },
      { value: "WD", label: "Vento" },
      { value: "OT", label: "Outro" },
    ];
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Motivo..."
      onClick={(value: any) => {
        props.onChange && props.onChange(value || "");
      }}
    />
  );
};

export default SelectDiscarded;
