import React from "react";
import { FormikProps, Field } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import {
  InputRow,
  InputDate,
  InputText,
  InputInteger,
  InputDecimal
} from "src/components/Inputs";
import {
  SelectBlockHolePosition,
  SelectBlockWallStructure
} from "src/components/Selects";
import { HeaderSectionTitle } from "src/components/Headers";

interface Props extends FormikProps<TBlockCeramicLot> {}

const Form: React.FC<Props> = ({ values, setFieldValue }) => {
  return (
    <Row>
      <Col sm={6}>
        <InputRow label="Data de fabricação" label_col={5}>
          <Field name="fabrication_date" component={InputDate} required />
        </InputRow>
        <InputRow label="Data da coleta" label_col={5}>
          <Field
            name="collect_date"
            component={InputDate}
            min={values.fabrication_date}
            required
          />
        </InputRow>
        <InputRow label="Lote" label_col={5}>
          <Field name="lot" component={InputText} required />
        </InputRow>
        <InputRow label="Qtd. de absorção" label_col={5}>
          <Field name="amount_absorption" component={InputInteger} required />
        </InputRow>
        <InputRow label="Posição do furo" label_col={5}>
          <Field name="hole_position" component={SelectBlockHolePosition} />
        </InputRow>
        <InputRow label="Estrutura da parede" label_col={5}>
          <Field name="wall_structure" component={SelectBlockWallStructure} />
        </InputRow>
        <InputRow label="FBK esperado (MPa)" label_col={5}>
          <Field
            name="fbk"
            component={InputDecimal}
            onChange={(value: number) => setFieldValue("fbk", value)}
            required
          />
        </InputRow>
        <InputRow label="Local" label_col={5}>
          <Field name="place" component={InputText} />
        </InputRow>
        <InputRow label="Fornecedor" label_col={5}>
          <Field name="provider" component={InputText} />
        </InputRow>
        <InputRow label="Nota fiscal" label_col={5}>
          <Field name="invoice" component={InputText} />
        </InputRow>
      </Col>
      <Col sm={6}>
        <HeaderSectionTitle title={"Dimensões nominais"} />
        <InputRow label="Largura (mm)" label_col={5}>
          <Field name="width" component={InputInteger} required />
        </InputRow>
        <InputRow label="Altura (mm)" label_col={5}>
          <Field name="height" component={InputInteger} required />
        </InputRow>
        <InputRow label="Comprimento (mm)" label_col={5}>
          <Field name="length" component={InputInteger} required />
        </InputRow>

        {values.id === undefined && (
          <>
            <HeaderSectionTitle title={"Quantidades para ruptura"} />
            <InputRow label="7 Dias" label_col={5}>
              <Field name="d7" component={InputInteger} required />
            </InputRow>
            <InputRow label="14 Dias" label_col={5}>
              <Field name="d14" component={InputInteger} required />
            </InputRow>
            <InputRow label="28 Dias" label_col={5}>
              <Field name="d28" component={InputInteger} required />
            </InputRow>
          </>
        )}

        <HeaderSectionTitle title="Finalidades" />
        <FormGroup>
          <div className="custom-control custom-checkbox">
            <Field
              className="custom-control-input"
              name="validation"
              id="validation"
              type="checkbox"
              checked={values.validation}
            />
            <label className="custom-control-label" htmlFor="validation">
              Ensaio para validação
            </label>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="custom-control custom-checkbox">
            <Field
              className="custom-control-input"
              name="calculate_conformity"
              id="calculate_conformity"
              type="checkbox"
              checked={values.calculate_conformity}
            />
            <label
              className="custom-control-label"
              htmlFor="calculate_conformity"
            >
              Determinar as conformidades
            </label>
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default Form;
