import React from "react";
import { FormikProps, Field } from "formik";
import {
  InputColumn,
  InputText,
  InputColorPicker
} from "src/components/Inputs";
import { SmallText } from "src/components/Text";
import { Row, Col } from "reactstrap";
import { InputSingleImage } from "src/components/Inputs/InputImage";
import * as appApi from "src/services/api/applications";

const Form: React.FC<FormikProps<ApplicationValues>> = ({
  values,
  setFieldValue
}) => {
  const renderApplicationSettings = () => {
    return (
      <>
        <InputColumn label="Nome da aplicação">
          <Field component={InputText} name="name" />
        </InputColumn>
        <InputColumn label="Marca">
          <InputSingleImage
            currentPhoto={values.company_logo}
            fileSent={() => {}}
            sentImage={(
              file: any,
              progress: (
                lengthComputable: number,
                loaded: number,
                total: number
              ) => void
            ) => {
              return appApi
                .sendFiles(values.id, { company_logo: file }, progress)
                .then(res => {
                  return Promise.resolve(res.photo);
                })
                .catch(err => {
                  return Promise.reject(err);
                });
            }}
            deleteImage={() => {
              return appApi
                .sendFiles(values.id, { company_logo: "" })
                .then(() => {
                  setFieldValue("company_logo", null);

                  return Promise.resolve();
                });
            }}
          />
        </InputColumn>
        <InputColumn label="Cor de sua organização">
          <InputColorPicker
            onChange={(value: string) => setFieldValue("color", value)}
            color={values.color}
          />
        </InputColumn>
        <InputColumn label="Nota de rodapé">
          <Field
            name="footnote"
            component={InputText}
            placeholder={"ex. Acme Ltda - (99) 9999-9999 - seu@email.com"}
          />
          <SmallText>
            A nota de rodapé é a última de linha de cada documento gerado,
            normalmente informação de contato da organização
          </SmallText>
        </InputColumn>
        <InputColumn label="Responsável técnico um">
          <Field component={InputText} name="stamp_one" />
        </InputColumn>
        <InputColumn label="Assinatura responsável técnico um">
          <InputSingleImage
            currentPhoto={values.sign_one}
            fileSent={() => {}}
            sentImage={(
              file: any,
              progress: (
                lengthComputable: number,
                loaded: number,
                total: number
              ) => void
            ) => {
              return appApi
                .sendFiles(values.id, { sign_one: file }, progress)
                .then(res => {
                  return Promise.resolve(res.photo);
                })
                .catch(err => {
                  return Promise.reject(err);
                });
            }}
            deleteImage={() => {
              return appApi.sendFiles(values.id, { sign_one: "" }).then(() => {
                setFieldValue("sign_one", null);
                return Promise.resolve();
              });
            }}
          />
        </InputColumn>
        <InputColumn label="Responsável técnico dois">
          <Field component={InputText} name="stamp_two" />
        </InputColumn>
        <InputColumn label="Assinatura responsável técnico dois">
          <InputSingleImage
            currentPhoto={values.sign_two}
            fileSent={() => {}}
            sentImage={(
              file: any,
              progress: (
                lengthComputable: number,
                loaded: number,
                total: number
              ) => void
            ) => {
              return appApi
                .sendFiles(values.id, { sign_two: file }, progress)
                .then(res => {
                  return Promise.resolve(res.photo);
                })
                .catch(err => {
                  return Promise.reject(err);
                });
            }}
            deleteImage={() => {
              return appApi.sendFiles(values.id, { sign_two: "" }).then(() => {
                setFieldValue("sign_two", null);
                return Promise.resolve();
              });
            }}
          />
        </InputColumn>
      </>
    );
  };

  return (
    <Row>
      <Col sm={6}>{renderApplicationSettings()}</Col>
    </Row>
  );
};

export default Form;
