import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { ButtonOption } from "../../../../components/Buttons";
import { HeaderSectionTitle } from "../../../../components/Headers";
import { InputCheckbox } from "../../../../components/Inputs";
import { fastToken } from "src/services/api/core";
import { invoiceReport } from "src/services/reports";
import { useParams } from "react-router-dom";

interface Props {
  contractId: number;
  billId: number;
}

const Report: React.FC<Props> = ({ contractId, billId }) => {
  const [cover, setCover] = useState(false);
  const [soil, setSoil] = useState(false);
  const [concrete, setConcrete] = useState(false);
  const [daily, setDaily] = useState(false);
  const [fullReport, setFullReport] = useState(false);
  const { appSlug } = useParams<{ appSlug: string }>();

  const getReportURL = () => {
    if (cover || soil || concrete || daily) {
      fastToken().then(({ data }) => {
        invoiceReport(
          contractId,
          appSlug,
          data.access,
          billId,
          cover,
          soil,
          concrete
        );
      });
    }
  };

  return (
    <Row>
      <Col>
        <HeaderSectionTitle title="Boletim de medição" />
        <InputCheckbox
          name="fullReport"
          label="Boletim completo"
          checked={fullReport}
          onClick={() => {
            setFullReport(!fullReport);
            setCover(!fullReport);
            setSoil(!fullReport);
            setConcrete(!fullReport);
            setDaily(!fullReport);
          }}
        />
        <InputCheckbox
          name="cover"
          label="Boletim de medição"
          checked={cover}
          onClick={() => setCover(!cover)}
        />
        <InputCheckbox
          name="soil"
          label="Controle de solos"
          checked={soil}
          onClick={() => setSoil(!soil)}
        />
        <InputCheckbox
          name="concrete"
          label="Controle de concreto"
          checked={concrete}
          onClick={() => setConcrete(!concrete)}
        />
        <ButtonOption
          className="btn-block btn-outline-secondary"
          type="button"
          onClick={getReportURL}
        >
          Gerar boletim
        </ButtonOption>
      </Col>
    </Row>
  );
};

export default Report;
