import React, { useState, useCallback, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ContainerFluid } from "../../../components/Container";
import ContractFilterBar from "../../../components/Selects/ContractFilterBar";
import TechControl from "../components/TechControl";
import * as clientApi from "../../../services/api/client";
import * as contractApi from "../../../services/api/contract";
import * as grauteApi from "../../../services/api/graute";
import CPsByPeriod from "./CPsByPeriod";
import Series from "./Series";
import Traces from "./Traces";
import { ListButtonRow } from "../../../components/Buttons";
import { InsertControl, EditControl } from "./Control";
import { ReportButton, ReportRow } from "src/components/Report";
import ReportBar from "./ReportBar";

const PAGE_TITLE = "Controle de graute";

const Graute = () => {
  const [contractId, setContractId] = useState<number>();
  const [control, setControl] = useState<TGrauteControl>();
  const [loadingControl, setLoadingControl] = useState<boolean>(false);

  const getClients = useCallback(() => {
    return clientApi.getClients("graute", "1", "id,name");
  }, []);

  const getContracts = useCallback((clientId: number) => {
    return contractApi.getContracts("", `${clientId}`, "", "", "graute", "1");
  }, []);

  useEffect(() => {
    if (contractId !== undefined && contractId !== null) {
      getControl();
    } else {
      setControl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  const getControl = useCallback(() => {
    if (contractId) {
      setLoadingControl(true);
      grauteApi
        .getControl(contractId)
        .then(({ data }) => {
          setControl(data[0]);
        })
        .finally(() => {
          setLoadingControl(false);
        });
    }
  }, [contractId]);

  const renderControl = () => {
    return (
      <>
        <ReportRow>
          <ReportBar controlId={control?.id!} contractId={contractId!} />
        </ReportRow>
        <Row>
          <Col>
            <Traces control={control!} />
            <Series control={control!} />
          </Col>
        </Row>
      </>
    );
  };

  const renderBeforeControl = () => {
    return <CPsByPeriod />;
  };

  const insertControl = () => {
    return <InsertControl contractId={contractId!} insertedItem={() => {}} />;
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={8}>
          <ContractFilterBar
            clientRequest={getClients}
            contractRequest={getContracts}
            onContractChange={setContractId}
          />
        </Col>
        <Col sm={4}>
          <ListButtonRow
            buttonsRight={
              <>
                {control !== undefined && (
                  <>
                    <EditControl item={control} updatedItem={setControl} />
                    <ReportButton color="white" />
                  </>
                )}
              </>
            }
          />
        </Col>
      </Row>
      <TechControl
        contract={contractId}
        control={control}
        renderControl={renderControl}
        loadingControl={loadingControl}
        insertControl={insertControl}
        renderBeforeControl={renderBeforeControl}
      />
    </ContainerFluid>
  );
};

export default Graute;
