import React, { useEffect } from "react";
import { ModalForm } from "../../../../../components/Modal";
import FormPost from "./FormPost";
import { initialSeries } from "../../init";
import {
  postSerie,
  getSerie,
  putSerie
} from "../../../../../services/api/graute";
import { FormikActions } from "formik";
import FormPut from "./FormPut";

interface EditProps extends EditType<TGrauteSeries> {
  control: TGrauteControl;
  showModal?: boolean;
}

const EditSeries: React.FC<EditProps> = ({
  control,
  item,
  showModal,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();

  useEffect(() => {
    if (showModal) {
      modalRef.current?.open();
    }
  }, [modalRef, showModal]);

  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    delete values.graute_cp;
    return putSerie(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(() => {
          setFieldError("number", "Número da série já existente");
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="md"
      title="Edita série"
      data={item}
      getItemRequest={id => getSerie(id!)}
      form={props => {
        return <FormPut {...props} control={control} />;
      }}
      initialValues={item => item}
      onSubmit={onSubmit}
    />
  );
};

interface InsertProps extends InsertType<TGrauteSeries> {
  control: TGrauteControl;
}

const InsertSeries: React.FC<InsertProps> = ({ control, insertedItem }) => {
  const onSubmit = (
    values: TGrauteSeries,
    { setFieldError }: FormikActions<object>
  ) => {
    return postSerie(values, control.id!)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(() => {
          setFieldError("number", "Número da série já existente");
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      type="post"
      size="lg"
      title="Cadastro de nova série"
      data={{}}
      form={props => {
        return <FormPost {...props} control={control} />;
      }}
      initialValues={() => {
        return {
          ...initialSeries,
          control: control.id!
        };
      }}
      onSubmit={onSubmit}
    />
  );
};

export { InsertSeries, EditSeries };
