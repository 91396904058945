/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { InputSelect } from "../Inputs";
import { useCylinders } from "../../context/tech_control/cylinder";
import { useContractAllocations } from "../../context/admin/contract";

interface Props extends SelectType<Cylinder> {
  type?: "sample" | "insitu";
  isMulti?: boolean;
  filter?: boolean;
}

const SelectCylinder: React.FC<Props> = props => {
  const [cylinders, cylindersActions] = useCylinders();
  const [allocations] = useContractAllocations();

  useEffect(() => {
    cylindersActions.fetchCapsules(props.type);
  }, []);

  const options = () => {
    return cylinders.data
      .filter(
        item =>
          !props.filter ||
          allocations === undefined ||
          allocations?.capsules.includes(item.id || -999)
      )
      .map(item => ({
        value: item.id,
        label: item.number
      }));
  };

  const onChangeCapsule = (item: any) => {
    if (!Array.isArray(item)) {
      item = cylinders.data.filter(c => c.id === item);
      if (item.length > 0) {
        props.onChange(item[0]);
      }
    } else {
      props.onChange(item);
    }
  };

  return (
    <InputSelect
      options={options()}
      placeholder="Cilindro..."
      {...props}
      onClick={onChangeCapsule}
      isClearable={false}
    />
  );
};

SelectCylinder.defaultProps = {
  isMulti: false,
  filter: true
};

export default SelectCylinder;
