import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import * as api from "../../../../../services/api/characterization";

interface State {
  liquidityTest: { stroke: number; humidity: number }[];
  plasticityTest: { humidity: number }[];
  liquidityLimit: number;
  plasticityLimit: number;
  plasticityIndex: number;
  loading: boolean;
  count: number;
}

const initialState: State = {
  liquidityTest: [...new Array(5).fill(0)].map(() => ({
    stroke: 0,
    humidity: 0
  })),
  plasticityTest: [...new Array(5).fill(0)].map(() => ({
    humidity: 0
  })),
  liquidityLimit: 0,
  plasticityLimit: 0,
  plasticityIndex: 0,
  loading: false,
  count: 0
};

const actions = {
  getPhysicalTest: (sampleId: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    if (getState().loading) {
      return;
    }

    setState({
      loading: true
    });

    return api
      .getLimits(sampleId)
      .then(({ data }: { data: PhysicalLimits[] }) => {
        if (data.length > 0) {
          const test = data[0];
          test.liquidity_tests.forEach((item, index: number) => {
            dispatch(actions.setOnLiquidityTest(item.stroke, index, "stroke"));
          });

          return test;
        }

        return undefined;
      })
      .finally(() => setState({ loading: false }));
  },

  setOnLiquidityTest: (
    value: number,
    index: number,
    field: "stroke" | "humidity"
  ) => ({ setState, getState }: StoreActionApi<State>) => {
    const { liquidityTest } = getState();
    const temp = liquidityTest;
    temp[index][field] = value;

    setState({
      liquidityTest: temp
    });
  },

  setOnPlasticityTest: (value: number, index: number) => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    const { plasticityTest } = getState();
    const temp = plasticityTest;
    temp[index].humidity = value;

    setState({
      plasticityTest: temp
    });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "limits"
});

const LimitsSubscriber = createSubscriber(Store);
const useLimits = createHook(Store);
const useLimitsActions = createHook(Store, {
  selector: null
});
const useLoading = createHook(Store, {
  selector: (state: State) => state.loading
});

export { LimitsSubscriber, useLimits, useLimitsActions, useLoading };
