import { penetrations } from "./penetrations";

const penetration = {
  id: undefined,
  time: undefined,
  read: undefined,
  penetration: undefined
};

const cbrStudy = {
  id: undefined,
  expansions: Array(4)
    .fill(0)
    .map(() => {
      return penetration;
    }),
  penetrations: penetrations.map(item => {
    return {
      id: undefined,
      time: item.time,
      read: undefined,
      penetration: item.penetration
    };
  }),
  cylinder_number: undefined,
  height: undefined
};

const initialValues = {
  cbr_studies: Array(5)
    .fill(0)
    .map((_, index) => {
      return { ...cbrStudy, cylinder_number: index };
    }),
  calibration: undefined,
  review: undefined,
  notes: "",
  execution_date: "",
  sample: undefined,
  executor: undefined
};

export default initialValues;
