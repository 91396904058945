import React from "react";
import { HeaderTitle } from "../../../../components/Headers";
import { toLocateNumber } from "../../../../utils/format";

interface Props {
  summary?: SampleSummary;
}

const SampleDetail: React.FC<Props> = ({ summary }) => {
  return (
    <>
      <HeaderTitle title="Resumo" />
      {summary ? (
        <dl className="row">
          <dt className="col-sm-6">L.L</dt>
          <dd className="col-sm-6">
            {summary.liquidity_limit >= 0 ? (
              <>
                {toLocateNumber(
                  Math.max(0, summary.liquidity_limit * 100),
                  3,
                  "NL",
                  "",
                  "%"
                )}
              </>
            ) : (
              "-"
            )}
          </dd>

          <dt className="col-sm-6">L.P (%)</dt>
          <dd className="col-sm-6">
            {summary.plasticity_limit >= 0 ? (
              <>
                {toLocateNumber(
                  Math.max(0, summary.plasticity_limit * 100),
                  3,
                  "NP",
                  "",
                  "%"
                )}
              </>
            ) : (
              "-"
            )}
          </dd>

          <dt className="col-sm-6">IP</dt>
          <dd className="col-sm-6">
            {summary.plasticity_index >= 0
              ? toLocateNumber(
                  Math.max(0, summary.plasticity_index * 100),
                  0,
                  "NL/NP",
                  "",
                  "%"
                )
              : "-"}
          </dd>

          <dt className="col-sm-6">IG</dt>
          <dd className="col-sm-6">
            {summary.plasticity_index >= 0 ? (
              <>{Math.max(summary.group_index, 0)}</>
            ) : (
              "-"
            )}
          </dd>

          <dt className="col-sm-6">HRB</dt>
          <dd className="col-sm-6">{summary.hrb}</dd>

          <dt className="col-sm-6">UNIFICADO</dt>
          <dd className="col-sm-6">{summary.unificada}</dd>

          <dt className="col-sm-6">Nº de Golpes</dt>
          <dd className="col-sm-6">{summary.proctor || "-"}</dd>

          <dt className="col-sm-6">máx (g/cm³)</dt>
          <dd className="col-sm-6">
            {toLocateNumber(Math.max(0, summary.great_density), 3, "-")}
          </dd>

          <dt className="col-sm-6">h ótima (%)</dt>
          <dd className="col-sm-6">
            {toLocateNumber(Math.max(0, summary.great_humidity * 100), 2, "-")}
          </dd>

          <dt className="col-sm-6">Exp (%)</dt>
          <dd className="col-sm-6">
            {toLocateNumber(Math.max(0, summary.expansion * 100), 2, "-")}
          </dd>

          <dt className="col-sm-6">I.S.C (%)</dt>
          <dd className="col-sm-6">
            {toLocateNumber(Math.max(0, summary.isc * 100), 3, "-")}
          </dd>
        </dl>
      ) : (
        <p className=" text-center text-muted font-14">
          Amostra não selecinada ou sem ensaios
        </p>
      )}
    </>
  );
};

export default SampleDetail;
