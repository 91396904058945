import React from "react";
import { FormikProps, Field } from "formik";
import { InputColumn, InputValidation, InputText } from "src/components/Inputs";

const Form: React.FC<FormikProps<CBRPress>> = ({ errors }) => {
  return (
    <>
      <InputColumn label="Referência da prensa">
        <Field name="press_ref" component={InputText} required={true} />
        <InputValidation message={errors.press_ref} />
      </InputColumn>
    </>
  );
};

export default Form;
