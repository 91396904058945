import React from "react";
import { Helmet } from "react-helmet";
interface Props {
  title: string;
  children:
    | React.ComponentType<any>
    | React.ReactElement
    | React.ReactElement[];
}

const Content: React.FC<Props> = ({ children, title }) => {
  return (
    <div className="wrapper">
      <div className="content-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title} • GM6</title>
          <script src={process.env.PUBLIC_URL + "/static/js/app.min.js"} />
        </Helmet>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

const ContainerFluid: React.FC<Props> = ({ children, title }) => {
  return (
    <Content title={title}>
      <div className="container-fluid">{children}</div>
    </Content>
  );
};

const Container: React.FC<Props> = ({ children, title }) => {
  return (
    <Content title={title}>
      <div className="container">{children}</div>
    </Content>
  );
};

export { Container, ContainerFluid };
