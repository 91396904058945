import api, { sendFile } from "./api";

const checkFeature = (feature_key: string) => {
  const endpoint = "/core/flags/";
  return api.post(endpoint, { feature_key });
};

const fastToken = () => {
  const endpoint = "/me/custom_token/";

  return api.post(endpoint, { access_token_lifetime: 300 });
};

const xlsx = (group?: "C" | "S" | "I", fields?: string) => {
  const endpoint = "/core/xlsx/";

  const params = {
    group,
    fields
  };

  return api.get(endpoint, { params });
};

const postXlsx = (data: Xlsx) => {
  const endpoint = "/core/xlsx/";

  return api.post(endpoint, data);
};

const putXlsx = (id: number, data: Xlsx) => {
  const endpoint = `/core/xlsx/${id}/`;

  return api.put(endpoint, data);
};

const deleteXlsx = (id: number) => {
  const endpoint = `/core/xlsx/${id}/`;

  return api.delete(endpoint);
};

const xlsxVariables = () => {
  const endpoint = "/core/xlsx/variables/";

  return api.get(endpoint);
};

const sendXlsxFile = (data: { file: any }, progress: (e: any) => void) => {
  const endpoint = "core/xlsx/file/";

  return sendFile(endpoint, data, progress);
};

const customXlsxReport = (xlsxId: number) => {
  const endpoint = "core/xlsx/custom_report/";

  const params = {
    xlsx: xlsxId
  };

  return api.get(endpoint, { params });
};

const sendGenericFile = (data: { file: any }, progress: (e: any) => void) => {
  const endpoint = "core/files/";

  return sendFile(endpoint, data, progress);
};

export {
  checkFeature,
  fastToken,
  xlsx,
  postXlsx,
  putXlsx,
  deleteXlsx,
  xlsxVariables,
  sendXlsxFile,
  customXlsxReport,
  sendGenericFile
};
