import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { TableList, useData } from "../../../components/Table";
import columns from "./columns";
import { getUsers } from "../../../services/api/admin";
import { LoadingComponent } from "../../../components/Loader";
import Form from "./Form";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Acesso de usuários";

const User = () => {
  const [users, setUsers] = useState<User[]>([]);
  const user = useData<User>();
  const [, setDbClick] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getUsers()
      .then(({ data }) => setUsers(data))
      .finally(() => setLoading(false));
  }, []);

  const renderList = () => (
    <Card>
      <CardBody>
        <LoadingComponent loading={loading}>
          <TableList
            data={users}
            columns={columns}
            selected={user.selected.set}
            updated={user.updated.item}
            created={user.created.item}
            deleted={user.deleted.item}
            searching={false}
            dbClick={item => {
              user.selected.set(item);
              setDbClick(true);
              setDbClick(false);
            }}
          />
        </LoadingComponent>
      </CardBody>
    </Card>
  );

  const renderForm = () => (
    <Card>
      <CardBody>
        <Form
          user={user.selected.item}
          created={user.created.set}
          updated={user.updated.set}
          deleted={user.deleted.set}
        />
      </CardBody>
    </Card>
  );

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={7}>{renderList()}</Col>
        <Col sm={5}>{renderForm()}</Col>
      </Row>
    </ContainerFluid>
  );
};

export default User;
