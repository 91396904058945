import React, { useEffect, useState } from "react";
import * as soilApi from "../../services/api/characterization";
import SelectBase from "./SelectBase";

interface Props extends SelectType<SoilProjectControl> {
  controlId: number;
}

const SelectTechProject: React.FC<Props> = props => {
  const [items, setItems] = useState<SoilProjectControl[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    soilApi.getProjects(props.controlId).then(({ data }) => setItems(data));
  };

  const options = () => {
    return items.map(item => ({
      value: item.id,
      label: `${item.code}`
    }));
  };

  const onChange = (value: any) => {
    let item = value;
    if (value !== undefined && value !== null && props.onChange) {
      item = items.filter(c => c.id === value)[0];
      props.onChange(item);
    } else {
      props.onChange(undefined);
    }
  };

  return (
    <SelectBase
      {...props}
      placeholder="Project..."
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      getItems={getItems}
      options={options()}
      onClick={onChange}
    />
  );
};

export default SelectTechProject;
