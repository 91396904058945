import React from "react";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";
import { postWork } from "../../../services/api/work";
import { FormikActions } from "formik";

const Insert: React.FC<InsertType<WorkFormValues>> = ({ insertedItem }) => {
  const onSubmit = (
    values: WorkFormValues,
    { setFieldError }: FormikActions<object>
  ) => {
    return postWork(values)
      .then(({ data }) => {
        insertedItem(data);
      })
      .catch(res => {
        Object.keys(res).forEach(key => {
          setFieldError(key, res[key]);
        });
      });
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastro de nova obra"
      data={{}}
      form={props => <Form {...props} />}
      initialValues={() => {
        return {};
      }}
      onSubmit={onSubmit}
    />
  );
};

export default Insert;
