import React, { useState, useEffect } from "react";
import * as api from "../../services/api/company";
import SelectBase from "./SelectBase";

const SelectCompany: React.FC<SelectType<CompanyValuesProps>> = props => {
  const [items, setItems] = useState<CompanyValuesProps[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    api.getCompanies().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({
        value: item.id,
        label: item.name
      }));
  };

  return (
    <SelectBase
      placeholder="Empresa..."
      options={options()}
      getItems={getItems}
      onClick={props.onChange}
      newItem={props.newItem}
      deletedItem={props.deletedItem}
      updatedItem={props.updatedItem}
      {...props}
    />
  );
};

export default SelectCompany;
