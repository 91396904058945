import { Field, Form, Formik, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ButtonSave, ListButtonRow } from "src/components/Buttons";
import { Container } from "src/components/Container";
import { HeaderSectionTitle } from "src/components/Headers";
import { InputCheckbox, InputColumn, InputText } from "src/components/Inputs";
import { ItemToManager } from "src/components/ItemContent";
import { LoadingComponent } from "src/components/Loader";
import { SelectISCPress } from "src/components/Selects";
import { SmallText } from "src/components/Text";
import { getControlId, putControl } from "src/services/api/characterization";
import ControlForm from "../ControlForm";
import Projects from "./Projects";

const TITLE = "Configuração de controle de solos";

const CharacterizationControl = () => {
  const match = useRouteMatch();
  const { appSlug } = useParams<{ appSlug: string }>();
  const { controlId } = useParams<any>();
  const [control, setControl] = useState<SoilControl>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getControlId(controlId)
      .then(({ data }) => setControl(data))
      .finally(() => setLoading(false));
  }, [controlId]);

  const onSubmitControl = (values?: SoilControl) => {
    if (values !== undefined) {
      const data = {
        ...values,
        contract: values.contract.id
      };

      return putControl(values.id!, data).then(({ data }) => setControl(data));
    }

    return null;
  };

  const renderControl = () => {
    return (
      <>
        <ItemToManager
          title="Controle de solos"
          notes="Estes são as configurações que irão auxiliar o controle de caracterização de amostras"
        >
          <Formik
            initialValues={control}
            enableReinitialize
            onSubmit={onSubmitControl}
            render={({ ...props }) => (
              <Form>
                <Row>
                  <Col sm={6}>
                    <ControlForm {...props} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ListButtonRow
                      buttonsLeft={
                        <ButtonSave isSubmitting={props.isSubmitting} />
                      }
                      marginTop="mt-2"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          />
        </ItemToManager>
      </>
    );
  };

  return (
    <Container title={TITLE}>
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item">
          <a href={`/${appSlug}/samples/`}>Caracterização de solos</a>
        </li>
        <li className="breadcrumb-item">
          <Link to="#">Configuração do controle</Link>
        </li>
      </ol>
      <Card>
        <CardBody>
          <LoadingComponent loading={loading}>
            {renderControl()}
            <hr />
            <Projects controlId={controlId} />
          </LoadingComponent>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CharacterizationControl;
