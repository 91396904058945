import api from "./api";

const getWorks = () => {
  let endpoint = "/works/";
  return api.get(endpoint);
};

const getWork = (id: number) => {
  let endpoint = `/works/${id}/`;
  return api.get(endpoint);
};

const postWork = (data: any) => {
  let endpoint = "/works/";
  return api.post(endpoint, data);
};

const putWork = (id: number, data: any) => {
  let endpoint = `/works/${id}/`;
  return api.put(endpoint, data);
};

const deleteWork = (id: number) => {
  let endpoint = `/works/${id}/`;
  return api.delete(endpoint);
};

export { getWork, getWorks, postWork, putWork, deleteWork };
