import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import { xlsxVariables } from "src/services/api/core";

const REQUEST_DELAY = 5 * 60 * 1000;

interface State {
  xlsxVariables: XlsxVariablesGroup[];
  loadingXlsxVariables: boolean;
  lastRequest: number;
}

const initialState: State = {
  xlsxVariables: [],
  loadingXlsxVariables: false,
  lastRequest: 0
};

const actions = {
  getXlsxVariables: () => async ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (
      getState().loadingXlsxVariables ||
      getState().lastRequest > new Date().getTime()
    ) {
      return;
    }

    setState({
      loadingXlsxVariables: true,
      lastRequest: new Date().getTime() + REQUEST_DELAY
    });
    return xlsxVariables()
      .then(({ data }) => {
        setState({
          xlsxVariables: data,
          loadingXlsxVariables: false
        });
      })
      .catch(() => {
        setState({ loadingXlsxVariables: false });
      });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "user"
});

const useCoreActions = createHook(Store, {
  selector: null
});

const useXlsxVariables = createHook(Store, {
  selector: (state: State) => state.xlsxVariables
});

const UserSubscriber = createSubscriber(Store);

export { useCoreActions, useXlsxVariables, UserSubscriber };
