import React from "react";
import { FormikActions } from "formik";
import Form from "./Form";
import { ModalForm } from "src/components/Modal";
import { putBank, postBank, getBank } from "src/services/api/bills";

const Insert: React.FC<InsertType<BankValues>> = ({ insertedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return postBank(values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastrar nova conta bancária"
      initialValues={() => {
        return {};
      }}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
    />
  );
};

const Edit: React.FC<EditType<BankValues>> = ({ item, updatedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return putBank(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="put"
      size="md"
      data={item ? { id: item } : undefined}
      getItemRequest={id => getBank(id)}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
      initialValues={item => item}
      title="Editar conta bancária"
    />
  );
};

export { Insert, Edit };
