import { formatterMoney } from "../../../utils/format";

const columns = [
  {
    title: "#",
    data: "id"
  },
  {
    title: "Nome",
    data: "name",
    width: "700px"
  },
  {
    title: "Área",
    data: "sub_department.name"
  },
  {
    title: "Unidade",
    data: "unit.abbreviation"
  },
  {
    title: "Qtd.",
    data: "quantity"
  },
  {
    title: "Preço",
    data: "price",
    render: function (data: number) {
      return formatterMoney.format(data);
    }
  }
];

export default columns;
