import React, { useState } from "react";
import { Field, FormikProps } from "formik";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import Alert from "../../../components/Alerts";
import {
  InputText,
  InputColumn,
  InputValidation,
  InputCNPJ,
  InputPhone,
  InputCPF
} from "../../../components/Inputs";

const ClientForm: React.FC<FormikProps<ClientFormValues>> = ({
  errors,
  setFieldValue
}) => {
  const [isPj, setIsPJ] = useState(true);

  return (
    <Row>
      <Col sm={12}>
        {errors.integrity_error && (
          <Alert color="danger" message={errors.integrity_error} />
        )}
      </Col>
      <Col>
        <FormGroup tag="fieldset">
          <Row>
            <Col>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    onChange={() => {
                      setIsPJ(false);
                      setFieldValue("register_code", "");
                    }}
                  />
                  Pessoa física
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check disabled>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    checked={isPj}
                    onChange={() => {
                      setIsPJ(true);
                      setFieldValue("register_code", "");
                    }}
                  />
                  Pessoa jurídica
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>

        <InputColumn label="Nome">
          <Field name="name" component={InputText} required autoFocus />
          <InputValidation message={errors.name} />
        </InputColumn>
        <InputColumn label="Nome social">
          <Field name="social_name" component={InputText} required />
          <InputValidation message={errors.social_name} />
        </InputColumn>
        <InputColumn label="CNPJ/CPF">
          {isPj ? (
            <InputCNPJ name="register_code" required />
          ) : (
            <InputCPF name="register_code" required />
          )}
          <InputValidation message={errors.register_code} />
        </InputColumn>
        <InputColumn label="Endereço">
          <Field name="address" component={InputText} />
          <InputValidation message={errors.address} />
        </InputColumn>

        <InputColumn label="Contato">
          <Field name="contact_name" component={InputText} />
          <InputValidation message={errors.contact_name} />
        </InputColumn>
        <InputColumn label="Telefone um">
          <InputPhone name="contact_phone_one" required />
          <InputValidation message={errors.contact_phone_one} />
        </InputColumn>
        <InputColumn label="Telefone dois">
          <InputPhone name="contact_phone_two" />
          <InputValidation message={errors.contact_phone_two} />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default ClientForm;
