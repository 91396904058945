import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as api from "../../../services/api/work";
import { TableList, useData } from "../../../components/Table";
import { LoadingComponent } from "../../../components/Loader";
import { ListButtonRow, ButtonDelete } from "../../../components/Buttons";
import columns from "./columns";
import Insert from "./Insert";
import Edit from "./Edit";
import { ContainerFluid } from "../../../components/Container";
import Detail from "./Detail";

const PAGE_TITLE = "Obras";

const WorkList = () => {
  const work = useData<WorkFormValues>();
  const [loading, setLoading] = useState(true);
  const [works, setWorks] = useState([]);
  const [dbClick, setDbClick] = useState(false);

  useEffect(() => {
    getWorks();
  }, []);

  const getWorks = () => {
    setLoading(true);
    api.getWorks().then(({ data }) => {
      setWorks(data);
      setLoading(false);
    });
  };

  const deleteWork = () => {
    if (work.selected.item && work.selected.item.id !== undefined)
      return api
        .deleteWork(work.selected.item.id)
        .then(res => {
          work.deleted.set(work.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });

    return Promise.reject();
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={8}>
          <Card>
            <CardBody>
              <LoadingComponent loading={loading}>
                <TableList
                  data={works}
                  columns={columns}
                  selected={work.selected.set}
                  updated={work.updated.item}
                  created={work.created.item}
                  deleted={work.deleted.item}
                  dbClick={item => {
                    work.selected.set(item);
                    setDbClick(true);
                    setDbClick(false);
                  }}
                  searching={false}
                />
              </LoadingComponent>
              <ListButtonRow
                marginTop="mt-2"
                buttonsLeft={
                  <>
                    <Insert insertedItem={work.created.set} />
                    <Edit
                      item={work.selected.item!}
                      updatedItem={work.updated.set}
                      dbClick={dbClick}
                    />
                  </>
                }
                buttonsRight={
                  <ButtonDelete
                    disabled={!work.selected.item}
                    onClick={deleteWork}
                  />
                }
              />
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <CardBody>
              <Detail item={work.selected.item} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContainerFluid>
  );
};

export default WorkList;
