/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { TableList, useData } from "src/components/Table";
import { LoadingComponent } from "src/components/Loader";
import { useCylinders } from "src/context/tech_control/cylinder";
import { toLocateNumber } from "src/utils/format";
import { ListButtonRow, ButtonDelete } from "src/components/Buttons";
import { InsertCylinder, EditCylinder } from "./Form";
import { deleteCylinder } from "src/services/api/cylinder";

const columns = [
  { title: "Nº", data: "number" },
  {
    title: "Peso (g)",
    data: "weight",
    render: function (data: number) {
      return toLocateNumber(data, 3);
    }
  },
  {
    title: "Uso",
    data: "usage",
    render: (data: "I" | "S") => (data === "I" ? "Hilf" : "Compactação")
  }
];

const Cylinders = () => {
  const [cylinders, cylindersActions] = useCylinders();
  const [dbClick, setDbClick] = useState<boolean>(false);
  const item = useData<Cylinder>();

  useEffect(() => {
    cylindersActions.fetchCapsules();
  }, []);

  const onDelete = () => {
    if (item.selected.item && item.selected.item.id !== undefined) {
      return deleteCylinder(item.selected.item.id)
        .then(({ data }) => {
          item.deleted.set(item.selected.item);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  return (
    <Row>
      <Col sm={8}>
        <LoadingComponent loading={cylinders.loading}>
          <TableList
            tableId="cylinders"
            data={cylinders.data}
            columns={columns}
            selected={item.selected.set}
            updated={item.updated.item}
            created={item.created.item}
            deleted={item.deleted.item}
            searching={false}
            scrollY={"200px"}
            dbClick={item => {
              item.selected.set(item);
              setDbClick(true);
              setDbClick(false);
            }}
          />
        </LoadingComponent>
        <ListButtonRow
          buttonsLeft={
            <>
              <InsertCylinder insertedItem={item.created.set} />
              <EditCylinder
                item={item.selected.item!}
                updatedItem={item.updated.set}
                dbClick={dbClick}
              />
            </>
          }
          buttonsRight={
            <ButtonDelete disabled={!item.selected.item} onClick={onDelete} />
          }
          marginTop="mt-2"
        />
      </Col>
    </Row>
  );
};

export default Cylinders;
