import moment from "moment";
import { toLocateNumber } from "../../../utils/format";

export const columnsGroupsTrace = [
  {
    title: "Local",
    data: "place"
  },
  {
    title: "Data",
    data: "trace_date",
    visible: false,
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  }
];

export const columnsTraces = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Nº",
    data: "number"
  },
  {
    title: "Data",
    data: "trace_date",
    visible: false,
    render(data: string) {
      return moment(data).local().format("DD/MM/YYYYY");
    }
  },
  {
    title: "Marca",
    data: "manufacturer"
  },
  {
    title: "Sacos",
    data: "bag_mortero"
  },
  {
    title: "Qtd. (Kg)",
    data: "bag_weight",
    return(data: number) {
      return toLocateNumber(data, 2);
    }
  },
  {
    title: "Val.",
    data: "expiration_date",
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  },
  {
    title: "Litros",
    data: "liters_bag",
    render(data: number) {
      return toLocateNumber(data, 2);
    }
  },
  {
    title: "Início",
    data: "start_hour",
    render(data: string) {
      return moment(data).local().format("HH:mm");
    }
  },
  {
    title: "T. Amb.",
    data: "environment_temp",
    visible: false
  },
  {
    title: "T. Mort",
    data: "mortero_temp",
    visible: false
  },
  {
    title: "T. Mass.",
    data: "mass_temp",
    visible: false
  },
  {
    title: "T. Água",
    data: "water_temp",
    visible: false
  },
  {
    title: "Final",
    data: "end_hour",
    render(data: string) {
      return moment(data).local().format("HH:mm");
    }
  },
  {
    title: "Colet.",
    data: "collected",
    render(data: boolean) {
      if (data) {
        return '<span class="badge badge-primary">Sim</span>';
      }
      return '<span class="badge badge-secondary">Não</span>';
    }
  },
  {
    title: "Desc.",
    data: "discarded_motive",
    render(data: string) {
      if (data === "N") return "";
      if (data === "TEMP") return "Temp.";
      if (data === "LUB") return "Lubri.";
      if (data === "OT") return "Outros";
      return "-";
    }
  }
];

export const seriesColumns = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Nº",
    data: "number"
  },
  {
    title: "Torre",
    data: "tower",
    visible: false
  },
  {
    title: "Local",
    data: "place"
  },
  {
    title: "Moldagem",
    data: "molding_date",
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  },
  {
    title: "Compressão",
    data: "conf_break_resistance",
    render(data: boolean) {
      if (data) {
        return '<span class="badge badge-primary">Aprovado</span>';
      }
      return '<span class="badge badge-danger">Reprovado</span>';
    }
  },
  {
    title: "Flexão",
    data: "conf_flexion_resistance",
    render(data: boolean) {
      if (data) {
        return '<span class="badge badge-primary">Aprovado</span>';
      }
      return '<span class="badge badge-danger">Reprovado</span>';
    }
  },
  {
    title: "Status",
    data: "approved",
    render(data: boolean) {
      if (data) {
        return '<span class="badge badge-primary">Liberado</span>';
      }
      return '<span class="badge badge-secondary">Aguardando</span>';
    }
  }
];

export const cpsColumns = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Código",
    data: "code"
  },
  {
    title: "Data ruptura",
    data: "rupture_date",
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  },
  {
    title: "Idade",
    data: "age",
    render(age: number) {
      return `${age >= 24 ? age / 24 : age}${age >= 24 ? "d" : "h"}`;
    }
  },
  {
    title: "Carga",
    data: "load",
    render(data: number) {
      return toLocateNumber(data, 3, "-");
    }
  },
  {
    title: "FCK (MPa)",
    data: "resistance",
    render(data: number) {
      return toLocateNumber(data, 3, "-");
    }
  },
  {
    title: "Rompido",
    data: "id",
    visible: false
  },
  {
    title: "Status",
    data: "discarded",
    render(data: string) {
      if (data === "tested") {
        return '<span class="badge badge-primary">Ensaiado</span>';
      }
      if (data === "review") {
        return '<span class="badge badge-danger">Análise</span>';
      }
      if (data === "waiting") {
        return '<span class="badge badge-secondary">Aguardando</span>';
      }

      return "";
    }
  }
];

export const cpsToBreakColumns = [
  {
    title: "#",
    data: "id",
    visible: false
  },
  {
    title: "Obra",
    data: "work"
  },
  {
    title: "Série",
    data: "serie_number"
  },
  {
    title: "Código",
    data: "code"
  },
  {
    title: "Data ruptura",
    data: "rupture_date",
    render(data: string) {
      return moment(data).local().format("DD/MM/YY");
    }
  },
  {
    title: "Idade",
    data: "age",
    render(age: number) {
      return `${age >= 24 ? age / 24 : age}${age >= 24 ? "d" : "h"}`;
    }
  },
  {
    title: "Carga",
    data: "load",
    render(data: number) {
      return toLocateNumber(data, 3, "-");
    }
  },
  {
    title: "FCK (MPa)",
    data: "resistance",
    render(data: number) {
      return toLocateNumber(data, 3, "-");
    }
  },
  {
    title: "Rompido",
    data: "id",
    visible: false
  },
  {
    title: "Status",
    data: "discarded",
    render(data: string) {
      if (data === "tested") {
        return '<span class="badge badge-primary">Ensaiado</span>';
      }
      if (data === "review") {
        return '<span class="badge badge-danger">Análise</span>';
      }
      if (data === "waiting") {
        return '<span class="badge badge-secondary">Aguardando</span>';
      }

      return "";
    }
  }
];
