import React, { useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import { ItemToManager } from "../../../components/ItemContent";
import { ContainerFluid } from "../../../components/Container";
import Units from "./Unit";
import Company from "./Company";
import Application from "./Application";
import Bank from "./Bank";
import { useParams } from "react-router";

const PAGE_TITLE = "Opcões de gestão";

const ControlSettings = () => {
  const { appSlug } = useParams<{ appSlug: string }>();

  const isMatech = useMemo(() => appSlug === "rtfxqqjw3ajm", [appSlug]);

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Card>
        <CardBody>
          <ItemToManager title="Unidades de serviço" notes="">
            <Units />
          </ItemToManager>
          <hr />

          <ItemToManager title="Organização" notes="">
            <Company />
          </ItemToManager>
          <hr />

          {isMatech && (
            <>
              <ItemToManager title="Dados bancários" notes="">
                <Bank />
              </ItemToManager>
              <hr />
            </>
          )}

          <ItemToManager title="Relatórios e documentos" notes="">
            <Application />
          </ItemToManager>
          <hr />
        </CardBody>
      </Card>
    </ContainerFluid>
  );
};

export default ControlSettings;
