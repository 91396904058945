import React, { useState, useEffect } from "react";
import { InputSelect } from "../Inputs";
import * as api from "../../services/api/service";

const SelectUnit = (props: any) => {
  const [items, setItems] = useState<Array<UnitValuesProps>>([]);

  const getItems = () => {
    api.getUnits().then(({ data }) => setItems(data));
  };

  useEffect(() => {
    getItems();
  }, []);

  const options = () => {
    return items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({
        value: item.id,
        label: `${item.name} (${item.abbreviation})`
      }));
  };

  return (
    <InputSelect
      options={options()}
      placeholder="Unidade..."
      onClick={props.onChange}
      {...props}
    />
  );
};

export default SelectUnit;
