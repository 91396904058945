import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Field } from "formik";
import {
  InputRow,
  InputDate,
  InputValidation,
  InputText,
  InputTextArea,
  InputColumn,
  InputCheckbox
} from "../../../components/Inputs";
import {
  SelectEquipment,
  SelectLayer,
  SelectMaterial,
  SelectProctor,
  SelectContract,
  SelectClient,
  SelectISCPress,
  SelectSoilProject
} from "../../../components/Selects";
import { HeaderTitle } from "../../../components/Headers";
import * as clientApi from "../../../services/api/client";
import * as contractApi from "../../../services/api/contract";
import * as soilApi from "../../../services/api/characterization";
import { InputSingleImage } from "../../../components/Inputs/InputFile";

interface Props extends FormikProps<SoilSample> {
  control?: SoilControl;
  type: "put" | "post";
  onUpdatePhoto?: () => void;
}

const SampleForm: React.FC<Props> = ({
  control,
  type,
  values,
  errors,
  setFieldValue
}) => {
  const [client, setClient] = useState<ClientFormValues>();

  useEffect(() => {
    contractApi.getContract(values.contract).then(({ data }) => {
      setClient(data.work.client);
    });
  }, [values.contract]);

  const getClients = () => {
    return clientApi.getClients("soil", "1", "id,name");
  };

  const getContracts = (clientId: number) => {
    return contractApi.getContracts("", `${clientId}`, "", "", "soil");
  };

  const renderClientWork = () => {
    if (type === "post") {
      return (
        <>
          <InputRow label_col={5} label="Cliente">
            {control?.contract.work?.client?.name}
          </InputRow>
          <InputRow label_col={5} label="Obra">
            {control?.contract.work?.name}
          </InputRow>
        </>
      );
    }

    return (
      <>
        <InputRow label_col={5} label="Cliente">
          <SelectClient
            request={getClients}
            onChange={setClient}
            selectedItem={client}
          />
        </InputRow>
        <InputRow label_col={5} label="Obra">
          <Field
            name="contract"
            component={SelectContract}
            client={client?.id || client}
            request={getContracts}
            isClearable={false}
            onChange={(value: number) => {
              soilApi.getControl(value).then(({ data }) => {
                if (data.length > 0) {
                  setFieldValue("control", data[0].id);
                } else {
                  setFieldValue("control", null);
                }
              });
            }}
            required
          />
          <InputValidation message={errors.contract} />
        </InputRow>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col>
          {renderClientWork()}
          <InputRow label_col={5} label="Projeto">
            <Field
              name="project"
              component={SelectSoilProject}
              controlId={control?.id}
              onChange={(value?: SoilProjectControl) => {
                setFieldValue("project", value?.id || null);
              }}
            />
            <InputValidation message={errors.layer} />
          </InputRow>
          <InputRow label_col={5} label="Data da coleta">
            <Field name="collection_date" component={InputDate} required />
            <InputValidation message={errors.collection_date} />
          </InputRow>
          <InputRow label_col={5} label="Data de chegada">
            <Field
              name="arrival_date"
              component={InputDate}
              required
              min={values.collection_date}
            />
            <InputValidation message={errors.arrival_date} />
          </InputRow>
          <InputRow label_col={5} label="Local da coleta">
            <Field name="collection_place" component={InputText} required />
            <InputValidation message={errors.collection_place} />
          </InputRow>
          <InputRow label_col={5} label="Trecho da coleta">
            <Field name="collection_stretch" component={InputText} />
            <InputValidation message={errors.collection_stretch} />
          </InputRow>
          <InputRow label_col={5} label="Equipamento de coleta">
            <Field
              name="equipment"
              component={SelectEquipment}
              isClearable={false}
              required
            />
            <InputValidation message={errors.equipment} />
          </InputRow>
          <InputRow label_col={5} label="Camada">
            <Field
              name="layer"
              component={SelectLayer}
              isClearable={false}
              required
            />
            <InputValidation message={errors.layer} />
          </InputRow>
          <InputRow label_col={5} label="Profundidade (cm)">
            <Field name="depth" component={InputText} />
            <InputValidation message={errors.depth} />
          </InputRow>
          <InputRow label_col={5} label="Tipo do material">
            <Field
              name="material_type"
              component={SelectMaterial}
              required
              isClearable={false}
            />
            <InputValidation message={errors.material_type} />
          </InputRow>
          {type === "put" && (
            <>
              <Row>
                <Col>
                  <InputColumn label="Foto da amostra" />
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <img
                    src={values.photo || ""}
                    alt=""
                    className="img-fluid rounded upload-image"
                  />
                </Col>
                <Col>
                  <InputSingleImage
                    sampleId={values.id!}
                    currentPhoto={values.photo}
                    fileSent={value => {
                      setFieldValue("photo", value);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col>
          <InputRow label_col={5} label="Estaca">
            <Field name="stake" component={InputText} />
            <InputValidation message={errors.stake} />
          </InputRow>
          <InputRow label_col={5} label="Furo">
            <Field name="hole" component={InputText} />
            <InputValidation message={errors.hole} />
          </InputRow>
          <InputRow label_col={5} label="Saco">
            <Field name="bag" component={InputText} />
            <InputValidation message={errors.bag} />
          </InputRow>
          <InputRow label_col={5} label="Faixa">
            <Field name="band" component={InputText} />
            <InputValidation message={errors.band} />
          </InputRow>
          <InputRow label_col={5} label="Latitude">
            <Field name="latitude" component={InputText} />
            <InputValidation message={errors.latitude} />
          </InputRow>
          <InputRow label_col={5} label="Longitude">
            <Field name="longitude" component={InputText} />
            <InputValidation message={errors.longitude} />
          </InputRow>
          {type === "put" && (
            <InputRow label_col={5} label="Prensa de CBR">
              <Field name="press" component={SelectISCPress} required />
              <InputValidation message={errors.press} />
            </InputRow>
          )}
          <InputRow label_col={5} label="Proctor">
            <Field
              name="proctor"
              component={SelectProctor}
              required={values.has_compaction}
            />

            <InputValidation message={errors.proctor} />
          </InputRow>
          <Row>
            <Col>
              <InputColumn label="Observações">
                <Field name="notes" component={InputTextArea} />
                <InputValidation message={errors.notes} />
              </InputColumn>
            </Col>
          </Row>
        </Col>
        <Col sm={3}>
          <Row>
            <Col>
              <HeaderTitle title="Amostra liberada" />
              <InputCheckbox
                label="Liberado para o cliente"
                name="approved"
                checked={values.approved}
                onClick={() => setFieldValue("approved", !values.approved)}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <HeaderTitle title="Ensaios a executar" />
              <InputCheckbox
                name="has_granulometry_wet"
                checked={values.has_granulometry_wet}
                label="Granulometria (via úmida)"
                onClick={() => {
                  let checked =
                    values.has_granulometry_dry || values.has_granulometry_bgs;
                  setFieldValue(
                    "has_granulometry_wet",
                    !checked && !values.has_granulometry_wet
                  );
                }}
              />
              <InputCheckbox
                name="has_granulometry_dry"
                checked={values.has_granulometry_dry}
                label="Granulometria (via seca)"
                onClick={() => {
                  let checked =
                    values.has_granulometry_wet || values.has_granulometry_bgs;
                  setFieldValue(
                    "has_granulometry_dry",
                    !checked && !values.has_granulometry_dry
                  );
                }}
              />
              <InputCheckbox
                name="has_granulometry_bgs"
                checked={values.has_granulometry_bgs}
                label="Granulometria BGS"
                onClick={() => {
                  let checked =
                    values.has_granulometry_wet || values.has_granulometry_dry;
                  setFieldValue(
                    "has_granulometry_bgs",
                    !checked && !values.has_granulometry_bgs
                  );
                }}
              />
              <InputCheckbox
                name="has_compaction"
                checked={values.has_compaction}
                label="Compactação"
                onClick={() =>
                  setFieldValue("has_compaction", !values.has_compaction)
                }
              />
              <InputCheckbox
                name="has_physical_limits"
                checked={values.has_physical_limits}
                label="Limites físicos"
                onClick={() =>
                  setFieldValue(
                    "has_physical_limits",
                    !values.has_physical_limits
                  )
                }
              />
              <InputCheckbox
                name="has_cbr"
                checked={values.has_cbr}
                label="Índice de suporte california (CBR)"
                onClick={() => setFieldValue("has_cbr", !values.has_cbr)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SampleForm;
