import React from "react";
import { FormikProps, Field } from "formik";
import {
  InputColumn,
  InputValidation,
  InputDecimal,
  InputText
} from "src/components/Inputs";

const Form: React.FC<FormikProps<FunnelValues>> = ({
  errors,
  setFieldValue
}) => {
  return (
    <>
      <InputColumn label="Número">
        <Field name="number" component={InputText} required={true} />
        <InputValidation message={errors.number} />
      </InputColumn>
      <InputColumn label="Peso (g)">
        <Field
          name="sand_weight"
          component={InputDecimal}
          required={true}
          onChange={(value: number) => setFieldValue("sand_weight", value)}
        />
        <InputValidation message={errors.sand_weight} />
      </InputColumn>
      <InputColumn label="Peso (mm)">
        <Field
          name="diameter"
          component={InputDecimal}
          required={true}
          onChange={(value: number) => setFieldValue("diameter", value)}
        />

        <InputValidation message={errors.diameter} />
      </InputColumn>
    </>
  );
};

export default Form;
