import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <div>
        <h4>Política de privacidade</h4>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12 left">
          <h4>Política de Privacidade Comercial</h4>

          <p>
            Esta política de privacidade define como o GM6 e quaisquer produtos,
            serviços, aplicativos ou integrações relacionados usam e protegem
            qualquer informação que você forneça ao usar nossos produtos,
            qualquer uma de nossas ofertas de aplicativos móveis ou de desktop e
            todos de nossas integrações com aplicativos e serviços de terceiros.
          </p>

          <p>
            GM6 está empenhado em garantir que sua privacidade seja protegida.
            Se solicitarmos que você forneça certas informações pelas quais você
            pode ser identificado ao usar nossos produtos, você pode ter certeza
            de que elas serão usadas apenas de acordo com esta declaração de
            privacidade. O que coletamos
          </p>

          <p>
            Podemos coletar certas informações de que precisamos para fornecer a
            você o serviço comercial e os produtos que você recebe de nós. As
            informações podem incluir o seguinte:
          </p>
          <ul>
            <li>Nome e cargo.</li>
            <li>Endereço profissional</li>
            <li>Número de telefone comercial</li>
            <li>Endereço de e-mail</li>
            <li>Endereço de IP</li>
            <li>Dados de localização</li>
          </ul>
          <h4>O que fazemos com a informação que recolhemos</h4>

          <p>
            Necessitamos desta informação para compreender as suas necessidades
            e prestar-lhe um melhor serviço e, em particular, pelas seguintes
            razões:
          </p>

          <p></p>

          <ul>
            <li>Manutenção de registros internos.</li>
            <li>
              Podemos usar as informações para melhorar nossos produtos e
              serviços.
            </li>
            <li>
              Nós nos comunicaremos com as pessoas que usam nossos produtos com
              base em seu tipo de uso e suas preferências de comunicação,
              conforme descrito aqui:
            </li>
            Usuários de avaliação gratuita: daremos suporte à sua experiência de
            avaliação gratuita enviando a você anúncios relacionados a produtos
            e serviços por e-mail sobre questões técnicas ou administrativas
            consideradas valiosas ou essenciais para sua capacidade contínua de
            usar o produto/serviço, por exemplo, notificações de término de
            avaliação gratuita. Usuários Pagos (incluindo clientes em um plano
            Gratuito): De tempos em tempos, podemos enviar a você avisos
            técnicos, atualizações, alertas de segurança e mensagens de suporte
            e administrativas e alertas de assinatura. Esses e-mails não são de
            natureza promocional. Se você optou por receber comunicações por
            e-mail, reservamo-nos o direito de enviar comunicações por e-mail
            (sobre ofertas promocionais, eventos, convites) de tempos em tempos,
            de acordo com suas preferências de comunicação. Os usuários que
            recebem esses materiais de marketing podem cancelar a qualquer
            momento. Ocasionalmente, também podemos usar suas informações para
            entrar em contato com você para fins de pesquisa de mercado. Podemos
            contatá-lo por e-mail, telefone, fax ou correio.
          </ul>
          <p>
            Limites adicionais de uso de seus dados de usuário do Google: Não
            obstante qualquer outra disposição desta Política de Privacidade, se
            você fornecer ao aplicativo acesso aos seguintes tipos de dados do
            Google, o uso desses dados pelo aplicativo estará sujeito a estas
            restrições adicionais:
          </p>

          <ul>
            <li>
              O aplicativo só usará acesso para ler, escrever, modificar ou
              controlar corpos de mensagens do Gmail (incluindo anexos),
              metadados, cabeçalhos e configurações para fornecer um cliente de
              e-mail da web que permite aos usuários compor, enviar, ler e
              processar e-mails e não transferir esses dados do Gmail para
              outras pessoas, a menos que isso seja necessário para fornecer e
              melhorar esses recursos, cumprir a lei aplicável ou como parte de
              uma fusão, aquisição ou venda de ativos.
            </li>

            <li>
              O aplicativo não usará esses dados do Gmail para veicular
              anúncios.
            </li>

            <li>
              O aplicativo não permitirá que humanos leiam esses dados, a menos
              que tenhamos seu consentimento afirmativo para mensagens
              específicas, isso é necessário para fins de segurança, como
              investigação de abuso, para cumprir a lei aplicável ou para as
              operações internas do aplicativo e, mesmo assim, somente quando o
              os dados foram agregados e anonimizados.
            </li>
            <li>
              O uso do aplicativo e a transferência para qualquer outro
              aplicativo de informações recebidas das APIs do Google estarão em
              conformidade com a Política de dados do usuário dos serviços de
              API do Google, incluindo os requisitos de uso limitado.
            </li>
          </ul>
          <h4>Segurança</h4>

          <p>
            Estamos empenhados em garantir que suas informações estejam seguras.
            Para evitar o acesso ou divulgação não autorizados, implementamos
            procedimentos físicos, eletrônicos e gerenciais adequados para
            salvaguardar e proteger as informações que coletamos online. Como
            usamos cookies
          </p>

          <p>
            Os cookies permitem que os aplicativos da web respondam a você como
            um indivíduo. O aplicativo da web pode adaptar suas operações às
            suas necessidades, gostos e desgostos, reunindo e lembrando
            informações sobre suas preferências.
          </p>

          <p>
            Para obter mais informações sobre nosso uso de cookies, leia nossa
            Política de Cookies.
          </p>

          <h4>Links para outros sites</h4>

          <p>
            Nosso site pode conter links para outros sites de interesse. No
            entanto, depois de usar esses links para sair do nosso site, você
            deve observar que não temos nenhum controle sobre esse outro site.
            Portanto, não podemos ser responsáveis pela proteção e privacidade
            de qualquer informação que você forneça enquanto visita tais sites e
            tais sites não são regidos por esta declaração de privacidade. Você
            deve ter cuidado e consultar a declaração de privacidade aplicável
            ao site em questão. Controlando suas informações pessoais
          </p>

          <p>
            Você pode optar por restringir a coleta ou o uso de suas informações
            pessoais das seguintes maneiras:
          </p>
          <ul>
            <li>
              Sempre que lhe for pedido para preencher um formulário no site,
              procure a caixa que você pode clicar para indicar que não deseja
              que a informação seja usada por ninguém para fins de marketing
              direto.
            </li>

            <li>
              Se você concordou anteriormente em usarmos suas informações
              pessoais para fins de marketing direto, você pode mudar de ideia a
              qualquer momento, escrevendo ou enviando um e-mail para
              privacyteamwork.com.
            </li>
          </ul>
          <p>
            Não venderemos, distribuiremos ou alugaremos suas informações
            pessoais a terceiros, a menos que tenhamos sua permissão ou sejamos
            obrigados por lei a fazê-lo. Podemos usar suas informações pessoais
            para lhe enviar informações promocionais sobre terceiros que achamos
            que você pode achar interessante se você nos disser que deseja que
            isso aconteça.
          </p>

          <p>
            Você pode solicitar detalhes das informações pessoais que mantemos
            sobre você. Se você acredita que qualquer informação que mantemos
            sobre você está incorreta ou incompleta, escreva ou envie um e-mail
            o mais rápido possível para contato@gm6.com.br. Corrigiremos
            prontamente qualquer informação considerada incorreta.
          </p>
        </div>
      </div>
    </div>
  );
}
