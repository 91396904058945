import React from "react";
import { Row, Col } from "reactstrap";
import { Field, FormikProps } from "formik";
import {
  InputColumn,
  InputText,
  InputDecimal,
  InputMoney,
  InputValidation
} from "../../../components/Inputs";
import {
  SelectSubDepartment,
  SelectUnit,
  SelectServiceControl
} from "../../../components/Selects";

const ServiceForm: React.FC<FormikProps<ServiceValuesProps>> = ({
  errors,
  setFieldValue
}) => {
  return (
    <Row>
      <Col>
        <InputColumn label="Nome">
          <Field name="name" component={InputText} required={true} />
          <InputValidation message={errors.name} />
        </InputColumn>
        <InputColumn label="Área do serviço">
          <Field
            name="sub_department"
            component={SelectSubDepartment}
            isClearable={false}
            required={true}
          />
          <InputValidation message={errors.sub_department} />
        </InputColumn>
        <InputColumn label="Tipo de controle">
          <Field
            name="type_control"
            component={SelectServiceControl}
            isClearable={false}
          />
          <InputValidation message={errors.type_control} />
        </InputColumn>
        <InputColumn label="Unidade">
          <Field
            name="unit"
            component={SelectUnit}
            required={true}
            isClearable={false}
          />
          <InputValidation message={errors.unit} />
        </InputColumn>
        <InputColumn label="Quantidade">
          <Field
            name="quantity"
            component={InputDecimal}
            required={true}
            onChange={(value: number) => {
              setFieldValue("quantity", value);
            }}
          />
          <InputValidation message={errors.quantity} />
        </InputColumn>
        <InputColumn label="Preço">
          <InputMoney name="price" required={true} />
          <InputValidation message={errors.price} />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default ServiceForm;
