import React from "react";
import {
  InputValidation,
  InputColumn,
  InputDecimal,
  InputCheckbox,
  InputText
} from "src/components/Inputs";
import { Field, FormikProps } from "formik";
import { Row, Col } from "reactstrap";

const Form: React.FC<FormikProps<Cylinder>> = ({
  values,
  errors,
  setFieldValue
}) => {
  return (
    <Row>
      <Col>
        <InputColumn label="Identificador">
          <Field name="number" component={InputText} required={true} />
          <InputValidation message={errors.number} />
        </InputColumn>
        <InputColumn label="Peso (g)">
          <Field
            name="weight"
            component={InputDecimal}
            required={true}
            onChange={(value: number) => setFieldValue("weight", value)}
          />
          <InputValidation message={errors.weight} />
        </InputColumn>
        <InputColumn label="Volume (cm³)">
          <Field
            name="volume"
            component={InputDecimal}
            required={true}
            onChange={(value: number) => setFieldValue("volume", value)}
          />
          <InputValidation message={errors.volume} />
        </InputColumn>
        <InputColumn label="Altura (cm)">
          <Field
            name="height"
            component={InputDecimal}
            required={true}
            onChange={(value: number) => setFieldValue("height", value)}
          />
          <InputValidation message={errors.height} />
        </InputColumn>
        <InputCheckbox
          label={"Cilindro para ensaio de Insitu pelo método Hilfe"}
          name="usage"
          checked={values.usage === "I"}
          onClick={() =>
            setFieldValue("usage", values.usage === "I" ? "S" : "I")
          }
        />
      </Col>
    </Row>
  );
};

export default Form;
