import React, { useState } from "react";
import { Container } from "../../../components/Container";
import { Formik, Form, Field, FormikActions } from "formik";
import { InputText, InputColumn } from "../../../components/Inputs";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { resetPasswordEmail } from "src/services/api/auth";

const CheckEmail = () => {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [failMessage, setFailMessage] = useState<string>("");

  const onSubmit = (values: any, { setFieldValue }: FormikActions<any>) => {
    resetPasswordEmail(values.email)
      .then(() => {
        setFieldValue("email", "");
        setSuccessMessage(
          `Um link para redefinir sua senha foi enviado para ${values.email}`
        );
      })
      .catch(() => {
        setFailMessage(`Não existe conta para ${values.email}`);
      });
  };

  const renderForm = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{}}
        render={() => {
          return (
            <Form>
              <InputColumn label="Email">
                <Field
                  name="email"
                  type="email"
                  component={InputText}
                  required
                />
              </InputColumn>
              {successMessage.length > 0 && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {failMessage.length > 0 && (
                <div className="alert alert-warning" role="alert">
                  {successMessage}
                </div>
              )}
              <Button
                className="mt-2"
                color="primary"
                size="sm"
                block
                type="submit"
              >
                Enviar e-mail
              </Button>
            </Form>
          );
        }}
      />
    );
  };

  const render = () => {
    return renderForm();
  };

  return (
    <Container title="Trocar senha">
      <Row className="justify-content-center">
        <Col sm={4} lg={4}>
          <div className="text-center w-75 pb-4 m-auto">
            <span>
              <img
                src={process.env.PUBLIC_URL + "/static/assets/images/logo.svg"}
                alt=""
                height="40"
              />
            </span>
          </div>
          <Card className="pl-2 pr-2">
            <CardBody>
              <Row>
                <Col>
                  <div className=" mb-1">
                    <h4>Esqueceu sua senha?</h4>
                  </div>
                  <p className="text-muted mb-3">
                    Preencha o email e vamos enviar um link para alterar sua
                    senha.
                  </p>
                  {render()}
                  <p className="mt-3 mb-1">
                    <label className="text-sm-right text-bold">
                      <u>
                        <a href="/login" className="text-dark">
                          Voltar para o login
                        </a>
                      </u>
                    </label>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckEmail;
