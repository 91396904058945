/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ContainerFluid } from "src/components/Container";
import { Row, Col, Card, CardBody } from "reactstrap";
import ContractFilterBar from "src/components/Selects/ContractFilterBar";
import { ListButtonRow, ButtonDelete } from "src/components/Buttons";
import TechControl from "../components/TechControl";
import * as clientApi from "../../../services/api/client";
import * as contractApi from "../../../services/api/contract";
import { InsertLot, EditLot } from "./Lot";
import { useData, TableList } from "src/components/Table";
import { columnsLot, columnsSamples } from "./columns";
import { HeaderTitle } from "src/components/Headers";
import {
  getLots,
  getSamples,
  deleteLot
} from "src/services/api/block_concrete";
import { EditTestingBody } from "./Sample";
import LotSummary from "./LotSummary";
import { LoadingComponent } from "src/components/Loader";
import { ReportButton, ReportRow } from "src/components/Report";
import ReportBar from "./ReportBar";

const PAGE_TITLE = "Bloco de concreto";

const BlockConcrete = () => {
  const [contractId, setContractId] = useState<number>();
  const lot = useData<TBlockConcreteLot>();
  const [lots, setLots] = useState<TBlockConcreteLot[]>([]);
  const cp = useData<TBlockConcreteTestingBody>();
  const [cps, setCps] = useState<TBlockConcreteTestingBody[]>([]);
  const [dbClickLot, setDbClickLot] = useState<boolean>(false);
  const [dbClickCp, setDbClickCp] = useState<boolean>(false);
  const [loadingLots, setLoadingLots] = useState<boolean>(false);
  const [loadingCps, setLoadingCps] = useState<boolean>(false);

  const getClients = () => {
    return clientApi.getClients("block_concrete", "1", "id,name");
  };

  const getContracts = (clientId: number) => {
    return contractApi.getContracts(
      "",
      `${clientId}`,
      "",
      "",
      "block_concrete",
      "1"
    );
  };

  useEffect(() => {
    lot.selected.set(undefined);
    if (contractId !== undefined) {
      setLoadingLots(true);
      getLots(contractId)
        .then(({ data }) => {
          setLots(data);
        })
        .finally(() => {
          setLoadingLots(false);
        });
    } else {
      setLots([]);
    }
  }, [contractId]);

  useEffect(() => {
    cp.selected.set(undefined);
    if (lot.selected.item !== undefined) {
      setLoadingCps(true);
      getSamples(lot.selected.item.id!)
        .then(({ data }) => {
          setCps(data);
        })
        .finally(() => {
          setLoadingCps(false);
        });
    } else {
      setCps([]);
    }
  }, [lot.selected.item]);

  const onDeleteLot = () => {
    if (lot.selected.item && lot.selected.item.id !== undefined) {
      return deleteLot(lot.selected.item.id)
        .then(({ data }) => {
          lot.deleted.set(lot.selected.item);
          setCps([]);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  const renderLots = () => {
    return (
      <Card className="filter-bar">
        <CardBody>
          <Row>
            <Col sm={5}>
              <HeaderTitle title="Lotes" />
            </Col>
            <Col sm={7}>
              <Row>
                <Col>
                  <HeaderTitle title="Amostras" />
                </Col>
                <Col className="text-right">
                  <ReportButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <ReportRow>
            <ReportBar contractId={contractId!} />
          </ReportRow>
          <Row>
            <Col sm={5}>
              <LoadingComponent loading={loadingLots}>
                <TableList
                  tableId="lots"
                  data={lots}
                  columns={columnsLot}
                  selected={lot.selected.set}
                  updated={lot.updated.item}
                  created={lot.created.item}
                  deleted={lot.deleted.item}
                  searching={false}
                  dbClick={item => {
                    lot.selected.set(item);
                    setDbClickLot(true);
                    setDbClickLot(false);
                  }}
                />
              </LoadingComponent>
              <ListButtonRow
                buttonsLeft={
                  <>
                    <InsertLot
                      insertedItem={lot.created.set}
                      contractId={contractId!}
                    />
                    <EditLot
                      item={lot.selected.item!}
                      updatedItem={lot.updated.set}
                      dbClick={dbClickLot}
                    />
                  </>
                }
                buttonsRight={
                  <ButtonDelete
                    disabled={!lot.selected.item}
                    onClick={onDeleteLot}
                  />
                }
                marginTop="mt-2"
              />
            </Col>
            <Col sm={7}>
              <LoadingComponent loading={loadingCps}>
                <TableList
                  tableId="cps"
                  data={cps}
                  columns={columnsSamples}
                  selected={cp.selected.set}
                  updated={cp.updated.item}
                  created={cp.created.item}
                  deleted={cp.deleted.item}
                  searching={false}
                  dbClick={item => {
                    cp.selected.set(item);
                    setDbClickCp(true);
                    setDbClickCp(false);
                  }}
                />
              </LoadingComponent>
              <ListButtonRow
                buttonsLeft={
                  <>
                    <EditTestingBody
                      item={cp.selected.item!}
                      lot={lot.selected.item!}
                      updatedItem={cp.updated.set}
                      dbClick={dbClickCp}
                    />
                  </>
                }
                marginTop="mt-2"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  const renderControl = () => {
    return (
      <Row>
        <Col sm={9}>{renderLots()}</Col>
        <Col sm={3}>
          <LotSummary lot={lot.selected.item} />
        </Col>
      </Row>
    );
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <ContractFilterBar
        clientRequest={getClients}
        contractRequest={getContracts}
        onContractChange={setContractId}
      />
      <TechControl contract={contractId} renderControl={renderControl} />
    </ContainerFluid>
  );
};

export default BlockConcrete;
