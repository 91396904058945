import React from "react";
import {
  InputValidation,
  InputColumn,
  InputDecimal,
  InputText
} from "src/components/Inputs";
import { Field, FormikProps } from "formik";
import { Row, Col } from "reactstrap";

const Form: React.FC<FormikProps<Capsule>> = ({ errors, setFieldValue }) => {
  return (
    <Row>
      <Col>
        <InputColumn label="Identificador">
          <Field name="number" component={InputText} />
          <InputValidation message={errors.number} />
        </InputColumn>
        <InputColumn label="Peso (g)">
          <Field
            name="weight"
            component={InputDecimal}
            required={true}
            onChange={(value: number) => setFieldValue("weight", value)}
          />
          <InputValidation message={errors.weight} />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default Form;
