import React, { useState } from "react";
import { FormikProps, Field } from "formik";
import { InputColumn, InputText } from "src/components/Inputs";
import { Row, Col, Tooltip } from "reactstrap";
import { formatCNPJ } from "src/utils/format";

const Form: React.FC<FormikProps<CompanyValuesProps>> = ({ values }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const renderCompanyData = () => {
    return (
      <>
        <InputColumn label="CNPJ">
          <p>{formatCNPJ(values.register_code)}</p>
        </InputColumn>
        <InputColumn label="Nome social">
          <p>{values.social_name}</p>
        </InputColumn>
        <InputColumn label="Nome fantasia">
          <Field name="name" component={InputText} required />
        </InputColumn>
        <InputColumn
          label="E-mail"
          create={<i id="dates" className="mdi ml-1 mdi-alert-circle" />}
        >
          <Field name="email" type="email" component={InputText} />
          {/* <SmallText></SmallText> */}
          <Tooltip
            className="m-tooltip-inner"
            placement="right"
            isOpen={tooltipOpen}
            target="dates"
            toggle={toggle}
          >
            Todos os e-mails (cobrança, avisos, novidades) referentes a esta
            empresa serão enviados para este email. Caso se mantenha vazio, os
            emails serão direcionados para o administrador desta conta.
          </Tooltip>
        </InputColumn>
      </>
    );
  };

  return (
    <Row>
      <Col>{renderCompanyData()}</Col>
      <Col>
        <InputColumn label="Endereço">
          <Field name="address" component={InputText} required />
        </InputColumn>
        <InputColumn label="CEP">
          <Field name="cep" component={InputText} required />
        </InputColumn>
        <InputColumn label="Contato">
          <Field name="contact_name" component={InputText} required />
        </InputColumn>
        <InputColumn label="Telefone">
          <Field name="contact_phone_one" component={InputText} required />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default Form;
