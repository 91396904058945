/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Field } from "formik";
import {
  InputColumn,
  InputDecimal,
  InputRow
} from "../../../../../../components/Inputs";
import { SelectCylinder } from "../../../../../../components/Selects";
import { toLocateNumber } from "../../../../../../utils/format";
import * as math from "../../../../../../utils/math";
import { useCylinders } from "../../../../../../context/tech_control/cylinder";

interface Props {
  name: string;
  errors: object;
  humidity: number;
  values?: any;
  setFieldValue: (field: string, value: any) => void;
  onWetDensityChange?: (value: number) => void;
  onDryDensityChange?: (value: number) => void;
}

interface StudyProps extends Props {}

const CylinderStudy: React.FC<StudyProps> = ({
  name,
  humidity,
  values,
  setFieldValue,
  onDryDensityChange
}) => {
  const [cylinder, setCylinder] = useState<Cylinder>();
  const [wetSoilWeight, setWetSoilWeight] = useState<number>(0);
  const [wetDensity, setWetDensity] = useState<number>(0);
  const [dryDensity, setDryDensity] = useState<number>(0);
  const [cylinders] = useCylinders();

  useEffect(() => {
    setWetSoilWeight(
      values.cylinder_study.wet_soil_weight - (cylinder ? cylinder.weight : 0)
    );
  }, [values.cylinder_study.wet_soil_weight, cylinder?.id]);

  useEffect(() => {
    if (values.cylinder_study.cylinder !== undefined) {
      const fullCylinder = cylinders.data.filter(
        item => item.id === values.cylinder_study.cylinder
      );
      if (fullCylinder.length > 0) {
        setCylinder(fullCylinder[0]);
      }
    }
  }, [values.cylinder_study.cylinder, cylinders.data]);

  useEffect(() => {
    setWetDensity(
      math.wetBulkDensity(wetSoilWeight, cylinder ? cylinder.volume : 0)
    );
  }, [wetSoilWeight, wetDensity]);

  useEffect(() => {
    const value = math.dryBulkDensity(wetDensity, humidity);
    setDryDensity(value);
    if (onDryDensityChange) onDryDensityChange(value);
  }, [wetDensity, humidity]);

  // const renderCylinder = useMemo(() => {
  return (
    <Row>
      <Col className="pl-1 pr-1">
        {/* Cylinder */}
        <InputColumn>
          <Field
            component={SelectCylinder}
            name={`${name}.cylinder_study.cylinder`}
            onChange={(item: Cylinder) => {
              setFieldValue(`${name}.cylinder_study.cylinder`, item.id);
            }}
            required
            type="sample"
          />
        </InputColumn>

        {/* Peso bruto úmido */}
        <InputColumn>
          <Field
            component={InputDecimal}
            name={`${name}.cylinder_study.wet_soil_weight`}
            onChange={(value: number) => {
              setFieldValue(`${name}.cylinder_study.wet_soil_weight`, value);
            }}
            required
          />
        </InputColumn>

        <div className="text-center">
          {/* Peso do cilindro */}
          <InputRow
            label={toLocateNumber(cylinder ? cylinder.weight : 0, 3, 0)}
          />

          {/* Peso do solo úmido */}
          <InputRow label={toLocateNumber(wetSoilWeight, 3, 0)} />

          {/* Volume do cilindro */}
          <InputRow
            label={toLocateNumber(cylinder ? cylinder.volume : 0, 3, 0)}
          />

          {/* Densidade aparente umida */}
          <InputRow label={toLocateNumber(wetDensity, 3, 0)} />

          {/* Densidade aparente seca */}
          <InputRow label={toLocateNumber(dryDensity, 3, 0)} />
        </div>
      </Col>
    </Row>
  );
  // }, [wetSoilWeight, cylinder?.id, wetDensity, dryDensity]);

  // return <>{renderCylinder}</>;
};

const CylinderStudyLabels = () => (
  <Row>
    <Col>
      <InputRow label="Cilindro" />
      <InputRow label="Cilindro + solo úmido (g)" />
      <InputRow label="Peso do cilindro (g)" />
      <InputRow label="Peso do solo úmido (g)" />
      <InputRow label="Volume do cilindro (cm³)" />
      <InputRow label="Dens. apar. umida (g/cm³)" />
      <InputRow label="Dens. apar. seca (g/cm³)" />
    </Col>
  </Row>
);

export { CylinderStudyLabels };
export default CylinderStudy;
