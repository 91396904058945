import React from "react";
import { InputSelect } from "../Inputs";

const SelectBlockWallStructure: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: "H", label: "Maciça" },
      { value: "V", label: "Vazada" }
    ];
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Mês..."
      onClick={props.onChange}
    />
  );
};

export default SelectBlockWallStructure;
