import React from "react";

interface Props {
  title: string;
  className?: string;
}

const HeaderTitle: React.FC<Props> = ({ title, className }) => (
  <h4 className={`header-title ${className}`}>{title}</h4>
);

HeaderTitle.defaultProps = {
  className: ""
};

export default HeaderTitle;
