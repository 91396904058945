import React from "react";
import { Field, FormikProps } from "formik";
import {
  InputColumn,
  InputCheckbox,
  InputText
} from "../../../components/Inputs";
import { SelectISCPress } from "../../../components/Selects";
import { SmallText } from "src/components/Text";

const ControlForm: React.FC<FormikProps<SoilControl | undefined>> = ({
  values,
  setFieldValue
}) => {
  return (
    <>
      <InputColumn label="Prensa CBR">
        <Field name="press" component={SelectISCPress} required />
      </InputColumn>
      <InputCheckbox
        name="approved_default"
        label="Liberar amostras automaticamento para o cliente"
        checked={values?.approved_default}
        onClick={() =>
          setFieldValue("approved_default", !values?.approved_default)
        }
      />
      <InputCheckbox
        name="code_control"
        label="Numerar as amostras desta obra de 1 a x"
        checked={values?.code_control}
        onClick={() => setFieldValue("code_control", !values?.code_control)}
      />
      <InputColumn label="Código de relatório">
        <Field name="report_code" component={InputText} required />
        <SmallText>
          É possível utilizar as seguintes variáveis como nome do relatório:{" "}
          {"{{sample_code}}"}, {"{{today_year}}"}, {"{{today_month}}"},{" "}
          {"{{today_day}}"}
        </SmallText>
      </InputColumn>
    </>
  );
};

export default ControlForm;
