const capsuleStudy = {
  id: undefined,
  wet_soil_weight: undefined,
  dry_soil_weight: undefined,
  capsule_weight: undefined,
  capsule: undefined
};

const cylinderStudy = {
  id: undefined,
  wet_soil_weight: undefined,
  cylinder_weight: undefined,
  cylinder_volume: undefined,
  cylinder: undefined
};

const initialValues = {
  id: undefined,
  cylinders: [0, 0, 0, 0, 0].map((_, index) => ({
    id: undefined,
    cylinder_study: cylinderStudy,
    number: index
  })),
  capsules: [0, 0, 0, 0, 0].map((_, index) => ({
    id: undefined,
    capsule_study: capsuleStudy,
    number: index,
    is_hygroscopic: false,
    soil_weight: 0.0,
    water_weight: 0.0
  })),
  hygroscopic_capsules: [0, 0].map((_, index) => ({
    id: undefined,
    capsule_study: capsuleStudy,
    number: index,
    is_hygroscopic: true,
    soil_weight: 0.0,
    water_weight: 0.0
  })),
  review: 0,
  notes: "",
  execution_date: "",
  is_not_plastic: false,
  is_not_liquid: false,
  application: undefined,
  sample: undefined,
  executor: undefined
};

export default initialValues;
