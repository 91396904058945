const CP_AGES = [12, 1, 3, 7, 14, 28, 63].map(item => item * 24);

export function calculateFck(
  load: number,
  sideOne: number,
  sideTwo: number,
  flexion: boolean = false
) {
  if (flexion) {
    return (1.5 * load * 10000 * 100) / Math.pow(Math.max(sideOne, 1), 3);
  }

  return (load * 10000) / parseFloat((sideOne * sideTwo).toFixed(3));
}

export { CP_AGES };
