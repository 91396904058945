import React from "react";
import Input from "./Input";

interface Props {
  field?: any;
  form?: string;
  name: string;
  required: boolean;
  disabled: boolean;
  placeholder?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<Props> = ({
  field,
  form,
  name,
  required,
  disabled,
  onChange,
  ...props
}) => (
  <Input
    name={name}
    type="text"
    disabled={disabled}
    required={required}
    field={field}
    onChange={onChange}
    {...props}
  />
);

InputText.defaultProps = {
  required: false,
  disabled: false
};

export default InputText;
