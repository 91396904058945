/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import { HeaderTitle } from "../../../components/Headers";
import { toLocateNumber } from "../../../utils/format";
import { getUsage, getPlatformPrices } from "../../../services/api/usage";
import { SelectMonthYear } from "../../../components/Selects";
import { InputColumn } from "../../../components/Inputs";
import Chart from "./Chart";
import moment from "moment";
import { Container } from "../../../components/Container";

const PAGE_TITLE = "Uso e faturamento";

interface ItemProps {
  id: string;
  name: string;
  price: number;
  tooltipDetail?: [string, number | undefined | { [key: string]: number }][];
}

const BillItem: React.FC<ItemProps> = ({ id, name, price, tooltipDetail }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const getValue = (item: number | undefined | { [key: string]: number }) => {
    if (typeof item !== "number" && item !== undefined) {
      return Object.values(item).reduce((a, b) => a + b, 0);
    }

    return item;
  };

  const renderTooltip = () => {
    return (
      <Tooltip
        className="m-tooltip-inner"
        placement="top"
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
      >
        {tooltipDetail !== undefined && (
          <>
            {tooltipDetail.map(item => (
              <Row>
                <Col className="text-left text-muted">{item[0]}</Col>
                <Col sm="auto">
                  <strong>
                    {toLocateNumber(getValue(item[1]), 2, "", "R$ ")}
                  </strong>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Tooltip>
    );
  };

  return (
    <Row>
      <Col>
        <h5>
          {name}
          {tooltipDetail && (
            <>
              <i id={id} className="mdi ml-1 mdi-alert-circle-outline" />
              {renderTooltip()}
            </>
          )}
        </h5>
      </Col>
      <Col sm="auto">
        <h4 className="card-pricing-price">
          {toLocateNumber(price, 2, "", "R$ ")}
        </h4>
      </Col>
    </Row>
  );
};

const UsageList = () => {
  const [usage, setUsage] = useState<ApplicationBill>();
  const [price, setPrices] = useState<ApplicationBill>();
  const [currentMonth, setCurrentMonth] = useState<number>(moment().month());

  useEffect(() => {
    updateUsage();
  }, []);

  const updateUsage = (month = "", year = "") => {
    getPlatformPrices(month, year).then(() => {
      setPrices(price);
      getUsage(month, year).then(({ data }) => setUsage(data));
    });
  };

  const onChangeSeason = (item: { value: [number, number]; label: string }) => {
    setCurrentMonth(item.value[0] - 1);
    updateUsage(item.value[0].toString(), item.value[1].toString());
  };

  return (
    <Container title={PAGE_TITLE}>
      <>
        {usage !== undefined && (
          <Card>
            <CardBody>
              <Row>
                <Col sm={7}>
                  <Chart
                    soilModule={usage.total.soil_module}
                    concreteModule={usage.total.concrete_module}
                    soil={usage.dynamic_bill.samples}
                    holes={usage.dynamic_bill.holes}
                    concrete={usage.dynamic_bill.concrete_tbs}
                    month={currentMonth}
                  />
                  <small>
                    Os dados de uso estão disponíveis apenas para acompanhamento
                    durante o mês corrente.
                  </small>
                </Col>
                <Col sm={5}>
                  <HeaderTitle title="Módulos" />
                  <InputColumn>
                    <SelectMonthYear
                      onChange={onChangeSeason}
                      minMonth={1}
                      minYear={2018}
                    />
                  </InputColumn>
                  <hr />
                  <BillItem
                    id="soil"
                    name="Controle de qualidade de solos"
                    price={usage.total.soil_total}
                    tooltipDetail={[
                      ["Módulo de solos", usage.total.soil_module],
                      ["Amostra de solos", usage.total.samples],
                      ["Furos de insitu", usage.total.holes]
                    ]}
                  />
                  <hr />
                  <BillItem
                    id="concrete"
                    name="Controle de qualidade de concreto"
                    price={usage.total.concrete_total}
                    tooltipDetail={[
                      ["Módulo de concreto", usage.total.concrete_module],
                      ["Corpos de prova", usage.total.concrete_tbs],
                      ["CPs de bloco de concreto", 0],
                      ["CPs de bloco cerâmico", 0]
                    ]}
                  />
                  <hr />
                  <BillItem
                    id="invoice"
                    name="Controle de medições e equipes"
                    price={usage.total.invoice_total}
                    tooltipDetail={[
                      ["Módulo de medições", usage.total.invoice_module],
                      ["Usuários adicionais", 0]
                    ]}
                  />
                  <hr />
                  <BillItem id="total" name="TOTAL" price={usage.total.total} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </>
    </Container>
  );
};

export default UsageList;
