import React from "react";

const DIV_NAME = "reports";

interface Props {
  children: React.ComponentType<any> | React.ReactElement | null;
}

const ReportRow: React.FC<Props> = ({ children }) => (
  <div className="collapse" id={`${DIV_NAME}`}>
    {children}
  </div>
);

export default ReportRow;
