/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { InputSelect } from "../Inputs";

type Option = {
  value: any;
  label: string;
};

interface Props {
  placeholder: string;
  newItem: any;
  deletedItem: any;
  updatedItem: any;
  options: Array<Option>;
  isMulti?: boolean;
  selectedOption?: Option;
  getItems: () => void;
  onClick?: (value: any) => void;
}

const SelectBase: React.FC<Props> = props => {
  useEffect(() => {
    if (props.newItem || props.deletedItem || props.updatedItem) {
      props.getItems();
    }
  }, [props.newItem, props.deletedItem, props.updatedItem]);

  return (
    <InputSelect
      onClick={props.onClick}
      isMulti={props.isMulti}
      selectedValue={props.selectedOption}
      {...props}
    />
  );
};

export default SelectBase;
