import React from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Field } from "formik";
import {
  InputColumn,
  InputInteger,
  InputValidation,
  InputDate,
  InputDecimal,
  InputText,
  InputSelect
} from "../../../../../components/Inputs";
import { HeaderTitle } from "../../../../../components/Headers";

interface Props extends FormikProps<TGrauteSeries> {
  control: TGrauteControl;
}

const FormPut: React.FC<Props> = ({ control, errors, setFieldValue }) => {
  const providerOptions = () => {
    return control.providers.split(";").map(item => {
      return { value: item, label: item };
    });
  };

  return (
    <Row>
      <Col sm={12}>
        <HeaderTitle title="Informações da série" />
        <InputColumn label="Número da série">
          <Field name="number" component={InputInteger} required />
          <InputValidation message={errors.number} />
        </InputColumn>
        <InputColumn label="Data de moldagem">
          <Field name="molding_date" component={InputDate} required />
        </InputColumn>
        <InputColumn label="Chegada ao laboratório">
          <Field name="lab_arrival" component={InputDate} required />
        </InputColumn>
        <InputColumn label="FCK (MPa)">
          <Field
            name="fck"
            component={InputDecimal}
            required
            onChange={(value: number) => {
              setFieldValue("fck", value);
            }}
          />
        </InputColumn>
        {/* <hr /> */}
        <HeaderTitle title="DADOS DO GRAUTE" />
        <InputColumn label="Marca do graute">
          <Field
            name="provider"
            component={InputSelect}
            required
            options={providerOptions()}
          />
        </InputColumn>
        <InputColumn label="Torre">
          <Field name="tower" component={InputText} required />
        </InputColumn>
        <InputColumn label="Local">
          <Field name="join" component={InputText} required />
        </InputColumn>
        <InputColumn label="Junta">
          <Field name="link" component={InputText} />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default FormPut;
