import React from "react";
import { FormikProps, Field } from "formik";
import {
  InputColumn,
  InputValidation,
  InputDate,
  InputDecimal
} from "src/components/Inputs";
import { SelectISCPress } from "src/components/Selects";

const Form: React.FC<FormikProps<PressCalibration>> = ({
  errors,
  setFieldValue
}) => {
  return (
    <>
      <InputColumn label="Prensa">
        <Field name="press" component={SelectISCPress} required={true} />
        <InputValidation message={errors.press} />
      </InputColumn>
      <InputColumn label="Data de calibração">
        <Field name="calibration_date" component={InputDate} required={true} />
        <InputValidation message={errors.calibration_date} />
      </InputColumn>
      <InputColumn label="Constante">
        <Field
          name="constant"
          component={InputDecimal}
          precision={4}
          required={true}
          onChange={(value: number) => setFieldValue("constant", value)}
        />
        <InputValidation message={errors.constant} />
      </InputColumn>
    </>
  );
};

export default Form;
