import React from "react";
import { Field } from "formik";

interface Props {
  name: string;
  description?: string;
  checked?: boolean;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

const InputCheckbox: React.FC<Props> = ({
  name,
  checked,
  label,
  disabled,
  description,
  onClick
}) => (
  <div className="form-group mb-1">
    <div className="custom-control custom-checkbox">
      <Field
        className="custom-control-input"
        name={name}
        id={name}
        type="checkbox"
        checked={checked}
        onChange={onClick}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={name}>
        {label}
      </label>
    </div>
    {description && (
      <span className="help-block">
        <small>{description}</small>
      </span>
    )}
  </div>
);

InputCheckbox.defaultProps = {
  checked: false,
  disabled: false
};

export default InputCheckbox;
