/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { Row, Col } from "reactstrap";
import { InputDecimal, InputRow } from "../../../../../../components/Inputs";
import { toLocateNumber } from "../../../../../../utils/format";
import { EditTestAccessPermission } from "../../../../../../components/Permissions";
import * as math from "../../../../../../utils/math";
import { useConstant } from "../context";

const LabelHorizontal = ({ label }: { label: string }) => (
  <label className="col-sm-2 col-form-label pl-1 pr-1">{label}</label>
);

const PenetrationsHeader = () => (
  <Row className="text-center mb-2">
    <LabelHorizontal label="min" />
    <LabelHorizontal label="mm" />
    <LabelHorizontal label="Leitura extens." />
    <LabelHorizontal label="Determ." />
    <LabelHorizontal label="Corrigido" />
    <LabelHorizontal label="%" />
  </Row>
);

interface ReadProps {
  name: string;
  time: number;
  penetration: number;
  value: number;
  onChangeDetermined: (value: number) => void;
  setFieldValue: (field: string, value: any) => void;
}
// export const Leitura = () =>
const PenetrationRead: React.FC<ReadProps> = ({
  name,
  time,
  penetration,
  value,
  onChangeDetermined,
  setFieldValue
}) => {
  const [constant] = useConstant();
  const [determined, setDetermined] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    const determined = math.readDetermined(value, constant);
    setDetermined(determined);
  }, [value, constant]);

  useEffect(() => {
    const percentage = math.penetrationPercentage(time, determined);
    onChangeDetermined(percentage);
    setPercentage(math.penetrationPercentage(time, determined));
  }, [determined]);

  return (
    <div className="form-group row align-items-center text-center">
      {/* time */}
      <LabelHorizontal label={`${toLocateNumber(time / 60, 1)}`} />

      {/* Millimeters */}
      <LabelHorizontal label={toLocateNumber(penetration, 2)} />
      {/* Read */}
      <Col sm={2} className="pl-1 pr-1">
        <EditTestAccessPermission
          renderNoAccess={() => <InputRow label={toLocateNumber(10.9, 3)} />}
        >
          <Field
            name={`${name}.read`}
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`${name}.read`, value);
            }}
            required
          />
        </EditTestAccessPermission>
      </Col>

      {/* Determined */}
      <LabelHorizontal label={toLocateNumber(determined, 2)} />

      {(time === 120 || time === 240) && (
        <>
          {/* Corrected */}
          <LabelHorizontal label={toLocateNumber(determined, 2)} />

          {/* Percentage */}
          <LabelHorizontal label={toLocateNumber(percentage * 100, 2)} />
        </>
      )}
    </div>
  );
};

export { PenetrationRead, PenetrationsHeader };
