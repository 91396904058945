import React from "react";
import { Row, Col } from "reactstrap";
import { Form, Field, FormikProps } from "formik";
import {
  InputRow,
  InputDate,
  InputValidation,
  InputInteger
} from "../../../components/Inputs";

type SampleArrival = {
  arrival_date: string;
  amount: number;
};

const SampleArrivalForm: React.FC<FormikProps<SampleArrival>> = ({
  errors,
  setFieldValue
}) => {
  return (
    <Form className="form-horizontal">
      <Row>
        <Col>
          <InputRow label_col={5} label="Data de chegada">
            <Field name="arrival_date" component={InputDate} required />
            <InputValidation message={errors.arrival_date} />
          </InputRow>
          <InputRow label_col={5} label="Quantidade">
            <Field
              name="amount"
              component={InputInteger}
              onChange={(event: any) => {
                setFieldValue("amount", event.target.value);
              }}
              required
            />
            <InputValidation message={errors.amount} />
          </InputRow>
        </Col>
      </Row>
    </Form>
  );
};

export default SampleArrivalForm;
