import React, { useEffect, useRef } from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";

const maskValidation = /^\d+$/;

const validate = (value: string) => {
  let errorMessage;
  if (!maskValidation.test(value)) {
    errorMessage = "Código inválido";
  }
  return errorMessage;
};

const mask = (rawValue: string) => {
  // Match com uma letra
  // if ((rawValue || "").match(/^\d+$/)) {
  //   return rawValue.split("").map(() => /\d/);
  // }

  // Padrão match com duas letras e dois números
  return rawValue.split("").map(() => /\d/);
};

interface Props {
  name: string;
  required: boolean;
  disabled?: boolean;
}

const InputCPCode: React.FC<Props> = ({ name, required, disabled }) => {
  const mRef = useRef<MaskedInput>(null);

  useEffect(() => {
    mRef.current?.inputElement.focus();
  }, []);

  return (
    <Field
      name={name}
      validate={validate}
      render={({ field, form: { setFieldValue } }: any) => (
        <MaskedInput
          {...field}
          ref={mRef}
          autoFocus
          className="form-control form-control-sm"
          disabled={disabled}
          required={required}
          mask={mask}
          guide={false}
          onChange={event => {
            let { value } = event.target;
            setFieldValue(name, value);
          }}
        />
      )}
    />
  );
};

export default InputCPCode;
