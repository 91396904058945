import api from "./api";

const getClients = (group = "", active = "", fields = "", department = "") => {
  const endpoint = `/clients/?group=${group}&active=${active}&department=${department}&fields=${fields}`;
  return api.get(endpoint);
};

const getClient = (id: number) => {
  const endpoint = `/clients/${id}/`;
  return api.get(endpoint);
};

const putClient = (id: number, data: any) => {
  const endpoint = `/clients/${id}/`;
  return api.put(endpoint, data);
};

const postClient = (data: any) => {
  const endpoint = "/clients/";
  return api.post(endpoint, data);
};

const deleteClient = (id: number) => {
  const endpoint = `/clients/${id}/`;
  return api.delete(endpoint);
};

export { getClient, getClients, putClient, postClient, deleteClient };
