import React, { ReactNode } from "react";
import { Row, Col } from "reactstrap";
import { LoadingComponent } from "../../../components/Loader";
import { TextMuted } from "../../../components/Text";

interface Props {
  contract: any;
  control?: any;
  loadingControl?: boolean;
  renderControl: () => ReactNode;
  insertControl?: () => ReactNode;
  renderBeforeControl?: () => ReactNode;
}

const TechControl: React.FC<Props> = ({
  contract,
  control,
  loadingControl,
  insertControl,
  renderControl,
  renderBeforeControl
}) => {
  const renderContent = () => {
    if (!contract && renderBeforeControl !== undefined) {
      return renderBeforeControl();
    } else if (!contract) {
      return <TextMuted>Uma obra deve ser selecionada</TextMuted>;
    }

    if (!control && insertControl !== undefined) {
      return (
        <>
          <TextMuted>
            O controle deste ensaio ainda não foi configurado
          </TextMuted>
          {insertControl()}
        </>
      );
    }

    return <div className="text-left">{renderControl()}</div>;
  };

  return (
    <Row className="text-center align-items-center mt-2">
      <Col>
        <LoadingComponent loading={!!loadingControl}>
          {renderContent()}
        </LoadingComponent>
      </Col>
    </Row>
  );
};

TechControl.defaultProps = {
  loadingControl: false
};

export default TechControl;
