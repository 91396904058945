import React from "react";
import { Row, Col } from "reactstrap";
import { Field, FormikProps } from "formik";
import {
  InputColumn,
  InputValidation,
  InputText,
  InputPhone
} from "../../../components/Inputs";
import { SelectClient } from "../../../components/Selects";
import { getClients } from "../../../services/api/client";

const WorkForm: React.FC<FormikProps<WorkFormValues>> = ({ errors }) => {
  return (
    <Row>
      <Col>
        <InputColumn label="Cliente">
          <Field
            name="client"
            request={getClients}
            component={SelectClient}
            isClearable={false}
            required
          />
          <InputValidation message={errors.client} />
        </InputColumn>
        <InputColumn label="Nome">
          <Field name="name" component={InputText} required />
          <InputValidation message={errors.name} />
        </InputColumn>
        <InputColumn label="CEI">
          <Field name="register_code" component={InputText} />
          <InputValidation message={errors.register_code} />
        </InputColumn>
        <InputColumn label="Endereço">
          <Field name="address" component={InputText} required />
          <InputValidation message={errors.address} />
        </InputColumn>
        <InputColumn label="Contato">
          <Field name="contact_name" component={InputText} />
          <InputValidation message={errors.contact_name} />
        </InputColumn>
        <InputColumn label="Telefone">
          <InputPhone name="contact_phone_one" />
          <InputValidation message={errors.contact_phone_one} />
        </InputColumn>
      </Col>
    </Row>
  );
};

export default WorkForm;
