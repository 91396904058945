import React from "react";
import { FormikProps, Field } from "formik";
import { InputColumn, InputText } from "src/components/Inputs";
import { Row, Col } from "reactstrap";

const Form: React.FC<FormikProps<CompanyValuesProps>> = () => {
  const renderCompanyData = () => {
    return (
      <>
        <InputColumn label="Nome do banco">
          <Field name="name" component={InputText} />
        </InputColumn>
        <InputColumn label="Código do banco">
          <Field name="code" component={InputText} />
        </InputColumn>
        <InputColumn label="Conta (com dígito)">
          <Field name="account" component={InputText} />
        </InputColumn>
        <InputColumn label="Agência">
          <Field name="office" component={InputText} />
        </InputColumn>
        <InputColumn label="Operação">
          <Field name="operation" component={InputText} />
        </InputColumn>
      </>
    );
  };

  return (
    <Row>
      <Col>{renderCompanyData()}</Col>
    </Row>
  );
};

export default Form;
