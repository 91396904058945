import React, { ReactNode } from "react";
import { Row, Col } from "reactstrap";
import { HeaderTitle } from "../Headers";

interface Props {
  title: string;
  notes: string;
  children: ReactNode;
}

const ItemToManager: React.FC<Props> = ({ title, notes, children }) => (
  <Row>
    <Col sm={3}>
      <HeaderTitle title={title} />
      {notes && <small>{notes}</small>}
    </Col>
    <Col sm={9}>{children}</Col>
  </Row>
);

export { ItemToManager };
