import { toLocateNumber, formatSimpleDate } from "../../../utils/format";

const columns = [
  { title: "Nº", data: "number" },
  {
    title: "Data",
    data: "date",
    render(data: string) {
      return formatSimpleDate(data);
    },
    type: "date-eu",
    targets: 0
  },
  { title: "Estaca", data: "place" },
  { title: "Descrição", data: "description" },
  { title: "Amostra", data: "sample_code" },
  { title: "Camada", data: "layer.name" },
  {
    title: "Grau de compactação (%)",
    data: "compaction_rate",
    render(data: number) {
      return toLocateNumber(data * 100, 0);
    }
  },
  {
    title: "Método",
    data: "method",
    render(data: string) {
      return data === "S" ? "Areia" : "Hilf";
    }
  },
  {
    title: "Estado",
    data: "approved",
    render(data: boolean) {
      if (data) {
        return '<span class="badge badge-success">Aprovado</span>';
      }
      return '<span class="badge badge-danger">Reprovado</span>';
    }
  }
];
export default columns;
