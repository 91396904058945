import React from "react";
import { IDataTableColumn } from "react-data-table-component";
import { formatSimpleDate } from "../../../utils/format";
import reactCSS from "reactcss";

const styles = (progress: number) =>
  reactCSS({
    default: {
      progress: {
        width: `${progress * 100}px`
      }
    }
  });

const columns: IDataTableColumn<SoilSample>[] = [
  {
    name: "#",
    selector: "code",
    sortable: true,
    width: "70px"
  },
  {
    name: "#",
    selector: "control_code",
    sortable: true,
    width: "70px"
  },
  {
    name: "Place",
    selector: "collection_place",
    sortable: true,
    wrap: true,
    grow: 1,
    style: {
      flex: "1 !important"
    },
    format: ({ collection_place }) => {
      if (collection_place.length > 100) {
        return `${collection_place.substring(0, 100)}...`;
      }

      return collection_place;
    }
  },
  {
    name: "Data de chegada",
    selector: "arrival_date",
    width: "130px",
    format: ({ arrival_date }) => {
      return formatSimpleDate(arrival_date);
    }
  },
  {
    name: "Material",
    selector: "material_type.name",
    width: "110px",
    format: ({ material_type }) => {
      return material_type?.name || "";
    }
  },
  {
    name: "Progresso",
    selector: "progress",
    width: "110px",
    format: ({ progress }) => {
      return (
        <div className="progress mb-2">
          <div
            className="progress-bar progress-bar-striped bg-success"
            role="progressbar"
            style={styles(progress).progress}
            aria-valuenow={progress * 100}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      );
    }
  },
  {
    name: "Finalização",
    selector: "finalized_date",
    width: "110px",
    format: ({ finalized_date }) => {
      return formatSimpleDate(finalized_date);
    }
  }
];

export default columns;
