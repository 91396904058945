import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useData, TableList } from "../../../components/Table";
import { getEmployees, deleteEmployee } from "../../../services/api/admin";
import columns from "./columns";
import EmployeeDetail from "./EmployeeDetails";
import Insert from "./Insert";
import Edit from "./Edit";
import { ButtonDelete, ListButtonRow } from "../../../components/Buttons";
import { LoadingComponent } from "../../../components/Loader";
import { ContainerFluid } from "../../../components/Container";

const PAGE_TITLE = "Funcionários";

const EmployeeList = () => {
  const employee = useData<EmployeeFormType>();
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<Array<EmployeeFormType>>([]);
  const [dbClick, setDbClick] = useState(false);

  const getItems = () => {
    setLoading(true);
    getEmployees().then(({ data }) => {
      setEmployees(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getItems();
  }, []);

  const deleteItem = () => {
    if (
      employee.selected.item !== undefined &&
      employee.selected.item.id !== undefined
    ) {
      return deleteEmployee(employee.selected.item.id)
        .then(res => {
          employee.deleted.set(employee.selected.item);
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        });
    }

    return Promise.reject();
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <Row>
        <Col sm={8}>
          <Card>
            <CardBody>
              {/* <ListButtonRow buttonsRight={<ReportButton />} /> */}
              <LoadingComponent loading={loading}>
                <TableList
                  data={employees}
                  columns={columns}
                  selected={employee.selected.set}
                  updated={employee.updated.item}
                  created={employee.created.item}
                  deleted={employee.deleted.item}
                  dbClick={item => {
                    employee.selected.set(item);
                    setDbClick(true);
                    setDbClick(false);
                  }}
                  searching={false}
                />
              </LoadingComponent>
              <ListButtonRow
                marginTop="mt-2"
                buttonsLeft={
                  <>
                    <Insert insertedItem={employee.created.set} />
                    <Edit
                      item={
                        employee.selected.item ? employee.selected.item : {}
                      }
                      updatedItem={employee.updated.set}
                      dbClick={dbClick}
                    />
                  </>
                }
                buttonsRight={
                  <ButtonDelete
                    disabled={!employee.selected.item}
                    onClick={deleteItem}
                  />
                }
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <EmployeeDetail employee={employee.selected.item} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContainerFluid>
  );
};

export default EmployeeList;
