import React from "react";
import { Row, Col } from "reactstrap";

interface Props {
  title: string;
  className?: string;
  tooltipMessage?: string;
  leftIcon?: () => React.ComponentType<any> | React.ReactElement | null;
  rightIcon?: () => React.ComponentType<any> | React.ReactElement | null;
}

const HeaderSectionTitle: React.FC<Props> = ({
  title,
  className,
  leftIcon,
  rightIcon
}) => {
  return (
    <>
      <Row className={`section-title`}>
        <Col>
          <h4 className={`font-13 text-purple text-uppercase ${className}`}>
            <>
              {leftIcon && leftIcon()}
              {title}
              {rightIcon && rightIcon()}
            </>
          </h4>
        </Col>
      </Row>
    </>
  );
};

HeaderSectionTitle.defaultProps = {
  className: ""
};

export default HeaderSectionTitle;
