import api from "./api";

export function getClients() {
  const endpoint = "/clients/?control_type=graute";
  return api.get(endpoint);
}

// Traces
export function getTraces(
  fields = "",
  trace_date = "",
  tower = "",
  join = "",
  control = ""
) {
  const endpoint =
    `/grautes/traces/${control}/` +
    `?fields=${fields}` +
    `&tower=${tower}` +
    `&join=${join}` +
    `&control=${control}` +
    `&trace_date=${trace_date}`;
  return api.get(endpoint);
}

export function postTrace(id: number, data: any) {
  const endpoint = `/grautes/traces/${id}/`;
  return api.post(endpoint, data);
}

export function getTrace(id: number) {
  const endpoint = `/grautes/trace/${id}/`;
  return api.get(endpoint);
}

export function putTrace(id: number, data: any) {
  const endpoint = `/grautes/trace/${id}/`;
  return api.put(endpoint, data);
}

export function deleteTrace(id: number) {
  const endpoint = `/grautes/trace/${id}/`;
  return api.delete(endpoint);
}

export function getSeries(control: number, fields: string) {
  const endpoint = `/grautes/series/${control}/?fields=${fields}`;
  return api.get(endpoint);
}

export function getLastSerie() {
  const endpoint = '/grautes/series/?amount=1&order_by="desc"';
  return api.get(endpoint).then(({ data }) => {
    return data[0] || {};
  });
}

export function postSerie(data: any, controlId: number) {
  const endpoint = `/grautes/series/${controlId}/`;
  return api.post(endpoint, data);
}

export function putSerie(id: number, data: any) {
  const endpoint = `/grautes/serie/${id}/`;
  return api.put(endpoint, data);
}

export function deleteSerie(id: number) {
  const endpoint = `/grautes/serie/${id}/`;
  return api.delete(endpoint);
}

export function getSerie(id: number) {
  const endpoint = `/grautes/serie/${id}/`;
  return api.get(endpoint);
}

export function getDistinctPlaces(
  contractId: number,
  towers: string,
  joins: string
) {
  const endpoint = `/grautes/controls/${contractId}/places/?towers=${towers}&joins=${joins}`;
  return api.get(endpoint);
}

// CPS
export function getCPs(seriesId: number) {
  const endpoint = `/grautes/series/${seriesId}/cps/`;
  return api.get(endpoint);
}

export function getCP(id: number) {
  const endpoint = `/grautes/series/cps/${id}/`;
  return api.get(endpoint);
}

export function searchCPs(code: number) {
  const endpoint = `/grautes/series/cps/?code=${code}`;
  return api.get(endpoint).then(res => {
    return res;
  });
}

export function updateCP(id: number, data: any) {
  const endpoint = `/grautes/series/cps/${id}/`;
  return api.put(endpoint, data);
}

export function deleteCP(id: number) {
  const endpoint = `/grautes/series/cps/${id}/`;
  return api.delete(endpoint);
}

export function getControl(contractId: number) {
  const endpoint = `/grautes/controls/${contractId}/`;

  return api.get(endpoint);
}

export function getControlById(controlId: number) {
  const endpoint = `/grautes/${controlId}/`;

  return api.get(endpoint);
}

export function postControl(contractId: number, data: any) {
  const endpoint = `/grautes/controls/${contractId}/`;
  return api.post(endpoint, data);
}

export function putControl(controlId: number, data: any) {
  const endpoint = `/grautes/${controlId}/`;
  return api.put(endpoint, data);
}

export function getCPsToBreak(startDate: string, endDate: string) {
  const endpoint = `/grautes/cps/?start_date=${startDate}&end_date=${endDate}&breaked=0&fields=id,code,rupture_date,age,load,fck,height,diameter,weight,work`;
  return api.get(endpoint);
}
