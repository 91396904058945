import React from "react";
import { InputSelect } from "../Inputs";

const SelectBlockClass: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" }
    ];
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Classe..."
      onClick={(value: any) => props.onChange && props.onChange(value || "")}
      isClearable={false}
    />
  );
};

export default SelectBlockClass;
