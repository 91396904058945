import {
  formatSimpleDate,
  toLocateNumber,
  getMonth
} from "../../../utils/format";

const columns = [
  { title: "#", data: "id" },
  { title: "Cliente", data: "client.name", visible: false },
  { title: "Obra", data: "work.name" },
  {
    title: "Mês",
    data: "month",
    render(data: number) {
      return getMonth(data);
    }
  },
  { title: "Ano", data: "year" },
  {
    title: "Início",
    data: "start",
    render(data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Fim",
    data: "end",
    render(data: string) {
      return formatSimpleDate(data);
    }
  },
  {
    title: "Status",
    data: "bill_status.status",
    render(data: string) {
      return data === "0"
        ? "Em aprovação"
        : data === "1"
        ? "Aberta"
        : data === "2"
        ? "Enviado"
        : data === "3"
        ? "Recebido"
        : data === "4"
        ? "Recebido parcial"
        : data === "5"
        ? "Faturado"
        : "-";
    }
  },
  {
    title: "Valor total",
    data: "total",
    render(data: number) {
      return toLocateNumber(data, 2, "-", "R$ ");
    }
  },
  {
    title: "NF",
    data: "bill_status.invoice",
    render(data: string) {
      return data || "-";
    }
  },
  {
    title: "Valor líquido",
    data: "bill_status.net_value",
    render(data: number) {
      return toLocateNumber(data, 2, "-", "R$ ");
    }
  },
  {
    title: "Data do status",
    data: "bill_status",
    render(data: any) {
      return data.forecast_receipt
        ? formatSimpleDate(data.forecast_receipt)
        : data.net_date
        ? formatSimpleDate(data.net_date)
        : data.sent_date
        ? formatSimpleDate(data.sent_date)
        : formatSimpleDate(data.modificattion_date);
    }
  },
  {
    title: "Banco",
    data: "bill_status.bank.name",
    render(data: string) {
      return data || "-";
    }
  },
  {
    title: "Área de serviço",
    render() {
      return "-";
    }
  }
];

export default columns;
