import React from "react";
import { InputSelect } from "../Inputs";

class SelectProctor extends React.Component {
  options() {
    return [
      { value: "12", label: "Normal" },
      { value: "26", label: "Intermediário" },
      { value: "55", label: "Modificado" }
    ];
  }

  render() {
    return (
      <InputSelect
        {...this.props}
        options={this.options()}
        placeholder="Energia de compactação..."
      />
    );
  }
}

export default SelectProctor;
