import api from "./api";

export const ASCENDENTE = "asc";
export const DESCENDENTE = "des";

export function getLots(contractId: number, fields: string = "") {
  let endpoint = `/ceramic_block/lots/${contractId}/?fields=${fields}`;
  return api.get(endpoint);
}

export function getLot(id: number) {
  let endpoint = `/ceramic_block/lot/${id}/`;
  return api.get(endpoint);
}

export function putLot(id: number, data: any) {
  let endpoint = `/ceramic_block/lot/${id}/`;
  return api.put(endpoint, data);
}

export function getSamples(id: number) {
  let endpoint = `/ceramic_block/break_samples/${id}/`;
  return api.get(endpoint);
}

export function getSample(id: number) {
  let endpoint = `/ceramic_block/break_sample/${id}/`;
  return api.get(endpoint);
}

export function putBreakSample(id: number, data: any) {
  let endpoint = `/ceramic_block/break_sample/${id}/`;
  return api.put(endpoint, data);
}

export function postLot(id: number, data: any) {
  let endpoint = `/ceramic_block/lots/${id}/`;
  return api.post(endpoint, data);
}

export function deleteLot(id: number) {
  let endpoint = `/ceramic_block/lot/${id}/`;
  return api.delete(endpoint);
}
