import React, { useEffect, useState } from "react";
import * as tools_api from "../../services/api/tools";
import SelectBase from "./SelectBase";

const SelectEquipment: React.FC<SelectType<Equipment>> = props => {
  const [items, setItems] = useState<Equipment[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    tools_api.getEquipments().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(item => ({
        value: item.id,
        label: item.name
      }));
  };

  return (
    <SelectBase
      placeholder="Equipamento..."
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      onClick={props.onChange}
      getItems={getItems}
      options={options()}
      {...props}
    />
  );
};

export default SelectEquipment;
