/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormikProps, Field, FormikActions } from "formik";
import { Row, Col } from "reactstrap";
import { HeaderTitle, HeaderSectionTitle } from "src/components/Headers";
import { formatCPCode, toLocateNumber } from "src/utils/format";
import { InputRow, InputDecimal, InputDate } from "src/components/Inputs";
import { getFBK } from "../utils";
import { ModalForm } from "src/components/Modal";
import { getSample, putBreakSample } from "src/services/api/floor";

interface FormProps extends FormikProps<TFloorTestingBody> {
  lot: TFloorLot;
}

const Form: React.FC<FormProps> = ({ lot, values, setFieldValue }) => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <HeaderTitle title="Informações da amostra" />
          <dl className="row">
            <dt className="col-sm-6">Lote</dt>
            <dd className="col-sm-6">{formatCPCode(lot.id)}</dd>

            <dt className="col-sm-6">Código do CP</dt>
            <dd className="col-sm-6">{formatCPCode(values.code)}</dd>

            <dt className="col-sm-6">Atrazo (dias)</dt>
            <dd className="col-sm-6" />
          </dl>
        </Col>
        <Col sm={6}>
          <HeaderTitle title="Dimensões nominais do lote" />
          <dl className="row">
            <dt className="col-sm-5">Largura (mm)</dt>
            <dd className="col-sm-5">{toLocateNumber(lot.width, 3, "-")}</dd>

            <dt className="col-sm-5">Altura (mm)</dt>
            <dd className="col-sm-5">{toLocateNumber(lot.height, 3, "-")}</dd>

            <dt className="col-sm-5">Comprimento (mm)</dt>
            <dd className="col-sm-5">{toLocateNumber(lot.length, 3, "-")}</dd>
          </dl>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={6}>
          <HeaderSectionTitle title={"Análise dimensional"} />
          <InputRow label="Largura (mm)" label_col={6}>
            <Field
              name="width"
              component={InputDecimal}
              required
              onChange={(value: number) => setFieldValue("width", value)}
            />
          </InputRow>
          <InputRow label="Altura (mm)" label_col={6}>
            <Field
              name="height"
              component={InputDecimal}
              required
              onChange={(value: number) => setFieldValue("height", value)}
            />
          </InputRow>
          <InputRow label="Comprimento (mm)" label_col={6}>
            <Field
              name="length"
              component={InputDecimal}
              required
              onChange={(value: number) => setFieldValue("length", value)}
            />
          </InputRow>
          <InputRow label="Peso (g)" label_col={6}>
            <Field
              name="weight"
              component={InputDecimal}
              required
              onChange={(value: number) => setFieldValue("weight", value)}
            />
          </InputRow>
        </Col>
        <Col>
          <HeaderSectionTitle title={"Ensaio de ruptura"} />
          <InputRow label="Data de ruptura" label_col={5}>
            <Field name="test_date" component={InputDate} />
          </InputRow>
          <InputRow label="Carga" label_col={5}>
            <Field
              name="load"
              component={InputDecimal}
              onChange={(value: number) => setFieldValue("load", value)}
            />
          </InputRow>
          <dl className="row">
            <dt className="col-sm-5">
              <h3>FPK (MPa)</h3>
            </dt>
            <dd className="col-sm-7">
              <h2>
                {toLocateNumber(
                  getFBK(values.load, values.width, values.length),
                  3,
                  "-"
                )}
              </h2>
            </dd>
          </dl>
        </Col>
      </Row>
    </>
  );
};

interface EditProps extends EditType<TFloorTestingBody> {
  lot: TFloorLot;
}

const EditTestingBody: React.FC<EditProps> = ({
  item,
  lot,
  dbClick,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();

  useEffect(() => {
    if (dbClick) {
      modalRef.current?.open();
    }
  }, [dbClick]);

  const onSubmit = (values: any, { setFieldError }: FormikActions<object>) => {
    return putBreakSample(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      });
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="lg"
      title="Ensaio de corpo de prova"
      data={item}
      buttonLabel="Ensaiar"
      getItemRequest={id => getSample(id)}
      form={props => {
        return <Form {...props} lot={lot} />;
      }}
      initialValues={item => {
        return item;
      }}
      onSubmit={onSubmit}
    />
  );
};

export { EditTestingBody };
