/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { getReports, deleteReport } from "src/services/api/client_side";
import {
  reduceString,
  toLocateNumber,
  formatSimpleDate
} from "src/utils/format";
import { Loader } from "src/components/Loader";
import { useUser } from "src/context/admin/user";

interface Props {
  contractId?: number;
  shouldUpdate: boolean;
}

type TReport = {
  id: number;
  name: string;
  size: number;
  notes: string;
  creation_date: string;
  report: string;
};

const FilesList: React.FC<Props> = ({ contractId, shouldUpdate }) => {
  const [user] = useUser();
  const [reports, setReports] = useState<TReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getItems = () => {
    if (contractId !== undefined && contractId !== null) {
      setLoading(true);
      getReports(contractId).then(({ data }) => {
        setReports(data);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    getItems();
  }, [contractId]);

  useEffect(() => {
    if (shouldUpdate) {
      getItems();
    }
  }, [shouldUpdate]);

  const deleteFile = (id: number, index: number) => {
    deleteReport(id)
      .then(() => {
        reports.splice(index, 1);
        setReports(JSON.parse(JSON.stringify(reports)));
      })
      .catch(() => {});
  };

  const tableLine = () => {
    return reports.map((item, index) => (
      <tr>
        <td>{reduceString(item.name.toUpperCase(), 40)}</td>
        <td>{item.notes}</td>
        <td>{toLocateNumber(item.size / (1024 * 1024), 2, "-", "", " MB")}</td>
        <td className="text-center">{formatSimpleDate(item.creation_date)}</td>
        <td className="text-center">
          <a
            href={item.report}
            target="_blank"
            rel="noopener noreferrer"
            className="action-icon"
          >
            <i className="mdi mdi-download" />
          </a>
        </td>
        <td className="text-center">
          {!user.data?.is_client && (
            <button
              type="submit"
              className="btn btn-link action-icon"
              onClick={() => deleteFile(item.id, index)}
            >
              <i className="mdi mdi-delete" />
            </button>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <Card>
      <CardBody>
        {!loading ? (
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Tamanho</th>
                  <th className="text-center">Data</th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>{tableLine()}</tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </CardBody>
    </Card>
  );
};

export default FilesList;
