import api from "./api";

export const ACTIVE = 1;
export const NOT_ACTIVE = 0;

export function getContracts(
  sub_department = "",
  client = "",
  work = "",
  fields = "",
  group = "",
  active = "",
  department = "",
  active_at = ""
) {
  const endpoint =
    `/contracts/` +
    `?sub_department=${sub_department || ""}` +
    `&client=${client || ""}` +
    `&work=${work || ""}` +
    `&fields=${fields}` +
    `&group=${group}` +
    `&active=${active}` +
    `&department=${department}` +
    `&active_at=${active_at}`;
  return api.get(endpoint);
}

export function postContract(data: any) {
  const endpoint = "/contracts/";
  return api.post(endpoint, data);
}

export function getContract(id: number) {
  const endpoint = `/contracts/${id}/`;
  return api.get(endpoint);
}

export function getContractSlug() {
  const endpoint = `/contracts/`;
  return api.get(endpoint);
}

export function putContract(id: number, data: any) {
  const endpoint = `/contracts/${id}/`;
  return api.put(endpoint, data);
}

export function deleteContract(id: number) {
  const endpoint = `/contracts/${id}/`;
  return api.delete(endpoint);
}

export function getAllocation(contractId: number) {
  const endpoint = `/contracts/${contractId}/allocations/`;
  return api.get(endpoint);
}

export function postAllocation(contractId: number, data: any) {
  const endpoint = `/contracts/${contractId}/allocations/`;
  return api.post(endpoint, data);
}

export function putAllocation(id: number, data: any) {
  const endpoint = `/contracts/allocations/${id}/`;
  return api.put(endpoint, data);
}
