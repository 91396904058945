/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ModalForm } from "../../../../components/Modal";
import {
  updateCP,
  getSerie,
  getControlById,
  getCP
} from "../../../../services/api/graute";
import Form from "./Form";

interface Props {
  showModal?: boolean;
  itemToEdit?: TGrauteTestingBody;
  updatedItem?: (cp: TGrauteTestingBody) => void;
}

const TestingBody: React.FC<Props> = ({
  showModal,
  itemToEdit,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();
  const [series, setSeries] = useState<TGrauteSeries>();
  const [control, setControl] = useState<TGrauteControl>();
  const [item, setItem] = useState<TGrauteTestingBody>();

  const getItems = () => {
    if (itemToEdit !== undefined) {
      return getCP(itemToEdit.id!).then(({ data: cp }) => {
        return getSerie(cp.serie!).then(({ data: series }) => {
          return getControlById(series.control).then(({ data: control }) => {
            setSeries(series);
            setControl(control);
            setItem(cp);

            return Promise.resolve({ data: cp });
          });
        });
      });
    }

    return Promise.reject();
  };

  useEffect(() => {
    if (showModal) {
      modalRef.current?.open();
    }
  }, [showModal]);

  const onSubmit = (values: any) => {
    if (
      item !== undefined &&
      item.id !== undefined &&
      updatedItem !== undefined
    ) {
      return updateCP(item.id, values).then(({ data }) => updatedItem(data));
    }

    return Promise.reject();
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="md"
      title="Editar CP"
      buttonLabel="Romper CP"
      data={itemToEdit}
      getItemRequest={() => getItems()}
      form={props => <Form {...props} control={control} series={series} />}
      initialValues={item => item}
      onSubmit={onSubmit}
    />
  );
};

export default TestingBody;
