import React from "react";
import SelectBase from "./SelectBase";

const SelectGroup: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: "client_manager", label: "Cliente administrador" },
      { value: "client", label: "Cliente comum" },
      { value: "client_access_files", label: "Acesso apenas a arquivos" }
    ];
  };

  return (
    <SelectBase
      placeholder="Grupo de acesso..."
      onClick={props.onChange}
      getItems={() => {}}
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      options={options()}
      {...props}
    />
  );
};

export default SelectGroup;
