/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Field } from "formik";
import {
  InputColumn,
  InputDecimal,
  InputRow,
  InputInteger
} from "../../../../../components/Inputs";
import { SelectCapsule } from "../../../../../components/Selects";
import { toLocateNumber } from "../../../../../utils/format";
import {
  waterWeight,
  soilWeight,
  humidity as _humidity
} from "../../../../../utils/math";
import { HeaderTitle } from "../../../../../components/Headers";
import { useCapsules } from "../../../../../context/tech_control/capsule";
import { useGranulometry } from "../Granulometry/context";

interface Props {
  name: string;
  errors: object;
  values?: any;
  setFieldValue: (field: string, value: any) => void;
}

interface StudyProps extends Props {
  onHumidityChange: (value: number) => void;
  onChangeStroke?: (value: number) => void;
  strokeInput?: boolean;
}

const CapsuleStudy: React.FC<StudyProps> = ({
  name,
  values,
  setFieldValue,
  onHumidityChange,
  onChangeStroke,
  strokeInput
}) => {
  const [capsule, setCapsule] = useState<Capsule>();
  const [water, setWater] = useState<number>(0);
  const [soil, setSoil] = useState<number>(0);
  const [humidity, setHumidity] = useState<number>(0);
  const [capsules] = useCapsules();

  useEffect(() => {
    // Peso da água
    if (values !== undefined) {
      setWater(
        waterWeight(
          values?.capsule_study.wet_soil_weight,
          values?.capsule_study.dry_soil_weight
        )
      );

      // Peso do solo
      if (capsule !== undefined) {
        setSoil(
          soilWeight(values?.capsule_study.dry_soil_weight, capsule.weight)
        );
      }
    }
  }, [
    capsule?.id,
    values?.capsule_study.wet_soil_weight,
    values?.capsule_study.dry_soil_weight
  ]);

  useEffect(() => {
    if (values?.capsule_study.capsule !== undefined) {
      const fullCapsule = capsules.data.filter(
        item => item.id === values?.capsule_study.capsule
      );
      if (fullCapsule.length > 0) {
        setCapsule(fullCapsule[0]);
      }
    }
  }, [values?.capsule_study.capsule, capsules.data]);

  useEffect(() => {
    const value = _humidity(water, soil);
    setHumidity(value);

    onHumidityChange(value);
  }, [water, soil]);

  return (
    <Row>
      <Col className="pl-1 pr-1">
        {/* Strokes */}
        {strokeInput && (
          <InputColumn>
            <Field
              component={InputInteger}
              name={`${name}.stroke`}
              onChange={(event: any) => {
                onChangeStroke && onChangeStroke(event.target.value);
                setFieldValue(`${name}.stroke`, event.target.value);
              }}
              required
            />
          </InputColumn>
        )}

        {/* Capsule */}
        <InputColumn>
          <Field
            component={SelectCapsule}
            name={`${name}.capsule_study.capsule`}
            onChange={(item: Capsule) => {
              setFieldValue(`${name}.capsule_study.capsule`, item.id);
            }}
            required
          />
        </InputColumn>

        {/* Peso bruto úmido */}
        <InputColumn>
          <Field
            component={InputDecimal}
            name={`${name}.capsule_study.wet_soil_weight`}
            onChange={(value: number) => {
              setFieldValue(`${name}.capsule_study.wet_soil_weight`, value);
            }}
            required
          />
        </InputColumn>

        {/* Peso bruto seco */}
        <InputColumn>
          <Field
            component={InputDecimal}
            name={`${name}.capsule_study.dry_soil_weight`}
            onChange={(value: number) => {
              setFieldValue(`${name}.capsule_study.dry_soil_weight`, value);
            }}
            required
          />
        </InputColumn>
        <div className="text-center">
          {/* Peso da cápsula */}
          <InputRow
            label={toLocateNumber(capsule ? capsule.weight : 0, 3, 0)}
          />

          {/* Peso da água */}
          <InputRow label={toLocateNumber(water, 3, 0)} />

          {/* Peso do solo */}
          <InputRow label={toLocateNumber(soil, 3, 0)} />

          {/* Umidade */}
          <InputRow label={toLocateNumber(humidity * 100, 2, 0)} />
        </div>
      </Col>
    </Row>
  );
};

const CapsuleTotal: React.FC<Props> = ({ setFieldValue }) => {
  const [granulometry, actionGranulometry] = useGranulometry();
  const {
    withheldWeightN10,
    wetWeightN10,
    dryWeightN10,
    totalDryWeight
  } = granulometry;

  const renderLabel = (value: number) => (
    <InputRow label={toLocateNumber(value, 3, 0)} />
  );

  return (
    <Row>
      <Col className="pl-1 pr-1">
        <InputColumn>
          <Field
            name="total_capsule"
            component={SelectCapsule}
            required
            onChange={(item: Capsule) => {
              setFieldValue(`total_capsule`, item.id);
            }}
          />
        </InputColumn>
        <InputColumn>
          <Field
            name="total_gross_weight"
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`total_gross_weight`, value);
            }}
            required
          />
        </InputColumn>
        <InputColumn>
          <Field
            name="total_wet_weight"
            component={InputDecimal}
            onChange={(value: number) => {
              actionGranulometry.setTotalWetWeight(value);
              setFieldValue(`total_wet_weight`, value);
            }}
            required
          />
        </InputColumn>
        <div className="text-center">
          {renderLabel(withheldWeightN10)}
          {renderLabel(wetWeightN10)}
          {renderLabel(dryWeightN10)}
          {renderLabel(totalDryWeight)}
        </div>
      </Col>
    </Row>
  );
};

const CapsulePartial: React.FC<Props> = ({ setFieldValue }) => {
  const [granulometry, actionGranulometry] = useGranulometry();
  const { partialDryWeight } = granulometry;

  return (
    <Row>
      <Col className="pl-1 pr-1">
        <InputColumn>
          <Field
            name="partial_capsule"
            component={SelectCapsule}
            required
            onChange={(item: Capsule) => {
              setFieldValue(`partial_capsule`, item.id);
            }}
          />
        </InputColumn>
        <InputColumn>
          <Field
            name="partial_gross_weight"
            component={InputDecimal}
            onChange={(value: number) => {
              actionGranulometry.setPartialGrossWeight(value);
              setFieldValue(`partial_gross_weight`, value);
            }}
            required
          />
        </InputColumn>
        <InputColumn>
          <Field
            name="partial_wet_weight"
            component={InputDecimal}
            onChange={(value: number) => {
              setFieldValue(`partial_wet_weight`, value);
            }}
            required
          />
        </InputColumn>
        <div className="text-center">
          <InputRow label="-" />
          <InputRow label="-" />
          <InputRow label="-" />
          <InputRow label={toLocateNumber(partialDryWeight, 3, 0)} />
        </div>
      </Col>
    </Row>
  );
};

interface LabelProps {
  strokeInput?: boolean;
}

const CapsuleStudyLabels: React.FC<LabelProps> = ({ strokeInput }) => (
  <Row>
    <Col>
      {strokeInput && <InputRow label="Golpes" />}
      <InputRow label="Cápsula" />
      <InputRow label="Bruto úmido + Tara (g)" />
      <InputRow label="Bruto seco + Tara (g)" />
      <InputRow label="Cápsula (g)" />
      <InputRow label="Água (g)" />
      <InputRow label="Solo (g)" />
      <InputRow label="Umidade (%)" />
    </Col>
  </Row>
);

const CapsuleTotalLabels = () => (
  <Row>
    <Col>
      <HeaderTitle title="AMOSTRA" />
      <InputRow label="Cápsula" />
      <InputRow label="Bruto úmido total (g)" />
      <InputRow label="Bruto úmido (g)" />
      <InputRow label="Retido na #N10 (g)" />
      <InputRow label="Úmido pass. na #N10 (g)" />
      <InputRow label="Seco pass. na #N10 (g)" />
      <InputRow label="Amostra seca (g)" />
    </Col>
  </Row>
);

export { CapsuleStudyLabels, CapsuleTotal, CapsulePartial, CapsuleTotalLabels };
export default CapsuleStudy;
