import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Container } from "../components/Container";
import {
  useConcreteRoutes,
  useSoilRoutes,
  useInternalManagerRoutes
} from "../components/MenuBar/menuRoutes";
const PAGE_TITLE = "Início";

const Panel = () => {
  const [showConcreteSection, concreteRoutes] = useConcreteRoutes();
  const [showSoilSection, soilRoutes] = useSoilRoutes();
  const [
    showManagerSection,
    internalManagerRoutes
  ] = useInternalManagerRoutes();

  return (
    <Container title={PAGE_TITLE}>
      <h1 className="mt-4" style={{ fontWeight: "lighter" }}>
        Painel
      </h1>
      <Row>
        {showSoilSection && (
          <Col sm="4">
            <Card>
              <CardBody style={{ height: 400 }}>
                <Row>
                  <Col sm="auto">
                    <img
                      src="https://www.gm6.com.br/images/solos.svg"
                      alt="#"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className="mb-3 mt-3">Gestão de qualidade de solos</h3>
                    <ul className="list-unstyled">
                      {soilRoutes.map(route => (
                        <>
                          {route.show && (
                            <li>
                              <Link to={route.href}>
                                <p className="lead mb-0">{route.name}</p>
                              </Link>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
        {showConcreteSection && (
          <Col sm="4">
            <Card>
              <CardBody style={{ height: 400 }}>
                <Row>
                  <Col sm="auto">
                    <img
                      src="https://www.gm6.com.br/images/concreto.svg"
                      alt="#"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className="mb-3 mt-3">
                      Gestão de qualidade de concreto
                    </h3>
                    <ul className="list-unstyled">
                      {concreteRoutes.map(route => (
                        <>
                          {route.show && (
                            <li>
                              <Link to={route.href}>
                                <p className="lead mb-0">{route.name}</p>
                              </Link>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
        {showManagerSection && (
          <Col sm="4">
            <Card>
              <CardBody style={{ height: 400 }}>
                <Row>
                  <Col sm="auto">
                    <img
                      src="https://www.gm6.com.br/images/equipes.svg"
                      alt="#"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className="mb-3 mt-3">
                      Gestão de contratos e medições
                    </h3>
                    <ul className="list-unstyled">
                      {internalManagerRoutes.map(route => (
                        <>
                          {route.show && (
                            <li>
                              <Link to={route.href}>
                                <p className="lead mb-0">{route.name}</p>
                              </Link>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Panel;
