/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { Field } from "formik";
import { Row, Col } from "reactstrap";
import { InputDecimal, InputRow } from "../../../../../../components/Inputs";
import { toLocateNumber } from "../../../../../../utils/format";
import { EditTestAccessPermission } from "../../../../../../components/Permissions";
import * as math from "../../../../../../utils/math";

const LabelHorizontal = ({
  label,
  col = 2
}: {
  label: string;
  col?: number;
}) => (
  <label className={`col-sm-${col} col-form-label pl-1 pr-1`}>{label}</label>
);

const ExpansionHeader = () => (
  <>
    <Row className="text-center">
      <LabelHorizontal label="Defl. (mm)" col={3} />
      <LabelHorizontal label="Difer. (mm)" />
      <LabelHorizontal label="Exp (%)" />
    </Row>
  </>
);

interface ExpansionProps {
  name: string;
  height: number;
  read: number;
  onChangeExpansion: (value: number) => void;
  setFieldValue: (field: string, value: any) => void;
}

const ExpansionRead: React.FC<ExpansionProps> = ({
  name,
  height,
  read,
  onChangeExpansion,
  setFieldValue
}) => {
  const [diff, setDiff] = useState(0);
  const [expansion, setExpansion] = useState(0);

  useEffect(() => {
    const diff = math.expansionDifference(read);
    setDiff(diff);

    const expansion = math.expansion(diff, height);
    onChangeExpansion(expansion);
    setExpansion(expansion);
  }, [read]);

  return useMemo(
    () => (
      <div className="form-group row align-items-center text-center">
        <Col sm={3}>
          <EditTestAccessPermission
            renderNoAccess={() => <InputRow label={toLocateNumber(read, 3)} />}
          >
            <Field
              component={InputDecimal}
              name={`${name}.read`}
              required
              onChange={(value: number) => {
                setFieldValue(`${name}.read`, value);
              }}
            />
          </EditTestAccessPermission>
        </Col>
        <LabelHorizontal label={toLocateNumber(diff, 3)} />
        <LabelHorizontal label={toLocateNumber(expansion * 100, 2)} />
      </div>
    ),
    [name, diff, expansion, read, setFieldValue]
  );
};

export { ExpansionRead, ExpansionHeader };
