import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "src/services/api/auth";

const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    logout().then(() => {
      history.push("/login");
    });
  }, [history]);

  return <div></div>;
};

export default Logout;
