import React from "react";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";
import { getClient, putClient } from "../../../services/api/client";
import { FormikActions } from "formik";

interface Props {
  item: ClientFormValues;
  updatedItem: (item: ClientFormValues) => void;
  dbClick: boolean;
}

const Edit: React.FC<Props> = ({ item, updatedItem }) => {
  const onSubmit = (
    values: ClientFormValues,
    { setFieldError }: FormikActions<object>
  ) => {
    if (values.id !== undefined) {
      return putClient(values.id!, values)
        .then(({ data }) => {
          updatedItem(data);
        })
        .catch(res => {
          Object.keys(res).forEach(key => {
            setFieldError(key, res[key]);
          });

          return Promise.reject();
        });
    }
    return Promise.reject();
  };

  return (
    <ModalForm
      type="put"
      size="md"
      title="Editar cliente"
      data={item}
      getItemRequest={id => getClient(id)}
      form={props => <Form {...props} />}
      initialValues={item => {
        return item;
      }}
      onSubmit={onSubmit}
    />
  );
};

export default Edit;
