import React, { ReactNode } from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";

interface Props {
  label?: String;
  className?: String;
  create?: ReactNode;
  children?: ReactNode;
}

const InputColumn: React.FC<Props> = ({
  label,
  className,
  create,
  children
}) => (
  <Row>
    <Col sm={12}>
      <FormGroup className={`align-items-start flex-column ${className}`}>
        {label !== "" && (
          <Label className="mb-1">
            {label}
            {create}
          </Label>
        )}
        {children}
      </FormGroup>
    </Col>
  </Row>
);

InputColumn.defaultProps = {
  label: "",
  className: ""
};

export default InputColumn;
