import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { ListButtonRow, ButtonSave } from "src/components/Buttons";
import { Formik, Form as FormikForm, FormikActions } from "formik";
import Form from "./Form";
import {
  getMyApplication,
  putApplication
} from "src/services/api/applications";
import { LoadingComponent } from "src/components/Loader";

const Application: React.FC<Props> = () => {
  const [item, setItem] = useState<ApplicationValues>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getMyApplication()
      .then(({ data }) => setItem(data))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = (values: any, { setSubmitting }: FormikActions<object>) => {
    if (values) {
      values.company = values.company?.id;
      putApplication(values.id, values)
        .then(({ data }) => setItem(data))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Formik
              onSubmit={onSubmit}
              enableReinitialize
              initialValues={item!}
              render={props => {
                return (
                  <LoadingComponent loading={loading}>
                    <FormikForm>
                      <Form {...props} />
                      <ButtonSave isSubmitting={props.isSubmitting} />
                    </FormikForm>
                  </LoadingComponent>
                );
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ListButtonRow buttonsLeft={<></>} marginTop="mt-2" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Application;
