import React from "react";
import { InputSelect } from "../Inputs";

const SelectConcreteType: React.FC<SelectType<any>> = props => {
  const options = () => {
    return [
      { value: "0", label: "Não testar" },
      { value: "1", label: "Tipo I" },
      { value: "2", label: "Tipo II" },
      { value: "3", label: "Tipo III" },
      { value: "4", label: "Tipo IV" },
      { value: "5", label: "Tipo V" }
    ];
  };

  return (
    <InputSelect
      {...props}
      options={options()}
      placeholder="Tipo do concreto."
      onClick={props.onChange}
    />
  );
};

export default SelectConcreteType;
