import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ButtonOption } from "../../../components/Buttons";
import EmployeePUT from "./crud/EmployeePUT";

const Edit: React.FC<EditType<EmployeeFormType>> = props => {
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (props.dbClick) setShowModal(true);
  }, [props.dbClick]);

  return (
    <>
      <ButtonOption onClick={toggle} disabled={!props.item} />

      <Modal isOpen={showModal} size="lg" toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Editar funcionário</ModalHeader>
        <ModalBody>
          <EmployeePUT {...props} toggle={toggle} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Edit;
