import api, { sendFile } from "./api";

export function postReport(
  contractId: number,
  data: any,
  getProgress: (e: any) => void
) {
  const endpoint = `/contracts/${contractId}/report/`;
  return sendFile(endpoint, data, getProgress);
}

export function getReports(contractId: number) {
  const endpoint = `/contracts/${contractId}/report/`;
  return api.get(endpoint);
}

export function deleteReport(id: number) {
  const endpoint = `/contracts/report/${id}/`;
  return api.delete(endpoint);
}

// function post(endpoint, data, getProgress) {
//   const { app_slug } = context;

//   endpoint = `/api${app_slug ? `/${app_slug}` : ""}${endpoint}`;

//   const formData = new FormData();
//   Object.keys(data).forEach(key => formData.append(key, data[key]));

//   const csrfToken = cookie.load("csrftoken");

//   if (csrfToken !== undefined) {
//     const lookupOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "multipart/form-data",
//         "X-CSRFToken": csrfToken,
//         "Accept-Language": "pt-BR"
//       },
//       credentials: "include",
//       onUploadProgress: progressEvent => {
//         const percentCompleted = Math.floor(
//           (progressEvent.loaded * 100) / progressEvent.total
//         );
//         getProgress(percentCompleted);
//       }
//     };

//     return axios
//       .post(endpoint, formData, lookupOptions)
//       .then(response => {
//         return response.data;
//       })
//       .catch(error => {
//         return Promise.reject(error.response.data);
//       });
//   }
// }
