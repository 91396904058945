import React from "react";
import { Row, Col } from "reactstrap";
import { Form, FormikProps } from "formik";
import ExecutionBar from "../components/ExecutionBar";
import { HeaderTitle } from "../../../../../components/Headers";
import { useLimitsActions } from "./context";
import Chart from "./Chart";
import CapsuleStudy, { CapsuleStudyLabels } from "../components/CapsuleStudy";
import { InputCheckbox } from "../../../../../components/Inputs";
import ResultsSummary from "./components/ResultsSummary";
import initialValues from "./init";

const LimitsForm: React.FC<FormikProps<PhysicalLimits>> = props => {
  const [, actionLimits] = useLimitsActions();

  const renderLiquidity = () => (
    <>
      <HeaderTitle className="text-center" title="Limite de liquidez" />
      <Row>
        <Col sm={3}>
          <CapsuleStudyLabels strokeInput />
        </Col>
        {!props.values.is_not_liquid ? (
          <>
            {[...new Array(5).fill(0)].map((_, index) => (
              <Col>
                <CapsuleStudy
                  name={`liquidity_tests[${index}]`}
                  errors={props.errors}
                  setFieldValue={props.setFieldValue}
                  onHumidityChange={(value: number) =>
                    actionLimits.setOnLiquidityTest(value, index, "humidity")
                  }
                  values={
                    props.values.liquidity_tests
                      ? props.values.liquidity_tests[index]
                      : {}
                  }
                  onChangeStroke={(value: number) =>
                    actionLimits.setOnLiquidityTest(value, index, "stroke")
                  }
                  strokeInput
                />
              </Col>
            ))}
          </>
        ) : (
          <Col className="align-self-center">
            <h1 className="display-1 text-center">NL</h1>
          </Col>
        )}
      </Row>
    </>
  );

  const renderPlasticity = () => (
    <>
      <HeaderTitle className="text-center" title="Limite de plasticidade" />
      <Row>
        <Col sm={3}>
          <CapsuleStudyLabels />
        </Col>
        {!props.values.is_not_plastic ? (
          <>
            {[...new Array(5).fill(0)].map((_, index) => (
              <Col>
                <CapsuleStudy
                  name={`plasticity_tests[${index}]`}
                  errors={props.errors}
                  setFieldValue={props.setFieldValue}
                  onHumidityChange={(value: number) =>
                    actionLimits.setOnPlasticityTest(value, index)
                  }
                  values={
                    props.values.plasticity_tests
                      ? props.values.plasticity_tests[index]
                      : {}
                  }
                />
              </Col>
            ))}
          </>
        ) : (
          <Col className="align-self-center">
            <h1 className="display-1 text-center">NP</h1>
          </Col>
        )}
      </Row>
    </>
  );

  const renderOptions = () => (
    <>
      <HeaderTitle title="Amostra não caracterizável" />
      <InputCheckbox
        name="is_not_liquid"
        checked={props.values.is_not_liquid}
        label="Amostra sem liquidez"
        onClick={() => {
          props.setFieldValue("is_not_liquid", !props.values.is_not_liquid);
          if (props.values.is_not_liquid) {
            props.setFieldValue(
              "liquidity_tests",
              initialValues.liquidity_tests
            );
          }
        }}
      />
      <InputCheckbox
        name="is_not_plastic"
        checked={props.values.is_not_plastic}
        label="Amostra sem plasticidade"
        onClick={() => {
          props.setFieldValue("is_not_plastic", !props.values.is_not_plastic);
          if (props.values.is_not_plastic) {
            props.setFieldValue(
              "plasticity_tests",
              initialValues.plasticity_tests
            );
          }
        }}
      />
    </>
  );

  const renderChart = () => (
    <>
      {!props.values.is_not_liquid ? (
        <Chart />
      ) : (
        <Row className="canvas-size-lim">
          <Col className="align-self-center">
            <h1 className="display-1 text-center">NL</h1>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <Form>
      <ExecutionBar isSubmitting={props.isSubmitting} />
      <Row>
        <Col sm={7}>
          {renderLiquidity()}
          <div className="mb-2" />
          {renderPlasticity()}
        </Col>
        <Col sm={5}>
          {renderOptions()}
          <div className="mb-2" />
          {renderChart()}
          <ResultsSummary />
        </Col>
      </Row>
    </Form>
  );
};

// export default LimitsForm;
export default React.memo(
  LimitsForm,
  (props, nextProps) => JSON.stringify(props) === JSON.stringify(nextProps)
);
