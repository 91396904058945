import React, { ReactNode } from "react";
import { Row, Col } from "reactstrap";

interface Props {
  buttonsLeft?: ReactNode;
  buttonsRight?: ReactNode;
  marginTop?: string;
  marginButtom?: string;
}

const ListButtonRow: React.FC<Props> = ({
  buttonsLeft,
  buttonsRight,
  marginTop = "",
  marginButtom = ""
}) => {
  return (
    <Row className={`${marginTop} ${marginButtom}`}>
      {buttonsLeft && <Col className="button-list">{buttonsLeft}</Col>}
      {buttonsRight && (
        <Col className="button-list">
          <Row className="float-right">{buttonsRight}</Row>
        </Col>
      )}
    </Row>
  );
};

ListButtonRow.defaultProps = {
  marginTop: "",
  marginButtom: ""
};

export default ListButtonRow;
