import apis from "./api";

const ASC = "asc";
const DES = "des";

const getControls = (contractId: number) => {
  const endpoint = `/insitu/${contractId}/tests/`;
  return apis.get(endpoint);
};

const postControl = (contractId: number, data: any) => {
  const endpoint = `/insitu/${contractId}/tests/`;
  return apis.post(endpoint, data);
};

const getControl = (id: number) => {
  const endpoint = `/insitu/${id}/`;
  return apis.get(endpoint);
};

const putControl = (id: number, data: any) => {
  const endpoint = `/insitu/${id}/`;
  return apis.put(endpoint, data);
};

const getHoles = (
  contractId: number,
  quantity = "",
  ordering = "",
  date = "",
  fields = "",
  layers: string[] = [],
  places: string[] = [],
  dates: string[] = [],
  optionalPlaces: string[] = [],
  samples: number[] = [],
) => {
  const endpoint =
    `/insitu/${contractId}/holes_filter/new/` +
    `?quantity=${quantity}` +
    `&ordering=${ordering}` +
    `&date=${date}` +
    `&fields=${fields}`;

  const data = {
    layers,
    places,
    dates,
    optional_places: optionalPlaces,
    samples,
  };

  return apis.post(endpoint, data);
};

const postHole = (contractId: number, data: any) => {
  const endpoint = `/insitu/${contractId}/holes/`;
  return apis.post(endpoint, data);
};

const getHole = (id: number) => {
  const endpoint = `/insitu/hole/${id}/`;
  return apis.get(endpoint);
};

const putHole = (id: number, data: any) => {
  const endpoint = `/insitu/hole/${id}/`;
  return apis.put(endpoint, data);
};

const deleteHole = (id: number) => {
  const endpoint = `/insitu/hole/${id}/`;
  return apis.delete(endpoint);
};

export {
  getControls,
  postControl,
  getControl,
  putControl,
  getHole,
  postHole,
  getHoles,
  putHole,
  deleteHole,
  ASC,
  DES,
};
