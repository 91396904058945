import React from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Field } from "formik";
import {
  InputColumn,
  InputText,
  InputCheckbox,
  InputRow,
  InputInteger,
  InputDecimal
} from "../../../../components/Inputs";
import { HeaderTitle } from "../../../../components/Headers";
import { CPS_AGE, formatAgeLabel } from "../utils";

interface Props extends FormikProps<TConcreteControl> {}

const Form: React.FC<Props> = ({ values, setFieldValue }) => {
  const onChangeAgeAmount = (event: any, age: number) => {
    let cpAges = values.concrete_age;
    const value = event.target.value || 0;

    const contains = cpAges.some(item => item.age === age);
    if (contains) {
      cpAges = cpAges.map(item => {
        if (item.age === age) {
          return { ...item, quantity: parseInt(value) };
        }

        return item;
      });
    } else {
      cpAges.push({ age: age, quantity: parseInt(value) });
    }

    setFieldValue("concrete_age", cpAges);
  };

  const getAgeIndex = (age: number) => {
    let i = -1;
    values.concrete_age.forEach((item, index) => {
      if (item.age === age) {
        i = index;
      }
    });

    return i;
  };

  const renderAgesSelector = () => {
    if (values.stress_test > 0) {
      return (
        <>
          <HeaderTitle
            title="Idades e quatidades para ensaio de compressão"
            className="mt-2 pt-2 border-top"
          />
          <Row>
            {CPS_AGE.map(age => (
              <Col sm={3}>
                <InputColumn label={formatAgeLabel(age)}>
                  <Field
                    name={`concrete_age[${getAgeIndex(age)}].quantity`}
                    component={InputInteger}
                    onChange={(event: any) => onChangeAgeAmount(event, age)}
                  />
                </InputColumn>
              </Col>
            ))}
          </Row>
        </>
      );
    }

    return null;
  };

  const renderDimensions = () => {
    if (values.stress_test > 0) {
      return (
        <>
          <HeaderTitle
            title="Dimensão e resistência esperada dos corpos de prova"
            className="mt-2 pt-2 border-top"
          />
          <Row>
            <Col sm={3}>
              <InputColumn label="Altura (mm)">
                <Field component={InputInteger} name="height" required />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="Diâmetro (mm)">
                <Field component={InputInteger} name="diameter" required />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="FCK (MPa)">
                <Field
                  component={InputDecimal}
                  name="fck"
                  onChange={(value: number) => setFieldValue("fck", value)}
                  required
                />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="Capeamento">
                <Field component={InputText} name="capstone_type" />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="Largura (mm)">
                <Field component={InputText} name="width" />
              </InputColumn>
            </Col>

            <Col sm={3}>
              <InputColumn label="Dist. entre apoios (mm)">
                <Field component={InputText} name="support_width" />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="Dist. ruptura-apoio (mm)">
                <Field component={InputText} name="fraction_width" />
              </InputColumn>
            </Col>
          </Row>
          <InputCheckbox
            name="dimensions_control"
            label="Controle de dimensões e peso dos corpos de prova"
            description="Controle de dimensões e peso dos corpos de prova"
            checked={values.dimensions_control}
            onClick={() =>
              setFieldValue("dimensions_control", !values.dimensions_control)
            }
          />
          <InputCheckbox
            name="auto_tb_number"
            label="Controle automático do código dos corpos de prova"
            description="Os CPs serão numerados de 1 a n em cada série do controle"
            checked={values.auto_tb_number}
            onClick={() =>
              setFieldValue("auto_tb_number", !values.auto_tb_number)
            }
          />
          <InputCheckbox
            name="dimensions_on_report"
            label="Apresentar dimensões no relatório de ruptura"
            checked={values.dimensions_on_report}
            onClick={() =>
              setFieldValue(
                "dimensions_on_report",
                !values.dimensions_on_report
              )
            }
          />
        </>
      );
    }

    return null;
  };

  return (
    <>
      <InputRow label="Nome" label_col={2}>
        <Field component={InputText} name="name" />
      </InputRow>
      <InputCheckbox
        name="auto_serie_number"
        label="Corpos de prova prismáticos"
        checked={values.is_prismatic}
        onClick={() => setFieldValue("is_prismatic", !values.is_prismatic)}
      />
      <InputCheckbox
        name="auto_serie_number"
        label="Controle automático do código das séries"
        description="As séries serão numeras de 1 a n neste controle"
        checked={values.auto_serie_number}
        onClick={() =>
          setFieldValue("auto_serie_number", !values.auto_serie_number)
        }
      />
      <InputCheckbox
        name="unique_cp_codes"
        label="Impedir o cadastro de CPs com códigos iguais"
        description="Não será possível cadastrar códigos de CPs repetidos neste control"
        checked={values.unique_cp_codes}
        onClick={() =>
          setFieldValue("unique_cp_codes", !values.unique_cp_codes)
        }
      />
      <InputRow
        label="Quantidade de misturas por lote/série"
        label_col={2}
        className="align-items-center"
      >
        <Field component={InputText} name="mix_amount" />
      </InputRow>
      <HeaderTitle
        title="Ensaios e controles que serão executados em cada mistura"
        className="mt-2 pt-2 border-top"
      />
      <Row>
        <Col sm={4}>
          <InputCheckbox
            name="stress_test"
            label="Ruptura à compressão"
            checked={values.stress_test === 1}
            onClick={() =>
              setFieldValue("stress_test", values.stress_test === 1 ? 0 : 1)
            }
          />
        </Col>
        <Col sm={4}>
          <InputCheckbox
            name="slump_test"
            label="Slump"
            checked={values.slump_test === 1}
            onClick={() =>
              setFieldValue("slump_test", values.slump_test === 1 ? 0 : 1)
            }
          />
        </Col>
        <Col sm={4}>
          <InputCheckbox
            name="slump_flow_test"
            label="Slump Flow"
            checked={values.slump_flow_test === 1}
            onClick={() =>
              setFieldValue(
                "slump_flow_test",
                values.slump_flow_test === 1 ? 0 : 1
              )
            }
          />
        </Col>
        <Col sm={4}>
          <InputCheckbox
            name="air_test"
            label="Teor de Ar incorporado"
            checked={values.air_test === 1}
            onClick={() =>
              setFieldValue("air_test", values.air_test === 1 ? 0 : 1)
            }
          />
        </Col>
        <Col sm={4}>
          <InputCheckbox
            name="jring_test"
            label="Anel J"
            checked={values.jring_test === 1}
            onClick={() =>
              setFieldValue("jring_test", values.jring_test === 1 ? 0 : 1)
            }
          />
        </Col>
        <Col sm={4}>
          <InputCheckbox
            name="segregation_test"
            label="Adensamento"
            checked={values.segregation_test === 1}
            onClick={() =>
              setFieldValue(
                "segregation_test",
                values.segregation_test === 1 ? 0 : 1
              )
            }
          />
        </Col>
      </Row>
      <InputCheckbox
        name="temp_control"
        label="Controle de temperaturas (temperatura ambiente, ar e concreto)"
        description="Controle de temperaturas (temperatura ambiente, ar e concreto)"
        checked={values.temp_control}
        onClick={() => setFieldValue("temp_control", !values.temp_control)}
      />
      {renderDimensions()}
      {renderAgesSelector()}
      <HeaderTitle title="Relatório" className="mt-2 pt-2 border-top" />
      <InputRow label_col={2} label="Observação">
        <Field component={InputText} name="obs01" />
      </InputRow>
      <InputRow label_col={2} label="Observação">
        <Field component={InputText} name="obs02" />
      </InputRow>
      <InputRow label_col={2} label="Observação">
        <Field component={InputText} name="obs03" />
      </InputRow>
      <InputRow label_col={2} label="Equipamentos">
        <Field component={InputText} name="equipments" />
      </InputRow>
      <InputCheckbox
        name="statistic_control"
        label="Apresentar controle estatístico dos corpos de prova"
        description="Os valores de FCK dos corpos de prova serão pintados conforme avaliação estatística"
        checked={values.statistic_control}
        onClick={() =>
          setFieldValue("statistic_control", !values.statistic_control)
        }
      />
      <InputCheckbox
        name="use_rules_growth"
        label="Avaliar controle estatístico como determinado pela norma"
        description="O controle estatístico será baseado nos crescimento esperados pela norma"
        checked={values.use_rules_growth}
        onClick={() =>
          setFieldValue("use_rules_growth", !values.use_rules_growth)
        }
      />
      <InputCheckbox
        name="approve_to_client"
        label="Aprovar para liberar para cliente"
        description="Os ensaios devem ser aprovados para que o cliente tenha acesso aos resultados"
        checked={values.approve_to_client}
        onClick={() =>
          setFieldValue("approve_to_client", !values.approve_to_client)
        }
      />
    </>
  );
};

export default Form;
