/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Field, FieldArray, FormikProps } from "formik";
import {
  InputColumn,
  InputInteger,
  InputGroupAppend,
  InputText,
} from "../../../components/Inputs";
import { SelectMaterial } from "../../../components/Selects";
import { getLayers } from "../../../services/api/tools";
import { HeaderSectionTitle } from "../../../components/Headers";
import { LoadingComponent } from "../../../components/Loader";

interface Props extends FormikProps<DensityControlValues> {
  contractId: number;
}

const ControlForm: React.FC<Props> = ({ setFieldValue, values }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getLayers()
      .then(({ data }) => {
        setFieldValue(
          "acceptances",
          data.map((item: LayerValues) => {
            const current = values.acceptances.find(
              //@ts-ignore
              (acc) => acc.layer === item.id,
            );
            return {
              id: current?.id,
              name: item.name,
              layer: item.id,
              acceptance: current?.acceptance || 0,
            };
          }),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <InputColumn label="Material">
        <Field name="material_type" component={SelectMaterial} required />
      </InputColumn>
      <InputColumn label="Equipamentos">
        <Field name="equipments" component={InputText} />
      </InputColumn>
      <Row>
        <Col className="mt-2">
          <HeaderSectionTitle title="Coeficientes de aceitação" />
          <FieldArray
            name="acceptances"
            render={({ form, name }) => (
              <>
                {form.values[name]
                  .sort((a: LayerValues, b: LayerValues) =>
                    a.name.localeCompare(b.name),
                  )
                  .map((item: LayerValues, index: number) => (
                    <InputColumn label={item.name}>
                      <InputGroupAppend label="%">
                        <Field
                          name={`${name}[${index}].acceptance`}
                          component={InputInteger}
                        />
                      </InputGroupAppend>
                    </InputColumn>
                  ))}
              </>
            )}
          />
        </Col>
      </Row>
    </LoadingComponent>
  );
};

export default ControlForm;
