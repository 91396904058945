import React from "react";
import { FormikProps, Field } from "formik";
import { InputColumn, InputText, InputValidation } from "src/components/Inputs";

const Form: React.FC<FormikProps<Equipment>> = ({ errors }) => {
  return (
    <>
      <InputColumn label="Name">
        <Field name="name" component={InputText} required={true} />
        <InputValidation message={errors.name} />
      </InputColumn>
    </>
  );
};

export default Form;
