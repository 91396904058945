/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { LoadingComponent } from "../Loader";
import SelectBase from "./SelectBase";

interface Props extends SelectType<ContractValuesProps> {
  client?: number;
  isMulti?: boolean;
  request: (clientId: number) => Promise<any>;
  onChange: (contractId?: number) => void;
}

const SelectContract: React.FC<Props> = ({
  client,
  isMulti,
  request,
  onChange,
  ...props
}) => {
  const [items, setItems] = useState<ContractValuesProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getItems = () => {
    if (client) {
      setLoading(true);
      request(client)
        .then(({ data }) => setItems(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getItems();
  }, [client]);

  const options = () => {
    return items
      .sort((a, b) => {
        if (a.work && b.work) return a.work.name.localeCompare(b.work.name);
        return 0;
      })
      .map(item => ({
        value: item.id,
        label: item.work ? item.work.name : ""
      }));
  };

  return (
    <LoadingComponent loading={loading}>
      <SelectBase
        placeholder="Contrato..."
        onClick={onChange}
        options={options()}
        newItem={undefined}
        updatedItem={undefined}
        deletedItem={undefined}
        getItems={() => {}}
        isMulti={isMulti}
        {...props}
      />
    </LoadingComponent>
  );
};

export default SelectContract;
