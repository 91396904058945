import api from "./api";

const getCylinders = (usage?: "insitu" | "sample") => {
  const endpoint = "/cylinders/";
  const params = {
    usage
  };

  return api.get(endpoint, { params });
};

const postCylinders = (data: any) => {
  let endpoint = "/cylinders/";
  return api.post(endpoint, data);
};

const getCylinder = (id: number) => {
  let endpoint = `/cylinders/${id}/`;
  return api.get(endpoint);
};

const putCylinder = (id: number, data: any) => {
  let endpoint = `/cylinders/${id}/`;
  return api.put(endpoint, data);
};

const deleteCylinder = (id: number) => {
  let endpoint = `/cylinders/${id}/`;
  return api.delete(endpoint);
};

export {
  getCylinders,
  postCylinders,
  getCylinder,
  putCylinder,
  deleteCylinder
};
