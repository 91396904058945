import React from "react";
import { Row, Col } from "reactstrap";
import { Field, FormikProps, Form } from "formik";
import { HeaderTitle } from "../../../../components/Headers";
import {
  InputText,
  InputColumn,
  InputValidation,
  InputPhone
} from "../../../../components/Inputs";
import { ButtonSave } from "../../../../components/Buttons";

const EmployeeForm: React.FC<FormikProps<EmployeeFormType>> = ({
  errors,
  isSubmitting
}) => {
  return (
    <Form className="form-horizontal">
      <Row>
        <Col sm={12}>
          <HeaderTitle title="Informações de contato" />
          <InputColumn label="Nome abreviado">
            <Field name="short_name" component={InputText} required />
            <InputValidation message={errors.short_name} />
          </InputColumn>
          <InputColumn label="Nome completo">
            <Field name="full_name" component={InputText} required />
            <InputValidation message={errors.full_name} />
          </InputColumn>
          <InputColumn label="Endereço">
            <Field name="address" component={InputText} />
            <InputValidation message={errors.address} />
          </InputColumn>
          <Row>
            <Col sm={3}>
              <InputColumn label="Telefone 1">
                <InputPhone name="contact_one" />
                <InputValidation message={errors.contact_one} />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="Telefone 2">
                <InputPhone name="contact_two" />
                <InputValidation message={errors.contact_two} />
              </InputColumn>
            </Col>
            <Col sm={6}>
              <InputColumn label="Email">
                <Field name="email" type="email" component={InputText} />
                <InputValidation message={errors.email} />
              </InputColumn>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="button-list">
          <ButtonSave isSubmitting={isSubmitting} />
        </Col>
      </Row>
    </Form>
  );
};

export default EmployeeForm;
