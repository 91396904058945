import React, { useState } from "react";
import classnames from "classnames";
import { Nav, NavLink, NavItem, TabContent, TabPane } from "reactstrap";
import Concrete from "./Concrete";
import Soil from "./Soil";
import Daily from "./Daily";

interface Props {
  contract: ContractValuesProps;
  start: string;
  end: string;
}

const Tabs: React.FC<Props> = props => {
  const [activeTab, setActiveTab] = useState<string>("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "1"
            })}
            onClick={() => {
              toggle("1");
            }}
          >
            Corpos de prova concreto
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "2"
            })}
            onClick={() => {
              toggle("2");
            }}
          >
            Amostras de solos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "3"
            })}
            onClick={() => {
              toggle("3");
            }}
          >
            Controle de diárias
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="mt-2">
        <TabPane tabId="1">
          <Concrete {...props} />
        </TabPane>
        <TabPane tabId="2">
          <Soil {...props} />
        </TabPane>
        <TabPane tabId="3">
          <Daily {...props} />
        </TabPane>
      </TabContent>
    </>
  );
};

export default Tabs;
