import React from "react";
import { InputSelect } from "../Inputs";
import { Loader } from "../Loader";

class SelectSoilControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [], client: null, loading: false };
  }

  componentDidMount() {
    this.setState({
      client: this.props.client
    });
    this.getSoilControls(this.state.client);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.client !== undefined &&
      nextProps.client !== this.props.client
    ) {
      this.setState({ client: nextProps.client });
      this.getSoilControls(nextProps.client);
    }
    return true;
  }

  getSoilControls(client) {
    this.setState({ loading: true });
    const { group, active } = this.props;
    this.props.request("", client, "", active, "", group).then(res => {
      this.setState({ items: res || [], loading: false });
    });
  }

  options() {
    return this.state.items.map((item, index) => ({
      value: item.id,
      label: item.work.name
    }));
  }

  render() {
    return (
      <>
        {this.state.items && !this.state.loading ? (
          <InputSelect
            {...this.props}
            options={this.state.items.map((item, index) => ({
              value: item.id,
              label: item.work.name
            }))}
          />
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default SelectSoilControl;
