import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { ImageThumbnail } from "../Image";
import { ButtonOption } from "../Buttons";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import * as soil_api from "../../services/api/characterization";
import { sendGenericFile } from "../../services/api/core";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface Props {
  onChange: (files?: File) => void;
  onSend?: () => void;
  onRemove?: () => void;
  currentFile: string;
  description?: string;
  imagePreview?: boolean;
}

const InputSingleFile: React.FC<Props> = ({
  onChange,
  onSend,
  onRemove,
  currentFile,
  description,
  imagePreview
}) => {
  return (
    <Row>
      {imagePreview && (
        <Col sm="auto">
          <ImageThumbnail src={currentFile} width={100} />
        </Col>
      )}
      <Col className="mt-auto mb-auto">
        <input
          type="file"
          className="custom-file-input"
          id="inputGroupFile04"
          onChange={event => {
            if (event.currentTarget.files)
              onChange(event.currentTarget.files[0]);
          }}
        />
        <label className="custom-file-label" htmlFor="inputGroupFile04">
          Escolha o arquivo...
        </label>
        <label>{description}</label>
      </Col>
      {imagePreview && (
        <Col className="button-list col-sm-auto mt-auto mb-auto">
          {onSend && <ButtonOption onClick={onSend}>Enviar</ButtonOption>}
          {onRemove && currentFile && (
            <ButtonOption onClick={onRemove}>Remover</ButtonOption>
          )}
        </Col>
      )}
    </Row>
  );
};

InputSingleFile.defaultProps = {
  imagePreview: true
};

const InputMultipleFiles = () => {
  return <div>io</div>;
};

interface State {
  files: any;
}

interface ImageProps {
  sampleId: number;
  currentPhoto?: string | null;
  fileSent: (value: string | null) => void;
}
class InputSingleImage extends React.PureComponent<ImageProps, State> {
  constructor(props: ImageProps) {
    super(props);

    this.state = {
      files: []
    };
  }

  componentDidMount() {
    const files: { [key: string]: any }[] = [];
    if (this.props.currentPhoto) {
      files.push({
        source: this.props.currentPhoto,
        options: {
          type: "local"
        }
      });
    }

    this.setState({
      files
    });
  }

  pond?: FilePond | null = undefined;

  render() {
    return (
      <FilePond
        ref={ref => (this.pond = ref)}
        files={this.state.files}
        allowMultiple={false}
        allowRevert
        labelIdle={
          'Solte aqui sua imagem ou <span class="filepond--label-action">Buscar</span>'
        }
        server={{
          process: (
            _fieldName: any,
            file: any,
            _metadata: any,
            load: any,
            _error: any,
            progress: any
          ) => {
            soil_api
              .postPhoto(
                this.props.sampleId,
                {
                  photo: file
                },
                (e: any) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                }
              )
              .then((res: any) => {
                this.props.fileSent(res.photo);
                load(res.photo);
              });

            return undefined;
          },
          load: "/",
          restore: "/",
          revert: (_source, load, _error) => {
            soil_api.postPhoto(this.props.sampleId, { photo: "" }).then(() => {
              this.props.fileSent(null);
              load();
            });
          }
        }}
        onupdatefiles={fileItems => {
          // Set currently active file objects to this.state
          this.setState({
            files: fileItems.map(fileItem => fileItem.file)
          });
        }}
      />
    );
  }
}

interface InputFileProps {
  file?: string;
  onComplete: (file: any) => void;
}

const InputFile: React.FC<InputFileProps> = ({ file, onComplete }) => {
  const [fileName, setFileName] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>();

  const formatName = (name?: string) => {
    if (name !== undefined && name.length > 20) {
      return `${name.slice(0, 12)}...${name.slice(
        Math.max(12, name.length - 12),
        name.length
      )}`;
    }

    return name;
  };

  const onProgress = (event: any) => {
    if (event) {
      setUploadProgress(event.loaded / event.total);
    }
  };

  const formatFileLabel = () => {
    if (uploadProgress) {
      return `Enviando: ${Math.round(uploadProgress * 100)}%`;
    }
    return formatName(fileName) || "Escolha o arquivo...";
  };

  return (
    <>
      <input
        type="file"
        className="custom-file-input"
        id="inputGroupFile04"
        accept=".xls,.xlsx"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.currentTarget.files) {
            const file = event.currentTarget.files[0];
            setFileName(file.name);
            sendGenericFile({ file }, onProgress).then(data => {
              onComplete(data);
              setUploadProgress(undefined);
            });
          }
        }}
        required={file === undefined}
      />
      <label className="custom-file-label ml-2 mr-2" htmlFor="inputGroupFile04">
        {formatFileLabel()}
      </label>
    </>
  );
};

export { InputSingleFile, InputMultipleFiles, InputSingleImage, InputFile };
