import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { HeaderTitle } from "../../../../components/Headers";
import { getSummarySamples } from "../../../../services/api/characterization";
import { Loader } from "../../../../components/Loader";
import { formatSimpleDate, toLocateNumber } from "../../../../utils/format";

type TSampleItem = {
  code: number;
  granulometry_wet: boolean;
  granulometry_dry: boolean;
  physical_limit: boolean;
  compaction: boolean;
  cbr: boolean;
  arrival_date: string;
  finalized_date: string;
};

const Sample = ({ item }: { item: TSampleItem }) => {
  return (
    <tr>
      <td>{item.code}</td>
      <td>{item.granulometry_wet && "X"}</td>
      <td>{item.granulometry_dry && "X"}</td>
      <td>{item.physical_limit && "X"}</td>
      <td>{item.compaction && "X"}</td>
      <td>{item.cbr && "X"}</td>
      <td>{formatSimpleDate(item.arrival_date)}</td>
      <td>{formatSimpleDate(item.finalized_date)}</td>
    </tr>
  );
};

interface Props {
  contract: ContractValuesProps;
  start: string;
  end: string;
}

const Soil: React.FC<Props> = props => {
  const [summary, setSummary] = useState<any>();

  useEffect(() => {
    getSummarySamples(
      props.contract.id!,
      props.start,
      props.end
    ).then(({ data }) => setSummary(data));
  }, [props.contract.id, props.end, props.start]);

  return (
    <Row>
      <Col>
        <HeaderTitle title="Total de amostras no período" />
        {summary ? (
          <>
            {Object.keys(summary).length > 0 ? (
              <>
                <table className="table table-sm text-center align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>G.U</th>
                      <th>G.S</th>
                      <th>Comp.</th>
                      <th>L.F</th>
                      <th>CBR</th>
                      <th>Ent. Lab</th>
                      <th>Finaliz.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary.time_samples.samples
                      .sort((a: TSampleItem, b: TSampleItem) => {
                        return a.code > b.code;
                      })
                      .map((item: TSampleItem) => (
                        <Sample item={item} />
                      ))}
                  </tbody>
                </table>
                <dl className="row">
                  <dt className="col-sm-5">Total aguardadas para o período</dt>
                  <dd className="col-sm-7">
                    {toLocateNumber(summary.time_samples.total, 0, "-")}
                  </dd>

                  <dt className="col-sm-5">Total de amostras finalizadas</dt>
                  <dd className="col-sm-7">
                    {toLocateNumber(summary.time_samples.finalized, 0, "-")}
                  </dd>
                  <dt className="col-sm-5">
                    Total de amostras não finalizadas
                  </dt>
                  <dd className="col-sm-7">
                    {toLocateNumber(
                      summary.time_samples.total -
                        summary.time_samples.finalized,
                      0,
                      "-"
                    )}
                  </dd>
                </dl>
              </>
            ) : (
              <>Controle de solos não disponível</>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Col>
      <Col>
        <HeaderTitle title="Total de amostras da obra" />
        {summary ? (
          <>
            {Object.keys(summary).length > 0 ? (
              <>
                <table className="table table-sm text-center align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>G.U</th>
                      <th>G.S</th>
                      <th>Comp.</th>
                      <th>L.F</th>
                      <th>CBR</th>
                      <th>Ent. Lab</th>
                      <th>Finaliz.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary.all_samples.samples
                      .sort((a: TSampleItem, b: TSampleItem) => {
                        return a.code > b.code;
                      })
                      .map((item: TSampleItem) => (
                        <Sample item={item} />
                      ))}
                  </tbody>
                </table>
                <dl className="row">
                  <dt className="col-sm-5">Total de amostras</dt>
                  <dd className="col-sm-7">
                    {toLocateNumber(summary.all_samples.total, 0, "-")}
                  </dd>

                  <dt className="col-sm-5">Total de amostras finalizadas</dt>
                  <dd className="col-sm-7">
                    {toLocateNumber(summary.all_samples.finalized, 0, "-")}
                  </dd>
                  <dt className="col-sm-5">
                    Total de amostras não finalizadas
                  </dt>
                  <dd className="col-sm-7">
                    {toLocateNumber(
                      summary.all_samples.total - summary.all_samples.finalized,
                      0,
                      "-"
                    )}
                  </dd>
                </dl>
              </>
            ) : (
              <>Controle de solos não disponível</>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
};

export default Soil;
