import { useUser } from "src/context/admin/user";
import { useParams } from "react-router-dom";
import useFlags from "src/hooks/useFlags";

const useInternalManagerRoutes = (): [
  boolean,
  { href: string; name: string; show: boolean }[]
] => {
  const { isMatech } = useFlags();
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();

  const routes = [
    {
      href: "proposal",
      name: "Propostas",
      show: false
    },
    {
      href: "contracts",
      name: "Contratos",
      show: true
    },
    {
      href: "services",
      name: "Serviços",
      show: isMatech
    },
    {
      href: "invoices",
      name: "Medições",
      show: isMatech
    },
    {
      href: "invoice_control",
      name: "Controle de medições",
      show: isMatech
    },
    {
      href: "team",
      name: "Controle de equipe",
      show: false
    },
    {
      href: "reports",
      name: "Relatórios",
      show: true
    },
    {
      href: "control_options",
      name: "Configurações",
      show: true
    }
  ];

  return [
    user.data?.applications[appSlug].permissions.can_view_invoices || false,
    routes
  ];
};

const useConcreteRoutes = (): [
  boolean,
  { href: string; name: string; show: boolean }[]
] => {
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();

  const routes = [
    {
      href: "concrete",
      name: "Compressão de concreto",
      show:
        user.data?.applications[appSlug].permissions.can_view_concrete || false
    },
    {
      href: "graute",
      name: "Graute de torres eólicas",
      show:
        user.data?.applications[appSlug].permissions.can_view_graute || false
    },
    {
      href: "block_concrete",
      name: "Bloco de concreto",
      show:
        user.data?.applications[appSlug].permissions.can_view_block_floor ||
        false
    },
    {
      href: "block_ceramic",
      name: "Bloco cerâmico",
      show:
        user.data?.applications[appSlug].permissions.can_view_block_floor ||
        false
    },
    {
      href: "floor",
      name: "Pisos intertravados",
      show:
        user.data?.applications[appSlug].permissions.can_view_block_floor ||
        false
    }
  ];

  return [routes.some(item => item.show), routes];
};

const useSoilRoutes = (): [
  boolean,
  { href: string; name: string; show: boolean }[]
] => {
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();

  const routes = [
    {
      href: "samples",
      name: "Caracterização de solos",
      show: true
    },
    {
      href: "density",
      name: 'Densidade "in-situ"',
      show: true
    }
    // {
    //   href: "",
    //   name: "Carga sobre placa"
    // }
  ];

  return [
    user.data?.applications[appSlug].permissions.can_view_soil || false,
    routes
  ];
};

const useTechSettingsRoutes = (): [
  boolean,
  { href: string; name: string; show: boolean }[]
] => {
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();

  const routes = [
    {
      href: "tech_options",
      name: "Configurações",
      show:
        user.data?.applications[appSlug].permissions.can_edit_config_lab ||
        false
    }
  ];

  return [
    user.data?.applications[appSlug].permissions.can_edit_config_lab || false,
    routes
  ];
};

const useAdministrativeRoutes = (): [
  boolean,
  { href: string; name: string; show: boolean }[]
] => {
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();

  const routes = [
    {
      href: "clients",
      name: "Clientes",
      show: true
    },
    {
      href: "works",
      name: "Obras",
      show: true
    },
    {
      href: "employees",
      name: "Funcionários",
      show: true
    },
    {
      href: "users",
      name: "Acesso externo",
      show: true
    },
    {
      href: "admin_options",
      name: "Configuração",
      show: true
    }
  ];

  return [
    user.data?.applications[appSlug].permissions.can_view_admin || false,
    routes
  ];
};

const useClientOptions = (): [
  boolean,
  { href: string; name: string; show: boolean }[]
] => {
  const [user] = useUser();
  const { appSlug } = useParams<{ appSlug: string }>();

  const routes = [
    {
      href: "client_side",
      name: "Laudos e reatórios",
      show: true
    }
  ];
  return [
    user.data?.applications[appSlug].permissions.can_access_client_files ||
      false,
    routes
  ];
};

export {
  useInternalManagerRoutes,
  useConcreteRoutes,
  useSoilRoutes,
  useTechSettingsRoutes,
  useAdministrativeRoutes,
  useClientOptions
};
