import React, { useState, useEffect } from "react";
import { FormikProps, Field } from "formik";
import { Row, Col } from "reactstrap";
import { toLocateNumber } from "../../../utils/format";
import { ButtonOption } from "../../../components/Buttons";
import {
  InputRow,
  InputDate,
  InputValidation,
  InputText,
  InputDecimal,
  InputTextArea,
  InputCheckbox
} from "../../../components/Inputs";
import {
  SelectFunnel,
  SelectSample,
  SelectLayer,
  SelectSand,
  SelectCylinder
} from "../../../components/Selects";
import {
  compactionRate,
  drySoilDensity,
  wetSoilDensity,
  wetSoilWeightWithoutContainer,
  sandHoleWeight,
  volume
} from "./utils";
import { getHoles, DES } from "../../../services/api/density";
import { LoadingComponent } from "../../../components/Loader";

interface Props extends FormikProps<DensityHoleValues> {
  contractId: number;
  controlId: number;
  selectedItem?: DensityHoleValues;
}

const HoleForm: React.FC<Props> = ({
  values,
  errors,
  setFieldValue,
  contractId,
  controlId,
  selectedItem
}) => {
  const [loading, setLoading] = useState(false);
  const [funnel, setFunnel] = useState<FunnelValues | null>();
  const [sand, setSand] = useState<SandValues | null>();
  const [cylinder, setCylinder] = useState<Cylinder | null>();
  const [humidity, setHumidity] = useState<number>(0);

  useEffect(() => {
    if (selectedItem) {
      setFunnel(selectedItem.funnel);
      setSand(selectedItem.sand);
      setCylinder(selectedItem.cylinder);
      setHumidity(selectedItem.humidity);
    }
  }, [selectedItem]);

  const getLastHole = () => {
    setLoading(true);
    getHoles(controlId, "1", DES)
      .then(({ data: lastHoles }: { data: DensityHoleValues[] }) => {
        if (lastHoles.length > 0) {
          const lastHole = lastHoles[0];
          setFunnel(lastHole.funnel);
          setSand(lastHole.sand);
          setFieldValue("method", lastHole.method);
          setFieldValue("date", lastHole.date);
          setFieldValue("place", lastHole.place);
          setFieldValue("layer", lastHole.layer.id);
          setFieldValue("start_weight", lastHole.start_weight);
          setFieldValue("funnel", lastHole.funnel?.id || null);
          setFunnel(lastHole.funnel);
          setFieldValue("sand", lastHole.sand?.id || null);
          setSand(lastHole.sand);
          setFieldValue("cylinder", lastHole.cylinder?.id || null);
          setCylinder(lastHole.cylinder);
          setFieldValue("sample_density", lastHole.sample_density);
          setFieldValue("sample_humidity", lastHole.sample_humidity * 100);
          setFieldValue("container_weight", lastHole.container_weight);
          setFieldValue("sample", lastHole.sample);
        }
      })
      .finally(() => setLoading(false));
  };

  const getCompactionRate = (): number => {
    let _volume = 0;
    let wetSoil = 0;

    if (values.method === "S") {
      if (funnel && sand) {
        const sandWeight = sandHoleWeight(
          values.start_weight,
          values.end_weight,
          funnel.sand_weight
        );
        _volume = volume(sandWeight, sand.density);

        wetSoil = wetSoilWeightWithoutContainer(
          values.dry_soil_weight,
          values.container_weight
        );
      }
    } else if (values.method === "C") {
      if (cylinder) {
        _volume = cylinder.volume;
        wetSoil = wetSoilWeightWithoutContainer(
          values.dry_soil_weight,
          cylinder?.weight
        );
      }
    }

    if (_volume > 0 && wetSoil > 0) {
      const _wetSoilDensity = wetSoilDensity(wetSoil, _volume);

      const _drySoilDensity = drySoilDensity(_wetSoilDensity, humidity);

      return compactionRate(_drySoilDensity, values.sample_density);
    }

    return 0;
  };

  return (
    <LoadingComponent loading={loading}>
      <Row>
        <Col className="justify-content-right">
          <ButtonOption
            className="btn-block btn-outline-secondary"
            onClick={getLastHole}
          >
            Carregar furo anterior
          </ButtonOption>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputCheckbox
            label="Método da areia"
            name="method_sand"
            checked={values.method === "S"}
            onClick={() => {
              setFieldValue("method", "S");
              setFieldValue("sand", undefined);
              setFieldValue("funnel", undefined);
              setFieldValue("start_weight", undefined);
              setFieldValue("end_weight", undefined);
            }}
          />
        </Col>
        <Col>
          <InputCheckbox
            label="Método da cilindro"
            name="method_cylinder"
            checked={values.method === "C"}
            onClick={() => setFieldValue("method", "C")}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col sm={6}>
          <InputRow label_col={6} label="Data">
            <Field name="date" component={InputDate} required />
            <InputValidation message={errors.date} />
          </InputRow>
          <InputRow label_col={6} label="Estaca/Local">
            <Field name="place" component={InputText} required />
            <InputValidation message={errors.place} />
          </InputRow>
          <InputRow label_col={6} label="Complemento do local">
            <Field name="optional_place" component={InputText} />
            <InputValidation message={errors.place} />
          </InputRow>
          <InputRow label_col={6} label="Cota">
            <Field name="cota" component={InputText} required />
            <InputValidation message={errors.cota} />
          </InputRow>
          <InputRow label_col={6} label="Posição">
            <Field
              name="position"
              component="select"
              required
              className="custom-select custom-select-sm"
            >
              <option value="">Escolha a posição</option>
              <option value="LE">Lado esquerdo</option>
              <option value="E">Eixo</option>
              <option value="LD">Lado direito</option>
            </Field>
            <InputValidation message={errors.position} />
          </InputRow>
          {values.method === "S" && (
            <>
              <InputRow label_col={6} label="Peso da areia antes (g)">
                <Field
                  name="start_weight"
                  component={InputDecimal}
                  required
                  onChange={(value: number) => {
                    setFieldValue("start_weight", value);
                  }}
                />
                <InputValidation message={errors.start_weight} />
              </InputRow>
              <InputRow label_col={6} label="Funil">
                <Field
                  name="funnel"
                  component={SelectFunnel}
                  required
                  isClearable={false}
                  onChange={(item?: FunnelValues) => {
                    setFunnel(item);
                    if (item) setFieldValue("funnel", item.id);
                  }}
                />
                <InputValidation message={errors.funnel} />
              </InputRow>
            </>
          )}
          {values.method === "C" && (
            <InputRow label_col={6} label="Cilindro">
              <Field
                name="cylinder"
                component={SelectCylinder}
                required
                isClearable={false}
                onChange={(item: Cylinder) => {
                  setCylinder(cylinder);
                  setFieldValue("cylinder", item.id);
                }}
                type="insitu"
              />
            </InputRow>
          )}
          <InputRow label_col={6} label="Peso do solo + rec (g)">
            <Field
              name="dry_soil_weight"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("dry_soil_weight", value);
              }}
            />
            <InputValidation message={errors.dry_soil_weight} />
          </InputRow>
          <InputRow label_col={6} label="Código da amostra">
            <Field
              name="sample"
              component={SelectSample}
              contractId={contractId}
              onChange={(sample?: SoilSample) => {
                if (sample) {
                  setFieldValue("sample", sample.id);
                  setFieldValue(
                    "sample_humidity",
                    sample && sample.summary
                      ? sample.summary.great_humidity * 100
                      : 0
                  );
                  setFieldValue(
                    "sample_density",
                    sample && sample.summary ? sample.summary.great_density : 0
                  );
                }
              }}
            />
            <InputValidation message={errors.sample} />
          </InputRow>
          <InputRow label_col={6} label="Dens. máxima (g/cm³)">
            <Field
              name="sample_density"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("sample_density", value);
                setFieldValue("sample", "");
              }}
            />
            <InputValidation message={errors.sample_density} />
          </InputRow>
          <InputRow label_col={6} label="Umidade ótima (%)">
            <Field
              name="sample_humidity"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("sample_humidity", value);
                setFieldValue("sample", "");
              }}
            />
            <InputValidation message={errors.sample_humidity} />
          </InputRow>
        </Col>

        <Col sm={6}>
          <InputRow label_col={6} label="Camada">
            <Field
              name="layer"
              component={SelectLayer}
              required
              isClearable={false}
            />
            <InputValidation message={errors.layer} />
          </InputRow>
          <InputRow label_col={6} label="Descrição">
            <Field name="description" component={InputText} required />
            <InputValidation message={errors.description} />
          </InputRow>
          <InputRow label_col={6} label="Espessura da camada (cm)">
            <Field
              name="depth"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("depth", value);
              }}
            />
            <InputValidation message={errors.depth} />
          </InputRow>
          <br />
          <br />
          {values.method === "S" && (
            <>
              <InputRow
                label_col={6}
                label="Peso da areia depois (g)"
                className="mt-1"
              >
                <Field
                  name="end_weight"
                  component={InputDecimal}
                  required
                  onChange={(value: number) => {
                    setFieldValue("end_weight", value);
                  }}
                />
                <InputValidation message={errors.end_weight} />
              </InputRow>
              <InputRow label_col={6} label="Areia">
                <Field
                  name="sand"
                  component={SelectSand}
                  required
                  isClearable={false}
                  onChange={(item?: SandValues) => {
                    setSand(item);
                    if (item) setFieldValue("sand", item.id);
                  }}
                />
                <InputValidation message={errors.sand} />
              </InputRow>
              <InputRow label_col={6} label="Tara do recipiente (g)">
                <Field
                  name="container_weight"
                  component={InputDecimal}
                  required
                  onChange={(value: number) => {
                    setFieldValue("container_weight", value);
                  }}
                />
                <InputValidation message={errors.container_weight} />
              </InputRow>
            </>
          )}
          <InputRow label_col={6} label="Umidade do ensaio (%)">
            <Field
              name="humidity"
              component={InputDecimal}
              required
              onChange={(value: number) => {
                setFieldValue("humidity", value);
                setHumidity(value / 100);
              }}
            />
            <InputValidation message={errors.humidity} />
          </InputRow>
          <Row className="justify-content-center align-center">
            <Col className="text-center">
              <h4>GRAU DE COMPACTAÇÃO</h4>

              <h3>
                {toLocateNumber(getCompactionRate() * 100, 0, "-", "", "%")}
              </h3>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputRow label_col={3} label="Observações">
            <Field name="notes" component={InputTextArea} />
          </InputRow>
        </Col>
      </Row>
    </LoadingComponent>
  );
};

export default HoleForm;
