const sandHoleWeight = (
  startWeight: number,
  endWeight: number,
  sandFunnelWeight: number
): number => {
  return startWeight - endWeight - sandFunnelWeight;
};

const volume = (sandHoleWeight: number, sandDensity: number) => {
  return sandHoleWeight / sandDensity;
};

const wetSoilWeightWithoutContainer = (
  wetSoilWeight: number,
  containerWeight: number
): number => {
  return wetSoilWeight - containerWeight;
};

const wetSoilDensity = (wetSoilWeight: number, volume: number): number => {
  return wetSoilWeight / volume;
};

const drySoilDensity = (wetSoilDensity: number, humidity: number): number => {
  return wetSoilDensity / (humidity + 1);
};

const compactionRate = (
  drySoilDensity: number,
  sampleDensity: number
): number => {
  return drySoilDensity / sampleDensity;
};

const approved = (coefficient: number, compactionRate: number): boolean => {
  return coefficient <= compactionRate;
};

export {
  sandHoleWeight,
  volume,
  wetSoilWeightWithoutContainer,
  wetSoilDensity,
  drySoilDensity,
  compactionRate,
  approved
};
