/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { InputColumn } from "src/components/Inputs";
import { SelectISCPress, SelectBase } from "src/components/Selects";
import { useData } from "src/components/Table";
import { EditPress, InsertPress } from "./Press";
import { ListButtonRow, ButtonDelete } from "src/components/Buttons";
import { deleteSand } from "src/services/api/tools";
import { InsertCalibration, EditCalibration } from "./Constant";
import { getPressId } from "src/services/api/characterization";
import { toLocateNumber } from "src/utils/format";

const Cbrs: React.FC<Props> = () => {
  const press = useData<CBRPress>();
  const calibration = useData<PressCalibration>();
  const [calibrations, setCalibrations] = useState<PressCalibration[]>([]);

  useEffect(() => {
    if (press.selected.item !== undefined && press.selected.item !== null) {
      getCalibrationsItems();
    } else {
      setCalibrations([]);
    }
  }, [press.selected.item]);

  const getCalibrationsItems = () => {
    if (press.selected.item !== undefined) {
      // @ts-ignore
      return getPressId(press.selected.item).then(({ data }) => {
        setCalibrations(data.calibrations);
      });
    } else {
      return setCalibrations([]);
    }
  };

  const onDelete = () => {
    if (press.selected.item && press.selected.item.id !== undefined) {
      return deleteSand(press.selected.item.id)
        .then(({ data }) => {
          press.deleted.set(press.selected.item);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  const calibrationOptions = () => {
    return calibrations.map(item => ({
      value: item.id,
      label: toLocateNumber(item.constant, 4, "0,00", "", "")
    }));
  };

  return (
    <>
      <Row>
        <Col sm={4}>
          <InputColumn label="Prensa">
            <Row>
              <Col>
                <SelectISCPress
                  onChange={value => {
                    press.selected.set(value);
                  }}
                  updatedItem={press.updated.item}
                  deletedItem={press.deleted.item}
                  newItem={press.created.item}
                />
              </Col>
            </Row>
          </InputColumn>
          <Row>
            <Col>
              <ListButtonRow
                buttonsLeft={
                  <>
                    <InsertPress insertedItem={press.created.set} />
                    <EditPress
                      item={press.selected.item!}
                      updatedItem={press.updated.set}
                    />
                  </>
                }
                buttonsRight={
                  <ButtonDelete
                    disabled={!press.selected.item}
                    onClick={onDelete}
                  />
                }
                marginTop="mt-2"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={4}>
          <InputColumn label="Constantes">
            <Row>
              <Col>
                <SelectBase
                  placeholder="Prensa..."
                  newItem={calibration.created.item}
                  updatedItem={calibration.updated.item}
                  deletedItem={calibration.deleted.item}
                  options={calibrationOptions()}
                  getItems={getCalibrationsItems}
                  onClick={value => calibration.selected.set(value)}
                />
              </Col>
            </Row>
          </InputColumn>
          <Row>
            <Col>
              <ListButtonRow
                buttonsLeft={
                  <>
                    <InsertCalibration insertedItem={calibration.created.set} />
                    <EditCalibration
                      item={calibration.selected.item!}
                      updatedItem={calibration.updated.set}
                    />
                  </>
                }
                buttonsRight={
                  <ButtonDelete
                    disabled={!calibration.selected.item}
                    onClick={onDelete}
                  />
                }
                marginTop="mt-2"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Cbrs;
