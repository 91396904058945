import React, { useState, useEffect } from "react";
import { getPress } from "../../services/api/characterization";
import SelectBase from "./SelectBase";

const SelectISCPress: React.FC<SelectType<CBRPress>> = props => {
  const [items, setItems] = useState<CBRPress[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    getPress().then(({ data }) => setItems(data));
  };

  const options = () => {
    return items.map(item => ({
      value: item.id,
      label: item.press_ref
    }));
  };

  return (
    <SelectBase
      placeholder="Prensa..."
      newItem={props.newItem}
      updatedItem={props.updatedItem}
      deletedItem={props.deletedItem}
      getItems={getItems}
      options={options()}
      onClick={props.onChange}
      {...props}
    />
  );
};

export default SelectISCPress;
