import React from "react";
import { FormikHandlers } from "formik";

interface Props {
  field?: {
    onChange: FormikHandlers["handleChange"];
    onBlur: FormikHandlers["handleBlur"];
    value: any;
    name: string;
  };
  form?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  min?: string;
  max?: string;
}

const InputDate: React.FC<Props> = ({
  field,
  name,
  required,
  disabled,
  form,
  ...props
}) => (
  <input
    className="form-control form-control-sm"
    name={name}
    type="date"
    disabled={disabled}
    required={required}
    {...field}
    {...props}
  />
);

InputDate.defaultProps = {
  required: false,
  disabled: false
};

export default InputDate;
