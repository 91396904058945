import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import { strainers } from "./strainers";
import * as math from "../../../../../utils/math";
import * as api from "../../../../../services/api/characterization";

interface State {
  humidities: number[];
  strainers: TStrainer[];
  totalGrossWeight: number;
  partialGrossWeight: number;
  totalWetWeight: number;
  partialWetWeight: number;
  withheldWeightN10: number;
  wetWeightN10: number;
  dryWeightN10: number;
  totalDryWeight: number;
  partialDryWeight: number;
  avgHumidity: number;
  loading: boolean;
  count: number;
}

const initialStrainers = (): TStrainer[] => {
  return strainers.map(item => {
    return {
      ...item,
      id: 0,
      withheld_weight: 0,
      accumulated: 0,
      percentage: 0,
      projected: 0
    };
  });
};

const initialState: State = {
  humidities: [0, 0],
  loading: false,
  strainers: initialStrainers(),
  totalGrossWeight: 2000,
  totalWetWeight: 2000,
  partialGrossWeight: 200,
  partialWetWeight: 200,
  withheldWeightN10: 0,
  wetWeightN10: 0,
  dryWeightN10: 0,
  totalDryWeight: 0,
  avgHumidity: 0,
  partialDryWeight: 0,
  count: 0
};

const actions = {
  getGranulometry: (sampleId: number) => async ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    if (getState().loading) {
      return;
    }

    setState({
      loading: true
    });

    return api
      .getGranulometry(sampleId)
      .then(({ data }: { data: Granulometry[] }) => {
        if (data.length > 0) {
          const test = data[0];
          dispatch(actions.setPartialGrossWeight(test.partial_gross_weight));
          dispatch(actions.setTotalWetWeight(test.total_wet_weight));
          test.strainers.forEach(study => {
            const { strainer } = study;
            dispatch(
              actions.withheldWeightN10(strainer.withheld_weight, strainer.gap)
            );
          });

          return data[0];
        }

        return undefined;
      })
      .finally(() => setState({ loading: false }));
  },

  setHumidity: (value: number, index: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { humidities } = getState();
    const temp = humidities;
    temp[index] = value;

    setState({
      humidities: temp,
      avgHumidity: math.avgHumidity(temp)
    });

    dispatch(actions.setDryWeightN10());
    dispatch(actions.setPartialDryWeight());
  },

  withheldWeightN10: (value: number, gap: number) => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { strainers } = getState();

    const items = strainers.map(item => {
      if (item.gap === gap) {
        return {
          ...item,
          withheld_weight: value
        };
      }

      return item;
    });

    setState({
      strainers: items,
      withheldWeightN10: math.withheldWeightN10(items)
    });

    dispatch(actions.setAccumulatedWeight());
    dispatch(actions.setWetWeightN10());
  },

  setTotalWetWeight: (value: number) => ({
    setState,
    dispatch
  }: StoreActionApi<State>) => {
    setState({
      totalWetWeight: value
    });

    dispatch(actions.setAccumulatedWeight());
    dispatch(actions.setWetWeightN10());
  },

  setPartialGrossWeight: (value: number) => ({
    setState,

    dispatch
  }: StoreActionApi<State>) => {
    setState({
      partialGrossWeight: value
    });

    dispatch(actions.setPartialDryWeight());
  },

  setWetWeightN10: () => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { withheldWeightN10, totalWetWeight } = getState();

    setState({
      wetWeightN10: math.wetWeightN10(totalWetWeight, withheldWeightN10)
    });

    dispatch(actions.setDryWeightN10());
  },

  setDryWeightN10: () => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { avgHumidity, wetWeightN10 } = getState();

    setState({
      dryWeightN10: math.dryWeightN10(avgHumidity, wetWeightN10)
    });

    dispatch(actions.setTotalDryWeight());
  },

  setTotalDryWeight: () => ({ setState, getState }: StoreActionApi<State>) => {
    const { withheldWeightN10, dryWeightN10 } = getState();

    setState({
      totalDryWeight: math.totalDryWeight(withheldWeightN10, dryWeightN10)
    });
  },

  setPartialDryWeight: () => ({
    setState,
    getState,
    dispatch
  }: StoreActionApi<State>) => {
    const { partialGrossWeight, avgHumidity } = getState();

    setState({
      partialDryWeight: math.partialDryWeight(partialGrossWeight, avgHumidity)
    });

    dispatch(actions.setAccumulatedWeight());
  },

  setAccumulatedWeight: () => ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    const { strainers, totalDryWeight, partialDryWeight, count } = getState();

    setState({
      strainers: math.accumulatedWeight(
        strainers,
        totalDryWeight,
        partialDryWeight
      ),
      count: count + 1
    });
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "granulometry"
});

const GranulometrySubscriber = createSubscriber(Store);
const useGranulometry = createHook(Store);
const useGranulometryActions = createHook(Store, {
  selector: null
});
const useGranulometryStrainers = createHook(Store, {
  selector: (state: State) => state.strainers
});
const useLoading = createHook(Store, {
  selector: (state: State) => state.loading
});

export {
  GranulometrySubscriber,
  useGranulometry,
  useGranulometryActions,
  useGranulometryStrainers,
  useLoading
};
