/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useCoreActions, useXlsxVariables } from "src/context/admin/core";
import { InputSelect } from "../Inputs";

interface Props {
  multiple: boolean;
  group: "C" | "S" | "I";
}

const GROUP = {
  C: "Concreto",
  I: "Insitu",
  S: "Solos",
  IN: "Medição"
};

const SelectXlsxVariables: React.FC<Props> = ({
  multiple,
  group,
  ...props
}) => {
  const [xlsxVariables] = useXlsxVariables();
  const [, coreActions] = useCoreActions();

  useEffect(() => {
    coreActions.getXlsxVariables();
  }, []);

  const options = () => {
    if (xlsxVariables.length > 0) {
      return xlsxVariables
        .filter(item => item.name === GROUP[group])
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map(item => {
          const options = item.variables
            .filter(item => item.multiple === multiple)
            .sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
            .map(item => ({
              value: item.name,
              label: `${item.label}`
            }));

          return {
            label: item.name,
            options
          };
        });
    }
    return [];
  };

  return (
    <InputSelect options={options()} placeholder="Dado..." grouped {...props} />
  );
};

export default SelectXlsxVariables;
