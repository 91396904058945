/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ModalForm } from "src/components/Modal";
import Form from "./Form";
import {
  postCapsules,
  putCapsules,
  getCapsule
} from "src/services/api/capsule";

const EditCapsule: React.FC<EditType<Capsule>> = ({
  item,
  dbClick,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();

  useEffect(() => {
    if (dbClick) {
      modalRef.current?.open();
    }
  }, [dbClick]);

  const onSubmit = (values: any) => {
    return putCapsules(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="md"
      title="Editar capsula"
      buttonLabel="Editar"
      data={item}
      getItemRequest={id => getCapsule(id)}
      form={props => {
        return <Form {...props} />;
      }}
      initialValues={item => item}
      onSubmit={onSubmit}
    />
  );
};

const InsertCapsule: React.FC<InsertType<Capsule>> = ({ insertedItem }) => {
  const onSubmit = (values: any) => {
    return postCapsules(values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastro de nova capsula"
      buttonLabel="Nova"
      form={props => {
        return <Form {...props} />;
      }}
      initialValues={() => {
        return {};
      }}
      onSubmit={onSubmit}
    />
  );
};

export { InsertCapsule, EditCapsule };
