import React, { useState } from "react";
import { Modal } from "../../../components/Modal";
import { ButtonOption } from "../../../components/Buttons";
import Tabs from "./details/Tabs";

interface Props {
  contract: ContractValuesProps;
  start: string;
  end: string;
}

const Details: React.FC<Props> = props => {
  const [showModal, setShowModal] = useState(false);

  const toggle = () => setShowModal(!showModal);

  return (
    <>
      <ButtonOption onClick={toggle} type="button">
        Detalhes dos serviços
      </ButtonOption>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        size="xl"
        title="Detalhes da medição"
      >
        <Tabs {...props} />
      </Modal>
    </>
  );
};

export default Details;
