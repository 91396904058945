import React, { useState, useEffect } from "react";
import { putWork, getWork } from "../../../services/api/work";
import { FormikActions } from "formik";
import { ModalForm } from "../../../components/Modal";
import Form from "./Form";

const Edit: React.FC<EditType<WorkFormValues>> = ({
  item,
  updatedItem,
  dbClick
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (dbClick) setShowModal(true);
  }, [dbClick]);

  const onSubmit = (
    values: WorkFormValues,
    { setFieldError }: FormikActions<object>
  ) => {
    if (values.id !== undefined) {
      return putWork(values.id, values)
        .then(res => {
          updatedItem(res);

          return Promise.resolve();
        })
        .catch(res => {
          Object.keys(res).forEach(key => {
            setFieldError(key, res[key]);
          });

          return Promise.reject();
        });
    }

    return Promise.reject();
  };
  return (
    <ModalForm
      type="put"
      size="md"
      title="Editar obra"
      data={item}
      show={showModal}
      getItemRequest={id => getWork(id)}
      form={props => <Form {...props} />}
      initialValues={item => {
        if (item) {
          return {
            ...item,
            client: item.client.id
          };
        }
      }}
      onSubmit={onSubmit}
    />
  );
};

export default Edit;
