import React from "react";
import Select from "react-select";

const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    padding: "0"
  }),
  control: (provided, state) => ({
    ...provided,
    "min-height": "31px",
    height: "10px"
  }),
  valueContainer: provided => ({
    ...provided,
    height: "31px"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "31px"
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};

const isMultiStyle = {
  container: () => ({
    width: "100%",
    padding: "0"
  })
};

class InputSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: null };
    this.selectRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.selectedValue !== this.props.selectedValue) {
      this.setState({
        selectedOption: nextProps.selectedValue
      });
    }

    if (
      JSON.stringify(nextProps.options) !== JSON.stringify(this.props.options)
    ) {
      this.setState({
        selectedOption: null
      });
    }

    return true;
  }

  getDefaultValue() {
    const { options, field, grouped } = this.props;
    if (field && options) {
      if (Array.isArray(field.value)) {
        const filtred_options = options.filter(
          option => field.value.indexOf(option.value) > -1
        );
        if (options.length > 0) return filtred_options;
      } else {
        let option = null;
        if (grouped) {
          options.forEach(group => {
            option =
              group.options.find(option => option.value === field.value) ||
              null;
          });

          return option;
        } else {
          return options.find(option => option.value === field.value) || null;
        }
        // if (options.length > 0) return filtred_options[0];
      }
    }

    return null;
  }

  render() {
    const {
      field,
      name,
      required = false,
      disabled = false,
      isClearable = true,
      options,
      placeholder,
      onClick,
      form
    } = this.props;

    return (
      <>
        <Select
          ref={this.selectRef}
          name={field ? field.name : name}
          isClearable={isClearable}
          placeholder={placeholder}
          styles={this.props.isMulti ? isMultiStyle : customStyles}
          onChange={selectedOption => {
            this.setState({
              selectedOption: selectedOption || null
            });
            if (form && form.setFieldValue) {
              if (Array.isArray(selectedOption)) {
                form.setFieldValue(
                  field ? field.name : name,
                  selectedOption
                    ? selectedOption.map(option => option.value)
                    : null
                );
              } else {
                form.setFieldValue(
                  field ? field.name : name,
                  selectedOption ? selectedOption.value : null
                );
              }
            }
            if (onClick) {
              if (Array.isArray(selectedOption)) {
                onClick(
                  selectedOption
                    ? selectedOption.map(option => option.value)
                    : null
                );
              } else {
                onClick(selectedOption ? selectedOption.value : null);
              }
            }
          }}
          isDisabled={disabled}
          options={options}
          isMulti={this.props.isMulti}
          value={
            field && field.value === null
              ? null
              : this.state.selectedOption || this.getDefaultValue()
          }
        />
        <input
          name={field ? field.name : name}
          tabIndex={-1}
          value={
            field && field.value === null
              ? ""
              : this.state.selectedOption || this.getDefaultValue()
          }
          style={{
            opacity: 0,
            width: 0,
            height: 0,
            position: "absolute"
          }}
          required={required}
        />
      </>
    );
  }
}

export default InputSelect;
