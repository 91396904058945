import api from "./api";

export function getCompanies() {
  let endpoint = "/companies/";
  return api.get(endpoint);
}

export function postCompany(data: any) {
  let endpoint = "/companies/";
  return api.post(endpoint, data);
}

export function getCompany(id: number) {
  let endpoint = `/companies/${id}/`;
  return api.get(endpoint);
}

export function putCompany(id: number, data: any) {
  let endpoint = `/companies/${id}/`;
  return api.put(endpoint, data);
}

export function deleteCompany(id: number) {
  let endpoint = `/companies/${id}/`;
  return api.delete(endpoint);
}
