import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from "react-sweet-state";
import * as api from "../../services/api/capsule";

interface State {
  data: Capsule[];
  loading: boolean;
}

const initialState: State = {
  data: [],
  loading: false
};

const actions = {
  fetchCapsules: () => async ({
    setState,
    getState
  }: StoreActionApi<State>) => {
    if (getState().loading) return;

    setState({ loading: true });

    api
      .getCapsules()
      .then(({ data }) => {
        setState({
          data,
          loading: false
        });
      })
      .catch(() => setState({ loading: false }));
  }
};

const Store = createStore({
  actions,
  initialState,
  name: "capsules"
});

const CapsulesSubscriber = createSubscriber(Store);
const useCapsules = createHook(Store);

export { CapsulesSubscriber, useCapsules };
