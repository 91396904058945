import React from "react";
import * as api from "../../services/api/admin";
import { InputSelectMultiple } from "../Inputs";
import { Loader } from "../Loader";

class SelectMultiplePermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems() {
    api.getPermissions().then(res => {
      this.setState({ items: res });
    });
  }

  options() {
    const { items } = this.state;
    return Object.keys(items)
      .sort((a, b) => {
        return items[a].localeCompare(items[b]);
      })
      .map((item, index) => (
        <option key={index} value={item}>
          {items[item]}
        </option>
      ));
  }

  onChangeSelected(event) {
    const options = [].slice
      .call(event.target.selectedOptions)
      .map(option => option.value);

    const permissions = this.state.items.filter(c =>
      options.includes(c.id.toString())
    );
    this.props.onChange(permissions);
  }

  render() {
    return (
      <>
        {this.state.items.length !== 0 ? (
          <InputSelectMultiple
            {...this.props}
            onChange={this.onChangeSelected.bind(this)}
          >
            {this.options()}
          </InputSelectMultiple>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default SelectMultiplePermission;
