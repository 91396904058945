import React, { useState } from "react";
import { Col, Tooltip } from "reactstrap";
import * as format from "../../../../utils/format";
import { HeaderSectionTitle } from "../../../../components/Headers";
import { SmallText, H4 } from "../../../../components/Text";
import { Field } from "formik";
import { InputDecimal } from "../../../../components/Inputs";

interface Props {
  index: number;
  name?: string;
  code: number;
  load: number;
  fck: string;
  showBorder?: boolean;
  approved?: boolean;
  setFieldValue?: (key: string, value: any) => void;
}

const CPItem: React.FC<Props> = ({
  load,
  code,
  fck,
  name,
  showBorder,
  approved,
  setFieldValue
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const renderAlertIcon = () => {
    if (!approved) {
      return (
        <>
          <i id={`CP${code}`} className="mdi ml-1 mdi-alert-circle" />
          <Tooltip
            className="m-tooltip-inner"
            placement="right"
            isOpen={tooltipOpen}
            target={`CP${code}`}
            toggle={toggle}
          >
            "Não liberado para o cliente"
          </Tooltip>
        </>
      );
    }

    return null;
  };

  return (
    <Col className={"text-sm-center " + (!showBorder ? "" : "border-left")}>
      <HeaderSectionTitle
        title={`CP: ${format.formatCPCode(code)}`}
        rightIcon={renderAlertIcon}
      />
      {name === undefined ? (
        <H4>{load.toFixed(2)}</H4>
      ) : (
        <Field
          name={`${name}.load`}
          component={InputDecimal}
          onChange={(value: number) => {
            setFieldValue && setFieldValue(`${name}.load`, value);
          }}
        />
      )}
      <SmallText>{`${fck} MPa`}</SmallText>
    </Col>
  );
};

CPItem.defaultProps = {
  showBorder: true
};

export default CPItem;
