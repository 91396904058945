import React from "react";
import { HeaderTitle } from "../../../../../../components/Headers";
import { toLocateNumber } from "../../../../../../utils/format";
import { useLimits } from "../context";

const ResultsSummary = () => {
  const [limits] = useLimits();

  return (
    <>
      <HeaderTitle title="Resultados" />
      <dl className="row">
        <dt className="col-sm-4">Limite de liquidez</dt>
        <dd className="col-sm-8">
          {toLocateNumber(limits.liquidityLimit * 100, 3, "NL", "", " %")}
        </dd>
        <dt className="col-sm-4">Limite de plasticidade</dt>
        <dd className="col-sm-8">
          {toLocateNumber(limits.plasticityLimit * 100, 3, "NP", "", " %")}%
        </dd>
        <dt className="col-sm-4">Índice de plasticidade</dt>
        <dd className="col-sm-8">
          {toLocateNumber(limits.plasticityIndex * 100, 2, "NL/NP", "", " %")}%
        </dd>
      </dl>
    </>
  );
};

export default ResultsSummary;
