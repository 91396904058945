import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import { InputColumn } from "src/components/Inputs";
import Select from "react-select";
import { ButtonOption } from "src/components/Buttons";
import { BASE_REPORT_URL } from "src/services/api/api";
import { getLots } from "src/services/api/block_ceramic";

interface Props {
  contractId: number;
}

const ReportBar: React.FC<Props> = ({ contractId }) => {
  const [lots, setLots] = useState<{ id: number; lot: string }[]>([]);

  useEffect(() => {
    getLots(contractId, "lot,id").then(({ data }) => {
      setLots(data);
    });
  }, [contractId]);

  const lotOptions = () => {
    return lots.map(lot => {
      return { label: lot.lot, value: lot.id };
    });
  };

  const onSubmit = (values: any) => {
    let url = "#";
    let args = `?contract=${contractId}&lots=${values.lots.join(",")}`;

    url = `${BASE_REPORT_URL}block_ceramic/reports${args}`;
    window.open(url, "_blank");
  };

  return (
    <Formik
      initialValues={{
        lots: []
      }}
      onSubmit={onSubmit}
      render={({ values, setFieldValue }) => (
        <Form>
          <Row>
            <Col sm={3}>
              <InputColumn label="Lotes">
                <Select
                  isMulti
                  styles={{
                    container: () => ({
                      width: "100%",
                      padding: "0"
                    })
                  }}
                  options={lotOptions()}
                  onChange={(values: any) =>
                    setFieldValue(
                      "lots",
                      values.map((item: any) => item.value)
                    )
                  }
                />
              </InputColumn>
            </Col>
            <Col sm={3} className="mt-3">
              <ButtonOption
                type="submit"
                className="btn-block"
                disabled={values.lots.length === 0}
              >
                Gerar relatório
              </ButtonOption>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default ReportBar;
