import React from "react";
import { Row, Col } from "reactstrap";
import { Field } from "formik";
import {
  InputDecimal,
  InputColumn,
  InputRow
} from "../../../../../../components/Inputs";
import { HeaderTitle } from "../../../../../../components/Headers";
import { toLocateNumber } from "../../../../../../utils/format";
import { useGranulometryStrainers, useGranulometryActions } from "../context";

interface StrainerProps {
  name: string;
  setFieldValue: (field: string, value: any) => void;
  inches: string;
  gap: number;
  percentage: number;
  accumulated: number;
}

const Strainer: React.FC<StrainerProps> = ({
  name,
  setFieldValue,
  inches,
  gap,
  accumulated,
  percentage
}) => {
  const [, actionsGranulometry] = useGranulometryActions();

  return (
    <Row>
      <Col sm={2} className="pl-1 pr-1">
        <InputRow className="text-center" label={inches} />
      </Col>
      <Col sm={2} className="pl-1 pr-1">
        <InputRow className="text-center" label={`${gap}`} />
      </Col>
      <Col className="pl-1 pr-1">
        <InputColumn>
          <Field
            name={`${name}.withheld_weight`}
            component={InputDecimal}
            onChange={(value: number) => {
              actionsGranulometry.withheldWeightN10(value, gap);
              setFieldValue(`${name}.withheld_weight`, value);
            }}
            required
          />
        </InputColumn>
      </Col>
      <Col className="pl-1 pr-1">
        <InputRow
          className="text-center"
          label={toLocateNumber(accumulated, 3, 0)}
        />
      </Col>
      <Col className="pl-1 pr-1">
        <InputRow
          className="text-center"
          label={toLocateNumber(percentage * 100, 2, 0)}
        />
      </Col>
    </Row>
  );
};

interface Props {
  name: string;
  setFieldValue: (field: string, value: any) => void;
  values: Strainer[];
}

const StrainerStudy: React.FC<Props> = ({ name, setFieldValue, values }) => {
  const [strainers] = useGranulometryStrainers();

  return (
    <>
      {values.map((item, index) => {
        const { inches, percentage, accumulated } = strainers.filter(
          i => i.gap === item.strainer.gap
        )[0];

        return (
          <Strainer
            gap={item.strainer.gap}
            accumulated={accumulated}
            percentage={percentage}
            inches={inches}
            name={`${name}[${index}].strainer`}
            setFieldValue={setFieldValue}
          />
        );
      })}
    </>
  );
};

const StrainerTitle = () => (
  <Row className="text-center">
    <Col sm={4} className="pl-1 pr-1">
      <HeaderTitle title="Peneiras" />
    </Col>
    <Col className="pl-1 pr-1">
      <HeaderTitle title="Peso retido parcial (g)" />
    </Col>
    <Col className="pl-1 pr-1">
      <HeaderTitle title="Peso que passa acumul. (g)" />
    </Col>
    <Col className="pl-1 pr-1">
      <HeaderTitle title="% que passa am. total" />
    </Col>
  </Row>
);

export { StrainerTitle };
export default StrainerStudy;
