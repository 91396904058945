import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import axios from "axios";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
);

class InputSingleImage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    };
  }

  componentDidMount() {
    const files = [];
    if (this.props.currentPhoto) {
      files.push({
        source: this.props.currentPhoto,
        options: {
          type: "local"
        }
      });
    }

    this.setState({
      files
    });
  }

  pond = undefined;

  render() {
    return (
      <FilePond
        ref={ref => (this.pond = ref)}
        credits={{}}
        files={this.state.files}
        allowMultiple={false}
        allowRevert
        labelIdle={
          'Solte aqui sua imagem ou <span class="filepond--label-action">Buscar</span>'
        }
        imagePreviewHeight={70}
        imageCropAspectRatio={"4:1"}
        stylePanelLayout={"compact"}
        styleLoadIndicatorPosition={"center bottom"}
        styleButtonRemoveItemPosition={"center bottom"}
        server={{
          process: (_fieldName, file, _metadata, load, _error, progress) => {
            this.props.sentImage(file, progress).then(imageUri => {
              load(imageUri);
            });

            return undefined;
          },
          load: (_source, load, _error) => {
            axios({
              url: this.props.currentPhoto, //your url
              method: "GET",
              responseType: "blob" // important
            })
              .then(({ data }) => {
                load(data);
              })
              .catch(() => {
                load();
              });
          },
          restore: "/",
          revert: (_source, load, _error) => {
            this.props.deleteImage().then(() => {
              load();
            });
          },
          remove: (_source, load, _error) => {
            this.props.deleteImage().then(() => {
              load();
            });
          }
        }}
        onupdatefiles={fileItems => {
          // Set currently active file objects to this.state
          this.setState({
            files: fileItems.map(fileItem => fileItem.file)
          });
        }}
      />
    );
  }
}

export { InputSingleImage };
