/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import { InputCheckbox, InputColumn } from "../../../components/Inputs";
import { ButtonSecondary } from "../../../components/Buttons";
import {
  sampleSummaryXlsxReport,
  fullSoilReportV2,
  summarySoilReportV2,
} from "src/services/reports";
import { useParams } from "react-router-dom";
import { getSamples } from "src/services/api/characterization";
import { trackReport } from "src/services/track";
import { dumpTempReportData } from "src/services/storage";
import { openReportPage } from "src/services/api/report";
import { checkFeature, fastToken } from "../../../services/api/core";

const SUMMARY_REPORT = 0;
const FULL_REPORT = 1;
const SUMMARY_XLSX_REPORT = 2;
const FULL_REPORT_V2 = 3;
const SUMMARY_REPORT_V2 = 4;

interface Props {
  contractId: number;
  samples: number[];
  onFilter: (filters: { layers?: string[]; places?: string[] }) => void;
}

const ReportBar: React.FC<Props> = ({ contractId, samples, onFilter }) => {
  const [reportType, setReportType] = useState<number>(-1);
  const [cover, setCover] = useState(false);
  const [granulometry, setGranulometry] = useState(false);
  const [limits, setLimits] = useState(false);
  const [compaction, setCompaction] = useState(false);
  const [cbr, setCbr] = useState(false);
  const [cbrCharts, setCbrCharts] = useState(false);
  const [layers, setLayers] = useState<Set<string>>(new Set());
  const { appSlug } = useParams<{ appSlug: string }>();
  const [places, setPlaces] = useState<Set<string>>(new Set());
  const [reportPlaces, setReportPlaces] = useState<string[]>([]);
  const [reportLayers, setReportLayers] = useState<string[]>([]);

  useEffect(() => {
    const places: Set<string> = new Set();
    const layers: Set<string> = new Set();
    getSamples(contractId, "layer,collection_place", 1, 9999).then(
      ({ data }) => {
        data.results.forEach((item: any) => {
          item.collection_place && places.add(item.collection_place);
          item.layer && layers.add(item.layer.name);
        });
        setLayers(layers);
        setPlaces(places);
      },
    );

    reportOptions();
  }, [contractId]);

  const getReportURL = () => {
    let reportStorageData: ReportStorageData | null = null;
    if (reportType === SUMMARY_XLSX_REPORT) {
      sampleSummaryXlsxReport(contractId, samples).then(({ data }) => {
        window.open(data.file, "_blank");
      });

      trackReport("sample", "summary_xlsx");
    } else if (reportType === SUMMARY_REPORT) {
      trackReport("sample", "summary");
      reportStorageData = {
        type: "sample_summary",
        params: {
          contract: contractId,
          application: appSlug,
        },
        data: {
          places: reportPlaces,
          layers: reportLayers,
          samples,
        },
      };
    } else if (reportType === FULL_REPORT) {
      trackReport("sample", "full");
      reportStorageData = {
        type: "sample_full",
        params: {
          contract: contractId,
          application: appSlug,
          cover,
          granulometry,
          limits,
          compaction,
          cbr,
          cbr_charts: cbrCharts,
        },
        data: {
          places: reportPlaces,
          layers: reportLayers,
          samples,
        },
      };
    } else if (reportType === FULL_REPORT_V2) {
      trackReport("sample", "full_v2");
      fastToken().then(({ data }) => {
        fullSoilReportV2(
          data.access,
          contractId,
          samples,
          appSlug,
          cover,
          granulometry,
          limits,
          compaction,
          cbr,
          cbrCharts,
        );
      });
    } else if (reportType === SUMMARY_REPORT_V2) {
      trackReport("sample", "summary_v2");
      fastToken().then(({ data }) => {
        summarySoilReportV2(data.access, contractId, samples, appSlug);
      });
    }

    if (reportStorageData !== null) {
      const key = dumpTempReportData(reportStorageData);
      openReportPage(key);
    }
  };

  const setType = (value: any) => {
    setReportType(value.value);
  };

  const [options, setOptions] = useState<any>([]);
  const reportOptions = async () => {
    const _options = [];
    let goSummaryReport = false;
    let goSoilReport = false;
    try {
      await checkFeature("go_report_soil_summary");
      goSummaryReport = true;
    } catch (e) {}

    try {
      await checkFeature("go_report_soil");
      goSoilReport = true;
    } catch (e) {}

    if (goSoilReport) {
      _options.push({
        value: FULL_REPORT_V2,
        label: "Relatório completo",
      });
    } else {
      _options.push({
        value: FULL_REPORT,
        label: "Relatório completo",
      });
      _options.push({
        value: FULL_REPORT_V2,
        label: "Relatório completo (teste)",
      });
    }

    if (goSummaryReport) {
      _options.push({
        value: SUMMARY_REPORT_V2,
        label: "Relatório resumo",
      });
    } else {
      _options.push({
        value: SUMMARY_REPORT,
        label: "Relatório resumo",
      });
      _options.push({
        value: SUMMARY_REPORT_V2,
        label: "Relatório resumo (teste)",
      });
    }

    _options.push({
      value: SUMMARY_XLSX_REPORT,
      label: "Planilha resumo",
    });

    setOptions(_options);
  };

  const placeOptions = () => {
    return Array.from(places)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => {
        return {
          value: item,
          label: item,
        };
      });
  };

  const layerOptions = () => {
    return Array.from(layers)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
  };

  const setPlaceValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportPlaces(options);
    onFilter({
      places: options,
      layers: reportLayers,
    });
  };

  const setLayerValues = (values: any) => {
    const options = values.map((option: any) => option.value);
    setReportLayers(options);
    onFilter({
      places: reportPlaces,
      layers: options,
    });
  };

  const enabledReport = (() => {
    const isSummaryReport = [
      SUMMARY_REPORT,
      SUMMARY_XLSX_REPORT,
      SUMMARY_REPORT_V2,
    ].includes(reportType);

    const isDefaultReport = reportType === FULL_REPORT || FULL_REPORT_V2;

    const dataSelected =
      samples.length > 0 || reportPlaces.length > 0 || reportLayers.length > 0;
    const pageSelected =
      cover || granulometry || compaction || limits || cbr || cbrCharts;

    return (dataSelected && pageSelected && isDefaultReport) || isSummaryReport;
  })();

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {}}
      render={() => (
        <Form>
          <Row>
            <Col sm={3}>
              <InputColumn label="Filtro">
                <Select
                  placeholder="Locais..."
                  options={placeOptions()}
                  isMulti
                  styles={{
                    container: () => ({
                      width: "100%",
                      padding: "0",
                    }),
                  }}
                  onChange={setPlaceValues}
                />
              </InputColumn>
              <InputColumn>
                <Select
                  placeholder="Camadas..."
                  options={layerOptions()}
                  isMulti
                  styles={{
                    container: () => ({
                      width: "100%",
                      padding: "0",
                    }),
                  }}
                  onChange={setLayerValues}
                />
              </InputColumn>
            </Col>
            <Col sm={2}>
              <InputColumn label="Ensaios">
                <InputCheckbox
                  label="Capa"
                  name="cover"
                  checked={cover}
                  disabled={[SUMMARY_REPORT, SUMMARY_REPORT_V2].includes(
                    reportType,
                  )}
                  onClick={() => setCover(!cover)}
                />
              </InputColumn>
              <InputColumn>
                <InputCheckbox
                  label="Granulometria"
                  name="granulometry"
                  checked={granulometry}
                  disabled={[SUMMARY_REPORT, SUMMARY_REPORT_V2].includes(
                    reportType,
                  )}
                  onClick={() => setGranulometry(!granulometry)}
                />
              </InputColumn>
              <InputColumn>
                <InputCheckbox
                  label="Limites físicos"
                  name="limits"
                  checked={limits}
                  disabled={[SUMMARY_REPORT, SUMMARY_REPORT_V2].includes(
                    reportType,
                  )}
                  onClick={() => setLimits(!limits)}
                />
              </InputColumn>
            </Col>
            <Col sm={2} className="mt-3">
              <InputColumn>
                <InputCheckbox
                  label="Compactação"
                  name="compaction"
                  checked={compaction}
                  disabled={[SUMMARY_REPORT, SUMMARY_REPORT_V2].includes(
                    reportType,
                  )}
                  onClick={() => setCompaction(!compaction)}
                />
              </InputColumn>

              <InputColumn>
                <InputCheckbox
                  label="CBR"
                  name="cbr"
                  checked={cbr}
                  disabled={[SUMMARY_REPORT, SUMMARY_REPORT_V2].includes(
                    reportType,
                  )}
                  onClick={() => setCbr(!cbr)}
                />
              </InputColumn>
              <InputColumn>
                <InputCheckbox
                  label="Gráficos CBR"
                  name="cbrCharts"
                  checked={cbrCharts}
                  disabled={
                    [SUMMARY_REPORT, SUMMARY_REPORT_V2].includes(reportType) ||
                    !cbr
                  }
                  onClick={() => setCbrCharts(!cbrCharts)}
                />
              </InputColumn>
            </Col>
            <Col sm={3}>
              <InputColumn label="Modelo do relatório">
                <Select
                  options={options}
                  styles={{
                    container: () => ({
                      width: "100%",
                      padding: "0",
                    }),
                  }}
                  onChange={setType}
                  required
                />
              </InputColumn>

              <ButtonSecondary
                type="submit"
                onClick={getReportURL}
                disabled={!enabledReport}
              >
                Gerar relatório
              </ButtonSecondary>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default ReportBar;
