/* eslint-disable react-hooks/exhaustive-deps */
import { Field, FormikActions, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { ButtonDelete, ListButtonRow } from "src/components/Buttons";
import { InputColumn, InputText, InputTextArea } from "src/components/Inputs";
import { ItemToManager } from "src/components/ItemContent";
import { LoadingComponent } from "src/components/Loader";
import { ModalForm } from "src/components/Modal";
import { SelectBase, SelectSoilProject } from "src/components/Selects";
import { useData } from "src/components/Table";
import * as soilApi from "src/services/api/characterization";

const Form: React.FC<FormikProps<SoilProjectControl>> = () => {
  return (
    <>
      <InputColumn label="Código">
        <Field name="code" component={InputText} required />
      </InputColumn>
      <InputColumn label="Nome">
        <Field name="name" component={InputText} required />
      </InputColumn>
      <InputColumn label="Observações">
        <Field name="notes" component={InputTextArea} />
      </InputColumn>
    </>
  );
};

interface InsertProps {
  controlId: number;
  insertedItem: (item: SoilProjectControl) => void;
}

const Insert: React.FC<InsertProps> = ({ controlId, insertedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return soilApi
      .postProject(controlId, values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="post"
      size="md"
      title="Cadastrar novo projeto"
      initialValues={() => {
        return {};
      }}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
    />
  );
};

interface EditProps {
  controlId: number;
  item: SoilProjectControl;
  updatedItem: (item: SoilProjectControl) => void;
}

const Edit: React.FC<EditProps> = ({ controlId, item, updatedItem }) => {
  const onSubmit = (
    values: any,
    { setFieldError, setSubmitting }: FormikActions<object>
  ) => {
    return soilApi
      .putProject(controlId, values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(({ data }) => {
        Object.keys(data).forEach(key => {
          setFieldError(key, data[key]);
        });

        return Promise.reject(data);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalForm
      type="put"
      size="md"
      data={item ? { id: item.id } : undefined}
      getItemRequest={id => {
        return soilApi.getProject(controlId, id);
      }}
      form={props => <Form {...props} />}
      onSubmit={onSubmit}
      initialValues={item => item}
      title="Editar funil"
    />
  );
};

interface Props {
  controlId: number;
}

const Projects: React.FC<Props> = ({ controlId }) => {
  const [projects, setProjects] = useState<SoilProjectControl[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const item = useData<SoilProjectControl>();

  useEffect(() => {
    setLoading(true);
    getProjects();
  }, []);

  const getProjects = () => {
    soilApi
      .getProjects(controlId)
      .then(({ data }) => setProjects(data))
      .finally(() => setLoading(false));
  };

  const options = () => {
    return projects.map(item => {
      return {
        value: item,
        label: item.code
      };
    });
  };

  const onDelete = (): Promise<any> => {
    return Promise.resolve();
  };

  return (
    <LoadingComponent loading={loading}>
      <ItemToManager
        title="Projetos"
        notes="Estes são as informações que podem ser utilizadas para definir qual projeto foi seguido para a execução de determinado ensaio"
      >
        <Row>
          <Col sm={6}>
            <InputColumn label="Projetos">
              <SelectSoilProject
                controlId={controlId}
                newItem={item.created.item}
                updatedItem={item.updated.item}
                deletedItem={item.deleted.item}
                onChange={item.selected.set}
              />
            </InputColumn>
            <Row>
              <Col>
                <ListButtonRow
                  buttonsLeft={
                    <>
                      <Insert
                        controlId={controlId}
                        insertedItem={item.created.set}
                      />
                      <Edit
                        controlId={controlId}
                        item={item.selected.item!}
                        updatedItem={item.updated.set}
                      />
                    </>
                  }
                  buttonsRight={
                    <ButtonDelete
                      disabled={!item.selected.item}
                      onClick={onDelete}
                    />
                  }
                  marginTop="mt-2"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ItemToManager>
    </LoadingComponent>
  );
};

export default Projects;
