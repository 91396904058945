import {
  formatCNPJ,
  formatCPF,
  formatPhone,
  trimString
} from "../../../utils/format";

const columns = [
  {
    title: "#",
    data: "id"
  },
  {
    title: "Nome",
    data: "name",
    render(data: string) {
      return trimString(data, 25);
    }
  },
  {
    title: "CNPJ/CPF",
    data: "register_code",

    render(data: string) {
      if (data.length === 14) {
        return formatCNPJ(data);
      }

      return formatCPF(data);
    }
  },
  {
    title: "Contato",
    data: "contact_name",
    render(data: string) {
      return trimString(data || "", 25);
    }
  },
  {
    title: "Telefone Um",
    data: "contact_phone_one",
    render(data: string) {
      return formatPhone(data);
    }
  }
];

export default columns;
