import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import moment from "moment";
import { InputDate, InputColumn } from "../../../components/Inputs";
import { LoadingComponent } from "../../../components/Loader";
import { columnsTBtoBreak } from "./columns";
import { ListButtonRow, ButtonOption } from "../../../components/Buttons";
import { TableList, useData } from "../../../components/Table";
import { getCPsToBreak } from "../../../services/api/concrete";
import TestingBody from "./TestingBoby";
import { checkFeature, fastToken } from "src/services/api/core";
import { cpToBreakReport } from "src/services/reports";
import { useParams } from "react-router-dom";

const CPsByPeriod = () => {
  const { appSlug } = useParams<{ appSlug: string }>();
  const item = useData<TConcreteTestingBody>();
  const [items, setItems] = useState<TConcreteTestingBody[]>([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [maxEnd, setMaxEnd] = useState(
    moment(new Date()).add(7, "days").format("YYYY-MM-DD")
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const getItems = (start: string, end: string) => {
    getCPsToBreak(start, end)
      .then(({ data }) => setItems(data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getItems(startDate, endDate);
  }, [endDate, startDate]);

  const getReportURL = (event: any) => {
    event.preventDefault();

    checkFeature("report_to_break_beta").then(() => {
      fastToken().then(({ data }) => {
        cpToBreakReport(startDate, endDate, appSlug, data.access);
      });
    });
  };

  const changeStartDate = (event: any) => {
    const { value } = event.target;

    setStartDate(value);

    const diffDays = moment(endDate).diff(moment(value), "days");

    if (diffDays > 7 || diffDays <= 0) {
      setMaxEnd(moment(value).add(7, "days").format("YYYY-MM-DD"));
    } else {
      getItems(value, endDate);
    }
  };

  const changeEndDate = (event: any) => {
    const { value } = event.target;

    setEndDate(value);

    getItems(startDate, value);
  };

  const updatedCP = (cp: TConcreteTestingBody) => {
    if (cp.load !== undefined && cp.load > 0) {
      item.deleted.set(cp);
    }
  };

  return (
    <Card className="filter-bar text-left">
      <CardBody>
        <Row>
          <Col sm={2}>
            <InputColumn label="Data de início">
              <InputDate
                name="date"
                onChange={changeStartDate}
                value={startDate}
                required
              />
              <small />
            </InputColumn>
          </Col>
          <Col sm={2}>
            <InputColumn
              label="Data de fim"
              create={<i id="dates" className="mdi ml-1 mdi-alert-circle" />}
            >
              <InputDate
                name="date"
                onChange={changeEndDate}
                value={endDate}
                min={startDate}
                max={maxEnd}
                required
              />
            </InputColumn>
          </Col>
          <Col sm="auto">
            <Tooltip
              className="m-tooltip-inner"
              placement="right"
              isOpen={tooltipOpen}
              target="dates"
              toggle={toggle}
            >
              Período limitado a uma semana a partir da data de início
            </Tooltip>
          </Col>
        </Row>
        <LoadingComponent loading={loading}>
          <TableList
            data={items}
            columns={columnsTBtoBreak}
            selected={item.selected.set}
            updated={item.updated.item}
            created={item.created.item}
            deleted={item.deleted.item}
            searching={false}
            dbClick={item => {
              item.selected.set(item);
              // setDbClick(true);
              // setDbClick(false);
            }}
          />
        </LoadingComponent>
        <ListButtonRow
          marginTop="mt-2"
          buttonsRight={<></>}
          buttonsLeft={
            <>
              <ButtonOption type="submit" onClick={getReportURL}>
                Relatório de ruptura
              </ButtonOption>
              <TestingBody updatedItem={updatedCP} />
            </>
          }
        />
      </CardBody>
    </Card>
  );
};

export default CPsByPeriod;
