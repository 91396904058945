/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ModalForm } from "../../../../components/Modal";
import {
  updateCP,
  getSeries,
  searchCPs,
  getControlById
} from "../../../../services/api/concrete";
import Form from "./Form";
import { LoadingComponent } from "../../../../components/Loader";
import FetchForm from "./FetchForm";
import { FormikActions } from "formik";
import { ListButtonRow } from "src/components/Buttons";

interface AlertProps {
  showModal: boolean;
  toggle: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

const AlertModal = ({ showModal, toggle, onConfirm, onCancel }: AlertProps) => {
  return (
    <Modal
      centered
      isOpen={showModal}
      toggle={toggle}
      className={"text-center"}
      size={"sm"}
      keyboard={false}
    >
      <ModalHeader>Alerta de ruptura</ModalHeader>
      <ModalBody>
        <h3>Idade de ruptura ainda não atingida!</h3>
        <h3>Deseja continuar?</h3>

        <ListButtonRow
          marginTop="mt-3"
          buttonsLeft={
            <>
              <Button size="sm" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button size="sm" color="danger" onClick={onConfirm}>
                Continuar
              </Button>
            </>
          }
        />
      </ModalBody>
    </Modal>
  );
};

interface Props {
  showModal?: boolean;
  itemToEdit?: TConcreteTestingBody;
  updatedItem?: (cp: TConcreteTestingBody) => void;
}

const TestingBody: React.FC<Props> = ({
  showModal,
  itemToEdit,
  updatedItem
}) => {
  const modalFetchRef: React.RefObject<ModalForm> = React.createRef();
  const modalTestRef: React.RefObject<ModalForm> = React.createRef();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [series, setSeries] = useState<TConcreteSeries>();
  const [control, setControl] = useState<TConcreteControl>();
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<TConcreteTestingBody>();

  useEffect(() => {
    if (item !== undefined && item.id && item.serie && item.control) {
      Promise.all([getSeries(item.serie), getControlById(item.control)]).then(
        values => {
          setSeries(values[0].data);
          setControl(values[1].data);
          setLoading(false);
        }
      );
    }
  }, [item]);

  useEffect(() => {
    if (showModal) {
      setItem(itemToEdit);
      modalTestRef.current?.open();
    }
  }, [showModal]);

  const onSubmit = (values: any) => {
    if (
      item !== undefined &&
      item.id !== undefined &&
      updatedItem !== undefined
    ) {
      return updateCP(item.id, values).then(({ data }) => updatedItem(data));
    }

    return Promise.reject();
  };

  const onSubmitFetch = (
    values: any,
    { setFieldError }: FormikActions<any>
  ) => {
    return searchCPs(values.code).then(({ data }) => {
      if (data.length === 0) {
        setFieldError("code", "Código não encontrato");
      } else if (data.length === 1) {
        // Go to test if the rupture data is equals or smallest than today
        // else show alert message
        if (data[0].out_days !== undefined && data[0].out_days <= 0) {
          modalTestRef.current?.open();
        } else {
          setShowAlert(true);
        }
        setItem(data[0]);

        return Promise.resolve(data);
      } else if (data.length > 1) {
        setFieldError("code", "Este código está presente em mais de uma obra");
      }

      return Promise.reject();
    });
  };

  return (
    <>
      <AlertModal
        showModal={showAlert}
        onCancel={() => {
          setShowAlert(false);
          modalFetchRef.current?.open();
          setItem(undefined);
        }}
        onConfirm={() => {
          setShowAlert(false);
          modalTestRef.current?.open();
        }}
        toggle={() => setShowAlert(false)}
      />
      <ModalForm
        ref={modalTestRef}
        type="put"
        size="mxl"
        title="Editar CP"
        buttonLabel="Romper CP"
        showButton={false}
        data={{ control, series }}
        form={props => {
          return (
            <LoadingComponent loading={loading}>
              <>
                {control !== undefined && series !== undefined && (
                  <Form {...props} control={control} series={series} />
                )}
              </>
            </LoadingComponent>
          );
        }}
        initialValues={() => {
          if (item) {
            return { ...item, load: item.load || undefined };
          }

          return item!;
        }}
        onSubmit={onSubmit}
        onClosed={() => {
          modalFetchRef.current?.open();
        }}
      />
      <ModalForm
        ref={modalFetchRef}
        type="put"
        size="sm"
        title="Buscar CP"
        buttonLabel="Buscar CP"
        buttonSaveLabel="Buscar"
        data={true}
        form={props => <FetchForm {...props} />}
        initialValues={() => {
          return {};
        }}
        onSubmit={onSubmitFetch}
      />
    </>
  );
};

export default TestingBody;
