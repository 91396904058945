import React, { useState } from "react";
import { Container } from "src/components/Container";
import {
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  CardBody,
  Card
} from "reactstrap";
import Profile from "./Profile";
import classnames from "classnames";
import UsageList from "./Usage";
import { useUser } from "src/context/admin/user";
import { useParams } from "react-router-dom";

interface TabProps {
  children: string;
  selected?: boolean;
  onSelect: () => void;
}

const Tab: React.FC<TabProps> = ({ children, selected, onSelect }) => (
  <NavItem>
    <NavLink
      className={classnames({
        active: selected
      })}
      href="#"
      onClick={onSelect}
    >
      {children}
    </NavLink>
  </NavItem>
);

const Settings = () => {
  const [pane, setPane] = useState<string>("profile");
  const { appSlug } = useParams<{ appSlug: string }>();
  const [user] = useUser();

  const panes = [
    {
      id: "profile",
      label: "Profile",
      pane: <Profile />,
      show: true
    },
    {
      id: "usage",
      label: "Uso e faturamento",
      pane: <UsageList />,
      show:
        user.data?.applications[appSlug].permissions.can_view_invoices || false
    }
  ];

  return (
    <Container title="Settings">
      <Card>
        <CardBody>
          <Row>
            <Col sm={3}>
              <h4 className="mt-3 text-center">{`${user.data?.first_name} ${user.data?.last_name}`}</h4>
              <p className="text-muted text-center">{`${user.data?.email}`}</p>
              <Nav pills className="flex-column me-3">
                {panes
                  .filter(item => item.show)
                  .map(item => (
                    <Tab
                      selected={pane === item.id}
                      onSelect={() => setPane(item.id)}
                    >
                      {item.label}
                    </Tab>
                  ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <TabContent activeTab={pane}>
                {panes.map(item => (
                  <TabPane tabId={item.id} selected={pane === item.id}>
                    {item.pane}
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Settings;
