/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Form from "./Form";
import { ModalForm } from "../../../../../components/Modal";
import {
  postTrace,
  getTrace,
  putTrace
} from "../../../../../services/api/graute";
import moment from "moment";
import { FormikActions } from "formik";
import { v4 as uuidv4 } from "uuid";

interface EditProps extends EditType<TGrauteTrace> {
  control: TGrauteControl;
  showModal?: boolean;
}

const EditTrace: React.FC<EditProps> = ({
  item,
  control,
  showModal,
  updatedItem
}) => {
  const modalRef: React.RefObject<ModalForm> = React.createRef();

  useEffect(() => {
    if (showModal) {
      modalRef.current?.open();
    }
  }, [showModal]);

  const onSubmit = (values: any) => {
    values.start_hour = moment(`${values.trace_date}T${values.start_hour}`)
      .utc()
      .toISOString();
    values.end_hour = moment(`${values.trace_date}T${values.end_hour}`)
      .utc()
      .toISOString();

    return putTrace(values.id, values)
      .then(({ data }) => {
        updatedItem(data);

        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return (
    <ModalForm
      ref={modalRef}
      type="put"
      size="lg"
      title="Editar traço"
      buttonLabel="Editar traço"
      data={item}
      getItemRequest={id => getTrace(id)}
      form={props => {
        return <Form {...props} control={control} />;
      }}
      initialValues={item => {
        if (item !== undefined) {
          return {
            ...item,
            trace_date: moment(item.trace_date).format("YYYY-MM-DD"),
            expiration_date: moment(item.expiration_date).format("YYYY-MM-DD"),
            start_hour: moment(item.start_hour).format("HH:mm"),
            end_hour: moment(item.end_hour).format("HH:mm")
          };
        }
      }}
      onSubmit={onSubmit}
    />
  );
};

interface InsertProps extends InsertType<TGrauteTrace> {
  control: TGrauteControl;
}

const InsertTrace: React.FC<InsertProps> = ({ control, insertedItem }) => {
  const onSubmit = (values: any, { setFieldError }: FormikActions<any>) => {
    values.start_hour = moment(`${values.trace_date}T${values.start_hour}`)
      .utc()
      .toISOString();
    values.end_hour = moment(`${values.trace_date}T${values.end_hour}`)
      .utc()
      .toISOString();

    values["control"] = control.id;
    values["uuid"] = uuidv4();

    return postTrace(control.id!, values)
      .then(({ data }) => {
        insertedItem(data);

        return Promise.resolve(data);
      })
      .catch(err => {
        setFieldError("serie_number", "Número de série já cadastrado");
        return Promise.reject(err);
      });
  };

  return (
    <ModalForm
      type="post"
      size="lg"
      title="Cadastro de novo traço"
      buttonLabel="Novo traço"
      data={{}}
      form={props => {
        return <Form {...props} control={control} />;
      }}
      initialValues={() => {
        return {};
      }}
      onSubmit={onSubmit}
    />
  );
};

export { InsertTrace, EditTrace };
