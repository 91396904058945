import React from "react";

const InputSelectMultiple = ({
  field,
  name,
  required = false,
  disabled = false,
  children,
  ...props
}) => (
  <select
    className="form-control"
    multiple
    name={name}
    type="select"
    disabled={disabled}
    required={required}
    field={field}
    {...props}
  >
    {children}
  </select>
);

export default InputSelectMultiple;
