import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { InputColumn } from "src/components/Inputs";
import { SelectEquipment } from "src/components/Selects";

import { useData } from "src/components/Table";
import { Edit, Insert } from "./Form";
import { ListButtonRow, ButtonDelete } from "src/components/Buttons";
import { deleteEquipments } from "src/services/api/tools";

const Equipments: React.FC<Props> = () => {
  const item = useData<Equipment>();
  const [selectedId, setSelectedId] = useState<number>();

  const onDelete = () => {
    if (selectedId) {
      return deleteEquipments(selectedId)
        .then(({ data }) => {
          item.deleted.set(item.selected.item);

          return Promise.resolve(data);
        })
        .catch(({ data }) => {
          return Promise.reject(data);
        });
    }

    return Promise.reject();
  };

  return (
    <Row>
      <Col sm={4}>
        <InputColumn label="Equipamento">
          <Row>
            <Col>
              <SelectEquipment
                onChange={value => {
                  item.selected.set(value);
                  setSelectedId(value);
                }}
                updatedItem={item.updated.item}
                deletedItem={item.deleted.item}
                newItem={item.created.item}
              />
            </Col>
          </Row>
        </InputColumn>
        <Row>
          <Col>
            <ListButtonRow
              buttonsLeft={
                <>
                  <Insert insertedItem={item.created.set} />
                  <Edit
                    item={item.selected.item!}
                    updatedItem={item.updated.set}
                  />
                </>
              }
              buttonsRight={
                <ButtonDelete
                  disabled={!item.selected.item}
                  onClick={onDelete}
                />
              }
              marginTop="mt-2"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Equipments;
