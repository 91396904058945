/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Strainer } from "./Strainer";
import { HeaderTitle } from "../../../../../../components/Headers";
import { useBGS } from "../context";

interface Props {
  name: string;
  values: any;
  bands: { gap: number; percentage: number[] }[];
  totalWeight: number;
  setFieldValue: (field: string, value: any) => void;
}

const StrainersTest: React.FC<Props> = ({
  name,
  values,
  totalWeight,
  setFieldValue
}) => {
  const [bgs, bgsActions] = useBGS();

  useEffect(() => {
    bgsActions.setPercentagePassing(values, totalWeight);
  }, [values, totalWeight]);

  return (
    <>
      <Row className="text-center">
        <Col sm={4} className="pl-1 pr-1">
          <HeaderTitle title="Peneiras" />
        </Col>
        <Col className="pl-1 pr-1">
          <HeaderTitle title="Peso material retido" />
        </Col>
        <Col className="pl-1 pr-1">
          <HeaderTitle title="% do Material Retido" />
        </Col>
        <Col className="pl-1 pr-1">
          <HeaderTitle title="% Retido acumulado" />
        </Col>
        <Col className="pl-1 pr-1">
          <HeaderTitle title="% Passante Total" />
        </Col>
        <Col className="pl-1 pr-1">
          <HeaderTitle title="% Mistura projetada" />
        </Col>
      </Row>
      {values.map((item: any, index: number) => (
        <>
          <Strainer
            key={index}
            name={`${name}[${index}].strainer`}
            inches={item.strainer.inches}
            gap={item.strainer.gap}
            withheldPercentage={bgs.percentages[index]?.withheld}
            accruedPercentage={bgs.percentages[index]?.accrued}
            passingPercentage={bgs.percentages[index]?.percentage}
            setFieldValue={setFieldValue}
          />
        </>
      ))}
    </>
  );
};

export default StrainersTest;
