const DEFECTS: { [key: string]: string } = {
  def_segregation: "Segregação",
  def_bicheiras: "Bicheiras",
  def_color_change: "Mudança de cor",
  def_chopped: "Desbastados",
  def_diameter: "Diâmetro",
  def_verticality: "Verticalidade",
  def_others: "Outros"
};

const RUPTURE_FORM: { [key: string]: string } = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  F: "F",
  N: "Nenhuma"
};

export { DEFECTS, RUPTURE_FORM };
