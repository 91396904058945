/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "src/components/Loader";
import { fastToken } from "src/services/api/core";
import {
  insituHolesReport,
  sampleFullReport,
  sampleSummaryReport
} from "src/services/api/report";
import * as Sentry from "@sentry/react";
import { loadTempReportData } from "src/services/storage";
import { AxiosResponse } from "axios";

const ReportRequest = () => {
  const [message, setMessage] = useState<string>(
    "Seu relatório está sendo gerado e abrirá em instantes"
  );
  const [reportFail, setReportFail] = useState<boolean>(false);
  let { reportHash } = useParams<any>();

  useEffect(() => {
    requestReport();
  }, []);

  const requestReport = async () => {
    let callReport: null | Promise<AxiosResponse<any>> = null;
    const report = loadTempReportData(reportHash);
    try {
      fastToken().then(({ data }) => {
        if (report) {
          if (report.type === "insitu") {
            callReport = insituHolesReport(
              data.access,
              report.params,
              report.data
            );
          } else if (report.type === "sample_summary") {
            callReport = sampleSummaryReport(
              data.access,
              report.params,
              report.data
            );
          } else if (report.type === "sample_full") {
            callReport = sampleFullReport(
              data.access,
              report.params,
              report.data
            );
          }
        } else {
          setReportFail(true);
          setMessage("Endereço de relatório invalido, tente novamente.");
        }

        if (callReport !== null) {
          callReport
            .then(({ data }) => {
              window.open(data.file, "_self");
            })
            .catch(e => {
              const scope = new Sentry.Scope();
              scope.setExtra("hash", reportHash);
              scope.setExtra("reportData", report);
              Sentry.captureException(e, scope);
              setMessage(
                "Um erro inesperado ocorreu. entre em contato com o responsável"
              );
              setReportFail(true);
            });
        }
      });
    } catch (e) {}
  };

  return (
    <div className="ex1">
      <div>
        <img
          src="https://gm6hq.s3.us-east-2.amazonaws.com/gm6/logo.png"
          alt=""
        />
        <div>
          <h2 className="txt">{message}</h2>
          {!reportFail ? (
            <Spinner />
          ) : (
            <i className="mdi mdi-alert mdi-48px icon-alert"></i>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportRequest;
