import React from "react";
import { Field } from "formik";
import { Col } from "reactstrap";
import { toLocateNumber } from "../../../../../../utils/format";
import { InputDecimal, InputRow } from "../../../../../../components/Inputs";
import { useBGS } from "../context";

interface Props {
  name: string;
  gap: number;
  inches: string;
  withheldPercentage: number;
  accruedPercentage: number;
  passingPercentage: number;
  disabled?: boolean;
  setFieldValue: (name: string, value: any) => void;
}

export const Strainer: React.FC<Props> = ({
  name,
  gap,
  inches,
  withheldPercentage,
  accruedPercentage,
  passingPercentage,
  disabled,
  setFieldValue
}) => {
  const [, bgsActions] = useBGS();
  return (
    <div className="form-group row align-items-center text-center">
      <Col sm={2} className="pl-1 pr-1">
        <InputRow className="text-center" label={inches} />
      </Col>
      <Col sm={2} className="pl-1 pr-1">
        <InputRow className="text-center" label={`${gap}`} />
      </Col>
      <Col className="pl-1 pr-1">
        <Field
          component={InputDecimal}
          name={`${name}.withheld_weight`}
          required
          onChange={(value: number) => {
            setFieldValue(`${name}.withheld_weight`, value);
          }}
          disabled={disabled}
          readonly={disabled}
        />
      </Col>
      <Col className="pl-1 pr-1">
        <InputRow
          className="text-center"
          label={toLocateNumber(withheldPercentage * 100, 2)}
        />
      </Col>
      <Col className="pl-1 pr-1">
        <InputRow
          className="text-center"
          label={toLocateNumber(accruedPercentage * 100, 2)}
        />
      </Col>
      <Col className="pl-1 pr-1">
        <InputRow
          className="text-center"
          label={toLocateNumber(passingPercentage * 100, 2)}
        />
      </Col>
      <Col className="pl-1 pr-1">
        <Field
          component={InputDecimal}
          name={`${name}.projected`}
          required
          onChange={(value: number) => {
            bgsActions.setProject(value, gap);
            setFieldValue(`${name}.projected`, value);
          }}
          disabled={disabled}
          readonly={disabled}
        />
      </Col>
    </div>
  );
};
