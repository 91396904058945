import React from "react";
import Select from "react-select";
import * as api from "../../services/api/admin";
import { Loader } from "../Loader";

class SelectMultipleSubDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems() {
    api.getSubDepartments().then(({ data }) => {
      this.setState({ items: data });
    });
  }

  options() {
    return this.state.items
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(item => ({ value: item.id, label: item.name }));
  }

  render() {
    return (
      <>
        {this.state.items.length !== 0 ? (
          <Select
            options={this.options()}
            placeholder="Sub departamentos..."
            isMulti
            styles={{
              container: () => ({
                width: "100%",
                padding: "0"
              })
            }}
            onChange={this.props.onChange}
          />
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default SelectMultipleSubDepartment;
