import React from "react";
import { Field } from "formik";

interface Props {
  id: string;
  name: string;
  checked?: boolean;
  label: string;
  value: any;
}

const InputRadio: React.FC<Props> = ({ id, name, checked, label, value }) => (
  <div className="form-group mb-0">
    <div className="custom-control custom-checkbox">
      <Field
        className="custom-control-input"
        id={id}
        name={name}
        type="radio"
        checked={checked}
        value={value}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  </div>
);

InputRadio.defaultProps = {
  checked: false
};

export default InputRadio;
