import api from "./api";

const getControl = (contractId: number) => {
  const endpoint = `/concretes/controls/${contractId}/`;

  return api.get(endpoint);
};

const getControlById = (controlId: number) => {
  const endpoint = `/concretes/${controlId}/`;

  return api.get(endpoint);
};

const postControl = (contractId: number, data: any) => {
  const endpoint = `/concretes/controls/${contractId}/`;

  return api.post(endpoint, data);
};

const putControl = (controlId: number, data: any) => {
  const endpoint = `/concretes/${controlId}/`;

  return api.put(endpoint, data);
};

const getCPsToBreak = (startDate: string, endDate: string) => {
  const endpoint = `/concretes/cps/?start_date=${startDate}&end_date=${endDate}&breaked=0&fields=id,code,rupture_date,age,load,fck,height,diameter,weight,work,serie,control`;

  return api.get(endpoint);
};

const getCP = (id: number) => {
  const endpoint = `/concretes/series/cps/${id}/`;

  return api.get(endpoint);
};

const getCPs = (seriesId: number, fields: string) => {
  const endpoint = `/concretes/series/${seriesId}/cps/?fields=${fields}`;

  return api.get(endpoint);
};

const searchCPs = (code: number) => {
  const endpoint = `/concretes/series/cps/?code=${code}`;

  return api.get(endpoint).then((res) => {
    return res;
  });
};

const updateCP = (id: number, data: any) => {
  const endpoint = `/concretes/series/cps/${id}/`;

  return api.put(endpoint, data);
};

const postSeries = (id: number, data: any) => {
  const endpoint = `/concretes/${id}/series/`;

  return api.post(endpoint, data);
};

const putSeries = (id: number, data: any) => {
  const endpoint = `/concretes/series/${id}/`;

  return api.put(endpoint, data);
};

const deleteSeries = (id: number) => {
  const endpoint = `/concretes/series/${id}/`;

  return api.delete(endpoint);
};

const getSeries = (id: number) => {
  const endpoint = `/concretes/series/${id}/`;

  return api.get(endpoint);
};

const getAllSeries = (
  controlId: number,
  fields: string,
  page: number,
  pageSize: number,
) => {
  const endpoint = `/concretes/${controlId}/series_new/?fields=${fields}&page=${page}&page_size=${pageSize}`;

  return api.get(endpoint);
};

const getLastSeries = (controlId: number) => {
  const endpoint = `/concretes/${controlId}/series/?amount=1&order_by=desc`;

  return api.get(endpoint).then(({ data }) => {
    return data[0] || {};
  });
};

const getDistinctPlaces = (contractId: number) => {
  const endpoint = `/concretes/controls/${contractId}/places/`;

  return api.get(endpoint);
};

const getConcreteSummary = (
  contractId: number,
  startDate: string = "",
  endDate: string = "",
  useTestedDate: "1" | "0",
) => {
  const endpoint =
    `/concretes/summary/${contractId}/` +
    `?start=${startDate}` +
    `&real_tested_date=${useTestedDate}` +
    `&end=${endDate}`;
  return api.get(endpoint);
};

export {
  getControl,
  getControlById,
  postControl,
  putControl,
  getCPsToBreak,
  getCP,
  getCPs,
  searchCPs,
  updateCP,
  postSeries,
  putSeries,
  deleteSeries,
  getSeries,
  getAllSeries,
  getLastSeries,
  getDistinctPlaces,
  getConcreteSummary,
};
