import { BANDS } from "./strainers";

const initialValues = {
  capsule_studies: [],
  strainers: BANDS.A.map(item => {
    return {
      id: undefined,
      strainer: {
        id: undefined,
        withheld_weight: undefined,
        gap: item.gap,
        project: undefined,
        inches: item.inches
      },
      is_bgs: true
    };
  }),
  review: 0,
  notes: "",
  execution_date: "",
  wet: false,
  total_gross_weight: undefined,
  partial_gross_weight: undefined,
  total_wet_weight: undefined,
  partial_wet_weight: undefined,
  total_weight: undefined,
  band: "A",
  is_bgs: true,
  sample: undefined,
  executor: undefined,
  total_capsule: undefined,
  partial_capsule: undefined
};

export default initialValues;
