import React, { useEffect, useState } from "react";
import { Formik, FormikActions } from "formik";
import Form from "./Form";
import * as api from "../../../../../services/api/characterization";
import { useBGS } from "./context";
import initialValues from "./init";

interface GranulometryProps {
  sample: SoilSample;
  hasUpdated: (sampleId: number) => void;
}

const GranulometryBGS: React.FC<GranulometryProps> = ({
  sample,
  hasUpdated
}) => {
  const [bgs, bgsActions] = useBGS();
  const [test, setTest] = useState<Granulometry>();

  useEffect(() => {
    bgsActions.getGranulometry(sample.id!).then(setTest);
  }, [bgsActions, sample.id]);

  const onSubmit = (values: any, actions: FormikActions<Granulometry>) => {
    if (values.id === undefined) {
      values = {
        ...values,
        sample: sample.id
      };

      api
        .postGranulometry(sample.id!, values)
        .then(({ data }: { data: Granulometry }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      api
        .putGranulometry(values.id, values)
        .then(({ data }: { data: Granulometry }) => {
          setTest(data);
          hasUpdated(sample.id!);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  return (
    <>
      {bgs.loading ? (
        <div>carregando....</div>
      ) : (
        <Formik
          onSubmit={onSubmit}
          // @ts-ignore
          initialValues={test || initialValues}
          enableReinitialize
          render={(props: any) => <Form {...props} />}
        />
      )}
    </>
  );
};

export default GranulometryBGS;
