import React from "react";
import { Field, FieldProps } from "formik";
import MaskedInput from "react-text-mask";

const mask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

interface Props {
  name: string;
  required?: boolean;
  disabled?: boolean;
}
const InputPhone: React.FC<Props> = ({ name, required, disabled }) => (
  <Field
    name={name}
    render={({ field, form: { setFieldValue } }: FieldProps<any>) => (
      <MaskedInput
        className="form-control form-control-sm"
        {...field}
        disabled={disabled}
        required={required}
        mask={mask}
        onChange={event => {
          let { value } = event.target;
          value = value.replace("(", "");
          value = value.replace(") ", "");
          value = value.replace("-", "");
          value = value.replace(/[_]+/g, "");

          setFieldValue(name, value);
        }}
      />
    )}
  />
);

InputPhone.defaultProps = {
  required: false,
  disabled: false
};

export default InputPhone;
